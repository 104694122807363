
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Storage } from '@/services/auth/storage';
import { ROLES, TOAST_INSTANCE } from '@/utils/constants';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import OrderCart from '@/components/orders/cart/OrderCart.vue';
import OrderDetailsModal from '@/components/orders/modals/OrderDetailsModal.vue';
import OrderGuestDetails from '@/components/orders/modals/GuestDetailsModal.vue';
import OrderItemViewer from '@/components/orders/item-viewer/OrderItemViewer.vue';
import OrderMenus from '@/components/orders/menu/OrderMenus.vue';
import OrderSubmittedModal from '@/components/orders/modals/OrderSubmittedModal.vue';
import PinScreen from '@/components/orders/menu/PinScreen.vue';
import StandardModal from '@/components/shared/suites/StandardModal.vue';

const namespace: string = 'orders';

@Component<Orders>({
	components: {
		FullPageSpinner,
		OrderCart,
		OrderDetailsModal,
		OrderGuestDetails,
		OrderItemViewer,
		OrderMenus,
		OrderSubmittedModal,
		PinScreen,
		StandardModal
	}
})
export default class Orders extends Vue {
	@Action('fetchAndSetOrderInfo', { namespace }) fetchAndSetOrderInfo!: (orderId: string) => void;
	@Action('setRestaurantCharges', { namespace }) setRestaurantCharges!: (restaurant: Restaurant) => void;
	@Action('setModifyingOrder', { namespace }) setModifyingOrder!: (isModifying: boolean) => void;
	@Action('setOrderDetails', { namespace }) setOrderDetails!: (orderDetails: OrderDetails) => void;
	@Action('resetOrderState', { namespace }) resetOrderState!: () => void;
	@Action('checkIfMemberAndApplyPromo', { namespace: 'orders' }) private checkIfMemberAndApplyPromo!: ({ identifier, menus } : { identifier: string; menus: Menu[] }) => void;
	@Action('fetchPublishedMenus', { namespace: 'menus' }) fetchPublishedMenus!: () => void;
	@Getter('getOrderType', { namespace }) orderType!: string;
	@Getter('getOrderDetails', { namespace }) orderDetails!: OrderDetails;
	@Getter('getSelectedItem', { namespace }) selectedItem!: MenuItem;
	@Getter('getModifyingOrder', { namespace }) modifyingOrder!: string;
	@Getter('getOrderValidationErrors', { namespace }) orderValidationErrors!: string[];
	@Getter('getTableInfoFetchedByApp', { namespace }) isTableInfoFetchedByApp!: boolean;
	@Getter('getRestaurant', { namespace: 'auth' }) restaurant!: Restaurant;
	@Getter('getRoleLevel', { namespace: 'auth' }) roleLevel!: number;
	@Getter('getPublishedMenus', { namespace: 'menus' }) publishedMenus!: Menu[];

	private storage: Storage = new Storage();
	private orderDetailsModalType: string = '';
	private showGuestDetailsModal: boolean = false;
	private showWarningModal: boolean = false;
	private showSubmittedModal: boolean = false;
	private cartOpen: boolean = false;
	private showPinScreen: boolean = false;
	private isDataFetching: boolean = false;
	private tableInfoFetchedByApp: boolean = false;
	private pinTimer: number | null = null;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;

	/**
	 * Fetch the published menus, set the restaurant charges and set the mode
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			this.setModifyingOrder(!this.$route.path.includes('create'));
			await this.fetchPublishedMenus();
			this.setRestaurantCharges(this.restaurant);

			if (this.modifyingOrder) {
				await this.fetchAndSetOrderInfo(this.$route.params.order_id);
				window.addEventListener('beforeunload', this.handleBeforeUnload);

				if (this.orderValidationErrors && Object.keys(this.orderValidationErrors).length > 0) {
					this.showWarningModal = true;
				}
			}
			else {
				this.orderDetailsModalType = 'create';
				if(window.B2BApp?.getTableNum()) {
					this.ingestAppTableData();
				}
			}

			if(window.B2BApp?.getMemberInfo()) {
				const memberInfo: CheckoutMemberInfo = JSON.parse(window.B2BApp.getMemberInfo());
				this.checkIfMemberAndApplyPromo({ identifier: memberInfo.identifier, menus: this.publishedMenus });
			}
			this.handlePinScreen();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('shared.error_fetching_menus', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	private beforeRouteLeave(to: any, from: any, next: any): void {
		this.resetOrderState();
		next();
	}

	private beforeDestroy(): void {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		window.removeEventListener('click', () => this.pinTimer = null);
		window.removeEventListener('touchend', () => this.pinTimer = null);
		window.removeEventListener('scroll', () => this.pinTimer = null);
	}

	private get isUserFromAPP(): boolean {
		return !!window.B2BApp;
	}

	/**
	 * Get the options for the order type select in the order details modal
	 *
	 * @return { { text: string, value: string }[] }
	 */
	private get orderTypeOptions(): { text: string, value: string }[] {
		const hasDineIn = this.publishedMenus.some((menu: Menu) => menu.dine_in);
		const hasTakeOut = this.publishedMenus.some((menu: Menu) => menu.on_demand || menu.scheduled);
		// const hasCatering = this.restaurant.catering && this.publishedMenus.some((menu: Menu) => menu.on_demand || menu.scheduled);

		// BOWLERO ONLY - Bowlero have lane reservation which is essentially the same as dine-in but with a different name and sales type
		const isBowleroGroup = this.restaurant.group === 'bowlero';
		const hasLaneRes = isBowleroGroup && hasDineIn;

		return [
			hasDineIn && { text: isBowleroGroup ? this.$t('order_management.parent_card.header.dine_in_retail_label') : this.$t('order_management.parent_card.header.dine_in_label'), value: 'dine-in' },
			hasLaneRes && { text: this.$t('order_management.parent_card.header.lane_res_label') as string, value: 'lane-res' },
			hasTakeOut && { text: this.$t('order_management.parent_card.header.takeout_label') as string, value: 'take-out' }
			// Hiding catering order type for creating orders for now
			// hasCatering && { text: this.$t('order_management.parent_card.header.generic_catering_label') as string, value: 'generic-catering' }
		].filter((option: false | { text: string, value: string }) => option) as { text: string, value: string }[];
	}

	/**
	 * Filter the menus based on the order type and if they have items or sections
	 *
	 * @return {Menu[]}
	 */
	private get filteredMenus(): Menu[] {
		return this.publishedMenus.filter((menu: Menu) => {
			// Check if the menu has items or sections
			if ((menu.items?.length || menu.sections?.length) && (menu.access_level === 'unrestricted' || menu.access_level === 'staff')) {
				// Check if the menu is available for the selected order type
				if (this.orderType === 'dine-in') {
					return menu.dine_in;
				}
				else if (this.orderType === 'take-out' || this.orderType === 'generic-catering') {
					return menu.on_demand || menu.scheduled;
				}
				else {
					return true;
				}
			}
			return false;
		});
	}

	/**
	 * If the user is coming from the app and we are getting table info, we need to fill out what we have
	 * so the user can skip the order details modal and go straight to the ordering.
	 *
	 * @return {void}
	 */
	private ingestAppTableData(): void {
		this.setOrderDetails({
			takeoutType: '',
			type: 'dine-in',
			tableNum: window.B2BApp.getTableNum(),
			tableLocation: window.B2BApp.getTableLocation(),
			tableArea: window.B2BApp.getTableArea(),
			checkNum: window.B2BApp.getCheckNum(),
			menuManagerLaneReservation: window.B2BApp.getOrderType?.() === 'lane_reservation' || undefined
		});
		this.tableInfoFetchedByApp = true;
		this.handleDetailsModalConfirm();
	}

	/**
	 * Delete the current order by resetting the order state and setting the modal type to create,
	 * so that the order details modal button text is correct
	 * We don't actually delete anything because the order is not saved until the user submits it
	 *
	 * @return {void}
	 */
	private handleDeleteOrder(): void {
		this.resetOrderState();
		this.orderDetailsModalType = 'create';
	}

	/**
	 * Hide guest details modal and show submitted modal after order is submitted
	 *
	 * @return {void}
	 */
	private handleOrderSubmitted(): void {
		this.showGuestDetailsModal = false;
		this.showSubmittedModal = true;
	}

	/**
	 * Handle the beforeunload event to show a warning if the user tries to leave the page
	 * This is to prevent the user from losing their order if they accidentally navigate away or refresh the page
	 * This only triggers if the user has filled out the order details (i.e. the order has been updated)
	 *
	 * @param {BeforeUnloadEvent} e
	 * @return {void}
	 */
	private handleBeforeUnload(e: BeforeUnloadEvent): void {
		// Do not show this prompt if on the app (for now)
		if(window.B2BApp) {
			return;
		}
		e.preventDefault();
		e.returnValue = '';
	}

	/**
	 * Hide submitted modal and show order details modal with create type
	 *
	 * @return {void}
	 */
	private handleNewOrder(): void {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		this.showSubmittedModal = false;
		this.orderDetailsModalType = 'create';
	}

	/**
	 * Handle the confirm event from the order details modal
	 * Adds an event listener to show a warning if the user tries to leave the page
	 *
	 * @return {void}
	 */
	private handleDetailsModalConfirm(): void {
		this.orderDetailsModalType = '';
		window.addEventListener('beforeunload', this.handleBeforeUnload);
	}

	/**
	 * If the user is a staff and has pins in Auth0, add a timer to show the pin screen after 60 seconds of inactivity
	 * Also add event listeners to reset the timer when the user interacts with the page
	 *
	 * @return {void}
	 */
	private handlePinScreen(): void {
		const hasPins = localStorage.getItem('hasPins') === 'true';
		if (hasPins && this.roleLevel === this.roles.staff) {
			// If the pin screen was open when the user left the page, show it again
			// This prevents the user from refreshing the page to get around the pin screen
			if (localStorage.getItem('pinEntered') === 'false') {
				this.displayPinScreen();
			}
			else {
				this.resetPinTimer();
			}
			window.addEventListener('click', this.resetPinTimer);
			window.addEventListener('touchend',this.resetPinTimer);
			window.addEventListener('scroll', this.resetPinTimer);
		}
	}

	/**
	 * Display the pin screen, set pinEntered to false in localStorage and clear the pin timer
	 *
	 * @return {void}
	 */
	private displayPinScreen(): void {
		if(!window.B2BApp) {
			this.showPinScreen = true;
		}
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		this.storage.setUniversal('pinEntered', 'false');
		if (this.pinTimer) {
			clearTimeout(this.pinTimer);
		}
	}

	/**
	 * Reset the pin timer to show the pin screen after 60 seconds of inactivity
	 *
	 * @return {void}
	 */
	private resetPinTimer(): void {
		if (this.pinTimer) {
			clearTimeout(this.pinTimer);
		}
		this.pinTimer = setTimeout(this.displayPinScreen, 60000);
	}

	/**
	 * Handle the pin-validated event from the pin screen
	 *
	 * @return {void}
	 */
	private handlePinValidated(): void {
		this.showPinScreen = false;
		this.storage.setUniversal('pinEntered', 'true');
		window.addEventListener('beforeunload', this.handleBeforeUnload);
		this.resetPinTimer();
	}
}
