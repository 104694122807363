import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AuthState } from '../types';

export const mutations: MutationTree<AuthState> = {

	SET_TENANT_ID(state: AuthState, tenantId: string) {
		Vue.set(state, 'tenantId', tenantId);
	},

	SET_AXIOS_INSTANCE(state: AuthState, axiosInst: string) {
		Vue.set(state, 'axiosInst', axiosInst);
	},

	SET_RESTAURANT(state, restaurant: Restaurant) {
		Vue.set(state, 'restaurant', restaurant);
	},

	SET_RESTAURANTS(state: AuthState, restaurants: string) {
		Vue.set(state, 'restaurants', JSON.parse(restaurants));
	},

	/**
	 * Set the current role assigned to the user in its app_metadata.
	 * For backwards compatibility purposes, if the role is empty we
	 * will give them manager access. Unsurprisingly Auth0 does not have
	 * a bulk API edit for users. Prior locations had the enterprise privileges
	 * on their account without the actual role.
	 *
	 * @param {Role} role
	 */
	SET_ROLE(state, role: Role) {
		if(role) {
			Vue.set(state, 'role', role);
		}
		else {
			Vue.set(state, 'role', 'manager');
		}
	},

	SET_ACCESS_TOKEN(state: AuthState, accessToken: string) {
		Vue.set(state, 'accessToken', accessToken);
	},

	SET_LAST_LOGIN(state: AuthState, lastLogin: string) {
		Vue.set(state, 'lastLogin', lastLogin);
	},

	SET_ID_TOKEN(state: AuthState, idToken: string) {
		Vue.set(state, 'idToken', idToken);
	},

	SET_EXPIRES_AT(state: AuthState, expiresAt: string) {
		Vue.set(state, 'expiresAt', expiresAt);
	},

	SET_AUTHENTICATED(state: AuthState, authenticated: boolean) {
		Vue.set(state, 'authenticated', authenticated);
	},

	SET_POS_INTEGRATED(state: AuthState) {
		Vue.set(state, 'posIntegrated', state.restaurant.pos_integrated);
	},

	SET_STAFF_EMAIL(state: AuthState, email: string) {
		Vue.set(state, 'staffEmail', email);
	},

	SET_EMPLOYEE_ID(state: AuthState, id: string) {
		Vue.set(state, 'employeeId', id);
	}
};
