import { GetterTree } from 'vuex';
import { EventSuiteState, RootState } from '../types';

export const getters: GetterTree<EventSuiteState, RootState> = {
	getEventSuites(state): EventSuite[] {
		return state.eventSuites;
	},

	getEventSuite(state) {
		return (id: number): EventSuite => {
			return state.eventSuites.find((eventSuite: EventSuite) => eventSuite.id === id)!;
		};
	},

	getSelectedEventSuite(state): EventSuite | null {
		return state.selectedEventSuite || null;
	}
};