
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { isEqual } from 'lodash';
import { NavigationGuardNext, Route } from 'vue-router';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent, decodeSuiteOwners } from '@/utils/decoding';
import EventSuiteListItem from '@/components/suites/events/EventSuiteListItem.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';

@Component<SuiteAssignments>({
	components: {
		EventSuiteListItem,
		SuitesHeader,
		TextButton,
		WarningModal,
		FullPageSpinner
	}
})
export default class SuiteAssignments extends Vue {
	@Action('fetchEvent', { namespace: 'events' }) private fetchEvent!: (eventId: string | number) => Function;
	@Action('bulkUpdateEventSuites', { namespace: 'eventSuites' }) private bulkUpdateEventSuites!: (payload: { eventSuites: EventSuite[]; eventId: number; }) => Function;
	@Action('deleteEventSuite', { namespace: 'eventSuites' }) private deleteEventSuite!: (payload: { id: number; eventId: number; }) => Function;
	@Action('fetchSuiteOwners', { namespace: 'suiteOwners' }) private fetchSuiteOwners!: () => Function;
	@Getter('getSelectedEvent', { namespace: 'events' }) private event!: SuitesEvent;
	@Getter('getMenus', { namespace: 'menus' }) private menus!: Menu[];
	@Getter('getSuiteOwners', { namespace: 'suiteOwners' }) private suiteOwners!: SuiteOwner[];

	private isDataFetching = false;
	private mutableEvent: SuitesEvent | null = null;
	private decodedSuiteOwners: SuiteOwner[] = [];
	private warningModalProps: WarningModalProps = {
		open: false,
		loading: false,
		confirmButtonText: this.$t('suite_assignments.warning_modal.continue_btn'),
		cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
		onClose: this.handleModalClose
	};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get breadcrumbsArray(): Breadcrumb[]{
		if (!this.mutableEvent) {
			return [];
		}
		return [{
			text: `${this.mutableEvent.title} (${Vue.filter('formatDate')(this.event.start_date)})`,
			to: `/events/${this.event.id}`,
			exact: true
		},
		{
			text: this.$t('suite_assignments.header'),
			to: `/events/${this.event.id}/suites`,
			exact: true
		}];
	}

	// An array of suites that have been updated
	private get updatedSuites(): EventSuite[] {
		return this.mutableEvent?.event_suites.filter((suite, index) => {
			return !isEqual(suite, this.event.event_suites[index]);
		}) || [];
	}

	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			if (!this.event || Number(this.$route.params.event_id) !== this.event.id) {
				await this.fetchEvent(this.$route.params.event_id);
			}
			!this.suiteOwners.length && await this.fetchSuiteOwners();
		}
		catch (error) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_fetching', { errorMessage: error });
		}
		finally {
			if (this.event) {
				this.mutableEvent = decodeEvent(this.event);
			}
			if (this.suiteOwners.length) {
				this.decodedSuiteOwners = decodeSuiteOwners(this.suiteOwners);
			}
			this.isDataFetching = false;
		}
	}

	// Check if event has been updated before leaving route and open warning modal if changes haven't been saved
	private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
		if (this.eventHasUpdated()) {
			this.warningModalProps = {
				...this.warningModalProps,
				open: true,
				title: this.$t('suite_settings.unsaved_changes'),
				text: this.$t('suite_assignments.warning_modal.skip_assignments_text'),
				confirmButtonText: this.$t('suite_assignments.warning_modal.save_continue_btn'),
				cancelButtonText: this.$t('suite_assignments.warning_modal.discard_btn'),
				onConfirm: () => this.handleSave(to),
				onCancel: () => this.handleLeave(to)
			};
		}
		else {
			next();
		}
	}

	/**
	 * Checks if the event has been updated
	 *
	 * @return {boolean}
	 */
	private eventHasUpdated(): boolean {
		return !isEqual(decodeEvent(this.event), this.mutableEvent);
	}

	/**
	 * Check if event has been updated before leaving route and open warning modal if changes haven't been saved
	 *
	 * @return {void}
	 */
	private onSkipClick(): void {
		if (this.eventHasUpdated()) {
			this.warningModalProps = {
				...this.warningModalProps,
				open: true,
				title: this.$t('suite_assignments.warning_modal.skip_assignments_title'),
				text: this.$t('suite_assignments.warning_modal.skip_assignments_text'),
				confirmButtonText: this.$t('suite_assignments.warning_modal.continue_btn'),
				cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
				onConfirm: () => this.handleLeave({ path: `/events/${this.event.id}` } as Route),
				onCancel: this.handleModalClose
			};
		}
		else {
			this.$router.push(`/events/${this.event.id}`);
		}
	}

	/**
	 * Handle changes to suite owner assignment from onSuiteOwnerChange event from event suite list item
	 *
	 * @param {number} payload - The payload from onSuiteOwnerChange event, contains suite owner id and event suite id
	 * @returns {void}
	 */
	private handleNewSuiteOwner({ suiteOwnerId, eventSuiteId }: { suiteOwnerId: number; eventSuiteId: number;}): void {
		if (this.mutableEvent) {
			const eventSuiteIndex = this.mutableEvent.event_suites.findIndex(eventSuite => eventSuite.id === eventSuiteId);
			if (eventSuiteIndex > -1) {
				this.decodedSuiteOwners = decodeSuiteOwners(this.suiteOwners);
				this.mutableEvent.event_suites[eventSuiteIndex].suite_owner_id = suiteOwnerId;
			}
		}
	}

	/**
	 * Handle changes to suite owner assignment from onMenuChange event from event suite list item
	 *
	 * @param {Object} payload - The payload from onMenusChange event, contains update menus and event suite id
	 * @returns {void}
	 */
	private handleMenusChange({ menus, eventSuiteId }: { menus: EventSuiteMenu[]; eventSuiteId: number; }) {
		if (this.mutableEvent) {
			const eventSuiteIndex = this.mutableEvent.event_suites.findIndex(eventSuite => eventSuite.id === eventSuiteId);
			if (eventSuiteIndex > -1) {
				this.mutableEvent.event_suites[eventSuiteIndex].menus = menus;
			}
		}
	}

	/**
	 * Handle saving changes to event, update event and navigate to event details page
	 *
	 * @param {Route} to
	 * @returns {Promise<void>}
	 */
	private async handleSave(to?: Route): Promise<void> {
		if (this.mutableEvent && this.eventHasUpdated()) {
			try {
				to ? this.warningModalProps.loading = true : this.isDataFetching = true;
				await this.bulkUpdateEventSuites({ eventSuites: this.updatedSuites, eventId: this.mutableEvent.id });
				// Updated event gets set when eventSuites so mutableEvent should be the same as the updated event so that beforeRouteLeave nav guard doesn't detect changes
				this.mutableEvent = decodeEvent(this.event);
				setTimeout(() => {
					this.$router.push(to ? to.path : { name: 'EventDetails', params: { event_id: this.event.id.toString() } });
				}, 500);
			}
			catch (error) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('suite_assignments.error_saving', { errorMessage: error });
			}
			finally {
				setTimeout(() => {
					this.warningModalProps.loading = false;
					this.isDataFetching = false;
				}, 500);
			}
		}
		else {
			this.$router.push({ name: 'EventDetails', params: { event_id: this.event.id.toString() } });
		}
	}

	/**
	 * Handle deleting an event suite, update mutableEvent object and close modal
	 *
	 * @returns {Promise<void> }
	 */
	private async handleDelete(eventSuiteId: number): Promise<void> {
		try {
			if (eventSuiteId) {
				await this.deleteEventSuite({ id: eventSuiteId, eventId: this.event.id });
				this.warningModalProps.open = false;

				// Remove deleted event suite from updated event as well.
				// Delete is a change that doesn't need to be saved, and shouldn't be detected as an unsaved change
				// Can't just make mutableEvent equal to event because other unsaved changes (i.e. suite owner) will be overwritten

				if (this.mutableEvent) {
					const eventSuitesIndexToRemove = this.mutableEvent.event_suites.findIndex((eventSuite: EventSuite) => eventSuite.id === eventSuiteId);
					this.mutableEvent.event_suites.splice(eventSuitesIndexToRemove, 1);
					this.mutableEvent.updated_at = this.event.updated_at;
				}
			}
		}
		catch (error) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_deleting', { errorMessage: error });
		}
	}

	/** Handle leaving the page without saving changes
	 *
	 * @param {Route} to The route to navigate to
	 * @returns {void}
	 */
	private handleLeave(to: Route): void {
		this.warningModalProps.open = false;
		// Revert changes to updated event so that beforeLeaveRoute nav guard doesn't detect changes
		this.mutableEvent = decodeEvent(this.event);
		this.$router.push(to.path);
	}

	private handleModalClose(): void {
		this.warningModalProps.open = false;
	}

	/** Set warning modal props for delete event suite modal and open modal
	 *
	 * @param {number} eventSuiteId The event suite id
	 * @returns {void}
	 */
	private openDeleteModal(eventSuiteId: number): void {
		this.warningModalProps = {
			...this.warningModalProps,
			open: true,
			title: this.$t('suite_assignments.warning_modal.delete_suite_title'),
			text: this.$t('suite_assignments.warning_modal.delete_suite_text'),
			confirmButtonText: this.$t('suite_assignments.warning_modal.remove_btn'),
			onConfirm: () => this.handleDelete(eventSuiteId),
			onCancel: this.handleModalClose
		};
	}
}
