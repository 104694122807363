
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE, ROLES } from '../utils/constants';
import { initTempAvailability } from '../utils/availability';
import { cloneDeep, isEqual, isNil } from 'lodash';
import omitBy from 'lodash/omitBy';
import TitleHeader from '@/components/shared/Header.vue';
import ListItem from '@/components/shared/ListItem.vue';
import AddButton from '@/components/shared/AddButton.vue';
import ModalWrapper from '@/components/shared/ModalWrapper.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import MenuEditor from '@/components/menu/MenuEditor.vue';
import Search from '@/components/shared/Search.vue';
const namespace: string = 'menus';

@Component<MenuList>({
	components: {
		TitleHeader,
		ListItem,
		AddButton,
		ModalWrapper,
		ConfirmationModal,
		PageLoader,
		MenuEditor,
		BannerToast,
		Search
	}
})
export default class MenuList extends Vue {
	@Action('searchContent', { namespace: 'search' }) private searchContent!: (searchString: string | null) => Promise<void>;
	@Action('fetchMenus', { namespace }) private fetchMenus!: () => Promise<Menu[]>;
	@Action('deleteMenu', { namespace }) private deleteMenu!: (menu: Menu) => Promise<void>;
	@Action('updateMenu', { namespace }) private updateMenu!: (payload: object) => Promise<void>;
	@Action('duplicateMenu', { namespace }) private duplicateMenu!: (menu: Menu) => Promise<void>;
	@Action('setSelectedMenu', { namespace }) private setSelectedMenu!: (menu: Menu | object) => Promise<void>;
	@Action('fetchEvents', { namespace: 'events' }) private fetchEvents!: () => Promise<void>;
	@Action('setSelectedEvent', { namespace: 'events' }) private setSelectedEvent!: (event: SuitesEvent|null) => void;
	@Getter('getSelectedMenu', { namespace }) private selectedMenu!: Menu;
	@Getter('getMenus', { namespace }) private menusData!: Menu[];
	@Getter('getFetchMenusComplete', { namespace }) private fetchMenusComplete!: boolean;
	@Getter('areSuitesEnabled', { namespace: 'auth' }) private areSuitesEnabled!: boolean;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace: 'auth' }) private isPosIntegrated!: boolean;
	@Prop({ type: Object, default: () => {} }) private restaurant!: Restaurant;

	private showDialog: boolean = false;
	private isCreating: boolean = false;
	private showDeleteModal: boolean = false;
	private loading: boolean = false;
	private showSearchDialog: boolean = false;
	private showConfirmationModal: string|null = '';
	private selectedLocale: RestaurantLocale|null = null;

	private itemToDelete: Menu = {} as Menu;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;
	private selectedMenuBeforeEditSnapshot: Menu = {} as Menu;
	private menuToCopy: Menu = {} as Menu;

	private actionsArray: ActionItem[] = [
		{
			iconName: 'mdi-archive-arrow-down',
			buttonLabel: this.$t('shared.actions_array.btn_archive'),
			onClick: async (evt: object, menu: Menu): Promise<void> => {
				await this.updateMenu({ menu: { ...menu, published: false } })
					.catch((errorMessage: string) => {
						this.bannerToastInfo.showMessage = true;
						this.bannerToastInfo.message = this.$t('shared.error_archiving', { name: menu.name, errorMessage });
					});
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-archive-arrow-up',
			buttonLabel: this.$t('shared.actions_array.btn_publish'),
			onClick: async (evt: object, menu: Menu): Promise<void> => {
				this.publishAttempt(menu);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-pencil',
			buttonLabel: this.$t('shared.actions_array.btn_edit'),
			onClick: async (evt: object, menu: Menu): Promise<void> => {
				await this.setSelectedMenu(menu)
					.then(() => this.openMenuEditor(false))
					.catch((errorMessage: string) => {
						this.bannerToastInfo.showMessage = true;
						this.bannerToastInfo.message = this.$t('shared.error_updating', { name: menu.name, errorMessage });
					});
			}
		},
		{
			iconName: 'mdi-file-multiple',
			buttonLabel: this.$t('shared.actions_array.btn_duplicate'),
			onClick: async (evt: object, menu: Menu): Promise<void> => {
				await this.duplicateMenu(menu)
					.catch((errorMessage: string) => {
						this.bannerToastInfo.showMessage = true;
						this.bannerToastInfo.message = this.$t('shared.error_duplicating', { name: menu.name, errorMessage });
					});
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-delete',
			buttonLabel: this.$t('shared.actions_array.btn_delete'),
			onClick: (evt: object, menu: Menu): void => {
				this.setItemToDeleteName(menu);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-window-close',
			buttonLabel: this.$t('shared.actions_array.btn_cancel'),
			onClick: null
		}
	];

	private get menus(): Menu[] {
		return (this.menusData || []).map(
			(menu: Menu): Menu => {
				return { to: { name: menu.allow_sections ? 'Sections' : 'MenuItems', params: { menu_id: menu.id } }, ...menu };
			}
		);
	}

	private get publishedMenus(): Menu[] {
		return this.menus.filter(menu => menu.published_at);
	}

	private get unpublishedMenus(): Menu[] {
		return this.menus.filter(menu => !menu.published_at);
	}

	/**
	 * Attempt to publish the menu. We throw a warning if the menu has children
	 * to warn the user that all of its content will be published.
	 *
	 * @param {Menu} menu
	 * @return {void}
	 */
	private publishAttempt(menu: Menu): void {
		this.menuToCopy = menu;
		if((menu.sections && menu.sections.length) || (menu.items && menu.items.length)) {
			this.showConfirmationModal = 'confirmPublish';
		}
		else {
			this.publish();
		}
	}

	/**
	 * Publish the menu
	 *
	 * @return {void}
	 */
	private async publish(): Promise<void> {
		if(this.menuToCopy) {
			await this.updateMenu({ menu: { ...this.menuToCopy, published: true } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_publishing', { name: this.menuToCopy.name, errorMessage });
				});
			this.showConfirmationModal = null;
		}
	}

	/**
	 * Open Menu Modal
	 *
	 * @param {boolean} creating
	 * @return {void}
	 */
	private openMenuEditor(creating: boolean): void {
		this.showDialog = true;
		this.isCreating = creating;

		if (this.isCreating) {
			// Setup the snapshot to match the initial values of the selectedMenu
			this.selectedMenuBeforeEditSnapshot.allow_sections = true;
			this.selectedMenuBeforeEditSnapshot.dine_in = false;
			this.selectedMenuBeforeEditSnapshot.price = '';
			this.selectedMenuBeforeEditSnapshot.availability = initTempAvailability();
		}
		else {
			this.selectedMenuBeforeEditSnapshot = cloneDeep(this.selectedMenu);
		}

		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Set the item name for the deletion modal
	 *
	 * @return {void}
	 * @param menu
	 */
	private setItemToDeleteName(menu: Menu): void {
		this.showDeleteModal = true;
		this.itemToDelete = menu;
	}

	/**
	 * Cancel closing of modal through the confirmation close modal
	 *
	 * @return {void}
	 */
	private cancelCloseModal(): void {
		this.showConfirmationModal = null;
	}

	/**
	 * Check if needs to show the confirm close modal to the user
	 * We need to get rid of the undefined properties first.
	 *
	 * @return {void}
	 */
	private confirmClose(saved: boolean = false) {
		const selectedMenuToCompare = omitBy(this.selectedMenu, isNil);
		const selectedMenuBeforeEditSnapshotToCompare = omitBy(this.selectedMenuBeforeEditSnapshot, isNil);
		this.selectedLocale = null;
		if (saved || isEqual(selectedMenuToCompare, selectedMenuBeforeEditSnapshotToCompare)) {
			this.close(saved);
		}
		else {
			this.showConfirmationModal = 'confirmClose';
		}
	}

	/**
	 * Close the Menu Editor modal
	 *
	 * @return {void}
	 */
	private async close(saved: boolean): Promise<void> {
		this.showConfirmationModal = null;
		this.showDialog = false;
		this.isCreating = false;

		// Remove the selected menu from the store
		this.setSelectedMenu({});
		this.selectedMenuBeforeEditSnapshot = {} as Menu;
		document.documentElement.classList.remove('modal-open');

		// Re-fetch the menus if the modal was simply closed
		if (!saved) {
			await this.fetchMenus()
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.error_fetching', { errorMessage });
				});
		}
	}

	/**
	 * Close the search modal
	 *
	 * @return {void}
	 */
	private closeSearchDialog(): void {
		this.searchContent(null);
		this.showSearchDialog = false;
	}

	private cancel(): void {
		this.showDeleteModal = false;
		this.itemToDelete = {} as Menu;
	}

	/**
	 * User confirmed deletion of the item, so we delete it and close the modal.
	 * If the user is able to access suites/events, we refetch them in case a menu assigned to an event suite was deleted.
	 *
	 * @return {Promise<void>}
	 */
	private async confirm(): Promise<void> {
		this.loading = true;
		await this.deleteMenu(this.itemToDelete)
			.then(async () => {
				if (this.areSuitesEnabled && this.roleLevel <= this.roles.manager) {
					await this.fetchEvents();
					this.setSelectedEvent(null);
				}
			})
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: this.itemToDelete.name, errorMessage });
			});
		this.itemToDelete = {} as Menu;
		this.loading = false;
		this.showDeleteModal = false;
	}
}
