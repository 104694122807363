import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Index from './views/Index.vue';
import PageNotFound from './views/PageNotFound.vue';

// Auth
import Login from './views/auth/Login.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
	{
		path: '/',
		name: 'Home',
		component: Index,
		redirect: 'menus',
		children: [
			{
				path: 'locations',
				name: 'Restaurants',
				component: () => import(/* webpackChunkName: "restaurants" */ './views/Restaurants.vue')
			},

			// ================================================================
			// Menus
			// ================================================================
			{
				path: 'menus',
				name: 'Menus',
				component: () => import(/* webpackChunkName: "menus" */ './views/Menus.vue')
			},
			{
				path: '/menus/:menu_id/items',
				name: 'MenuItems',
				component: () => import(/* webpackChunkName: "menu-items" */ './views/Items.vue'),
				props: route => {
					return { menu_id: parseInt(route.params.menu_id) };
				}
			},
			{
				path: '/menus/:menu_id/sections',
				name: 'Sections',
				component: () => import(/* webpackChunkName: "sections" */ './views/Sections.vue'),
				props: route => {
					return { menu_id: parseInt(route.params.menu_id) };
				}
			},
			{
				path: '/menus/:menu_id/sections/:section_id/items',
				name: 'SectionItems',
				component: () => import(/* webpackChunkName: "section-items" */ './views/Items.vue'),
				props: route => {
					return { menu_id: parseInt(route.params.menu_id), section_id: parseInt(route.params.section_id) };
				}
			},
			{
				path: '/add-ons',
				name: 'Add-Ons',
				component: () => import(/* webpackChunkName: "add-ons" */ './views/AddOns.vue')
			},
			{
				path: '/availabilities',
				name: 'Availabilities',
				component: () => import(/* webpackChunkName: "availability" */ './views/Availabilities.vue')
			},
			{
				path: '/replicate-menus',
				name: 'ReplicateMenus',
				component: () => import(/* webpackChunkName: "replicate-menus" */ './views/ReplicateMenus.vue')
			},
			{
				path: '/settings',
				name: 'Settings',
				component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue')
			},
			{
				path: '/customize',
				name: 'Customize',
				component: () => import(/* webpackChunkName: "customize" */ './views/Customize.vue')
			},

			// ================================================================
			// Order management
			// ================================================================
			{
				path: '/order-management',
				name: 'OrderManagement',
				component: () => import(/* webpackChunkName: "order-management" */ './views/OrderManagement.vue')
			},
			{
				path: '/order-management/create',
				name: 'CreateOrder',
				component: () => import(/* webpackChunkName: "create-order" */ './views/Orders.vue')
			},
			{
				path: '/order-management/:order_id',
				name: 'EditOrder',
				component: () => import(/* webpackChunkName: "edit-order" */ './views/Orders.vue')
			},

			// ================================================================
			// Advanced
			// ================================================================
			{
				path: '/advanced',
				name: 'Advanced',
				component: () => import(/* webpackChunkName: "advanced" */ './views/Advanced.vue')
			},
			{
				path: '/advanced/menu-groups',
				name: 'MenuGroups',
				component: () => import(/* webpackChunkName: "menu-groups" */ './views/MenuGroups.vue')
			},

			// ================================================================
			// Events
			// ================================================================
			{
				path: '/events',
				name: 'Events',
				component: () => import(/* webpackChunkName: "events" */ './views/suites/Events.vue')
			},
			{
				path: '/events/all',
				name: 'AllEvents',
				component: () => import(/* webpackChunkName: "all-events" */ './views/suites/AllEvents.vue')
			},
			{
				path: '/events/create',
				name: 'CreateEvent',
				component: () => import(/* webpackChunkName: "create-event" */ './views/suites/CreateEvent.vue')
			},
			{
				path: '/events/:event_id',
				name: 'EventDetails',
				component: () => import(/* webpackChunkName: "events" */ './views/suites/EventDetails.vue')
			},
			{
				path: '/events/:event_id/suites',
				name: 'SuiteAssignments',
				component: () => import(/* webpackChunkName: "suite-assignments" */ './views/suites/SuiteAssignments.vue')
			},
			{
				path: '/events/:event_id/suites/:suite_id',
				name: 'SuiteSettings',
				component: () => import(/* webpackChunkName: "suite-assignments" */ './views/suites/SuiteSettings.vue')
			},
			{
				path: '/events/:event_id/suites/:suite_id/beo',
				name: 'Beo',
				component: () => import(/* webpackChunkName: "beo" */ './views/suites/Beo.vue')
			},
			{
				path: '/events/:event_id/production-sheet',
				name: 'ProductionSheet',
				component: () => import(/* webpackChunkName: "production-sheet" */ './views/suites/ProductionSheet.vue')
			},
			{
				path: '/events/:event_id/edit',
				name: 'EventDetailsEdit',
				component: () => import(/* webpackChunkName: "events" */ './views/suites/EditEvent.vue')
			},
			{
				path: '/event-settings',
				name: 'EventSettings',
				component: () => import(/* webpackChunkName: "event-settings" */ './views/suites/EventSettings.vue')
			},
			{
				path: '/event-settings/suite-owners',
				name: 'SuiteOwners',
				component: () => import(/* webpackChunkName: "suite-owners" */ './views/suites/SuiteOwners.vue')
			},
			{
				path: '/event-settings/event-types',
				name: 'EventTypes',
				component: () => import(/* webpackChunkName: "event-types" */ './views/suites/EventTypes.vue')
			},
			{
				path: '/orders-report',
				name: 'OrdersReport',
				component: () => import(/* webpackChunkName: "orders-report" */ './views/OrdersReport.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/login/callback',
		name: 'LoginCallback',
		component: () => import(/* webpackChunkName: "login-callback" */ './views/auth/LoginCallback.vue')
	},
	{
		path: '*',
		component: PageNotFound
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
