
import { Component, Prop, Vue } from 'vue-property-decorator';
import StatusLabel from '@/components/shared/suites/StatusLabel.vue';
import StatusIcon from '@/components/shared/suites/StatusIcon.vue';

@Component<EventCard>({
	components: {
		StatusLabel,
		StatusIcon
	}
})
export default class EventCard extends Vue {
	@Prop({ type: Object, required: true }) private event!: SuitesEvent;

	// Counts and groups event suite
	private get suiteStatuses(): SuiteStatus[] {
		if (!this.event.event_suites.length) {
			return [];
		}
		return this.event.event_suites.reduce((acc: SuiteStatus[], eventSuite: EventSuite) => {
			if (eventSuite.status === 'email_scheduled' || eventSuite.status === 'email_sent') {
				return acc;
			}
			if (acc.findIndex((statuses) => eventSuite.status === statuses.status) === -1) {
				acc.push({
					quantity: 1,
					status: eventSuite.status
				});
			}
			else {
				acc[acc.findIndex((statuses) => eventSuite.status === statuses.status)].quantity++;
			}
			return acc;
		}, []);
	}
}
