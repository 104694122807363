
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { getOrderType, getOrderScheduledDate, getOrderTitle } from '@/utils/getters';
import OrderCard from '@/components/order-management/OrderCard.vue';

@Component<ParentOrderCard>({
	components: {
		OrderCard
	}
})
export default class ParentOrderCard extends Vue {
	@Getter('isMarketplaceEnabled', { namespace: 'auth' }) private isMarketplaceEnabled!: Boolean;
	@Prop({ type: [Array, Object], default: () => [] }) private orders!: Order[] | Order;
	@Watch('orders', { deep: true, immediate: true })
	private onOrdersChanged(): void {
		if (this.isMarketplaceEnabled) {
			this.tempOrder = (this.orders as Order[])?.length ? (this.orders as Order[])[0] : undefined;
		}
		else {
			this.tempOrder = this.orders as Order;
		}
	}

	private tempOrder: Order | undefined = {} as Order;

	private get parentOrderType(): ParentOrderType {
		return getOrderType(this.tempOrder);
	}

	private get orderTitle(): string {
		return getOrderTitle(this.tempOrder);
	}

	private get scheduledDate(): string | undefined {
		return getOrderScheduledDate(this.tempOrder);
	}

	private showModal(order: Order): void {
		this.$emit('show-modal', order);
	}
}
