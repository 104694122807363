
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<OrdersTable>({})
export default class OrdersTable extends Vue {
	@Prop({ type: Array, required: true }) orderItems!: OrderItem[];
	@Prop({ type: Object, required: true }) orderCosts!: OrderCosts;
	@Prop({ type: Boolean, required: true }) includePrices!: boolean;

	private get hasGSTandPST(): boolean {
		return this.orderCosts.taxInfo.some(tax => tax.name === 'GST') && this.orderCosts.taxInfo.some(tax => tax.name === 'PST');
	}

	/**
	 * Get the formatted GST or PST tax value
	 *
	 * @param {string} taxName
	 * @return {string | null}
	 */
	private getTaxValue(taxName: 'GST' | 'PST'): string | null {
		const tax: OrderTaxInfo|undefined = this.orderCosts.taxInfo.find((tax) => tax.name === taxName);
		return tax ? tax.value.toFixed(2) : null;
	}
}
