
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';

const namespace: string = 'suites';

@Component<SuitesListModal>({
	components: {
		StandardModal,
		TextButton,
		TableHeaders
	}
})
export default class SuitesListModal extends Vue {
	@Action('addSuite', { namespace }) private addSuite!: (suite: { name: string }) => Promise<void>;
	@Action('updateSuite', { namespace }) private updateSuite!: (suite: Suite) => Promise<void>;
	@Action('deleteSuite', { namespace }) private deleteSuite!: (suiteId: number) => Promise<void>;
	@Getter('getSuites', { namespace }) private suitesList!: Suite[];
	@Prop({ type: Boolean, required: true, default: '' }) private showModal!: boolean;
	// TODO: Replace watcher by v-if in the component (see change history modal for example)
	@Watch('showModal', { immediate: true })
	onShowModalUpdate(isModalShown: boolean) {
		isModalShown && this.resetMutableSuites();
	}

	private isLoading: boolean = false;
	private mutableSuites: EditableSuite[] = [];
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('event_settings.suites.table_header') }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get isAnyEditing(): boolean {
		return this.mutableSuites.some(suite => suite.isEditing === true);
	}

	/**
	 * Set the mutable suites to the suites in the state
	 *
	 * @return {void}
	 */
	private resetMutableSuites(): void {
		this.mutableSuites = this.suitesList.map(suite => {
			return { ...suite, isEditing: false };
		});
	}

	/**
	 * Add empty row to the table and focus on the input
	 *
	 * @return {void}
	 */
	private addRow(): void {
		this.mutableSuites.push({
			name: '',
			isEditing: true,
			qr_code: null
		});
		this.$nextTick(() => (this.$refs.suiteNameInput as HTMLElement[])[0].focus());
	}

	/**
	 * Show input to edit suite's name and focus on the input
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private openEditInput(index: number): void {
		this.mutableSuites = this.mutableSuites.map((suite, suiteIndex) => {
			return {
				...suite, isEditing: suiteIndex === index
			};
		});
		this.$nextTick(() => (this.$refs.suiteNameInput as HTMLElement[])[0].focus());
	}

	/**
	 * Create or update suite depending if the suite has an id or not
	 * Prevent from submitting if no suite name
	 * Prevent api call if suite name is still the same
	 *
	 * @param {EditableSuite} suite
	 * @return {Promise<void>}
	 */
	private async saveSuite(suite: EditableSuite): Promise<void> {
		if (!suite.name) {
			return;
		}

		const savedSuite = this.suitesList.find(savedSuite => savedSuite.id === suite.id);
		if (savedSuite && savedSuite.name === suite.name) {
			return this.resetMutableSuites();
		}

		this.isLoading = true;
		try {
			if (suite.id) {
				await this.updateSuite({ id: suite.id, name: suite.name, qr_code: suite.qr_code });
			}
			else {
				await this.addSuite({ name: suite.name });
			}
			this.resetMutableSuites();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = suite.id
				? this.$t('shared.error_updating', { name: suite.name, errorMessage })
				: this.$t('shared.error_creating', { name: suite.name, errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Delete suite if suite id,
	 * Otherwise just remove the last item of mutableSuites array
	 *
	 * @param {EditableSuite} suite
	 * @return {Promise<void>}
	 */
	private async removeSuite(suite: EditableSuite): Promise<void> {
		if (suite.id) {
			this.isLoading = true;
			try {
				await this.deleteSuite(suite.id);
				this.resetMutableSuites();
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: suite.name, errorMessage });
			}
			finally {
				setTimeout(() => {
					this.isLoading = false;
				}, 500);
			}
		}
		else {
			this.mutableSuites.pop();
		}
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
