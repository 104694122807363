import { ActionTree } from 'vuex';
// import { state } from './search';
import { SearchState, RootState } from '../types';
import { handleAllErrors } from '../../utils/errorHandling';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';

export const actions: ActionTree<SearchState, RootState> = {

	/**
	 * Post to the search endpoint and gather the values
	 *
	 * @return {Promise<void>}
	 */
	async searchContent({ commit }, searchString): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			if (searchString) {
				const searchItems: SearchItem[] = (await axiosInst.post('/menus/search', { term: searchString })).data;
				if (searchItems && searchItems.length) {

					// Sort them by published so they display in order -> published, unpublished
					searchItems.sort((a: any, b: any) => b.published - a.published);
					const menus: SearchItem[] = [],
						sections: SearchItem[] = [],
						items: SearchItem[] = [];

					// Populate the different arrays
					searchItems.forEach(searchItem => {
						if (searchItem.type === 'menu') {
							searchItem.to = { name: 'Menus' };
							menus.push(searchItem);
						}
						else if (searchItem.type === 'menu item'){
							searchItem.to = { name: 'MenuItems', params: { menu_id: searchItem.menu_id } };
							items.push(searchItem);
						}
						else if (searchItem.type === 'section') {
							searchItem.to = { name: 'Sections', params: { menu_id: searchItem.menu_id } };
							sections.push(searchItem);
						}
						else {
							searchItem.to = { name: 'SectionItems', params: { menu_id: searchItem.menu_id, section_id: searchItem.section_id } };
							items.push(searchItem);
						}
					});

					commit('SET_SEARCH_ITEMS', { menus, sections, items });
				}
				else {
					commit('SET_SEARCH_ITEMS', null);
				}
			}
			else {
				commit('SET_SEARCH_ITEMS', null);
			}
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};