
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeSuiteOwner } from '@/utils/decoding';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import '@/validation-rules';

const namespace: string = 'suiteOwners';

@Component<SuiteOwnerModal>({
	components: {
		StandardModal,
		ValidationObserver,
		ValidationProvider
	}
})
export default class SuiteOwnerModal extends Vue {
	@Action('addSuiteOwner', { namespace }) private addSuiteOwner!: (suiteOwner: EditableSuiteOwner) => Promise<SuiteOwner | void>;
	@Action('updateSuiteOwner', { namespace }) private updateSuiteOwner!: (suiteOwner: EditableSuiteOwner) => Promise<void>;
	@Getter('getSuiteOwnerById', { namespace }) private getSuiteOwner!: (ownerId: number) => SuiteOwner;
	@Prop({ type: Number, required: false }) private ownerId!: number|undefined;
	@Prop({ type: Boolean, required: true, default: '' }) private showModal!: boolean;

	$refs!: {observer: Validate};

	private isLoading: boolean = false;
	private mutableSuiteOwner: EditableSuiteOwner = {
		firstName: '',
		lastName: '',
		company_name: '',
		title: '',
		email: '',
		phone_number: ''
	};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get modalTitle(): string {
		return this.ownerId
			? this.$t('event_settings.suite_owners.modal.edit_title')
			: this.$t('event_settings.suite_owners.modal.new_title');
	}

	private get suiteOwner(): SuiteOwner | null {
		return this.ownerId ? this.getSuiteOwner(this.ownerId) : null;
	}

	/**
	 * Set mutable suite owner to the selected suite owner if applicable
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.suiteOwner) {
			this.mutableSuiteOwner = decodeSuiteOwner(this.suiteOwner);
		}
	}

	/**
	* Format the phone number - remove non-digital characters
	* and add dashes to the respective positions
	*
	* @return {void}
	*/
	private formatPhoneNumber(): void {
		this.mutableSuiteOwner.phone_number = this.mutableSuiteOwner.phone_number.replace(/\D/g,'');
		if (this.mutableSuiteOwner.phone_number) {
			this.mutableSuiteOwner.phone_number = this.mutableSuiteOwner.phone_number.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
		}
	}

	/**
	 * Check if inputs are valid before saving or editing suite owner
	 *
	 * @return {Promise<void>}
	 */
	private async submitHandler(): Promise<void> {
		const isValid = await this.$refs.observer.validate();

		if (isValid) {
			this.ownerId ? this.editSuiteOwner() : this.saveSuiteOwner();
		}
	}

	/**
	 * Create new suite owner
	 *
	 * @return {Promise<void>}
	 */
	private async saveSuiteOwner(): Promise<void> {
		this.isLoading = true;
		try {
			const newSuiteOwner = await this.addSuiteOwner(this.mutableSuiteOwner);
			this.$emit('suite-owner-created', newSuiteOwner);
			setTimeout(() => {
				this.$emit('close');
			}, 500);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_settings.suite_owners.modal.error_creating', { errorMessage });
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Edit suite owner
	 *
	 * @return {Promise<void>}
	 */
	private async editSuiteOwner(): Promise<void> {
		this.isLoading = true;
		try {
			await this.updateSuiteOwner(this.mutableSuiteOwner);
			setTimeout(() => {
				this.$emit('close');
			}, 500);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_settings.suite_owners.modal.error_updating', { errorMessage });
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
