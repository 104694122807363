
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ItemList from '@/components/ItemList.vue';

const namespace: string = 'menus';

@Component<Items>({
	components: {
		ItemList
	}
})
export default class Items extends Vue {
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Getter('getMenu', { namespace }) private getMenu!: (id: number) => Menu;
	@Getter('getSection', { namespace: 'sections' }) private getSection!: (menuId: number, sectionId: number) => MenuSection;
	@Prop({ type: Number, default: 0 }) private menu_id!: number;
	@Prop({ type: Number, default: 0 }) private section_id!: number;

	private get menu(): Menu {
		return this.getMenu(this.menu_id);
	}

	private get section(): MenuSection {
		return this.getSection(this.menu_id, this.section_id);
	}
}
