
import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardModal from '@/components/shared/suites/StandardModal.vue';

@Component<RefundRequestModal>({
	components: {
		StandardModal
	}
})
export default class RefundRequestModal extends Vue {
	@Prop({ type: Boolean, required: true }) private isLoading!: boolean;

	private refundRequestReason: string = '';

	/**
	 * Emit to request a refund with the cancellation note.
	 *
	 * @return {void}
	 */
	private requestRefund(): void {
		this.$emit('request-refund', this.refundRequestReason);
	}
}
