
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';
import Tooltip from '@/components/shared/Tooltip.vue';

@Component<GenericTableLocationsComponent>({
	components: {
		ValidationProvider,
		Tooltip
	}
})
export default class GenericTableLocationsComponent extends Vue {
	@Prop({ type: Boolean, default: false }) private targetGenericTableLocationsEnabled!: boolean;
	@Prop({ type: Object, default: () => {} }) private targetGenericTableLocations!: GenericTableLocations;

	private mutableGenericTableLocationsEnabled: boolean = this.targetGenericTableLocationsEnabled;
	private mutableGenericTableLocations: any = this.targetGenericTableLocations;

	private updateGenericTableLocations(): void {
		if (!this.mutableGenericTableLocationsEnabled) {
			Vue.set(this, 'mutableGenericTableLocations', null);
		}
		else {
			// For now we only have one type
			if(!this.mutableGenericTableLocations) {
				Vue.set(this, 'mutableGenericTableLocations', { type: 'custom', custom: [{ location: '', areas: '' }] });
			}
		}
		setTimeout(() => {
			this.$emit('update-generic-table-locations', { enabled: this.mutableGenericTableLocationsEnabled, locations: this.mutableGenericTableLocations });
		}, 100);
	}

	private removeCustomLocation(index: number): void {
		this.mutableGenericTableLocations.custom.splice(index, 1);
	}

	private addCustomLocation(): void {
		this.mutableGenericTableLocations.custom.push({ area: '', locations: '' });
	}
}
