
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { DateTime } from 'luxon';
import { TOAST_INSTANCE } from '@/utils/constants';
import { isEmpty } from 'lodash';
import '@/validation-rules';
import DateTimePicker from '@/components/shared/DateTimePicker.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import Tooltip from '@/components/shared/Tooltip.vue';

interface FormDataType {
	title: string;
	start_date: string;
	end_date: string;
	order_lock_period: number | null;
	pre_order_email_days: number | null;
	pre_order_delivery_time_setting: EventPreOrderDeliveryTimeSetting | null;
	projected_attendance: number | null;
	manager_info: EventManager | null;
	event_type_id: number | null;
	event_suites: { suite_id: number }[];
}

@Component<EventDetailsForm>({
	components: {
		InfoBanner,
		DateTimePicker,
		TextButton,
		ValidationObserver,
		ValidationProvider,
		Tooltip
	}
})
export default class EventDetailsForm extends Vue {
	@Action('fetchEventTypes', { namespace: 'eventTypes' }) private fetchEventTypes!: Function;
	@Action('fetchSuites', { namespace: 'suites' }) private fetchSuites!: Function;
	@Getter('getEventTypes', { namespace: 'eventTypes' }) private eventTypes!: EventType[];
	@Getter('getSuites', { namespace: 'suites' }) private suites!: Suite[];
	@Prop({ type: Object, required: false }) private event!: SuitesEvent;
	@Prop({ type: Boolean, required: false, default: false }) private isEditing!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private loading!: boolean;
	@Watch('selectedSuites')
	onSelectedSuiteChange() {
		this.formData.event_suites = this.formattedSuitesSelection;
	}
	@Watch('formData', { deep: true })
	onFormDataChange() {
		this.$emit('change', this.formData);
	}

	$refs!: {observer: Validate};

	private preOrderingTimeSettings: EventPreOrderDeliveryTimeSetting[] = [
		{ type: 'basic', details: { values: ['before', 'beginning', 'half_time'] } },
		{ type: 'datetime', details: { start_date: '', end_date: '' } }
	];

	private isLoading: boolean = false;
	private minStartDate: string = DateTime.local().toISODate();
	private selectedSuites: number[] = [];
	private previouslySelectedSuites: number[] = [];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private mutableManagerInfo: EventManager = {};
	private formData: FormDataType = {
		title: '',
		start_date: '',
		end_date: '',
		order_lock_period: null,
		pre_order_email_days: null,
		pre_order_delivery_time_setting: null,
		projected_attendance: null,
		manager_info: null,
		event_type_id: null,
		event_suites: this.formattedSuitesSelection
	};

	// Sets the minimum selectable date for the end datetime picker, can't be before the start date
	private get minEndDate(): string {
		if (!this.formData.start_date) {
			return this.minStartDate;
		}
		return DateTime.fromISO(this.formData.start_date).toISODate();
	}

	// Sets the maximum selectable date for the start datetime picker, can't be after the end date
	private get maxStartDate(): string | null {
		if (!this.formData.end_date) {
			return null;
		}
		return DateTime.fromISO(this.formData.end_date).toISODate();
	}

	// Sets the minimum selectable time for the end datetime picker, can't be before the start time if the start date is the same as the end date
	private get minEndTime(): string | null {
		if (!this.formData.start_date || this.maxStartDate !== this.minEndDate) {
			return null;
		}
		return DateTime.fromISO(this.formData.start_date).toLocaleString(DateTime.TIME_24_SIMPLE);
	}

	// Sets the maximum selectable time for the start datetime picker, can't be after the end time if the end date is the same as the start date
	private get maxStartTime(): string | null {
		if (!this.formData.end_date || this.maxStartDate !== this.minEndDate) {
			return null;
		}
		return DateTime.fromISO(this.formData.end_date).toLocaleString(DateTime.TIME_24_SIMPLE);
	}

	// Sets the minimum selectable date for the pre ordering start date picker, can be 3 hours less than the event start date
	private get minPreOrderingStartDate(): string | null {
		if (!this.formData.start_date) {
			return null;
		}
		return DateTime.fromISO(this.formData.start_date).minus({ hours: 3 }).toISODate();
	}

	// Sets the maximum selectable date for the pre ordering start date picker, cannot be after the end date.
	private get maxPreOrderingStartDate(): string | null {
		if (!this.formData.end_date) {
			return null;
		}
		return this.formData.pre_order_delivery_time_setting?.details.end_date
			? DateTime.fromISO(this.formData.pre_order_delivery_time_setting?.details.end_date).toLocal().toISODate()
			: DateTime.fromISO(this.formData.end_date).toLocal().toISODate();
	}

	// Sets the minimum selectable time for the pre ordering start time picker, can be 3 hours less than the event start time if same date.
	private get minPreOrderingStartTime(): string | null {
		const formDataStartDate: DateTime = DateTime.fromISO(this.formData.start_date);
		const preOrderStartDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.start_date!);

		if(!preOrderStartDate.isValid) {
			return null;
		}

		if(preOrderStartDate.startOf('day') < formDataStartDate.startOf('day')) {
			return formDataStartDate.minus({ hours: 3 }).startOf('hour').toLocaleString(DateTime.TIME_24_SIMPLE);
		}
		if (preOrderStartDate.hasSame(formDataStartDate, 'day')) {
			let minTime: DateTime = formDataStartDate.minus({ hours: 3 }).startOf('hour');

			// Ensure min time doesn't go to a previous date
			if (minTime < formDataStartDate.startOf('day')) {
				minTime = formDataStartDate.startOf('day');
			}

			return DateTime.fromISO(minTime.toISO()).toLocaleString(DateTime.TIME_24_SIMPLE);
		}

		return null;
	}

	// Sets the maximum selectable time for the pre ordering start time picker
	private get maxPreOrderingStartTime(): string | null {
		const formDataStartDate: DateTime = DateTime.fromISO(this.formData.start_date);
		const formDataEndDate: DateTime = DateTime.fromISO(this.formData.end_date);
		const preOrderingEndDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.end_date!);
		const preOrderStartDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.start_date!);

		if(!preOrderStartDate.isValid) {
			return null;
		}

		// If the date is the same as the pre-ordering end date, the max time will be the same as the pre-ordering end time
		if(preOrderingEndDate.isValid && preOrderStartDate.hasSame(preOrderingEndDate, 'day')) {
			return DateTime.fromISO(preOrderingEndDate.toISO()).toLocaleString(DateTime.TIME_24_SIMPLE);
		}

		if (preOrderStartDate.hasSame(formDataStartDate, 'day')) {
			return this.maxStartTime;
		}

		// If the date is the same as the pre-ordering end date, the max time will be the same as the pre-ordering end time
		if (preOrderStartDate.hasSame(formDataEndDate, 'day')) {
			return DateTime.fromISO(formDataEndDate.toISO()).toLocaleString(DateTime.TIME_24_SIMPLE);
		}

		return null;
	}

	// Sets the minimum selectable date for the pre ordering start date picker, needs to be after the pre ordering start date
	private get minPreOrderingEndDate(): string | null {
		if (!this.formData.pre_order_delivery_time_setting?.details.start_date) {
			return null;
		}
		return DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.start_date).toISODate();
	}

	// Sets the maximum selectable date for the pre ordering end date picker, cannot be after the end date (this will change)
	private get maxPreOrderingEndDate(): string | null {
		if (!this.formData.end_date) {
			return null;
		}
		return DateTime.fromISO(this.formData.end_date).toISODate();
	}

	// Sets the minimum selectable time for the pre ordering end time picker, can't be before the start time of the start date if the date is the same as the end date
	private get minPreOrderingEndTime(): string | null {
		const preOrderStartDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.start_date!);
		const preOrderEndDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.end_date!);

		if(!preOrderEndDate.isValid) {
			return null;
		}

		if (preOrderEndDate.hasSame(preOrderStartDate, 'day')) {
			return preOrderStartDate.toLocaleString(DateTime.TIME_24_SIMPLE);
		}

		return null;
	}

	// Sets the maximum selectable time for the pre ordering end time picker, can't be after the end time if the pre-end date is the same as the end date
	private get maxPreOrderingEndTime(): string | null {
		const formDataEndDate: DateTime = DateTime.fromISO(this.formData.end_date);
		const preOrderEndDate: DateTime = DateTime.fromISO(this.formData.pre_order_delivery_time_setting!.details.end_date!);

		if(!preOrderEndDate.isValid) {
			return null;
		}

		if(preOrderEndDate.hasSame(formDataEndDate, 'day')) {
			return formDataEndDate.toLocaleString(DateTime.TIME_24_SIMPLE);
		}

		return null;
	}

	private get formattedSuitesSelection(): { suite_id: number }[] {
		return this.selectedSuites.map((suite_id: number) => {
			return { suite_id };
		});
	}

	/**
	 * Checks if suites have been selected and if so, disables them in the dropdown
	 *
	 * @returns {Suite[]} Suites with disabled selections
	 */
	private get suitesWithDisabledSelections(): Suite[] {
		return this.suites.map((suite: Suite) => {
			if (this.previouslySelectedSuites.includes(suite.id)) {
				return {
					...suite,
					disabled: true
				};
			}
			else {
				return {
					...suite,
					disabled: false
				};
			}
		});
	}

	private async created(): Promise<void> {
		try {
			this.isLoading = true;
			!this.eventTypes.length && await this.fetchEventTypes();
			!this.suites.length && await this.fetchSuites();

			// Set initial data from event if editing
			if (this.event && this.isEditing) {
				this.formData = {
					title: this.event.title,
					start_date: this.event.start_date,
					end_date: this.event.end_date,
					order_lock_period: this.event.order_lock_period || null,
					pre_order_email_days: this.event.pre_order_email_days,
					pre_order_delivery_time_setting: this.event.pre_order_delivery_time_setting,
					projected_attendance: this.event.projected_attendance,
					manager_info: this.event.manager_info,
					event_type_id: this.event.event_type_id,
					event_suites: this.event.event_suites.map((eventSuite: EventSuite) => {
						return { suite_id: eventSuite.id };
					})
				};
				this.selectedSuites = this.event.event_suites.map((eventSuite: EventSuite) => eventSuite.suite.id);
				this.previouslySelectedSuites = [...this.selectedSuites];
				this.mutableManagerInfo = {
					name: this.event.manager_info?.name || '',
					phone_number: this.event.manager_info?.phone_number || '',
					email: this.event.manager_info?.email || ''
				};
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_fetching', { errorMessage });
		}
		finally {
			this.isLoading = false;
		}
	}

	/**
	 * Selects all suites in the dropdown
	 *
	 * @return {void}
	 */
	private selectAllSuites(): void {
		this.selectedSuites = this.suites.map((suite: Suite) => suite.id);
	}


	/**
	 * When updating the event start/end time, we want to reset the pre-ordering time fields if the
	 * selection is of datetime, this is to avoid issues where a chosen pre-order start_time does not make
	 * sense after changing the event time. This also puts focus on the fields to fill them out again so they
	 * do not forget.
	 *
	 * @return {void}
	 */
	private updatePreOrderingTimeSettings(): void {
		if (this.formData.pre_order_delivery_time_setting?.type === 'datetime') {
			// Reactivity hack to reset the pre-ordering time fields because Vue2 & objects hates each other
			this.formData.pre_order_delivery_time_setting = null;
			this.$nextTick(() => {
				this.formData.pre_order_delivery_time_setting = {
					type: 'datetime',
					details: {
						start_date: '',
						end_date: ''
					}
				};
			});
		}
	}

	/**
	 * Update pre ordering time settings - this is mainly important for
	 * time-based selection, so we can autofill the start/end time by the
	 * selected datetime above in the event details.
	 *
	 * @return {void}
	 */
	private updateTimeSettings(): void {
		if (this.formData.pre_order_delivery_time_setting?.type === 'datetime') {
			this.formData.pre_order_delivery_time_setting.details.start_date = this.formData.start_date;
			this.formData.pre_order_delivery_time_setting.details.end_date = this.formData.end_date;
		}
	}

	/**
	* Format the phone number - remove non-digital characters
	* and add dashes to the respective positions
	*
	* @return {void}
	*/
	private formatPhoneNumber(): void {
		if (this.mutableManagerInfo.phone_number) {
			this.mutableManagerInfo.phone_number = this.mutableManagerInfo.phone_number.replace(/\D/g,'');
			if (this.mutableManagerInfo.phone_number) {
				this.mutableManagerInfo.phone_number = this.mutableManagerInfo.phone_number.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
			}
		}
	}

	/**
	* Filter out the empty properties of the event manager object
	* Set the event manager to null if it's an empty object
	*
	* @return {void}
	*/
	// TODO: Find a way to trigger it on submit instead of on input (https://gitlab.com/app8menu/menu-management/-/merge_requests/130#note_1507725568)
	private updateManagerInfo(): void {
		this.formData.manager_info = { ...this.mutableManagerInfo };
		!this.formData.manager_info.name && delete this.formData.manager_info.name;
		!this.formData.manager_info.email && delete this.formData.manager_info.email;
		!this.formData.manager_info.phone_number && delete this.formData.manager_info.phone_number;
		if (isEmpty(this.formData.manager_info)) {
			this.formData.manager_info = null;
		}
	}

	public async handleSubmit(): Promise<void> {
		const isValid = await this.$refs.observer.validate();

		if (isValid) {
			this.$emit('submit', this.formData);
		}
	}
}
