
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { cloneDeep } from 'lodash';
import TextButton from '@/components/shared/suites/TextButton.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import CustomQuestionOption from '@/components/advanced/custom-questions/CustomQuestionOption.vue';

@Component<CustomQuestionCard>({
	components: {
		TextButton,
		IconButton,
		ValidationProvider,
		CustomQuestionOption,
		WarningModal
	}
})
export default class CustomQuestionCard extends Vue {
	@Prop({ type: Object, required: false }) private customQuestion!: CustomQuestion;
	@Prop({ type: Number, required: true }) private questionIndex!: number;
	@Prop({ type: Number, required: true }) private deletingIndex!: number;
	@Prop({ type: Boolean, required: true }) private isQuestionEditing!: boolean;
	@Prop({ type: Boolean, required: true }) private isSaving!: boolean;
	@Prop({ type: Boolean, required: true }) private isAnyLoading!: boolean;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;
	@Watch('selectedLocale')
	onSelectedLocaleUpdate() {
		// If default we don't need to add any localization info, as those are already in the default values
		if (!this.selectedLocale.is_default) {
			this.mutableQuestion.localization = this.mutableQuestion.localization ? this.mutableQuestion.localization : {} as Localization;
			// If no localization for that specific language, we need to initialize them
			if (!this.mutableQuestion.localization[this.selectedLocale.locale_short]) {
				this.mutableQuestion.localization[this.selectedLocale.locale_short] = {
					question: '',
					options: this.mutableQuestion.options?.map(() => '')
				};
			}
		}
	}

	private isOptionEditing: boolean = false;
	private isAddingRow: boolean = false;
	private mutableQuestion: CustomQuestion = {} as CustomQuestion;
	private showWarning: { [key: string]: boolean} = {
		invalidLocale: false,
		delete: false
	};
	private questionTypes: { [key: string]: string }[] = [
		{ text: this.$t('advanced.custom_questions.question_types.select_one'), value: 'select_one' },
		{ text: this.$t('advanced.custom_questions.question_types.checkbox'), value: 'checkbox' }
		// TODO: To uncomment when implementing these question types
		// { text: this.$t('advanced.custom_questions.question_types.select_multiple'), value: 'select_multiple' },
		// { text: this.$t('advanced.custom_questions.question_types.open'), value: 'open' },
	];

	private get disableSave(): boolean {
		return this.mutableQuestion.question_type === 'select_one'
			? this.isOptionEditing || !this.mutableQuestion.options!.length || !this.mutableQuestion.question.length
			: !this.mutableQuestion.question.length;
	}

	private get isDefaultLocale(): boolean {
		return !this.selectedLocale || !Object.keys(this.selectedLocale).length || (this.selectedLocale && this.selectedLocale.is_default);
	}

	private get formattedQuestion(): string {
		if (this.isDefaultLocale) {
			return this.customQuestion.question.length < 32 ? this.customQuestion.question : this.customQuestion.question.slice(0,29) + '...';
		}
		const localeQuestion = this.customQuestion.localization ? this.customQuestion.localization[this.selectedLocale.locale_short].question : '';
		return localeQuestion.length < 32 ? localeQuestion : localeQuestion.slice(0,29) + '...';
	}

	private created(): void {
		this.mutableQuestion = cloneDeep(this.customQuestion);
		this.mutableQuestion.isEditing = this.customQuestion.question === '';
		this.$nextTick(() => {
			if (this.customQuestion.question === '') {
				(this.$refs.customQuestion as HTMLElement[][0]).focus();
			}
		});
	}

	/**
	 * Set question edit mode and emits to enable/disable editing other questions
	 *
	 * @param {boolean} isEditing
	 * @return {void}
	 */
	private toggleEditQuestion(isEditing: boolean): void {
		this.mutableQuestion.isEditing = true;
		this.$emit('toggle-edit', isEditing);
	}

	/**
	 * Set options depending of if the question type needs options.
	 *
	 * @return {void}
	 */
	private setOptions(): void {
		this.mutableQuestion.options = this.mutableQuestion.question_type === 'select_one' ? [] : undefined;
		if (this.mutableQuestion.localization) {
			for (const locale in this.mutableQuestion.localization) {
				this.mutableQuestion.localization[locale].options = this.mutableQuestion.question_type === 'select_one' ? [] : undefined;
			}
		}
	}

	/**
	 * If localization check if the options array for the default locales and other locales are the same length.
	 *
	 * @return {boolean}
	 */
	private areOptionsSameLength(): boolean {
		if (this.mutableQuestion.localization && Object.keys(this.mutableQuestion.localization).length) {
			for (const locale in this.mutableQuestion.localization) {
				if (this.mutableQuestion.localization[locale].options?.length !== this.mutableQuestion.options?.length) {
					return false;
				}
			}
		}
		return true;
	}

	/**
	 * Add empty option to either the default locale question and set editing to true
	 *
	 * @return {void}
	 */
	private addOptionRow(): void {
		this.mutableQuestion.options!.push('');
		this.isAddingRow = true;
		this.isOptionEditing = true;
		this.$emit('toggle-option-editing', true);
	}

	/**
	 * Set option editing flag to prevent other options from being editable
	 *
	 * @param {boolean} isEditing
	 * @return {void}
	 */
	private toggleEditOption(isEditing: boolean): void {
		this.isOptionEditing = isEditing;
		this.$emit('toggle-option-editing', isEditing);
	}

	/**
	 * Save option changes and adjust for each locale if needed.
	 * If option is an empty string it deletes it.
	 *
	 * @param {string} newOption
	 * @param {number} optionIndex
	 * @return {void}
	 */
	private saveOption(newOption: string, optionIndex: number): void {
		// If empty string, we delete the option for default and each locale
		if (newOption === '') {
			this.mutableQuestion.options!.splice(optionIndex, 1);

			// If localization, we loop through each locale and remove the option
			if (this.mutableQuestion.localization && Object.keys(this.mutableQuestion.localization).length) {
				for (const locale in this.mutableQuestion.localization) {
					this.mutableQuestion.localization[locale]?.options?.splice(optionIndex, 1);
				}
			}
		}
		// If we're on a locale question, update the options specific to that locale
		else if (!this.isDefaultLocale) {
			if (this.mutableQuestion.localization && this.mutableQuestion.localization[this.selectedLocale.locale_short]) {
				this.mutableQuestion.localization[this.selectedLocale.locale_short].options[optionIndex] = newOption;
			}
		}

		// If not locale, or default locale, update the default options
		else if (this.mutableQuestion && this.mutableQuestion.options) {
			this.mutableQuestion.options[optionIndex] = newOption;

			// If we're adding a new option and there's localization, we add an emtpy string option to each locale
			if (this.mutableQuestion.localization && !this.areOptionsSameLength()) {
				for (const locale in this.mutableQuestion.localization) {
					this.mutableQuestion.localization[locale]?.options.push('');
				}
			}
		}
		this.isOptionEditing = false;
		this.isAddingRow = false;
		this.$emit('toggle-option-editing', false);
	}

	/**
	 * If editing a question, set mutable question to its initial value and undo edit mode.
	 * If creating a question, emit cancel event to remove the question from mutable questions.
	 * Emit event to set question editing to false.
	 *
	 * @return {void}
	 */
	private cancelQuestion(): void {
		if (this.customQuestion.question !== '') {
			this.mutableQuestion = cloneDeep(this.customQuestion);
			this.mutableQuestion.isEditing = false;
			this.isOptionEditing = false;
		}
		else {
			this.$emit('cancel');
		}
		this.$emit('toggle-option-editing', false);
		this.$emit('toggle-edit', false);
		this.$emit('default-locale');
	}

	/**
	 * Handler when saving a question.
	 * Check if the question in the different locales are valid in order to show a warning modal or save the question.
	 *
	 * @return {void}
	 */
	private handleSave(): void {
		if (!this.checkInvalidLocales(this.mutableQuestion, false)) {
			this.showWarning.invalidLocale = true;
		}
		else {
			this.saveQuestion();
		}
	}

	/**
	 * Check for invalid locales.
	 * If delete flag then it returns a question without invalid locales. If no flag it just returns a boolean.
	 *
	 * @param {CustomQuestion} question
	 * @param {boolean} deleteInvalid
	 * @return {boolean | CustomQuestion}
	 */
	private checkInvalidLocales(question: CustomQuestion, deleteInvalid: boolean): boolean | CustomQuestion {
		if (question.localization) {
			for (const key in question.localization) {
				const localeObj: { question: string; options: string[]; } = question.localization[key];

				if (question.question_type === 'select_one' && this.isLocaleQuestionValid(localeObj)) {
					if (!deleteInvalid) {
						return false;
					}
					delete question.localization[key];
				}
				else if (question.question_type === 'checkbox' && (!localeObj.question || !localeObj.question.trim().length)) {
					if (!deleteInvalid) {
						return false;
					}
					delete question.localization[key];
				}
			}
			if (!deleteInvalid) {
				return true;
			}
		}
		if (!deleteInvalid) {
			return true;
		}
		return question;
	}

	/**
	 * Check if the locale question (dropdown type) and options are valid.
	 *
	 * @param {{ question: string; options: string[]; }} localeQuestionObj
	 * @return {boolean}
	 */
	private isLocaleQuestionValid(localeQuestionObj: { question: string; options: string[]; }): boolean {
		return !localeQuestionObj.question || !localeQuestionObj.question.trim().length || !localeQuestionObj.options.length || localeQuestionObj.options.some(option => !option.trim().length);
	}


	/**
	 * Remove isEditing from question, emit save event and unset editing mode.
	 * Remove invalid localisations if warning flag is displayed.
	 *
	 * @return {void}
	 */
	private saveQuestion(): void {
		let question: CustomQuestion = cloneDeep(this.mutableQuestion);
		delete question.isEditing;

		if (this.showWarning.invalidLocale) {
			this.showWarning.invalidLocale = false;
			question = this.checkInvalidLocales(question, true) as CustomQuestion;
		}

		this.$emit(
			'save',
			question,
			this.questionIndex,
			() => {
				this.mutableQuestion = cloneDeep(this.customQuestion);
				this.mutableQuestion.isEditing = false;
				this.isOptionEditing = false;
				this.$emit('toggle-edit', false);
				this.$emit('toggle-option-editing', false);
				this.$emit('default-locale');
			}
		);
	}

	/**
	 * Emit delete question event and unset editing mode
	 *
	 * @return {void}
	 */
	private deleteQuestion(): void {
		if (this.showWarning.delete) {
			this.showWarning.delete = false;
		}

		this.$emit('delete', this.questionIndex, () => {
			this.mutableQuestion.isEditing = false;
			this.isOptionEditing = false;
			this.$emit('toggle-edit', false);
		});
	}
}
