import { DateTime } from 'luxon';

/**
 * Formats time with a "0" in front of its value when its lesser than 10
 *
 * @param {number} value
 * @returns {string}
 */
const formattedTime = (value: number) => {
	return value < 10 ? `0${value}` : value;
};

export const minutesToHours = (totalTimeInMinutes: number): string => {
	const hours = totalTimeInMinutes / 60;
	const rhours = Math.floor(hours);
	const minutes = (hours - rhours) * 60;
	const rminutes = Math.round(minutes);

	return `${formattedTime(rhours)}:${formattedTime(rminutes)}`;
};

/**
 * Return an Array of time separated by a particular interval.
 * All the params are expected to be in minutes.
 *
 * @param {number} interval
 * @param {number} start
 * @param {number} end
 * @returns {string[]}
 */
export function getIntervalArray (interval: number, start: number, end: number): string[] {
	let totalMinutes = start;
	const intervalArray = [minutesToHours(start)];

	while (totalMinutes < end) {
		totalMinutes += interval;
		intervalArray.push(minutesToHours(totalMinutes));
	}

	return intervalArray;
}

/**
 * Return an array of time separated by an increasing interval for the notice times;
 * @returns {string[]}
 */
export function getNoticeTimesArray (): string[] {
	let totalMinutes = 0;
	const minutesInADay = 60 * 24;

	let interval = 5;
	const noticeTimesArray = [minutesToHours(0)];

	while (totalMinutes < 14 * minutesInADay) {
		if (totalMinutes < 30) {
			interval = 5;
		}
		else if (30 <= totalMinutes && totalMinutes < 60) {
			interval = 15;
		}
		else if (60 <= totalMinutes && totalMinutes < minutesInADay) {
			interval = 30;
		}
		else if (minutesInADay <= totalMinutes) {
			interval = minutesInADay / 2;
		}
		totalMinutes += interval;
		noticeTimesArray.push(minutesToHours(totalMinutes));
	}
	return noticeTimesArray;
}

/**
 * Return an Array of time separated by a particular interval
 *
 * @param {number} interval
 * @param {'minutes' | 'hours'} unit
 * @param {boolean} [twelveHourClock]
 * @returns {string[]}
 */
export function getTimeArray (interval: number, unit: string, twelveHourClock: boolean = false, includeLastHour: boolean = false): string[] {
	// set up day to 00:00 time.
	const baseDate = DateTime.fromObject({ hour: 0 });
	let newDate = baseDate;
	const timeArray: string[] = [];

	while (baseDate.day === newDate.day) {
		let suffix: string = '';
		let hour: number = newDate.hour;

		if (twelveHourClock) {
			suffix = hour > 12 ? 'PM' : 'AM';
			hour = hour > 12
				? hour - 12
				: hour === 0 ? 12 : hour;
		}

		timeArray.push(`${formattedTime(hour)}:${formattedTime(newDate.minute)}${suffix ? ' ' + suffix : ''}`);
		newDate = newDate.plus({ [unit]: interval });
	}

	if (includeLastHour) {
		timeArray.push('24:00');
	}

	return timeArray;
}

/**
 * Get total time in minutes
 *
 * @param {string} value
 */
export function getTotalMinutes(value: string): number {
	value = value.replace('h', '');
	const [hours, minutes] = value.split(':').map((v) => Number(v));
	return (hours * 60) + minutes;
}
