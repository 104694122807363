
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import CustomQuestionCard from '@/components/advanced/custom-questions/CustomQuestionCard.vue';

const namespace = 'auth';

interface CustomQuestionLoading {
	save: boolean;
	deleteIndex: number;
}

@Component<CustomQuestionsModal>({
	components: {
		StandardModal,
		InfoBanner,
		TextButton,
		IconButton,
		CustomQuestionCard,
		ValidationProvider
	}
})
export default class CustomQuestionsModal extends Vue {
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Getter('getCustomQuestions', { namespace }) private customQuestions!: CustomQuestion[];
	@Getter('getOrderOptions', { namespace }) private orderOptions!: OrderOptions;
	@Getter('getTenantId', { namespace }) private tenantId!: string;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Prop({ type: Boolean, required: true, default: false }) private showModal!: boolean;

	private isQuestionEditing: boolean = false;
	private isOptionEditing: boolean = false;
	private mutableCustomQuestions: CustomQuestion[] = [];
	private selectedLocale: RestaurantLocale = {} as RestaurantLocale;
	private loading: CustomQuestionLoading = {
		save: false,
		deleteIndex: -1
	};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get isAnyLoading(): boolean {
		return this.loading.deleteIndex > -1 || this.loading.save;
	}

	private get isDefaultLocale(): boolean {
		return !this.selectedLocale || !Object.keys(this.selectedLocale).length || (this.selectedLocale && this.selectedLocale.is_default);
	}

	private created(): void {
		if (this.customQuestions?.length) {
			this.mutableCustomQuestions = [ ...this.customQuestions ];
			// Add id to questions created before implementing the id
			this.mutableCustomQuestions.forEach((question: CustomQuestion, index) => question.id = question.id ?? Date.now() + index);
		}

		this.setLocaleToDefault();
	}

	/**
	 * Set question editing flag to prevent other questions from being edited or deleted
	 *
	 * @param {boolean} isEditing
	 * @return {void}
	 */
	private toggleEditQuestion(isEditing: boolean): void {
		this.isQuestionEditing = isEditing;
	}

	/**
	 * Set option editing flag to prevent locale change
	 *
	 * @param {boolean} isEditing
	 * @return {void}
	 */
	private toggleOptionEditing(isEditing: boolean): void {
		this.isOptionEditing = isEditing;
	}

	/**
	 * Add a new empty question card with and set edit mode.
	 *
	 * @return {void}
	 */
	private addQuestionCard(): void {
		this.mutableCustomQuestions.push({
			id: Date.now(),
			question: '',
			options: [],
			question_type: 'select_one',
			required: false
		});

		this.isQuestionEditing = true;
	}

	/**
	 * Switch to default locale
	 *
	 * @return {void}
	 */
	private setLocaleToDefault(): void {
		if (this.restaurant.locales?.length > 1) {
			this.restaurant.locales.sort((a: RestaurantLocale, b: RestaurantLocale) => Number(b.is_default) - Number(a.is_default));
			this.selectedLocale = this.restaurant.locales.find((locale: RestaurantLocale) => locale.is_default)!;
		}
	}

	/**
	 * Build order options object and save restaurant's order options.
	 * After saving, resets editing mode and sets locale to english.
	 *
	 * @param {CustomQuestion} question
	 * @param {number} index
	 * @param {() => void)} closeEditing Callback function to close the question card
	 * @return {Promise<void>}
	 */
	private async saveQuestion(question: CustomQuestion, index: number, closeEditing: () => void): Promise<void> {
		this.mutableCustomQuestions[index] = question;
		this.loading.save = true;

		const orderOptionsCopy = {
			...this.orderOptions,
			custom_questions: this.mutableCustomQuestions
		};

		try {
			await this.updateRestaurant({
				restaurant: {
					id: this.tenantId,
					order_options: orderOptionsCopy
				}
			});
			this.$emit('save');
			setTimeout(closeEditing, 500);
			this.bannerToastInfo.color = '#00B796';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.custom_questions.save_success');
		}
		catch (errorMessage) {
			this.bannerToastInfo.color = 'primary';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.custom_questions.save_error', { errorMessage });
		}
		finally {
			setTimeout(() => this.loading.save = false, 500);
		}
	}

	/**
	 * Build order options object and delete restaurant's order options
	 *
	 * @param {number} index
	 * @param {() => void)} closeEditing Callback function to close the question card
	 * @return {Promise<void>}
	 */
	private async deleteQuestion(index: number, closeEditing: () => void): Promise<void> {
		const questionsPayload: CustomQuestion[] = [...this.mutableCustomQuestions];
		questionsPayload.splice(index, 1);
		this.loading.deleteIndex = index;

		const orderOptionsCopy = {
			...this.orderOptions,
			custom_questions: questionsPayload
		};

		try {
			await this.updateRestaurant({
				restaurant: {
					id: this.tenantId,
					order_options: orderOptionsCopy
				}
			});
			this.$emit('save');
			setTimeout(() => {
				this.mutableCustomQuestions = [ ...this.customQuestions ];
				closeEditing();
			}, 500);
			this.bannerToastInfo.color = '#00B796';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.custom_questions.delete_success');
		}
		catch (errorMessage) {
			this.bannerToastInfo.color = 'primary';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.custom_questions.delete_error', { errorMessage });
		}
		finally {
			setTimeout(() => this.loading.deleteIndex = -1, 500);
		}
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
