
import { Component, Vue } from 'vue-property-decorator';
import { TOAST_INSTANCE } from '@/utils/constants';
import BannerToast from '@/components/shared/BannerToast.vue';
import ClosedDates from '@/components/availability/ClosedDates.vue';
import MenuHours from '@/components/availability/MenuHours.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';

@Component<Availabilities>({
	components: {
		BannerToast,
		ClosedDates,
		MenuHours,
		PageLoader,
		SuitesHeader
	}
})
export default class Availabilities extends Vue {
	private loading: boolean = false;
	private tab: any = null;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private created(): void {
		this.tab = window.location.hash === '#closed-dates' ? 1 : 0;
	}

	/**
	 * Show respective toast depending if the update
	 * succeeded or failed
	 *
	 * @param {any} responseObj
	 * @return {void}
	 */
	private showToast(responseObj: any): void {
		this.bannerToastInfo.color = responseObj.color;
		this.bannerToastInfo.showMessage = true;
		this.bannerToastInfo.message = responseObj.message;

		// We wanna re-render if we copied hours to other menus, to get
		// the updated data, mimic a small spinner to prevent flashing
		if(responseObj.load) {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		}
	}

	private onTabChange(): void {
		window.location.hash = this.tab === 0 ? 'menu-hours' : 'closed-dates';
	}
}
