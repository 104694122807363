
import { Component, Vue } from 'vue-property-decorator';

@Component<SlideFadeTransition>({})
export default class SlideFadeTransition extends Vue {
	private beforeEnter(el: any) {
	  el.style.height = '0px';
	}

	private onEnter(el: any) {
		el.style.height = `${el.scrollHeight}px`;
	}
	private beforeLeave(el: any) {
		el.style.height = `${el.scrollHeight}px`;
	}

	private onLeave(el: any) {
		el.style.height = '0px';
	}
}
