import { GetterTree } from 'vuex';
import { MenuState, RootState } from '../types';

export const getters: GetterTree<MenuState, RootState> = {
	getMenuGroups(state): MenuGroup[] {
		return state.menuGroups;
	},

	getMenus(state): Menu[] {
		return state.menus;
	},

	getPublishedMenus(state): Menu[] {
		return state.publishedMenus;
	},

	getMenu(state) {
		return (id: number): Menu => {
			return state.menus.find((menu: Menu) => menu.id === id)!;
		};
	},

	getSelectedMenu(state): Menu|object {
		return state.selectedMenu;
	},

	getFetchMenusComplete(state): boolean {
		return state.fetchMenusComplete;
	},

	getFetchMenusError(state): string {
		return state.fetchMenusError;
	},

	pulledLocationName(state): string|null {
		return state.pulledLocationName;
	}
};