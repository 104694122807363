
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FileUpload from '@/components/shared/form/FileUpload.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import '@/validation-rules';

@Component<CustomBranding>({
	components: {
		ValidationObserver,
		ValidationProvider,
		FileUpload,
		Tooltip
	}
})
export default class CustomBranding extends Vue {
	@Prop({ type: String, default: '' }) private targetRestaurantName!: string;
	@Prop({ type: String, default: '' }) private targetDisplayName!: string;
	@Prop({ type: String, default: '' }) private targetImage!: string;

	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;
	private mutableDisplayName: string = this.targetDisplayName;
	private mutableImage: string = this.targetImage;
	private mutableImageFile: string | null = null;


	private created(): void {
	}

	private enterDisplayNameField(): void {
		if(!this.mutableDisplayName) {
			this.mutableDisplayName = this.targetRestaurantName;
		}
	}

	private updateBrandingInfo(): void {
		this.$emit('update-branding-info', { displayName: this.mutableDisplayName, image: this.mutableImage, imageFile: this.mutableImageFile });
	}
}
