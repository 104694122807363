import { GetterTree } from 'vuex';
import { SuiteState, RootState } from '../types';

export const getters: GetterTree<SuiteState, RootState> = {
	getSuites(state): Suite[] {
		return state.suites;
	},

	getSuite(state) {
		return (id: number): Suite => {
			return state.suites.find((suite: Suite) => suite.id === id)!;
		};
	}
};