import { isFalseyButNotZero } from '@/utils/logicValidation';

export function formatValue(value: number, defaultValue: string|null, type: string): string|number|null {
	if (!isFalseyButNotZero(value) && value >= 0) {
		return type === 'price' ? value.toFixed(2) : Math.round(value);
	}
 	else {
		return defaultValue;
	}
}

