
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvents } from '@/utils/decoding';
import EventCard from '@/components/suites/events/EventCard.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'events';

@Component<Events>({
	components: {
		EventCard,
		FullPageSpinner,
		InfoBanner,
		SuitesHeader,
		TextButton
	}
})
export default class Events extends Vue {
	@Action('fetchEvents', { namespace }) private fetchEvents!: () => Promise<void>;
	@Getter('getUpcomingEvents', { namespace }) private upcomingEvents!: SuitesEvent[];
	@Getter('getPastEvents', { namespace }) private pastEvents!: SuitesEvent[];

	private decodedUpcomingEvents: SuitesEvent[] = [];
	private decodedPastEvents: SuitesEvent[] = [];
	private isDataFetching: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			if (!this.upcomingEvents.length || !this.pastEvents.length) {
				await this.fetchEvents();
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('events.error_fetching', { errorMessage });
		}
		finally {
			if (this.upcomingEvents.length) {
				this.decodedUpcomingEvents = decodeEvents(this.upcomingEvents);
			}
			if (this.pastEvents.length) {
				this.decodedPastEvents = decodeEvents(this.pastEvents);
			}
			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}
}
