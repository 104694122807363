
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getOrderScheduledDate, getOrderDelivery } from '@/utils/getters';
import { decodeOrder } from '@/utils/decoding';

@Component<OrderInfo>({})
export default class OrderInfo extends Vue {
	@Prop({ type: Object, required: true }) private order!: Order;
	@Prop({ type: String, required: false }) private orderPaymentPreference!: string;

	private mutableOrder: Order = this.order;

	private get orderUserName(): string | undefined {
		return this.mutableOrder.data.checkout?.contact?.full_name;
	}

	private get scheduledDate(): string | undefined {
		return getOrderScheduledDate(this.mutableOrder);
	}

	private get orderUserPhone(): string | undefined {
		return this.mutableOrder.data.checkout?.contact?.phone_number || undefined;
	}

	private get orderUserEmail(): string | undefined {
		return this.mutableOrder.data.checkout?.contact?.email || undefined;
	}

	private get orderDelivery(): string | undefined {
		return getOrderDelivery(this.order);
	}

	private get orderCustomQuestions(): AnsweredCustomQuestion[] | undefined {
		if (this.mutableOrder.data.checkout?.questions?.length) {
			return this.mutableOrder.data.checkout.questions;
		}
	}

	private get orderInstructions(): string | undefined {
		return this.mutableOrder.data.checkout?.pickup?.notes || undefined;
	}

	private mounted(): void {
		this.mutableOrder = decodeOrder(this.mutableOrder) as Order;
	}
}
