
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { TOAST_INSTANCE } from '@/utils/constants';
import { getOrderTime, getOrderTitle } from '@/utils/getters';
import DatePicker from '@/components/shared/DatePicker.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '../shared/suites/WarningModal.vue';

const namespace: string = 'orders';
const WARNING_MODAL_DEFAULT: object = {
	showModal: false,
	title: '',
	text: '',
	onConfirm: () => {}
};

@Component<BulkActionsModal>({
	components: {
		DatePicker,
		InfoBanner,
		StandardModal,
		TableHeaders,
		TextButton,
		WarningModal
	}
})
export default class BulkActionsModal extends Vue {
	@Action('fetchOrdersWithQuery', { namespace }) private fetchOrdersWithQuery!: (query: { [key: string]: string | number | boolean }) => Promise<Order[]>;
	@Action('bulkActions', { namespace }) private bulkActions!: (payload: { orderIds: number[], action: string }) => BulkActionResponse;
	@Getter('allowBulkComplete', { namespace: 'auth' }) private allowBulkComplete!: boolean;
	@Getter('allowBulkCancel', { namespace: 'auth' }) private allowBulkCancel!: boolean;

	private selectedDateRange: string[] = [];
	private orders: Order[] = [];
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('order_management.bulk_actions.selected') },
		{ text: this.$t('order_management.bulk_actions.scheduled_for') },
		{ text: this.$t('order_management.bulk_actions.order_number') },
		{ text: this.$t('order_management.bulk_actions.order_details') }
	];
	private selectedOrders: number[] = [];
	private warningModal: object = WARNING_MODAL_DEFAULT;
	private responseMessage: object = {
		color: '',
		text: '',
		icon: ''
	};
	private showWarningModal: boolean = false;
	private showContent: boolean = false;
	private loading: { fetchOrders: boolean; bulkAction: boolean } = {
		fetchOrders: false,
		bulkAction: false
	};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	/**
	 * Filter date picker's dates to allow only dates within 10 days of start date
	 *
	 * @return {string|null}
	 */
	private get maxDate(): string | null {
		if (this.selectedDateRange[0]) {
			return DateTime.fromISO(this.selectedDateRange[0]).plus({ days: 10 }).toISODate();
		}
		return null;
	}

	/**
	 * Fetch in-progress orders based on the selected date range
	 *
	 * @return {Promise<void>}
	 */
	private async getOrders(): Promise<void> {
		// If no date range is selected, show error message
		if (this.selectedDateRange.length < 2) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.bulk_actions.error_date_range');
			return;
		}
		try {
			this.loading.fetchOrders = true;
			this.orders = await this.fetchOrdersWithQuery({
				status: 'in-progress',
				startDate: this.selectedDateRange[0],
				endDate: this.selectedDateRange[1]
			});

			// Set all order to selected by default
			this.selectedOrders = this.orders.map(order => order.id);
			this.showContent = true;
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_fetching_orders', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.loading.fetchOrders = false;
			}, 500);
		}
	}

	/**
	 * Open the warning modal to confirm the bulk action
	 *
	 * @param {string} action - action to perform (complete or cancel)
	 * @return {void}
	 */
	private openWarningModal(action: string): void {
		this.warningModal = {
			showModal: true,
			title: this.$t(`order_management.bulk_actions.${action}_warning_title`),
			text: this.$t(`order_management.bulk_actions.${action}_warning_message`, { num: this.selectedOrders.length }),
			onConfirm: () => this.performBulkActions(action)
		};
	}

	/**
	 * Perform the bulk action on the selected orders
	 *
	 * @param {string} action - action to perform (complete or cancel)
	 * @return {Promise<void>}
	 */
	private async performBulkActions(action: string): Promise<void> {
		try {
			this.loading.bulkAction = true;
			const numberOfOrders = this.selectedOrders.length;
			const { updatedOrders, unchangedOrders } = await this.bulkActions({
				action,
				orderIds: this.selectedOrders
			});

			if (updatedOrders?.length) {
				// Remove updated orders from the list
				this.orders = this.orders.filter(order => !updatedOrders.includes(order.id));

				// Hide table and buttons if no orders left
				if (!this.orders.length) {
					this.showContent = false;
				}
				this.selectedOrders = unchangedOrders;
				// Display message based on the number of orders updated
				// If all orders were updated, display success message
				// If some orders were updated, display partial success message
				this.responseMessage = updatedOrders?.length === numberOfOrders ?
					{
						text: this.$t('order_management.bulk_actions.success_message', {
							action: action === 'complete' ? this.$t('order_management.bulk_actions.completed') : this.$t('order_management.bulk_actions.cancelled')
						}),
						color: 'statusGreen',
						icon: 'mdi-check'
					} :
					{
						text: this.$t('order_management.bulk_actions.partial_success_message', {
							numComplete: updatedOrders.length,
							numSelected: numberOfOrders,
							action: action === 'complete' ? this.$t('order_management.bulk_actions.completed') : this.$t('order_management.bulk_actions.cancelled')
						}),
						color: 'primary',
						icon: 'mdi-information-outline'
					};
				this.$emit('refresh');
			}
			// If no orders were updated, display error message, this message is also displayed if there is a server error
			else {
				this.responseMessage = {
					text: this.$t('order_management.bulk_actions.error_message'),
					color: 'statusRed',
					icon: 'mdi-alert-outline'
				};
			}
		}
		catch (error) {
			this.responseMessage = {
				text: this.$t('order_management.bulk_actions.error_message'),
				color: 'statusRed',
				icon: 'mdi-alert-outline'
			};
		}
		finally {
			setTimeout(() => {
				this.resetWarningModal();
				this.loading.bulkAction = false;
			}, 500);
		}
	}

	/**
	 * Get the scheduled time of the order depending of its type
	 *
	 * @param {Order} order
	 * @return {string}
	 */
	private getOrderScheduledTime(order: Order): string {
		return getOrderTime(order);
	}

	/**
	 * Get the details of the order depending of its type
	 *
	 * @param {Order} order
	 * @return {string}
	 */
	private getOrderDetails(order: Order): string {
		return getOrderTitle(order);
	}

	/**
	 * Update the selected date range and reset the selected orders
	 *
	 * @param {string[]} dateRange
	 * @return {void}
	 */
	private handleDateRangeChange(dateRange: string[]): void {
		this.selectedOrders = [];
		this.selectedDateRange = dateRange;
	}


	/**
	 * Add/remove order from selected orders list
	 *
	 * @param {Order} order
	 * @return {void}
	 */
	private handleOrderSelected(order: Order): void {
		const index = this.selectedOrders.findIndex((orderId: number) => orderId === order.id);
		index === -1 ? this.selectedOrders.push(order.id) : this.selectedOrders.splice(index, 1);
	}

	/**
	 * A check to see if the order is selected, for checkbox in orders table
	 *
	 * @param {number} orderId
	 * @return {boolean}
	 */
	private isSelected(orderId: number): boolean {
		return this.selectedOrders.some((selectedOrderId: number) => selectedOrderId === orderId);
	}

	/**
	 * Reset the warning modal to its default state
	 * This is needed because the default variable is defined outside of the component
	 * So it can't be used in the template
	 *
	 * @return {void}
	 */
	private resetWarningModal(): void {
		this.warningModal = WARNING_MODAL_DEFAULT;
	}
}
