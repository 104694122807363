
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { formatOrderItem } from '@/utils/formatItem';
import OrderModifiers from './OrderModifiers.vue';
import QuantityInput from '@/components/shared/suites/QuantityInput.vue';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace = 'orders';

@Component<OrderItemViewer>({
	components: {
		OrderModifiers,
		QuantityInput,
		StandardModal,
		TextButton
	}
})
export default class OrderItemViewer extends Vue {
	@Action('setSelectedItem', { namespace }) updateSelectedItem!: (item: MenuItem | null) => void;
	@Action('setOrderItemOptions', { namespace }) setOrderItemOptions!: (optionGroups: OrderOptionGroup[]) => void;
	@Action('setOrderItemQuantity', { namespace }) setOrderItemQuantity!: (quantity: number) => void;
	@Action('setEditingItem', { namespace }) setEditingItem!: (editing: boolean) => void;
	@Action('addToCart', { namespace }) addToCart!: (specialInstructions: string) => void;
	@Action('removeFromOrder', { namespace }) removeFromOrder!: (index: number) => void;
	@Action('resetItemViewerState', { namespace }) resetItemViewerState!: () => void;
	@Getter('getSelectedItem', { namespace }) item!: MenuItem;
	@Getter('getOrderItem', { namespace }) orderItem!: MenuOrderItem;
	@Getter('getOrderItemOptions', { namespace }) orderItemOptions!: OrderOptionGroup[];
	@Getter('getOrderItemPrice', { namespace }) orderItemPrice!: number;
	@Getter('getCartItems', { namespace }) cartItems!: MenuOrderItem[];
	@Getter('getCart', { namespace }) cart!: Cart;
	@Getter('getEditingItem', { namespace }) isEditing!: boolean;
	@Getter('getMaxItemsPerCart', { namespace: 'auth' }) maxItemsPerCart!: number;

	private formattedItem = {} as MenuOrderItem;
	private specialInstructions: string = '';
	private addingToOrder: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	$refs!: {
		modifiers: OrderModifiers;
	};

	/**
	 * Returns the number of items that can be added to the cart
	 * If the item is being edited, subtract 1 from the number of cart items because the item is already in the cart
	 *
	 * @return {number}
	 */
	private get remainingItemsInCart(): number {
		if (!this.maxItemsPerCart) {
			return -1;
		}
		return this.maxItemsPerCart - (this.orderItem.quantity + (this.cartItems.length - (this.isEditing ? 1 : 0)));
	}

	/**
	 * Don't display modifiers if there are no option groups or option groups with no options
	 * This can happen if pricing options were enabled and then disabled, a pricing option group will still exist
	 *
	 * @return {boolean}
	 */
	private get showModifiers(): boolean {
		return !!(this.formattedItem.orderOptions?.length && this.formattedItem.orderOptions.some(option => option.values.length));
	}

	/**
	 * Returns the number of remaining characters for the special instructions
	 *
	 * @return {number}
	 */
	private get remainingCharacters(): number {
		return 300 - this.specialInstructions.length;
	}

	private created(): void {
		this.formattedItem = formatOrderItem(this.item);

		const memo = this.orderItemOptions?.find(option => option.memo);
		if (memo) {
			this.specialInstructions = memo.values[0].name;
		}
	}

	/**
	 * Validates the required options before adding the item to the cart
	 *
	 * @return {void}
	 */
	private validateRequiredOptions(): void {
		if (this.item.options && this.item.options.length && this.$refs.modifiers) {
			this.$refs.modifiers.validateRequiredOptions();
		}
		else {
			this.handleAddToCart();
		}
	}

	/**
	 * Increases the quantity of the order item
	 *
	 * @return {void}
	 */
	private increaseQuantity(): void {
		if (this.remainingItemsInCart === 0) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.item_viewer.max_order_items', { limit: this.maxItemsPerCart });
			return;
		}
		const quantity = this.orderItem.quantity + 1;
		this.setOrderItemQuantity(quantity);
	}

	/**
	 * Decreases the quantity of the order item
	 *
	 * @return {void}
	 */
	private decreaseQuantity(): void {
		if (this.orderItem.quantity === 1) {
			return;
		}
		const quantity = this.orderItem.quantity - 1;
		this.setOrderItemQuantity(quantity);
	}

	/**
	 * Adds the item to the cart
	 *
	 * @return {Promise<void>}
	 */
	private async handleAddToCart(): Promise<void> {
		try {
			this.addingToOrder = true;
			await this.addToCart(this.specialInstructions);
			this.addingToOrder = false;
			this.resetItemViewerState();
		}
		catch (error) {
			this.addingToOrder = false;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.item_viewer.error_adding_to_order', { error });
		}
	}

	/**
	 * Prevent closing item if item is being added to the cart
	 *
	 * @return {void}
	 */
	private handleModalClose(): void {
		if (!this.addingToOrder) {
			this.resetItemViewerState();
		}
	}
}
