
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<StatusLabel>({})
export default class StatusLabel extends Vue {
	@Prop({ type: String, required: true, default: '' }) private status!: string;
	@Prop({ type: Number, required: false }) private count!: number;

	private statusText: { [key: string]: string } = {
		'not_configured': this.$tc('suite_status.not_configured', this.count),
		'pending': this.$t('suite_status.pending'),
		'accepted': this.$tc('suite_status.accepted', this.count),
		'completed': this.$tc('suite_status.completed', this.count),
		'cancelled': this.$tc('suite_status.cancelled', this.count),
		'refunded': this.$tc('suite_status.refunded', this.count)
	};

	private statusClass: { [key: string]: string } = {
		'not_configured': 'error',
		'pending': 'warning',
		'accepted': 'success',
		'completed': 'info',
		'cancelled': 'neutral',
		'refunded': 'neutral-dark'
	};
}
