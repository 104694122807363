import { render, staticRenderFns } from "./ChangeHistoryModal.vue?vue&type=template&id=155a13e3&scoped=true"
import script from "./ChangeHistoryModal.vue?vue&type=script&lang=ts"
export * from "./ChangeHistoryModal.vue?vue&type=script&lang=ts"
import style0 from "./ChangeHistoryModal.vue?vue&type=style&index=0&id=155a13e3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "155a13e3",
  null
  
)

export default component.exports