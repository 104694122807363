
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component<FileUpload>({})
export default class FileUpload extends Vue {
	@Prop({ type: String, default: '' }) private imageUrl!: string;
	@Watch('imageUrl', { immediate: true })
	onSelectedLocaleUpdate() {
		this.mutableImageUrl = this.imageUrl;
	}

	$refs!: {
		file: any
	};

	private dragover: boolean = false;
	private imagePreview: any = null;
	private mutableImageUrl: string = '';

	private removeImage(): void {
		if (this.imagePreview) {
			this.imagePreview = null;
			this.$emit('on-upload', null);
		}
		if (this.mutableImageUrl) {
			this.mutableImageUrl = '';
			this.$emit('on-remove');
		}
	}

	private destroyed(): void {
		this.imagePreview = null;
	}

	/**
	 * Handle file upload event,
	 * essentially just give an image link to post to S3
	 *
	 * @param {FileUploadEvent} evt
	 * @return {void}
	 */
	private onSelectFile(event: FileUploadEvent): void {
		this.dragover = false;
		const files = event.dataTransfer?.files || event.target.files;

		if (files?.length) {
			let reader = new FileReader;
			reader.onload = (e: any) => {
				this.imagePreview = e.target.result;
			};
			reader.readAsDataURL(files[0]);

			this.$emit('on-upload', files);
		}
	}

	private chooseFile(): void {
		this.$refs.file.click();
	}
}
