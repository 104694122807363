import { ActionTree } from 'vuex';
import { ItemState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { handleAllErrors } from '../../utils/errorHandling';
import { formatItem, formatOptionGroup } from '@/utils/formatItem';
import { decodeItem } from '@/utils/decoding';
import i18n from '@/i18n';

export const actions: ActionTree<ItemState, RootState> = {

	setSelectedItem({ commit }, selectedItem: MenuItem): void {
		selectedItem = decodeItem(selectedItem);
		commit('SET_SELECTED_ITEM', selectedItem);
	},

	/**
	 * Update a menu section item
	 *
	 * @return {Promise<void>}
	 */
	async updateMenuSectionItem({ dispatch, commit }, { menu, section, item, refetch = false }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;


		try {
			const updatedSectionItem = (await axiosInst.put(`/menus/${menu.id}/sections/${section.id}/items/${item.id}`, item)).data;

			if (refetch) {
				dispatch('menus/fetchMenuSectionItems', { menu, section: { ...section, menu_id: menu.id } }, { root: true });
			}
			else {
				commit('menus/SET_UPDATED_SECTION_ITEMS', { section, updatedSectionItem }, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenuSectionItems', { menu, section }, { root: true });
			console.warn('error', error);
			handleAllErrors(error);
		}
	},

	/**
	 * Update a menu section item
	 *
	 * @return {Promise<void>}
	 */
	async updateMenuItem ({ dispatch, commit }, { menu, item, refetch = false }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const updatedItem = (await axiosInst.put(`/menus/${menu.id}/items/${item.id}`, item)).data;

			if (refetch) {
				dispatch('menus/fetchMenuItems', menu , { root: true });
			}
			else {
				commit('menus/SET_UPDATED_MENU_ITEMS', { menu, updatedItem }, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenuItems', menu , { root: true });
			handleAllErrors(error);
		}
	},

	async createMenuItem ({ dispatch, commit }, { menu, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const createdMenuItem = (await axiosInst.post(`/menus/${menu.id}/items`, item)).data;

			commit('SET_SELECTED_ITEM', createdMenuItem);

			dispatch('menus/fetchMenuItems', menu , { root: true });
		}
		catch (error) {
			dispatch('menus/fetchMenuItems', menu , { root: true });
			handleAllErrors(error);
		}
	},


	async createMenuSectionItem ({ dispatch, commit }, { menu, section, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const createdMenuSectionItem = (await axiosInst.post(`/menus/${menu.id}/sections/${section.id}/items`, item)).data;

			commit('SET_SELECTED_ITEM', createdMenuSectionItem);

			dispatch('menus/fetchMenuSectionItems', { menu, section } , { root: true });
		}
		catch (error) {
			dispatch('menus/fetchMenuSectionItems', { menu, section } , { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a Menu item
	 *
	 * @return {Promise<void>}
	 */
	async deleteMenuItem({ dispatch, commit }, { menu, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/menus/${menu.id}/items/${item.id}`);
			commit('menus/SET_UPDATED_MENU_ITEMS', { menu, deletedItemId: item.id }, { root: true });

		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * Delete an Menu Section item
	 *
	 * @return {Promise<void>}
	 */
	async deleteMenuSectionItem({ dispatch, commit }, { menu, section, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/menus/${menu.id}/sections/${section.id}/items/${item.id}`);
			commit('menus/SET_UPDATED_SECTION_ITEMS', { section, deleteSectionItemId: item.id }, { root: true });
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * Upload image to S3 and update the an item's image accordingly
	 *
	 * @return {Promise<void>}
	 */
	async uploadItemImage ({ dispatch }, { menu, section, item, image }) {
		const axiosInst: AxiosInstance = authState.axiosInst;

		const formData = new FormData();
		formData.append('file', image[0], image[0].name);

		const headers = {
			'Content-Disposition': `form-data; name="file"; filename="${image[0].name}"`,
			'Content-Type': 'multipart/form-data'
		};

		try {
			if (section?.id) {
				await axiosInst.post(`/menus/${menu.id}/sections/${section.id}/items/${item.id}/image`, formData, { headers });
				dispatch('menus/fetchMenuSectionItems', { menu: menu, section: section }, { root: true });
			}
			else {
				await axiosInst.post(`/menus/${menu.id}/items/${item.id}/image`, formData, { headers });
				dispatch('menus/fetchMenuItems', menu, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * duplicates a menu section item (with options)
	 * @param dispatch
	 * @param oldMenu - copying from
	 * @param newMenu - copying to
	 * @param oldSection - copying from
	 * @param newSection - copying to
	 * @param item - copying
	 */
	async duplicateMenuSectionItem({ dispatch }, { oldMenu, newMenu, oldSection, newSection, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const options = (await axiosInst.get(`/menus/${oldMenu.id}/sections/${oldSection.id}/items/${item.id}/options`)).data;
			const newItemPayload = formatItem({
				...item,
				id: null,
				name: item.name + i18n.t('shared.copy'),
				section_id: newSection.id,
				menu_id: newMenu.id,
				published_at: null,
				published: false
			} as MenuItem);
			const newItem = (await axiosInst.post( `/menus/${newMenu.id}/sections/${newSection.id}/items/`, newItemPayload)).data as MenuItem;
			if (options.length > 0){
				await Promise.all(
					options.map((option: any) => {

						// If pricing type, we need to duplicate the addon, not just assign it to the item
						if(option.type === 'pricing') {
							// To associate the pricing group after creation
							const associationParams = { menuId: newMenu.id, sectionId: newSection.id, itemId: newItem.id };

							// Strip out ids to prevent 409 errors
							delete option.id;
							option.values.forEach((value: Option) => {
								delete value.id;
							});
							return dispatch('options/createOptionGroup', { options: option.values, body: formatOptionGroup(option), associationParams }, { root: true });
						}
						else {
							return axiosInst.post(`/menus/${newMenu.id}/sections/${newSection.id}/items/${newItem.id}/options/${option.id}`);
						}
					})
				);
			}
			if (oldSection.id === newSection.id){
				dispatch('menus/fetchMenuSectionItems', { menu: newMenu, section: newSection }, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * duplicates a menu item (with options)
	 * @param dispatch
	 * @param oldMenu - copying from
	 * @param newMenu - copying to
	 * @param item - copying
	 */
	async duplicateMenuItem({ dispatch }, { oldMenu, newMenu, item }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const options = (await axiosInst.get(`/menus/${oldMenu.id}/items/${item.id}/options`)).data;
			const newItemPayload = formatItem({
				...item,
				id: null,
				name: item.name + i18n.t('shared.copy'),
				menu_id: newMenu.id,
				published_at: null,
				published: false
			} as MenuItem);
			const newItem = (await axiosInst.post( `/menus/${newMenu.id}/items/`, newItemPayload)).data as MenuItem;
			if (options.length > 0){
				await Promise.all(
					options.map((option: any) => {
						if(option.type === 'pricing') {
							// To associate the pricing group after creation
							const associationParams = { menuId: newMenu.id, itemId: newItem.id };

							// Strip out ids to prevent 409 errors
							delete option.id;
							option.values.forEach((value: Option) => {
								delete value.id;
							});
							return dispatch('options/createOptionGroup', { options: option.values, body: formatOptionGroup(option), associationParams }, { root: true });
						}
						else {
							return axiosInst.post(`/menus/${newMenu.id}/items/${newItem.id}/options/${option.id}`);
						}
					})
				);
			}
			if (oldMenu.id === newMenu.id){
				dispatch('menus/fetchMenuItems', newMenu, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	}
};
