import { GetterTree } from 'vuex';
import { OptionState, RootState } from '../types';

export const getters: GetterTree<OptionState, RootState> = {
	getAddOnOptionGroups(state): OptionGroup[] {
		return state.addOnOptionGroups;
	},

	getAddOns(state): Option[] {
		return state.addOns;
	},

	getSelectedAddOnOptionGroup(state): OptionGroup|object {
		return state.selectedAddOnOptionGroup;
	},

	getItemOptionGroups(state): (OptionGroup | object)[] {
		return state.itemOptionGroups;
	}
};