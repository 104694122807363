
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import EquipmentListModal from '@/components/suites/EquipmentListModal.vue';
import SuitesListModal from '@/components/suites/SuitesListModal.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';

const namespace: string = 'equipment';

@Component<EventSettings>({
	components: {
		SuitesHeader,
		TextButton,
		FullPageSpinner,
		EquipmentListModal,
		SuitesListModal,
		InfoBanner
	}
})
export default class EventSettings extends Vue {
	@Action('fetchEquipment', { namespace }) private fetchEquipment!: () => Promise<void>;
	@Action('fetchSuites', { namespace: 'suites' }) private fetchSuites!: () => Promise<void>;
	@Getter('getEquipment', { namespace }) private equipment!: Equipment[];
	@Getter('getSuites', { namespace: 'suites' }) private suites!: Suite[];

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private isDataFetching: boolean = false;

	private eventSettings: { [key: string]: string | (() => void) }[] = [
		{
			id: 'suites-btn',
			title: this.$t('event_settings.suites.title'),
			description: this.$t('event_settings.suites.description_settings'),
			buttonIcon: 'mdi-home-plus-outline',
			buttonText: this.$t('event_settings.suites.btn_text'),
			onClick: () => this.toggleModal('suitesList')
		},
		{
			id: 'suite-owners-btn',
			title: this.$t('event_settings.suite_owners.title'),
			description: this.$t('event_settings.suite_owners.description'),
			buttonIcon: 'mdi-account-cog',
			buttonText: this.$t('event_settings.suite_owners.btn_text'),
			onClick: () => this.$router.push('/event-settings/suite-owners').catch(() => {})
		},
		{
			id: 'event-types-btn',
			title: this.$t('event_settings.event_types.title'),
			description: this.$t('event_settings.event_types.description'),
			buttonIcon: 'mdi-ticket',
			buttonText: this.$t('event_settings.event_types.btn_text'),
			onClick: () => this.$router.push('/event-settings/event-types').catch(() => {})
		},
		{
			id: 'equipment-list-btn',
			title: this.$t('event_settings.equipment_list.title'),
			description: this.$t('event_settings.equipment_list.description'),
			buttonIcon: 'mdi-silverware-fork-knife',
			buttonText: this.$t('event_settings.equipment_list.btn_text'),
			onClick: () => this.toggleModal('equipmentList')
		}
	];

	private showModal: { [key: string]: boolean } = {
		equipmentList: false,
		suitesList: false
	};

	private created(): void {
		this.fetchEventSettingsData();
	}

	/**
	 * Fetch the equipment and suites data if it hasn't been fetched before
	 *
	 * @return {Promise<void>}
	 */
	private async fetchEventSettingsData(): Promise<void> {
		let apiCalls = [];

		!this.equipment.length && apiCalls.push(this.fetchEquipment);
		!this.suites.length && apiCalls.push(this.fetchSuites);

		if (!apiCalls.length) {
			return;
		}

		this.isDataFetching = true;

		try {
			await Promise.all(apiCalls.map(apiCall => apiCall()));
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_settings.error_fetching', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	/**
	 * Toggle modal
	 *
	 * @param {string} modalName
	 * @return {void}
	 */
	private toggleModal(modalName: string): void {
		this.showModal[modalName] = !this.showModal[modalName];
	}
}
