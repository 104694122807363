
import { Component, Vue, Prop } from 'vue-property-decorator';
import OrderMenuCard from './OrderMenuCard.vue';

@Component<OrderMenu>({
	components: {
		OrderMenuCard
	}
})
export default class OrderMenu extends Vue {
	@Prop({ type: Object, required: true }) private menu!: Menu;

	private selectedSection: number = 0;

	/**
	 * Filters out sections with no items
	 *
	 * @return {MenuSection[]}
	 */
	private get filteredSections(): MenuSection[] {
		if (this.menu?.sections?.length) {
			return this.menu!.sections!.filter((section: MenuSection) => (section.items && section.items.length > 0));
		}
		return [];
	}

	/**
	 * Scrolls to the selected section
	 *
	 * @param {string} sectionId - The id of the section to scroll to
	 * @return {void}
	 */
	private scrollToSection(sectionId: string): void {
		const section = document.getElementById(`section-${sectionId}`);
		if (section) {
			section.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
}
