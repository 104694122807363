
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { cloneDeep } from 'lodash';

const namespace = 'orders';

@Component<OrderCartItem>({})
export default class OrderCartItem extends Vue {
	@Action('setSelectedItem', { namespace }) setSelectedItem!: (item: MenuItem) => void;
	@Action('setOrderItem', { namespace }) setOrderItem!: (item: MenuOrderItem) => void;
	@Action('setEditingItem', { namespace }) setEditingItem!: (editing: boolean) => void;
	@Getter('getPublishedMenus', { namespace: 'menus' }) private publishedMenus!: Menu[];
	@Prop({ type: Object, required: true }) private item!: MenuOrderItem;

	/**
	 * Find the item to edit and set it as the selected item
	 *
	 * @return {void}
	 */
	private setItemToEdit(): void {
		const menu = this.publishedMenus.find((menu: Menu) => menu.id === this.item.menu_id);
		let menuItem: MenuItem | null = null;
		if (menu?.sections?.length) {
			menuItem = menu.sections
				.map((section: MenuSection) => section.items)
				.flat()
				.find((menuItem: MenuItem) => menuItem.id === this.item.id);
		}
		else if (menu?.items?.length) {
			menuItem = menu.items.find((menuItem: MenuItem) => menuItem.id === this.item.id) || null;
		}

		if (menuItem) {
			this.setEditingItem(true);
			this.setSelectedItem(menuItem);
			this.setOrderItem(cloneDeep(this.item));
		}
	}

	/**
	 * Determine if an option should be displayed
	 * If the option is not a default selection, it should be displayed if it has a quantity
	 * If the option is a default selection, it should be displayed if it has suboptions or a quantity > 1
	 *
	 * @param {OrderOption} option
	 * @return {boolean}
	 */
	private displayOption(option: OrderOption): boolean {
		const optionHasSelectedSubOptions = option.options?.some(subOptionGroup => subOptionGroup.values.some(subOption => subOption.quantity > 0));
		return (!option.default_selection && option.quantity > 0) || !!(option.default_selection && (optionHasSelectedSubOptions || option.quantity > 1));
	}
}
