
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<AddonsLocaleEditor>({})
export default class AddonsLocaleEditor extends Vue {
	@Prop({ type: Object, default: () => {} }) private optionGroup!: OptionGroup;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;
	@Prop({ type: Array, default: () => [] }) private pricingItems!: Option[];
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Prop({ type: Boolean, default: false }) private valid!: boolean;
	@Watch('selectedLocale')
	onSelectedLocaleUpdate() {
		// If default we don't need to add any localization info, as those are already
		// in the default values
		if(!this.selectedLocale.is_default) {
			this.mutableOptionGroup.localization = this.mutableOptionGroup.localization ? this.mutableOptionGroup.localization : {} as Localization;
			// If no localization for that specific language, we need to initialize them
			if(!this.mutableOptionGroup.localization[this.selectedLocale.locale_short]) {
				this.mutableOptionGroup.localization[this.selectedLocale.locale_short] = {
					name: '',
					description: ''
				};
			}

			// Pricing options
			if(this.pricingItems && this.pricingItems.length) {
				this.mutablePricing = this.pricingItems;

				this.mutablePricing.map((pricingItem: Option) => {
					if(pricingItem.name) {
						pricingItem.localization = pricingItem.localization ? pricingItem.localization : {} as Localization;
						if(!pricingItem.localization[this.selectedLocale.locale_short]) {
							pricingItem.localization[this.selectedLocale.locale_short] = {
								name: ''
							};
						}
					}
					return pricingItem;
				});
			}
		}
	}

	private mutableOptionGroup: OptionGroup = this.optionGroup;
	private mutablePricing: Option[] = [];
}
