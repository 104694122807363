
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import IconButton from '@/components/shared/suites/IconButton.vue';
import OrderMenu from './OrderMenu.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';

const namespace: string = 'orders';

@Component<OrderMenus>({
	components: {
		IconButton,
		OrderMenu,
		SuitesHeader,
		WarningModal
	}
})
export default class OrderMenus extends Vue {
	@Prop({ type: Array, required: true }) private menus!: Menu[];
	@Getter('getCartItemsCount', { namespace }) private cartItemsCount!: number;
	@Getter('getModifyingOrder', { namespace }) private modifyingOrder!: boolean;
	@Getter('getOrderDetails', { namespace }) private orderDetails!: OrderDetails;

	private menuSelectedIndex: number = 0;
	private title: string = '';
	private showScrollToTopButton: boolean = false;

	// Warning modal
	private warningModalTitle: string = '';
	private warningModalBody: string = '';
	private showWarningModal: boolean = false;

	private get isUserFromAPP(): boolean {
		return !!window.B2BApp;
	}

	private created(): void {
		if(this.modifyingOrder) {
			this.title = this.$t('orders.modify_header');
		}
		else if(this.isUserFromAPP) {
			if(this.orderDetails?.checkNum) {
				this.title = this.$t('orders.add_to_check_header');
				this.warningModalTitle = this.$t('orders.warning_modal.add_to_check.title');
				this.warningModalBody = this.$t('orders.warning_modal.add_to_check.text');
			}
			else {
				this.title = this.$t('orders.new_check_header');
				this.warningModalTitle = this.$t('orders.warning_modal.new_check.title');
				this.warningModalBody = this.$t('orders.warning_modal.new_check.text');
			}
		}
		else {
			this.title = this.$t('orders.create_header');
		}
	}

	private backToApp(): void {
		window.B2BApp.backToApp();
	}

	private potentialBackToApp(): void {
		this.cartItemsCount ? this.showWarningModal = true : this.backToApp();
	}

	/**
	 * On scroll event, check if the user has scrolled more than 200px, if so, show the scroll to top button
	 *
	 * @param {any} e
	 * @return {void}
	 */
	private onScroll(e: any): void {
		const max: number = 200;
		const top: number = window.pageYOffset || e.target.scrollTop || 0;
		this.showScrollToTopButton = top > max;
	}

	private scrollToTop(): void {
		this.$vuetify.goTo(0);
	}
}
