import { render, staticRenderFns } from "./EventDetailsTable.vue?vue&type=template&id=790f86ef"
import script from "./EventDetailsTable.vue?vue&type=script&lang=ts"
export * from "./EventDetailsTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports