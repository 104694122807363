
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Provider, Validate } from '@/types';
import ExpansionPanel from '@/components/shared/suites/panels/ExpansionPanel.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

@Component<MembershipInformation>({
	components: {
		ExpansionPanel,
		TextButton,
		ValidationObserver,
		ValidationProvider
	}
})
export default class MembershipInformation extends Vue {
	@Action('checkIfMemberAndApplyPromo', { namespace: 'orders' }) private checkIfMemberAndApplyPromo!: ({ identifier, menus } : { identifier: string; menus: Menu[] }) => void;
	@Prop({ type: Array, required: true }) private menus!: Menu[];
	@Prop({ type: Object, required: true }) private membershipCustomization!: MembershipCustomization;
	@Prop({ type: Object, required: true }) private checkout!: Checkout;
	private identifier: string = '';
	private successMsg: string = '';
	private loading: boolean = false;
	private membershipApplied: boolean = false;

	$refs!: {
		observer: Validate
		provider: Provider,
	};

	/**
	 * Get the input settings based on the input type
	 *
	 * @return {object}
	 */
	private get inputSettings(): { type: string, label: string, rules: string, maxLength: string } {
		switch(this.membershipCustomization.input_type) {
			case 'full_name':
				return {
					type: 'text',
					label: this.membershipCustomization.input_label,
					rules: '',
					maxLength: '40'
				};
			case 'email':
				return {
					type: 'email',
					label: this.membershipCustomization.input_label,
					rules: 'email',
					maxLength: '40'
				};
			case 'phone_number':
				return {
					type: 'tel',
					label: this.membershipCustomization.input_label,
					rules: 'min:12|max:12',
					maxLength: '12'
				};
			default:
				return {
					type: 'text',
					label: this.membershipCustomization.input_label,
					rules: '',
					maxLength: '40'
				};
		}
	}

	private get appMemberInfo(): boolean {
		return !!window.B2BApp?.getMemberInfo() || false;
	}

	private created(): void {
		// Check if membership has already been applied
		if (this.checkout.memberInfo?.identifier) {
			if(this.inputSettings.type === 'tel') {
				this.identifier = this.checkout.memberInfo.identifier.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
			}
			else {
				this.identifier = this.checkout.memberInfo.identifier;
			}
			this.membershipApplied = true;
			this.successMsg = this.$t('orders.guest_details.membership.membership_applied');
		}
	}

	/**
	 * Format the phone number - remove non-digital characters
	 * and add dashes to the respective positions
	 *
	 * @return {void}
	 */
	private formatInput(): void {
		if(this.inputSettings.type === 'tel' && this.identifier) {
			this.identifier = this.identifier.replace(/\D/g,'');
			if(this.identifier) {
				this.identifier = this.identifier.match(/\d{3}(?=\d{2,3})|\d+/g)!.join('-');
			}
		}
	}

	/**
	 * Check if the user is a member and apply the promo
	 *
	 * @return {Promise<void>}
	 */
	private async applyMembership(): Promise<void> {
		try {
			let isValid = await this.$refs.observer.validate();
			this.loading = true;
			if (isValid) {
				const tempIdentifier = this.inputSettings.type === 'tel' ? this.identifier.replace(/[^0-9]/g, '') : this.identifier;
				await this.checkIfMemberAndApplyPromo({ identifier: tempIdentifier, menus: this.menus });
				this.$refs.provider.setErrors([]);
				this.successMsg = this.$t('orders.guest_details.membership.membership_applied');
				this.membershipApplied = true;
			}
		}
		catch (error) {
			this.successMsg = '';
			this.$refs.provider.setErrors([this.$t('orders.guest_details.membership.membership_not_applied', { type: this.inputSettings.label.toLowerCase() })]);
			this.membershipApplied = false;
		}
		finally {
			setTimeout(() => {
				this.loading = false;
			}, 500);
		}
	}
}
