
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { ValidationObserver } from 'vee-validate';
import { Validate } from '@/types';
import PageLoader from '@/components/shared/PageLoader.vue';
import TitleHeader from '@/components/shared/Header.vue';
import CustomBranding from '@/components/customize/CustomBranding.vue';
import CustomTheme from '@/components/customize/CustomTheme.vue';
import CustomFont from '@/components/customize/CustomFont.vue';
import CustomMenuBrowseStyles from '@/components/customize/CustomMenuBrowseStyles.vue';
import CustomCardStyles from '@/components/customize/CustomCardStyles.vue';
import CustomLocales from '@/components/customize/Locales.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'auth';

@Component<Customize>({
	components: {
		ValidationObserver,
		PageLoader,
		TitleHeader,
		CustomBranding,
		CustomTheme,
		CustomFont,
		CustomMenuBrowseStyles,
		CustomCardStyles,
		CustomLocales,
		BannerToast
	}
})
export default class Customize extends Vue {
	@Action('fetchRestaurant', { namespace }) private fetchRestaurant!: (payload: any) => Promise<Restaurant>;
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Action('uploadRestaurantImage', { namespace }) private uploadRestaurantImageAction!: (payload: object) => Promise<void>;
	@Getter('getRestaurant', { namespace }) private restaurantData!: Restaurant;
	@Getter('getTenantId', { namespace }) private tenantId!: string;

	$refs!: {observer: Validate};

	private fetchingLoader: boolean = false;
	private loading: boolean = false;
	private invalidColor: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private menuConfig: MenuConfiguration|object|null = this.restaurantData?.menu_configuration ? this.restaurantData?.menu_configuration : {};
	private name: string = this.restaurantData?.name;
	private displayName: string = this.restaurantData?.display_name;
	private image: string = this.restaurantData?.image;
	private imageFile: string | null = null;
	private locales: RestaurantLocale[]|null = this.restaurantData?.locales ? this.restaurantData?.locales : null;
	private multipleLocales: boolean = false;

	/**
	 * Fetch the restaurant
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		this.fetchingLoader = true;
		await this.fetchRestaurant({
			tenantId: this.tenantId
		})
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_fetching_menus', { errorMessage });
			});

		this.menuConfig = this.restaurantData.menu_configuration ? this.restaurantData.menu_configuration : { use_default_fonts: true };
		this.locales = this.restaurantData.locales;
		this.multipleLocales = !!(this.restaurantData.locales && this.restaurantData.locales.length);
		this.name = this.restaurantData.name;
		this.displayName = this.restaurantData.display_name;
		this.image = this.restaurantData.image;
		this.fetchingLoader = false;
	}

	private mounted(): void {
		this.positionSaveButton();
	}

	/**
	 * Save the menuConfig
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
  		const isValid = await this.$refs.observer.validate();

		if (isValid) {
			// Allow empty colors so that we can fallback on the default one
			// We need to actually delete the field since the backend does not
			// allow its values to be empty
			if (!(this.menuConfig as MenuConfiguration).primary_color) {
				delete (this.menuConfig as MenuConfiguration).primary_color;
			}

			if (!(this.menuConfig as MenuConfiguration).primary_font) {
				delete (this.menuConfig as MenuConfiguration).primary_font;
			}

			if (!(this.menuConfig as MenuConfiguration).secondary_font) {
				delete (this.menuConfig as MenuConfiguration).secondary_font;
			}

			await this.updateRestaurant({
				restaurant: {
					...this.restaurantData,
					menu_configuration: this.menuConfig,
					display_name: this.displayName,
					image: this.image,
					locales: this.locales && this.locales.length ? this.locales : null
				}
			})
				.then(async () => {
					await this.uploadRestaurantImage();
				})
				.then(() => {
					this.bannerToastInfo.color = '#00B796';
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('customize.success');
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.color = 'primary';
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('customize.error_updating', { restaurantName: this.restaurantData.name, errorMessage });
				});
		}

		setTimeout(() => {
			this.loading = false;
		}, 600);
	}

	/**
	 * Update branding info (display name, image)
	 *
	 * @return {void}
	 */
	private updateBrandingInfo(object: any): void {
		this.displayName = object.displayName;
		this.image = object.image;
		this.imageFile = object.imageFile;
	}

	/**
	 * Update restaurant locales
	 *
	 * @return {RestaurantLocale[]}
	 * @return {void}
	 */
	private updateRestaurantLocales(restaurantLocales: RestaurantLocale[]): void {
		this.locales = restaurantLocales;
	}

	/**
	 * Upload the restaurant's image
	 *
	 * @return {void}
	 */
	private async uploadRestaurantImage(): Promise<void> {
		if (this.imageFile) {
			return this.uploadRestaurantImageAction({
				restaurant: this.restaurantData,
				image: this.imageFile
			});
		}
		return;
	}

	/**
	 * Position save button depending on the drawer on load
	 *
	 * @return {void}
	 */
	private positionSaveButton(): void {
		setTimeout(() => {
			const drawer = document.getElementById('nav-drawer');
			if(drawer) {
				document.documentElement.style.setProperty('--dynamic-btn-left-value', `${drawer.offsetWidth}px`);
			}
		}, 200);
	}
}
