
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { getOrderTime, getOrderTitle } from '@/utils/getters';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';

@Component<CancelledOrderModal>({
	components: {
		StandardModal,
		TableHeaders
	}
})
export default class CancelledOrderModal extends Vue {
	@Action('fetchCancelledOrders', { namespace: 'orders' }) private fetchOrders!: () => Promise<Order[]>;

	private isLoading: boolean = false;
	private emptyTableText: string = this.$t('order_management.cancelled_orders.no_cancelled_orders');
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('order_management.cancelled_orders.scheduled_for') },
		{ text: this.$t('order_management.cancelled_orders.order_number') },
		{ text: this.$t('order_management.cancelled_orders.details') }
	];
	private orders: Order[] = [];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private async mounted(): Promise<void> {
		this.isLoading = true;
		try {
			this.orders = await this.fetchOrders();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_fetching_orders', { errorMessage });
			this.emptyTableText = this.$t('order_management.cancelled_orders.error_fetching');
		}
		finally {
			setTimeout(() => this.isLoading = false, 500);
		}
	}

	/**
	 * Get the scheduled time of the order depending of its type.
	 *
	 * @param {Order} order
	 * @return {string}
	 */
	private getOrderScheduledTime(order: Order): string {
		return getOrderTime(order);
	}

	/**
	 * Get the details of the order depending of its type.
	 *
	 * @param {Order} order
	 * @return {string}
	 */
	private getOrderDetails(order: Order): string {
		return getOrderTitle(order);
	}
}
