
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { DateTime } from 'luxon';
import browserLocale from '@/utils/i18n-helpers';
import '@/validation-rules';

@Component<DatePicker>({
	components: {
		ValidationProvider
	}
})
export default class DatePicker extends Vue {
	@Prop({ type: Object, required: false, default: () => {} }) private textFieldProps!: { [key: string]: any };
	@Prop({ type: Object, required: false, default: () => {} }) private datePickerProps!: { [key: string]: any };
	@Prop({ type: String, required: false, default: '350' }) private maxWidth!: string;
	@Prop({ type: String, required: false, default: 'yyyy-MM-dd' }) private displayDateFormat!: string;
	@Prop({ type: String, required: false, default: '' }) private rules!: string;
	@Prop({ type: String, required: false, default: '' }) private id!: string;
	@Prop({ type: String, required: false }) private confirmButtonText!: string;
	@Prop({ type: String, required: false }) private cancelButtonText!: string;
	@Prop({ type: String, required: false }) private clearButtonText!: string;
	@Prop({ type: String, required: false }) private closeButtonText!: string;
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;
	@Watch('menu')
	onMenuChange() {
		if (!this.menu) {
			this.$nextTick(() => {
				this.$refs.textField.blur();
			});
		}
	}

	$refs!: {
		textField: any;
	};

	public menu: boolean = false;
	private dateRange: string[] = [];
	private date: string = '';
	private locale: string = browserLocale() || '';

	private get range() {
		return !!(this.datePickerProps && this.datePickerProps.range);
	}

	private get formattedDateRange() {
		if (this.range) {
			if (this.dateRange.length) {
				return this.dateRange.map(date => DateTime.fromISO(date).toFormat(this.$t('shared.date_picker.date_format'))).join(' - ');
			}
			return '';
		}
		else {
			if (this.date) {
				return DateTime.fromISO(this.date).toFormat(this.$t('shared.date_picker.date_format'));
			}
			return '';
		}
	}

	private get datePickerModel() {
		if (this.range) {
			return this.dateRange;
		}
		else {
			return this.date;
		}
	}

	private set datePickerModel(value: string | string[]) {
		if (this.range) {
			this.dateRange = value as string[];
		}
		else {
			this.date = value as string;
		}
	}

	private mounted() {
		if (this.$attrs.value) {
			this.date = this.$attrs.value;
		}
	}

	/**
	 * Sets the date range to the parent component.
	 * Also closes the datepicker if a date range was selected.
	 * This logic could be improved when we start using the datepicker
	 * in other areas where we only select one date.
	 *
	 * @param {string[]} event
	 * @return {void}
	 */
	private handleInput(event: string[]): void {
		this.$emit('input', event);
		if(event.length >= 2) {
			setTimeout(() => {
				this.menu = false;
			}, 200);
		}
	}

	private handleClear(): void {
		this.dateRange = [];
		this.date = '';
		this.$emit('input', []);
	}

	// Keeps the text field focused when the date picker is open
	private handleBlur(): void {
		if (this.menu) {
			this.$refs.textField.focus();
		}
	}
}
