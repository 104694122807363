
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component<ChipContainer>({})
export default class ChipContainer extends Vue {
	@Prop({ type: Array, default: () => [] }) private chipsArray!: string[];
	@Prop({ type: Array, default: () => [] }) private valuesArray!: string[];
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;

	private mutableValuesArray: string[] = this.valuesArray;

	private addOrRemoveValue (chip: string): void {
		this.mutableValuesArray.includes(chip)
			? (this.mutableValuesArray.splice(this.mutableValuesArray.indexOf(chip), 1), this.$emit('alcohol-unchecked', chip))
			: this.mutableValuesArray.push(chip);
	}

}
