
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { Validate } from '@/types';
import { DateTime } from 'luxon';
import { TOAST_INSTANCE } from '@/utils/constants';
import ContactInformation from './form/ContactInformation.vue';
import DeliveryInformation from './form/DeliveryInformation.vue';
import MembershipInformation from './form/MembershipInformation.vue';
import SpecialRequests from './form/SpecialRequests.vue';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TakeoutInformation from './form/TakeoutInformation.vue';

const namespace = 'orders';

@Component<GuestDetailsModal>({
	components: {
		ContactInformation,
		DeliveryInformation,
		MembershipInformation,
		SpecialRequests,
		StandardModal,
		TakeoutInformation,
		ValidationObserver
	}
})
export default class GuestDetailsModal extends Vue {
	@Action('submitOrder', { namespace }) submitOrder!: () => void;
	@Getter('getOrderDetails', { namespace }) orderDetails!: OrderDetails;
	@Getter('getCheckoutInfo', { namespace }) checkout!: Checkout;
	@Getter('getModifyingOrder', { namespace }) modifyingOrder!: boolean;
	@Getter('getRestaurantDeliveryInfo', { namespace: 'auth' }) private restaurantDeliveryInfo!: DeliveryInfo | null;
	@Getter('getMembershipProgram', { namespace: 'auth' }) private membershipProgram!: string | null;
	@Getter('getMembershipCustomization', { namespace: 'auth' }) private membershipCustomization!: MembershipCustomization;
	@Prop({ type: Array, required: true }) private menus!: Menu[];
	@Prop({ type: Boolean, default: false }) showModal!: boolean;

	private coordinatesErrorMsg: string = '';
	private fetchingSchedulingInfo: boolean = false;
	private isValidating: boolean = false;
	private invalidDeliveryConfig: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;


	$refs!: { observer: Validate };

	private get showTakeoutDetails(): boolean {
		return this.orderDetails.type === 'take-out' || this.orderDetails.type === 'generic-catering';
	}

	/**
	 * Check if the delivery configuration is valid
	 * First check if the restaurant has delivery info
	 * Then check that the order type is delivery or generic catering
	 * Then check that the delivery type is address or custom (school and stadium are not supported)
	 *
	 * @return {boolean}
	 */
	private get showDeliveryDetails(): boolean {
		const hasDeliveryInfo = !!(this.restaurantDeliveryInfo && Object.keys(this.restaurantDeliveryInfo).length);
		const isOrderTypeValid = this.orderDetails?.takeoutType === 'delivery' || this.orderDetails?.type === 'generic-catering';
		const isDeliveryTypeValid = this.restaurantDeliveryInfo?.type === 'address' || this.restaurantDeliveryInfo?.type === 'custom';

		return hasDeliveryInfo && isDeliveryTypeValid && isOrderTypeValid;
	}

	private created(): void {
		// If the order type is delivery, but the restaurant does not have valid delivery settings, show an error message and disable the submit button
		if (this.checkout.pickup?.delivery && !this.showDeliveryDetails) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.guest_details.error_invalid_delivery_config');
			this.invalidDeliveryConfig = true;
		}
	}

	/**
	 * Calculates great-circle distances between two points using the
	 * harversine formula (keep in mind this is in a "crow" distance,
	 * meaning it does not count the obstacles in the way of the point
	 * A and B). The haversine formula also does not account for the earth
	 * not being perfectly spheroid, meaning there is a margin of error,
	 * but since this is used for a approximate distance, the margin is
	 * acceptable. If we want to make this even better, we could even use spherical
	 * geometry from GMaps API.
	 * For more info on the formula, see: https://en.wikipedia.org/wiki/Haversine_formula
	 *
	 * @param {number} lat1
	 * @param {number} lng1
	 * @param {number} lat2
	 * @param {number} lng2
	 * @return {number}
	 */
	private getDistanceFromLatLonInKm(lat1: number, lng1: number, lat2: number, lng2: number): number {
		const earthRadiusInKilometer = 6371;
		const dLat = this.degreeToRad(lat2 - lat1);
		const dLon = this.degreeToRad(lng2 - lng1);

		// Square of half the chord length between the points
		const squareChordLength =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.degreeToRad(lat1)) * Math.cos(this.degreeToRad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		// Angular distance in radians.
		var angularDistance = 2 * Math.atan2(Math.sqrt(squareChordLength), Math.sqrt(1 - squareChordLength));
		var distanceInKilometer = earthRadiusInKilometer * angularDistance;
		return distanceInKilometer;
	}

	/**
	 * Takes a degree value and translates it to
	 * a radian. Opted out of using the Number prototype toRad as
	 * it was being inconsistant in some cases.
	 *
	 * @param {number} degree
	 * @return {number}
	 */
	private degreeToRad(degree: number): number {
		return degree * (Math.PI / 180);
	}

	/**
	 * Validate all the input fields, save the pickup information if it's
	 * a take out order and save the user contact information if it's
	 * an anonymous order and proceed to the review screen.
	 *
	 * @return {Promise<void>}
	 */
	private async validateAndSubmit(): Promise<any> {
		this.isValidating = true;
		let isValid = await this.$refs.observer.validate();
		let deliveryValid = true;

		// Validate delivery radius
		if(this.checkout.pickup?.delivery) {
			deliveryValid = this.validateDelivery();
		}

		// If everything is validated, we can go next.
		if (isValid && deliveryValid) {
			this.coordinatesErrorMsg = '';
			this.setDueDateTimeIfAsapOrder();

			try {
				await this.submitOrder();

				if (this.modifyingOrder) {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.color = 'success';
					this.bannerToastInfo.message = this.$t('orders.guest_details.modify_success');
					this.$router.push('/order-management').catch(() => {});
				}
				else {
					this.$emit('order-submitted');
				}
			}
			catch (error) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('orders.guest_details.error_submitting_order', { error });
			}
		}
		else {
			setTimeout(() => {
				const requiredLabel = document.getElementsByClassName('error-input')[0] as HTMLElement;
				if (requiredLabel) {
					requiredLabel.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
				}
			}, 250);
		}
		this.isValidating = false;
	}

	/**
	 * Set the due date and time for an asap order
	 *
	 * @return {void}
	 */
	private setDueDateTimeIfAsapOrder(): void {
		const { pickup } = this.checkout;
		if (pickup && !pickup.scheduled) {
			pickup.dueByDate = DateTime.local().toISO()!;
			pickup.dueByTime = DateTime.local().toFormat(this.$t('orders.guest_details.takeout.timepicker_format'));
			if (pickup.dueByTime.length === 7) {
				pickup.dueByTime = '0' + pickup.dueByTime;
			}
		}
	}

	/**
	 * Validate delivery address
	 *
	 * @return {boolean}
	 */
	private validateDelivery(): boolean {
		const { delivery } = this.checkout!;
		this.coordinatesErrorMsg = '';
		if(delivery.type === 'address') {
			if(delivery.lat && delivery.lng) {
				if(delivery.streetNumber) {
					const defaultDelivery: DeliveryInfo = this.restaurantDeliveryInfo!;
					let distance = this.getDistanceFromLatLonInKm(delivery.lat, delivery.lng, defaultDelivery.lat!, defaultDelivery.lng!);
					if(distance > delivery.radius!) {
						this.coordinatesErrorMsg = this.$t('orders.guest_details.delivery.error_address_exceed_radius');
						return false;
					}
					return true;
				}
				this.coordinatesErrorMsg = this.$t('orders.guest_details.delivery.error_address_not_precise');
				return false;
			}
			this.coordinatesErrorMsg = this.$t('orders.guest_details.delivery.error_address_invalid');
			return false;
		}
		if(delivery.location && delivery.area) {
			return true;
		}
		return false;
	}
}
