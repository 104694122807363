
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';

const namespace: string = 'orders';

@Component<SpecialRequests>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})
export default class SpecialRequests extends Vue {
	@Action('setCheckoutAnsweredQuestions', { namespace }) setCheckoutAnsweredQuestions!: (answeredQuestions: AnsweredCustomQuestion[]) => void;
	@Action('setNotes', { namespace }) setNotes!: (notes: string) => void;
	@Getter('getOrderDetails', { namespace }) private orderDetails!: OrderDetails;
	@Getter('getCheckoutAnsweredQuestions', { namespace }) private checkoutAnsweredQuestions!: AnsweredCustomQuestion[] | null;
	@Getter('getNotes', { namespace }) private notes!: string;
	@Getter('getCustomQuestions', { namespace: 'auth' }) private questions!: CustomQuestion[] | null;

	private mutableQuestions: CustomQuestion[] = [];
	private specialInstructions: string = '';

	$refs!: {
		observer: Validate;
	};

	private get answeredQuestions(): CheckoutCustomQuestion[] {
		return this.mutableQuestions
			.filter((questionObj) => questionObj.answerObj && Object.keys(questionObj.answerObj).length)
			.map((questionObj) => {
				const index = questionObj.answerObj?.index as number;
				const answeredQuestion: CheckoutCustomQuestion = {
					question: questionObj.question,
					answer: questionObj.options![index]
				};

				// If the question has localization, add the question and answer for each locale
				if (questionObj.localization && Object.keys(questionObj.localization).length) {
					answeredQuestion.localization = {} as Localization;
					for (const locale in questionObj.localization) {
						answeredQuestion.localization[locale] = {
							question: questionObj.localization[locale].question,
							answer: questionObj.localization[locale].options[index]
						};
					}
				}
				return answeredQuestion;
			});
	}

	private created(): void {
		if (this.notes) {
			this.specialInstructions = this.notes;
		}
	}

	private mounted(): void {
		if (this.questions) {
			this.mutableQuestions = this.questions.map((questionObj: CustomQuestion) => {
				const answeredQuestion: CheckoutCustomQuestion | undefined = this.checkoutAnsweredQuestions?.find((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question);
				const output: CustomQuestion = { ...questionObj };

				// If the question has been already answered, set
				if (answeredQuestion) {
					const index = this.checkoutAnsweredQuestions?.findIndex((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question) as number;
					output.answerObj = {
						index,
						label: this.$options.filters?.localize(answeredQuestion, 'answer')
					};
				}
				return output;
			});
		}
	}

	/**
	* Return the localized options in an array of objects containing their indexes.
	* This index is needed to get each localized answer when building the answeredQuestions array.
	*
	* @param {CustomQuestion} question
	* @return {{ index: number; label: string; }[]}
	*/
	private buildOptions(question: CustomQuestion): { index: number; label: string; }[] {
		const localizedOptions: string[] | undefined = this.$options.filters?.localize(question, 'options');
		if (localizedOptions && localizedOptions.length) {
			const output = localizedOptions.map((questionOption: string, index: number) => {
				return {
					index,
					label: questionOption
				};
			});
			return output;
		}
		return question.options!.map((questionOption: string, index: number) => {
			return {
				index,
				label: questionOption
			};
		});
	}
}
