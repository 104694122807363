import { ROLES } from '@/utils/constants';
import { AxiosInstance } from 'axios';
import { GetterTree } from 'vuex';
import { AuthState, RootState } from '../types';

export const getters: GetterTree<AuthState, RootState> = {
	getTenantId(state: AuthState): string | null {
		return state.tenantId;
	},

	getAxiosInst(state: AuthState): AxiosInstance {
		return state.axiosInst;
	},

	getRestaurants(state: AuthState): Tenant[] | null {
		return state.restaurants;
	},

	getRestaurant(state: AuthState): Restaurant | object {
		return state.restaurant;
	},

	getRestaurantById(state): Tenant | object | null {
		return (id: string) => {
			if(state.restaurants) {
				return state.restaurants.find((restaurant: Tenant) => restaurant.id === id);
			}
			return null;
		};
	},

	getRoleLevel(state: AuthState): number {
		if (!state.role || (typeof ROLES[state.role] === undefined || typeof ROLES[state.role] === null)) {
			return ROLES.member;
		}
		else {
			return ROLES[state.role];
		}
	},

	isAuthenticated(state: AuthState): boolean {
		return state.authenticated;
	},

	isPosIntegrated(state: AuthState): boolean {
		return state.posIntegrated;
	},

	isMarketplaceEnabled(state: AuthState): boolean {
		return state.restaurant.marketplace_hub;
	},

	isOrderManagementEnabled(state: AuthState): boolean {
		return state.restaurant.order_management;
	},

	areSuitesEnabled(state: AuthState): boolean {
		return state.restaurant.suites_enabled;
	},

	isCateringRestaurant(state: AuthState): boolean {
		return state.restaurant.catering;
	},

	getOrderOptions(state:AuthState): OrderOptions {
		return state.restaurant.order_options || {};
	},

	getCustomQuestions(state:AuthState): CustomQuestion[] {
		return state.restaurant.order_options?.custom_questions || [];
	},

	getMaxItemsPerCart(state: AuthState): number {
		return state.restaurant.order_options.max_items_per_cart;
	},

	getRestaurantDeliveryInfo(state: AuthState): DeliveryInfo | null {
		return state.restaurant.delivery;
	},

	areNotificationSoundsAllowed(state: AuthState): boolean {
		return state.restaurant.menu_manager_config?.allow_order_manager_sound ?? false;
	},

	allowBulkComplete(state: AuthState): boolean {
		return state.restaurant.menu_manager_config?.allow_bulk_complete ?? false;
	},

	allowBulkCancel(state: AuthState): boolean {
		return state.restaurant.menu_manager_config?.allow_bulk_cancel ?? false;
	},

	isCsvImportEnabled(state: AuthState): boolean {
		return state.restaurant.menu_manager_config?.csv_import ?? false;
	},

	getContactFieldsConfig(state: AuthState): ContactFieldsConfig {
		const contactFieldsConfig: ContactFieldsConfig | undefined = state.restaurant.menu_manager_config?.contact_fields_config;

		// If the config is not set, return the default settings (all fields required and not hidden)
		if (!contactFieldsConfig || !Object.keys(contactFieldsConfig).length) {
			return {
				name: { required: true, hide: false },
				email: { required: true, hide: false },
				phone_number: { required: true, hide: false }
			};
		}
		return {
			name: {
				required: contactFieldsConfig?.name?.required ?? true,
				hide: contactFieldsConfig?.name?.hide ?? false
			},
			email: {
				required: contactFieldsConfig?.email?.required ?? true,
				hide: contactFieldsConfig?.email?.hide ?? false
			},
			phone_number: {
				required: contactFieldsConfig?.phone_number?.required ?? true,
				hide: contactFieldsConfig?.phone_number?.hide ?? false
			}
		};
	},

	getTimezone(state: AuthState): string {
		return state.restaurant.menu_configuration?.zone || 'America/Toronto';
	},

	getMembershipProgram(state: AuthState): string | undefined {
		return state.restaurant.membership_program;
	},

	getMembershipCustomization(state: AuthState): MembershipCustomization | undefined {
		return state.restaurant.menu_configuration?.membership_customization;
	}
};