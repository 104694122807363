
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Tooltip from '@/components/shared/Tooltip.vue';
import DeliveryAddress from './delivery/Address.vue';
import DeliveryStadium from './delivery/Stadium.vue';
import DeliverySchool from './delivery/School.vue';
import DeliveryCustom from './delivery/Custom.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import '@/validation-rules';

const namespace: string = 'auth';

@Component<DeliveryModal>({
	components: {
		ValidationObserver,
		ValidationProvider,
		StandardModal,
		VueGoogleAutocomplete,
		Tooltip,
		DeliveryAddress,
		DeliveryStadium,
		DeliverySchool,
		DeliveryCustom,
		BannerToast
	}
})
export default class DeliveryModal extends Vue {
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Prop({ type: Object, default: () => {} }) private restaurant!: Restaurant;
	@Prop({ type: Object, default: () => {} }) private targetDeliveryInfo!: Delivery;
	@Prop({ type: Number, default: 2 }) private targetDeliveryChargeTypeIsDollar!: number;
	@Prop({ type: Boolean, default: false }) private targetDeliveryEnabled!: boolean;
	@Prop({ type: Boolean, required: true, default: false }) private showModal!: boolean;
	@Watch('mutableDeliveryEnabled')
	onMutableDeliveryEnabledChange() {
		if (!this.mutableDeliveryEnabled) {
			this.deliveryChargeEnabled = false;
			this.mutableDeliveryInfo = {} as Delivery;
		}
	}
	@Watch('deliveryChargeEnabled')
	onDeliveryChargeEnabledChange() {
		if (!this.deliveryChargeEnabled) {
			this.mutableDeliveryCharge = '';
		}
		if(this.mutableDeliveryChargeTypeIsDollar === 2) {
			this.mutableDeliveryChargeTypeIsDollar = 0;
		}
	}

	$refs!: {observer: Validate};

	private deliveryTypes: { label: string, value: string }[] = [
		{ label: this.$t('advanced.delivery.types.address'), value: 'address' },
		{ label: this.$t('advanced.delivery.types.stadium'), value: 'stadium' },
		{ label: this.$t('advanced.delivery.types.school'), value: 'school' },
		{ label: this.$t('advanced.delivery.types.custom'), value: 'custom' }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private mutableDeliveryEnabled: boolean = this.targetDeliveryEnabled;
	private mutableDeliveryInfo: Delivery = this.targetDeliveryInfo;
	private mutableDeliveryCharge: string = '';
	private mutableDeliveryChargeTypeIsDollar: number = this.targetDeliveryChargeTypeIsDollar;
	private deliveryChargeEnabled: boolean = false;
	private loading: boolean = false;

	private mounted(): void {
		if(this.mutableDeliveryInfo?.delivery_charge) {
			this.deliveryChargeEnabled = true;
			this.mutableDeliveryCharge = this.mutableDeliveryInfo.delivery_charge.slice(0, - 1);
			this.$nextTick();
		}
	}

	/**
	 * Update delivery info from event
	 *
	 * @param {any} deliveryObject
	 * @return {void}
	 */
	private updateDeliverySettings(deliveryObject: any): void {
		this.mutableDeliveryEnabled = deliveryObject.enabled;
		this.mutableDeliveryInfo = deliveryObject.info;
		this.mutableDeliveryChargeTypeIsDollar = deliveryObject.type;
	}

	/**
	 * We validate the delivery depending on the type and then assign
	 * the values that we're not from the inputs if everything was
	 * valid
	 *
	 * @return {boolean}
	 */
	private validateDelivery(): boolean {
		if (this.mutableDeliveryEnabled && this.mutableDeliveryInfo) {
			// Validate geolocation
			if (this.mutableDeliveryInfo.type === 'address') {
				if (!this.validateDeliveryAddress()) {
					return false;
				}
			}

			// Validate custom
			if (this.mutableDeliveryInfo.type === 'custom') {
				if (!this.validateDeliveryCustom()) {
					return false;
				}
			}

			if (this.mutableDeliveryInfo.delivery_charge) {
				this.mutableDeliveryInfo.delivery_charge = this.mutableDeliveryInfo.delivery_charge + (this.mutableDeliveryChargeTypeIsDollar === 0 ? '$' : '%');
			}

			this.mutableDeliveryInfo.type = this.mutableDeliveryInfo.type?.toLowerCase();
			return true;
		}
		else {
			this.mutableDeliveryInfo = {} as Delivery;
			return true;
		}
	}

	/**
	 * Validate address delivery
	 * We essentially make sure that the autocomplete worked properly
	 * meaning we do have lat, lng, address and country which are crucial
	 *
	 * @return {boolean}
	 */
	private validateDeliveryAddress(): boolean {
		this.mutableDeliveryInfo.radius = +this.mutableDeliveryInfo.radius!;
		if (!this.mutableDeliveryInfo.lat || !this.mutableDeliveryInfo.lng || !this.mutableDeliveryInfo.address || !this.mutableDeliveryInfo.country) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.error_address');
			return false;
		}
		return true;
	}

	/**
	 * Validate custom delivery
	 * We essentially make sure that the object properties in the array (location, areas)
	 * are not empty, if they are, we slice them.
	 *
	 * @return {boolean}
	 */
	private validateDeliveryCustom(): boolean {
		this.mutableDeliveryInfo.custom = this.mutableDeliveryInfo.custom!.filter((location: CustomDeliveryElements) => location.location && location.areas);

		if (!this.mutableDeliveryInfo.areas_label && !this.mutableDeliveryInfo.location_label && !this.mutableDeliveryInfo.custom.length) {
			return false;
		}
		return true;
	}

	/**
	 * On delivery type changed, we wanna make sure to reset the delivery info
	 *
	 * @return {void}
	 */
	private deliveryTypeChanged(): void {
		if(this.mutableDeliveryInfo.type === 'address') {
			this.mutableDeliveryInfo = {
				type: 'address',
				address: '',
				country: '',
				lat: 0,
				lng: 0,
				radius: 0
			};
		}
		else if(this.mutableDeliveryInfo.type === 'stadium') {
			this.mutableDeliveryInfo = {
				type: 'stadium',
				sections: '',
				rows: '',
				seats: ''
			};
		}
		else if(this.mutableDeliveryInfo.type === 'school') {
			this.mutableDeliveryInfo = {
				type: 'school',
				classrooms: '',
				grades: ''
			};
		}
		else {
			this.mutableDeliveryInfo = {
				type: 'custom',
				custom: [{ location: '', areas: '' }]
			};
		}
	}

	/**
	 * Save the delivery info here. We first validate then we try to save
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
  		const isValid = await this.$refs.observer.validate();

		if(isValid && this.validateDelivery()) {
			try {
				// Only add delivery charge to payload if delivery is enabled, otherwise delivery charge will be left as an empty string in the delivery object when delivery is disabled
				if (this.mutableDeliveryEnabled) {
					this.mutableDeliveryInfo.delivery_charge = this.mutableDeliveryCharge ? `${this.mutableDeliveryCharge}${this.mutableDeliveryChargeTypeIsDollar === 0 ? '$' : '%'}` : '';
				}
				await this.updateRestaurant({ restaurant: { id: this.restaurant.id, delivery: this.mutableDeliveryInfo } });
				setTimeout(() => {
					this.loading = false;
					this.$emit('update-delivery-info', { enabled: this.mutableDeliveryEnabled, info: this.mutableDeliveryInfo, type: this.mutableDeliveryChargeTypeIsDollar });
				}, 500);
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('advanced.delivery.error_saving', { errorMessage });
			}
		}
		else {
			if(!this.bannerToastInfo.showMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('advanced.delivery.invalid_settings');
			}
		}

		setTimeout(() => {
			this.loading = false;
		}, 500);
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
