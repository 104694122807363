
import { Component, Prop, Vue } from 'vue-property-decorator';
import { validateHTMLColorHex } from 'validate-color';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<CustomTheme>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})
export default class CustomTheme extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetMenuConfig!: MenuConfiguration;

	private value = 0;
	private defaultPrimaryColor: string = '#5FBAB4';
	private mutableMenuConfiguration: MenuConfiguration = this.targetMenuConfig;

	private colorPickerOpened: any = false;
	private displayColor: string = this.mutableMenuConfiguration.primary_color ? this.forceHashPrefix(this.mutableMenuConfiguration.primary_color) : this.defaultPrimaryColor;

	private themeOptions: RadioOptions[] = [
		{ name: this.$t('customize.theme.light_rb_label'), value: 'light', option: 1 },
		{ name: this.$t('customize.theme.dark_rb_label'), value: 'dark', option: 2 }
	];
	private themeOptionSelected: number = 0;

	private created(): void {
		// default theme is light
		this.themeOptionSelected = this.mutableMenuConfiguration.theme === 'dark' ? 2 : 1;
	}

	private changeTheme(): void {
		Vue.set(this.mutableMenuConfiguration, 'theme', this.themeOptions.filter((themeOption: any) => themeOption.option === this.themeOptionSelected)[0].value!.toString().toLowerCase());
	}

	private updateDisplayColorFromPicker(evt: any): void {
		this.displayColor = evt.hex;

		Vue.set(this.mutableMenuConfiguration, 'primary_color', evt.hex);
	}

	private updateDisplayColor(hexColor: string): void {
		Vue.set(this.mutableMenuConfiguration, 'primary_color', this.forceHashPrefix(hexColor));

		// When invalid or empty, display color goes to the default one
		this.displayColor = validateHTMLColorHex(this.mutableMenuConfiguration.primary_color as string)
			? this.mutableMenuConfiguration.primary_color as string
			: this.defaultPrimaryColor;

		// Colors are valid if empty!
		if (!hexColor) {
			this.$emit('invalid-color', false);
		}
		else {
			this.$emit('invalid-color', !validateHTMLColorHex(this.mutableMenuConfiguration.primary_color as string));
		}
	}

	private forceHashPrefix(hexColor: string): string {
		if (hexColor) {
			return hexColor.startsWith('#') ? hexColor : `#${hexColor}`;
		}
		return '';
	}
}
