
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE, ROLES } from '../utils/constants';
import { valueSort } from '../utils/sort';
import { cloneDeep, isEqual, isNil } from 'lodash';
import omitBy from 'lodash/omitBy';
import TitleHeader from '@/components/shared/Header.vue';
import ListItem from '@/components/shared/ListItem.vue';
import AddButton from '@/components/shared/AddButton.vue';
import ModalWrapper from '@/components/shared/ModalWrapper.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import AddOnOptionGroupEditor from '@/components/add-ons/AddOnOptionGroupEditor.vue';

const namespace: string = 'options';

@Component<AddOnsList>({
	components: {
		TitleHeader,
		ListItem,
		AddButton,
		ModalWrapper,
		ConfirmationModal,
		PageLoader,
		AddOnOptionGroupEditor,
		BannerToast
	}
})
export default class AddOnsList extends Vue {
	@Action('fetchAddOnOptionGroups', { namespace }) private fetchAddOnOptionGroups!: () => Promise<Option[]>;
	@Action('deleteOptionGroup', { namespace }) private deleteOptionGroup!: (item: OptionGroup) => Promise<void>;
	@Action('setSelectedAddOnOptionGroup', { namespace }) private setSelectedAddOnOptionGroup!: (item: OptionGroup | object) => Promise<void>;
	@Getter('getAddOnOptionGroups', { namespace }) private addOnOptionGroups!: OptionGroup[];
	@Getter('getSelectedAddOnOptionGroup', { namespace }) private selectedAddOnOptionGroup!: OptionGroup;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace: 'auth' }) private isPosIntegrated!: boolean;

	private showDialog: boolean = false;
	private isCreating: boolean = false;
	private showDeleteModal: boolean = false;
	private showConfirmCloseModal: boolean = false;
	private fetchingLoader: boolean = false;
	private loading: boolean = false;
	private addOnsEdited: boolean = false;
	private itemToDelete: object | OptionGroup = {};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;
	private selectedLocale: RestaurantLocale|null = null;

	private selectedAddOnOptionGroupBeforeEditSnapshot: any | OptionGroup = {};
	private addOnsBeforeEditSnapshot: Option[] = [];

	private actionsArray: object[] = [
		{
			iconName: 'mdi-pencil',
			buttonLabel: this.$t('shared.actions_array.btn_edit'),
			onClick: (evt: object, item: OptionGroup): void => {
				this.openAddOnEditor(false, item);
				this.setSelectedAddOnOptionGroup(item);
			}
		},
		{
			iconName: 'mdi-delete',
			buttonLabel: this.$t('shared.actions_array.btn_delete'),
			onClick: (evt: object, item: OptionGroup): void => {
				this.setItemToDeleteName(item);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-window-close',
			buttonLabel: this.$t('shared.actions_array.btn_cancel'),
			onClick: null
		}
	];
	/**
	 * Checks for added, removed or edited addons and sets the respective flag
	 * if anything has changed.
	 *
	 * @param {Option[]} addOns
	 * @return {void}
	 */
	private setAddOnsEdited (addOns: Option[]): void {
		this.addOnsEdited = addOns.some((addOn, index) => (
			addOn.name !== this.addOnsBeforeEditSnapshot[index]?.name ||
			addOn.price !== this.addOnsBeforeEditSnapshot[index]?.price ||
			addOn.calories !== this.addOnsBeforeEditSnapshot[index]?.calories ||
			addOn.max_quantity !== this.addOnsBeforeEditSnapshot[index]?.max_quantity
		));
	}

	/**
	 * Open Create Add On Modal
	 * @return {void}
	 */
	private openAddOnEditor (creating: boolean, item: OptionGroup) {
		this.showDialog = true;
		this.isCreating = creating;

		if (!this.isCreating) {
			this.selectedAddOnOptionGroupBeforeEditSnapshot = cloneDeep(item);
			this.addOnsBeforeEditSnapshot = cloneDeep(item.values as Option[]);
		}

		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Fetch the option groups
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		this.fetchAddons();
	}

	/**
	 * Set the item name for the deletion modal
	 *
	 * @return {void}
	 */
	private setItemToDeleteName(item: OptionGroup): void {
		this.showDeleteModal = true;
		this.itemToDelete = item;
	}

	/**
	 * Cancel closing of modal through the confirmation close modal
	 *
	 * @return {void}
	 */
	private cancelCloseModal(): void {
		this.showConfirmCloseModal = false;
	}

	/**
	 * Check if needs to show the confirm close modal to the user
	 *
	 * @return {void}
	 */
	private confirmClose(saved: boolean = false) {
		const selectedOptionGroupToCompare = omitBy(this.selectedAddOnOptionGroup, isNil);
		const selectedOptionGroupBeforeEditSnapshotToCompare = omitBy(this.selectedAddOnOptionGroupBeforeEditSnapshot, isNil);
		this.selectedLocale = null;

		if (selectedOptionGroupBeforeEditSnapshotToCompare && selectedOptionGroupBeforeEditSnapshotToCompare.values && selectedOptionGroupBeforeEditSnapshotToCompare.values.length) {
			this.selectedAddOnOptionGroupBeforeEditSnapshot.values = this.selectedAddOnOptionGroupBeforeEditSnapshot.values.sort((a: Option, b: Option) => valueSort(a, b, 'price'));
		}

		if ((saved || (isEqual(selectedOptionGroupToCompare, selectedOptionGroupBeforeEditSnapshotToCompare)) && !this.addOnsEdited)) {
			this.close(saved);
		}
		else {
			this.showConfirmCloseModal = true;
		}
	}

	/**
	 * Close the AddOnOptionGroupEditor modal
	 *
	 * @return {void}
	 */
	private async close(saved: boolean): Promise<void> {
		this.showConfirmCloseModal = false;
		this.showDialog = false;
		this.isCreating = false;
		this.addOnsEdited = false;

		// Remove the selected option from the store
		this.setSelectedAddOnOptionGroup({});
		this.selectedAddOnOptionGroupBeforeEditSnapshot = {};
		this.addOnsBeforeEditSnapshot = [];
		document.documentElement.classList.remove('modal-open');

		// Re-fetch the option groups if the modal was simply closed
		if (!saved) {
			this.fetchAddons();
		}
	}

	private async fetchAddons(): Promise<void> {
		this.fetchingLoader = true;
		await this.fetchAddOnOptionGroups()
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('menu.addon_groups.error_fetching', { errorMessage });
			});
		this.fetchingLoader = false;
	}

	private cancel(): void {
		this.showDeleteModal = false;
		this.itemToDelete = {};
	}

	/**
	 * User confirmed deletion of the item, so we delete it
	 * and close the modal
	 *
	 * @return {Promise<void>}
	 */
	private async confirm(): Promise<void> {
		this.loading = true;
		await this.deleteOptionGroup(this.itemToDelete as OptionGroup)
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: (this.itemToDelete as OptionGroup).name, errorMessage });
			});
		this.itemToDelete = {};
		this.loading = false;
		this.showDeleteModal = false;
	}
}
