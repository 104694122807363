
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import PageLoader from '@/components/shared/PageLoader.vue';
import TitleHeader from '@/components/shared/Header.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import Availability from '@/components/menu/form/Availability.vue';

const namespace: string = 'menus';

@Component<ReplicateMenus>({
	components: {
		PageLoader,
		TitleHeader,
		BannerToast,
		Availability
	}
})
export default class ReplicateMenus extends Vue {
	@Action('copyMenusToRestaurants', { namespace }) private copyMenusToRestaurants!: (payload: any) => Promise<void>;
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Getter('getRestaurants', { namespace: 'auth' }) private restaurants!: Tenant[];
	@Getter('getTenantId', { namespace: 'auth' }) private tenantId!: string;

	private loading: boolean = false;
	private showSaveBtn: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private menuList: Menu[] = [];
	private restaurantIdList: string[] = [];

	private mounted(): void {
		this.positionSaveButton();
	}

	/**
	 * List of restaurants but with the restaurant that is copying stripped from the list
	 *
	 * @param {Menu[]} menus
	 * @return {Menu[]}
	 */
	private restaurantList(restaurants: Tenant[]): Tenant[] {
		return restaurants.filter((tempRestaurant: Tenant) => tempRestaurant.id !== this.tenantId);
	}

	/**
	 * Show respective toast depending if the update
	 * succeeded or failed
	 *
	 * @param {any} responseObj
	 * @return {void}
	 */
	private showToast(responseObj: any): void {
		this.bannerToastInfo.color = responseObj.color;
		this.bannerToastInfo.showMessage = true;
		this.bannerToastInfo.message = responseObj.message;
	}

	/**
	 * Push or remove the menu depending on if it was selected or unselected
	 * from the menu list that we will copy to selected restaurant(s).
	 *
	 * @param {boolean} value
	 * @param {number} id
	 * @return {void}
	 */
	private onMenuSelected(value: boolean, menu: Menu): void {
		value ? this.menuList.push(menu) : this.menuList.splice(this.menuList.indexOf(menu), 1);
	}

	/**
	 * Push or remove the restaurant depending on if it was selected or unselected
	 * from the id list that we will copy the menus to.
	 *
	 * @param {boolean} value
	 * @param {number} id
	 * @return {void}
	 */
	private onRestaurantSelected(value: boolean, id: string): void {
		value ? this.restaurantIdList.push(id) : this.restaurantIdList.splice(this.restaurantIdList.indexOf(id), 1);
	}

	/**
	 * Position save button depending on the drawer size when changing
	 * menu tab
	 *
	 * @return {void}
	 */
	private positionSaveButton(): void {
		this.showSaveBtn = false;
		setTimeout(() => {
			const drawer = document.getElementById('nav-drawer');
			if(drawer) {
				document.documentElement.style.setProperty('--dynamic-btn-left-value', `${drawer.offsetWidth}px`);
			}
			this.showSaveBtn = true;
		}, 400);
	}

	/**
	 * Copy the menus selected to the restaurants selected
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
		await this.copyMenusToRestaurants({ menus: this.menuList, restaurantIds: this.restaurantIdList })
			.then(() => {
				this.showToast({ success: true, color: '#00B796', message: this.$t('menu.replicate.success_message') });
			})
			.catch((errorMessage: any) => {
				this.showToast({ success: false, color: 'primary', message: this.$t('menu.replicate.error_saving', { errorMessage }) });
			})
			.finally(() => {
				this.menuList = [];
				this.restaurantIdList = [];
				this.loading = false;
			});
	}
}
