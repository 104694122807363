
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { Getter } from 'vuex-class';
import TextButton from '@/components/shared/suites/TextButton.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import '@/validation-rules';

const namespace = 'auth';

@Component<Custom>({
	components: {
		ValidationProvider,
		TextButton,
		InfoBanner
	}
})
export default class Custom extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetDeliveryInfo!: DeliveryInfo;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;

	private mutableDeliveryInfo: any = this.targetDeliveryInfo;
	private selectedLocale: RestaurantLocale = {} as RestaurantLocale;
	private customLocations: CustomDeliveryElements[] = [];

	private get isDefaultLocale(): boolean {
		return !this.selectedLocale || !Object.keys(this.selectedLocale).length || (this.selectedLocale && this.selectedLocale.is_default);
	}

	private created(): void {
		this.setLocaleToDefault();

		if(this.mutableDeliveryInfo.custom?.length) {
			this.customLocations = this.mutableDeliveryInfo.custom;
		}
		else if(!this.mutableDeliveryInfo.custom || !this.mutableDeliveryInfo.custom.length) {
			this.customLocations = [{ location: '', areas: '' }];
		}
	}

	/**
	 * Switch to default locale
	 *
	 * @return {void}
	 */
	private setLocaleToDefault(): void {
		if (this.restaurant.locales?.length > 1) {
			this.restaurant.locales.sort((a: RestaurantLocale, b: RestaurantLocale) => Number(b.is_default) - Number(a.is_default));
			this.selectedLocale = this.restaurant.locales.find((locale: RestaurantLocale) => locale.is_default)!;
		}
	}

	/**
	 * Locale changed and isn't default, we need to setup the localization objects for both the root object
	 * for the labels but also the custom array objects.
	 *
	 * @return {void}
	 */
	private selectedLocaleChanged(): void {
		if (!this.isDefaultLocale) {
			this.mutableDeliveryInfo.localization = this.mutableDeliveryInfo.localization || {} as Localization;
			this.mutableDeliveryInfo.localization[this.selectedLocale.locale_short] = this.mutableDeliveryInfo.localization[this.selectedLocale.locale_short] || {
				location_label: '',
				areas_label: ''
			};

			this.customLocations.forEach((custom: CustomDeliveryElements) => {
				custom.localization = custom.localization || {} as Localization;
				custom.localization[this.selectedLocale.locale_short] = custom.localization[this.selectedLocale.locale_short] || {
					location: '',
					areas: ''
				};
			});

			this.mutableDeliveryInfo.custom = this.customLocations;
		}
	}

	private updateDeliveryInfo(): void {
		this.mutableDeliveryInfo.custom = this.customLocations;
	}

	private removeCustomLocation(index: number): void {
		this.mutableDeliveryInfo.custom.splice(index, 1);
	}

	private addCustomLocation(): void {
		this.customLocations.push({ location: '', areas: '' });
	}
}
