
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ALLERGENS_LIST, DIETS_LIST } from '@/utils/constants';
import Tooltip from '@/components/shared/Tooltip.vue';
import ChipContainer from '@/components/shared/ChipContainer.vue';


@Component<NutritionFacts>({
	components: {
		Tooltip,
		ChipContainer
	}
})
export default class NutritionFacts extends Vue {
	@Prop({ type: String, default: '' }) private name!: string;
	@Prop({ type: Array, default: () => [] }) private ingredients!: string[];
	@Prop({ type: Array, default: () => [] }) private allergens!: string[];
	@Prop({ type: Array, default: () => [] }) private diets!: string[];
	@Prop({ type: String, default: '' }) private description!: string;
	@Prop({ type: Boolean, default: false }) private alcoholicBeverageCheck!: boolean;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Watch('alcoholicBeverageCheck')
	onAlcoholicBeverageCheckChange() {
		this.mutableAlcoholicBeverageCheck = this.alcoholicBeverageCheck;
	}

	private mutableIngredients: string[] = this.ingredients;
	private mutableAllergens: string[] = this.allergens;
	private mutableDiets: string[] = this.diets;
	private mutableDescription: string = this.description;
	private mutableAlcoholicBeverageCheck: boolean = this.alcoholicBeverageCheck;
	private allergenList: object[] = ALLERGENS_LIST;
	private dietList: object[] = DIETS_LIST;

	private updateAutoGeneratedDescription = () => (
		this.mutableIngredients?.filter(Boolean).length
			? `${this.mutableIngredients.filter(Boolean).join(', ')}`
			: ''
	);
	private autoGeneratedDescription = this.updateAutoGeneratedDescription();
	private autoGenerateDescriptionSwitch: boolean = !!(this.mutableDescription && (this.mutableDescription === this.autoGeneratedDescription));
	private useAutoGeneratedDescription: boolean = this.autoGenerateDescriptionSwitch && (this.mutableDescription === this.autoGeneratedDescription);

	private changeAutoGeneratedDescriptionSwitch(): void {
		if (this.autoGenerateDescriptionSwitch) {
			this.mutableDescription = this.autoGeneratedDescription;
		}
		else {
			this.mutableDescription = '';
		}
		this.$emit('update-description', this.mutableDescription);
		this.$emit('set-as-edited');
	}

	private addIngredient(): void {
		this.mutableIngredients.push('');
		this.$emit('set-as-edited');
	}

	private removeIngredient(index: number): void {
		this.mutableIngredients.splice(index, 1);
		this.autoGeneratedDescription = this.updateAutoGeneratedDescription();
		if (this.autoGenerateDescriptionSwitch) {
			this.mutableDescription = this.autoGeneratedDescription;
			this.$emit('update-description', this.mutableDescription);
		}
	}

	private onUpdateIngredient(): void {
		this.autoGeneratedDescription = this.updateAutoGeneratedDescription();
		if (this.autoGenerateDescriptionSwitch) {
			this.mutableDescription = this.autoGeneratedDescription;
			this.$emit('update-description', this.mutableDescription);
		}
	}

	private updateDescription(evt: any): void {
		this.autoGenerateDescriptionSwitch = false;
		setTimeout(() => {
			this.$emit('update-description', evt.target.value);
		}, 100);
	}

	private updateAlcoholicBeverageCheck(value: boolean): void {
		this.$emit('update-alcoholic-beverage-check', value);
	}
}
