import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { OptionState, RootState } from '../types';

const namespaced: boolean = true;

export const state: OptionState = {
	selectedAddOnOptionGroup: {},
	addOns: [],
	addOnOptionGroups: [],
	itemOptionGroups: []
};

export const Options: Module<OptionState, RootState> = {
	namespaced,
	state,
	mutations,
	getters,
	actions
};

export default Options;