
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ROLES, TOAST_INSTANCE } from '../../utils/constants';
import BannerToast from '@/components/shared/BannerToast.vue';
import CsvImportModal from '@/components/menu/CsvImportModal.vue';

const namespace: string = 'auth';

enum LeftActionTypeEnum {
	Search = 'mdi-magnify',
	Back = 'mdi-chevron-left',
	Close = 'mdi-close'
};

@Component<Header>({
	components: {
		BannerToast,
		CsvImportModal
	}
})
export default class Header extends Vue {
	@Action('pullMenus', { namespace: 'menus' }) private pullMenus!: (payload: any) => Promise<void>;
	@Action('pullAllMenus', { namespace: 'menus' }) private pullAllMenus!: () => Promise<void>;
	@Getter('isPosIntegrated', { namespace }) private isPosIntegrated!: boolean;
	@Getter('isCsvImportEnabled', { namespace }) private csvImportEnabled!: boolean;
	@Getter('getRoleLevel', { namespace }) private roleLevel!: number;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Prop({ type: String, default: '' }) private title!: string;
	@Prop({ type: String, default: '' }) private leftActionType!: LeftActionTypeEnum;
	@Prop({ type: Boolean, default: false }) private settings!: boolean;
	@Prop({ type: Boolean, default: false }) private pullAll!: boolean;
	@Prop({ type: String, default: '' }) private prevUrl!: string;

	private roles: Roles = ROLES;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private showCsvImportModal: boolean = false;

	/**
	 * Handle the left button action, this can be either
	 * a search, going to a previous screen or closing a
	 * modal.
	 *
	 * @return {void}
	*/
	private handleLeftAction(): void {
		if (this.leftActionType === LeftActionTypeEnum.Back) {
			if (this.$listeners.back) {
				this.$emit('back');
			}
			else {
				this.$router.back();
			}
		}
		else if (this.leftActionType === LeftActionTypeEnum.Close) {
			this.$emit('close-modal', false);
		}
		else if (this.leftActionType === LeftActionTypeEnum.Search) {
			this.$emit('search');
		}
	}

	/**
	 * Pull menu(s)
	 *
	 * @return {void}
	 */
	private async menuPull(): Promise<void> {
		try {
			this.pullAll ? await this.pullAllMenus() : await this.pullMenus({ id: this.restaurant.id, name: this.restaurant.name, pullAll: false });
			this.bannerToastInfo.color = '#00B796';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('menu.pull.success_menu_pull');
		}
		catch (error) {
			this.bannerToastInfo.color = 'primary';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('menu.pull.error_menu_pull');
		}
	}
}
