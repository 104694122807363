import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SuiteOwnerState, RootState } from '../types';
const namespaced: boolean = true;

export const state: SuiteOwnerState = {
	suiteOwners: []
};


export const SuiteOwner: Module<SuiteOwnerState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default SuiteOwner;