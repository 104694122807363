import { AuthState, OrdersState } from '@/store/types';
import { DateTime } from 'luxon';

/**
 * Format the order before submitting to the backend
 *
 * @param {OrdersState} state
 * @param {AuthState} authState
 * @return {void}
 */
export default function formatOrder(state: OrdersState, authState: AuthState): any {
	const { cart, checkout, costs, orderDetails } = state;
	const { restaurant } = authState;
	return {
		config: {
			restaurantId: restaurant.app8_restaurant,
			employeeId: authState.employeeId,
			tableNum: orderDetails.tableNum,
			tableInfo: {
				tableArea: orderDetails.tableArea,
				tableLocation: orderDetails.tableLocation,
				tableNum: orderDetails.tableNum,
				checkNum: orderDetails.checkNum
			},
			menuManagerEvent: orderDetails.menuManagerEvent,
			menuManagerLaneReservation: orderDetails.menuManagerLaneReservation,
			isAppUser: false
		},
		items: cart.items,
		costs: costs,
		checkout: {
			pickup: {
				delivery: checkout.pickup?.delivery ?? false,
				notes: checkout.pickup?.notes ?? null
			},
			delivery: checkout.pickup?.delivery && checkout.delivery?.type ? checkout.delivery : null,
			no_takeout_timeslot: restaurant.order_options?.no_takeout_timeslot ?? false,
			questions: checkout.questions?.length ? checkout.questions : null,
			contact: {
				full_name: checkout.contact.full_name,
				email: checkout.contact.email,
				phone_number: checkout.contact.phone_number
			},
			memberInfo: checkout.memberInfo && checkout.memberInfo.id
				? { id: checkout.memberInfo.id, identifier: checkout.memberInfo.identifier, promosApplied: checkout.memberInfo.promosApplied ? Array.from(checkout.memberInfo.promosApplied) : null, promos: checkout.memberInfo.promos }
				: null
		},
		date: DateTime.local().toISO(),
		isMenuManagerOrder: true
	};
}
