
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<StatusIcon>({})
export default class StatusIcon extends Vue {
	@Prop({ type: String, required: true, default: '' }) private status!: string;

	private statusIcon: { [key: string]: string } = {
		'past_event': 'mdi-history',
		'setup_incomplete': 'mdi-clipboard-alert-outline',
		'pre_order_active': 'mdi-lock-open-outline',
		'pre_order_locked': 'mdi-lock-outline'
	};

	private statusClass: { [key: string]: string } = {
		'past_event': 'neutral',
		'setup_incomplete': 'error',
		'pre_order_active': 'success',
		'pre_order_locked': 'info'
	};

}
