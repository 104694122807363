
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { TOAST_INSTANCE } from '@/utils/constants';
import { alphabeticalSort } from '@/utils/sort';
import { addBorderAroundTable, addStylingToHorizontalHeader, downloadFile, formatItemOptions, setCellFontSizeAndAlignement, alignColumns, formatCustomQuestions } from '@/utils/exceljs-helpers';
import { getOrderDelivery } from '@/utils/getters';
import * as ExcelJS from 'exceljs';
import OrderItemsList from '@/components/suites/beo/OrderItemsList.vue';
import OrderItem from '@/components/suites/beo/OrderItem.vue';
import OrdersTable from '@/components/report/OrdersTable.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import SuitesPanel from '@/components/shared/suites/panels/SuitesPanel.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import DatePicker from '@/components/shared/DatePicker.vue';

const namespace = 'auth';

@Component<OrdersReport>({
	components: {
		TextButton,
		OrderItemsList,
		OrderItem,
		SuitesHeader,
		SuitesPanel,
		DatePicker,
		OrdersTable
	}
})
export default class OrdersReport extends Vue {
	@Action('fetchOrdersReport', { namespace }) private fetchOrdersReport!: (dateRange: string[]) => Promise<{ groupedItems: OrderItemGroup[], orders: EventSuiteOrder[]}>;
	@Getter('getRestaurantDeliveryInfo', { namespace }) private deliveryInfo!: DeliveryInfo | null;

	private selectedDateRange: string[] = [];
	private reportDateRange: string[] = [];
	private ordersSummary: OrderItemGroup[] | null = null;
	private orders: EventSuiteOrder[] | null = null;
	private isFetching: boolean = false;
	private includePrices: boolean = true;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private sortSelected: string = 'date';
	private sortOptions: { label: string, value: string }[] = [
		{ label: this.$t('orders_report.orders_summary.sort_options.date'), value: 'date' },
		{ label: this.$t('orders_report.orders_summary.sort_options.grade'), value: 'grade' },
		{ label: this.$t('orders_report.orders_summary.sort_options.student'), value: 'student' }
	];

	private get formattedDates(): { startDate: string, endDate: string } | null {
		return this.reportDateRange.length ? {
			startDate: this.reportDateRange.length ? DateTime.fromISO(this.reportDateRange[0]).toFormat(this.$t('orders_report.report_input_date_format')) : '',
			endDate: this.reportDateRange.length ? DateTime.fromISO(this.reportDateRange[1]).toFormat(this.$t('orders_report.report_input_date_format')) : ''
		} : null;
	}

	private get sortedOrders(): EventSuiteOrder[] | null {
		if (this.orders === null) {
			return null;
		}
		if (this.deliveryInfo?.type === 'school' && (this.sortSelected === 'grade' || this.sortSelected === 'student')) {
			return [...this.orders].sort((a, b) => alphabeticalSort(
				a.data.checkout.delivery?.[this.sortSelected as 'grade'|'student'] ? a.data.checkout.delivery : '',
				b.data.checkout.delivery?.[this.sortSelected as 'grade'|'student'] ? b.data.checkout.delivery : '',
				this.sortSelected
			));
		}
		return this.orders;
	}

	private setDateRange(event: string[]): void {
		this.selectedDateRange = event;
	}

	/**
	 * Fetch orders report for the selected date range
	 *
	 * @return {Promise<void>}
	 */
	private async getReport(): Promise<void> {
		this.isFetching = true;
		try {
			const res = await this.fetchOrdersReport(this.selectedDateRange);
			this.orders = res.orders;
			this.ordersSummary = res.groupedItems;
			this.reportDateRange = this.selectedDateRange;
		}
		catch (errorMessage) {
			this.orders = [];
			this.ordersSummary = [];
			this.reportDateRange = [];
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders_report.error_fetching', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isFetching = false;
			}, 500);
		}
	}

	/**
	 * Filter date picker's dates to allow only dates within 1 month if a first date is selected
	 *
	 * @param {string} startDate - first date selected in the date range picker
	 * @return {boolean}
	 */
	private maxRange(startDate: string): boolean {
		if (this.selectedDateRange[0]) {
			const maxDate = DateTime.fromISO(this.selectedDateRange[0]).plus({ month: 1 });
			const gapToOneMonth = maxDate.diff(DateTime.fromISO(startDate), 'months').toObject().months!;
			return gapToOneMonth >= 0 && gapToOneMonth <= 1;
		}
		return true;
	}

	/**
	 * Check if an order has delivery or pickup instructions
	 *
	 * @param {EventSuiteOrder} order
	 * @return {boolean}
	 */
	private doesOrderHaveInstructions(order: EventSuiteOrder): boolean {
		return !!(order.data.checkout?.pickup?.notes || order.data.checkout?.delivery);
	}

	/**
	 * Get delivery time for suite orders
	 *
	 * @param {EventSuiteOrder} order
	 * @return {string | null}
	 */
	private getSuitesDeliveryTime(order: EventSuiteOrder): string | null {
		if (order.data.suites && order.data.suites.orderDeliveryTime) {
			const deliveryTime: DateTime = DateTime.fromISO(order.data.suites.orderDeliveryTime);
			if (deliveryTime.isValid) {
				return deliveryTime.toFormat(this.$t('beo.summary.delivery_times.datetime.date_format'));
			}
			return this.$t(`beo.summary.delivery_times.basic.${order.data.suites.orderDeliveryTime}`);
		}
		return null;
	}

	/**
	 * Print the orders report page
	 *
	 * @return {void}
	 */
	private printReport(): void {
		document.title = this.$t('orders_report.print_title', this.formattedDates!);
		history.replaceState(null, '', `/${this.$t('orders_report.print_url')}`);
		window.print();
		document.title = 'Menu Management';
		history.replaceState(null, '', '/orders-report');
	}

	/**
	 * Export the orders report to an Excel file.
	 *
	 * @return {void}
	 */
	private exportData(): void {
		const workbook = new ExcelJS.Workbook();
		this.createProductionSummaryExcelTab(workbook);
		this.createAcceptedOrdersSummary(workbook);

		// Save the workbook to a file
		workbook.xlsx.writeBuffer().then(buffer => {
			downloadFile(buffer, this.$t('orders_report.spreadsheet.file_name', { startDate: this.formattedDates?.startDate, endDate: this.formattedDates?.endDate }));
		});
	}

	/**
	 * Create the first tab in the Excel file with the production summary.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createProductionSummaryExcelTab(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('orders_report.production_summary_title'), {
			pageSetup: { orientation: 'landscape' }
		});

		worksheet.columns = [
			{ header: this.$t('orders_report.spreadsheet.quantity_col'), key: 'quantity', width: 13 },
			{ header: this.$t('orders_report.spreadsheet.item_name_col'), key: 'name', width: 30 },
			{ header: this.$t('orders_report.spreadsheet.modifiers_col'), key: 'options', width: 55 },
			{ header: [this.$t('orders_report.spreadsheet.category_col')], key: 'category', width: 30 }
		];

		const ordersData = this.ordersSummary!.map((category: OrderItemGroup) => {
			const sectionData = category.items.map((item) => {
				return {
					name: this.$options.filters?.localize(item, 'name'),
					quantity: item.quantity,
					options: formatItemOptions(item),
					category: this.$options.filters?.localize(category, 'name') || ''
				};
			});
			return sectionData;
		}).flat();

		worksheet.addRows(ordersData);
		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		addStylingToHorizontalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);
	}

	/**
	 * Create the second tab in the Excel file with the accepted orders summary.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createAcceptedOrdersSummary(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('orders_report.orders_summary.title'), {
			pageSetup: { orientation: 'landscape' }
		});

		// Build columns
		const columns = [
			{ header: this.$t('orders_report.spreadsheet.order_type_col'), key: 'type', width: 15 },
			{ header: this.$t('orders_report.spreadsheet.order_number_col'), key: 'number', width: 20 },
			{ header: this.$t('orders_report.spreadsheet.scheduled_for_col'), key: 'scheduled_for', width: 30, style: { numFmt: this.$t('orders_report.spreadsheet.date_format') } },
			{ header: this.$t('orders_report.spreadsheet.table_number_col'), key: 'delivery', width: 60 },
			{ header: this.$t('orders_report.spreadsheet.item_name_col'), key: 'name', width: 30 },
			{ header: this.$t('orders_report.spreadsheet.category_col'), key: 'category', width: 30 },
			{ header: this.$t('orders_report.spreadsheet.quantity_col'), key: 'quantity', width: 13 },
			{ header: this.$t('orders_report.spreadsheet.modifiers_col'), key: 'options', width: 55 },
			{ header: this.$t('orders_report.spreadsheet.instructions_col'), key: 'instructions', width: 30 },
			{ header: this.$t('orders_report.spreadsheet.guest_name_col'), key: 'guest_name', width: 20 },
			{ header: this.$t('orders_report.spreadsheet.guest_phone_col'), key: 'phone', width: 20 },
			{ header: this.$t('orders_report.spreadsheet.questions_col'), key: 'questions', width: 55 }
		];
		if (this.includePrices) {
			columns.splice(6, 0, { header: this.$t('orders_report.spreadsheet.rate_col'), key: 'rate', width: 12 });
		}
		worksheet.columns = columns;

		this.sortedOrders!.forEach((order: EventSuiteOrder) => {
			// Add the rows for each item
			const itemsData = order.data.items.map((item) => {
				const dateValue = DateTime.fromISO(order.pickup_time ? order.pickup_time : order.updated_at).setZone('UTC', { keepLocalTime: true });

				return {
					type: this.$t(`orders_report.spreadsheet.order_type.${order.type}`),
					number: order.id,
					scheduled_for: dateValue.toJSDate(),
					delivery: getOrderDelivery(order) || '',
					name: this.$options.filters?.localize(item, 'name'),
					category: this.$options.filters?.localize(item.parent, 'name') || '',
					quantity: item.quantity,
					options: formatItemOptions(item),
					rate: this.$n(Number(item.price) || 0, 'currency'),
					phone: order.data.checkout.contact?.phone_number || '',
					guest_name: order.data.checkout.contact?.full_name || '',
					instructions: order.data.checkout.pickup?.notes || '',
					questions: formatCustomQuestions(order.data.checkout)
				};
			});

			worksheet.addRows(itemsData);
		});

		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		alignColumns(worksheet, [2, 7, 8], 'right');
		addStylingToHorizontalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);
	}
}
