
import { Component, Prop, Vue } from 'vue-property-decorator';

import Tooltip from '@/components/shared/Tooltip.vue';
import { formatValue } from '@/utils/valueValidation';


@Component<Pricing>({
	components: {
		Tooltip
	}
})
export default class Pricing extends Vue {
	@Prop({ type: Array, default: () => [] }) private pricingItems!: Option[];
	@Prop({ type: String, default: true }) private toolTipText!: '';
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;

	private get pricingArray() {
		if (this.disabled) {
			return this.pricingItems.filter((item: any) => (item.price));
		}
		return this.pricingItems;
	}

	private formatPricingItemPrice = (price: any) => formatValue(price, '', 'price');

	private formatPricingItemCalories = (calories: number) => formatValue(calories, null, 'calories');

	private addAddOnForm () {
		this.$emit('add-pricing-item');
	}

	private removeAddOnForm (index: number) {
		this.$emit('remove-pricing-item', index);
	}
}
