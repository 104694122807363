
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<SectionLocaleEditor>({})
export default class SectionLocaleEditor extends Vue {
	@Prop({ type: Object, default: () => {} }) private section!: MenuSection;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Prop({ type: Boolean, default: false }) private valid!: boolean;
	@Watch('selectedLocale')
	onSelectedLocaleUpdate() {
		// If default we don't need to add any localization info, as those are already
		// in the default values
		if(this.selectedLocale && !this.selectedLocale.is_default) {
			this.mutableSection.localization = this.mutableSection.localization ? this.mutableSection.localization : {} as Localization;
			// If no localization for that specific language, we need to initialize them
			if(!this.mutableSection.localization[this.selectedLocale.locale_short]) {
				this.mutableSection.localization[this.selectedLocale.locale_short] = {
					name: '',
					description: ''
				};
			}
		}
	}

	private mutableSection: MenuSection = this.section;
}
