
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';

const namespace: string = 'eventTypes';

@Component<EventTypes>({
	components: {
		SuitesHeader,
		FullPageSpinner,
		TextButton,
		IconButton,
		TableHeaders
	}
})
export default class EventTypes extends Vue {
	@Action('fetchEventTypes', { namespace }) private fetchEventTypes!: () => Promise<void>;
	@Action('updateEventType', { namespace }) private updateEventType!: (eventType: EventType) => Promise<void>;
	@Action('addEventType', { namespace }) private addEventType!: (eventType: { name: string }) => Promise<void>;
	@Getter('getEventTypes', { namespace }) private eventTypes!: EventType[];

	private isLoading: { [key: string]: boolean } = {
		page: false,
		save: false
	};
	private mutableEventTypes: EditableEventType[] = [];
	private breadcrumbs: Breadcrumb[] = [{
		text: this.$t('event_settings.header'),
		to: '/event-settings',
		exact: true
	},
	{
		text: this.$t('event_settings.event_types.title'),
		to: '/event-settings/event-types',
		exact: true
	}];
	// TODO: Uncomment Default menu assignments, # of event columns and delete when implemented
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('event_settings.event_types.table_header.name') },
		// { text: this.$t('event_settings.event_types.table_header.default_menus') },
		// { text: this.$t('event_settings.event_types.table_header.number_events') },
		{ text: this.$t('event_settings.event_types.table_header.edit'), class: 'icon-col' }
		// { text: this.$t('event_settings.event_types.table_header.delete') }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	// Return if any event type is in editing mode
	private get isAnyEditing(): boolean {
		return this.mutableEventTypes.some(type => type.isEditing === true);
	}

	/**
	 * Fetch the event types if they haven't been fetched before
	 * And set the mutable event types
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		if (!this.eventTypes.length) {
			this.isLoading.page = true;
			try {
				await this.fetchEventTypes();
				this.resetMutableEventTypes();
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('event_settings.event_types.error_fetching', { errorMessage });
			}
			finally {
				setTimeout(() => {
					this.isLoading.page = false;
				}, 500);
			}
		}
		else {
			this.resetMutableEventTypes();
		}
	}

	/**
	 * Set the mutable event types to the event types in the state
	 *
	 * @return {void}
	 */
	private resetMutableEventTypes(): void {
		this.mutableEventTypes = this.eventTypes.map(type => {
			return { ...type, isEditing: false };
		});
	}

	/**
	 * Add empty row to the table and focus on the input
	 *
	 * @return {void}
	 */
	private addRow(): void {
		this.mutableEventTypes.push({
			name: '',
			isEditing: true
		});
		this.$nextTick(() => (this.$refs.eventTypeInput as HTMLElement[])[0].focus());
	}

	/**
	 * Show input to edit event type and focus on the input
	 *
	 * @param {number} id - id of the event type
	 * @return {void}
	 */
	private openEditInput(id: number): void {
		this.mutableEventTypes = this.mutableEventTypes.map((eventType) => {
			return {
				...eventType, isEditing: eventType.id === id
			};
		});
		this.$nextTick(() => (this.$refs.eventTypeInput as HTMLElement[])[0].focus());
	}

	/**
	 * Cancel the editing or addition of an event type
	 *
	 * @param {EditableEventType} eventType
	 * @return {void}
	 */
	private cancelEditing(eventType: EditableEventType): void {
		if (eventType.id) {
			this.resetMutableEventTypes();
		}
		else {
			this.mutableEventTypes.pop();
		}
	}

	/**
	 * Create or update an event type depending if there's an id or not
	 * Prevent from submitting if no event type name
	 * Prevent api call if event type name is still the same
	 *
	 * @param {EditableEventType} eventType
	 * @return {Promise<void>}
	 */
	private async onSaveHandler(eventType: EditableEventType): Promise<void> {
		if (!eventType.name) {
			return;
		}

		const savedEventType = this.eventTypes.find(savedEventType => savedEventType.id === eventType.id);
		if (savedEventType && savedEventType.name === eventType.name) {
			return this.resetMutableEventTypes();
		}

		this.isLoading.save = true;
		try {
			if (eventType.id) {
				await this.updateEventType({ id: eventType.id, name: eventType.name });
			}
			else {
				await this.addEventType({ name: eventType.name });
			}
			setTimeout(() => {
				this.resetMutableEventTypes();
				this.isLoading.save = false;
			}, 500);
		}
		catch (errorMessage) {
			setTimeout(() => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_updating', { name: eventType.name, errorMessage });
				this.isLoading.save = false;
			}, 500);
		}
	}
}
