
import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

@Component<DeclineOrderModal>({
	components: {
		StandardModal,
		TextButton
	}
})
export default class DeclineOrderModal extends Vue {
	@Prop({ type: Boolean, required: true }) private loading!: boolean;

	private customReason: string = '';
	private reasonsList: string[] = [
		'unavailable',
		'special_request',
		'delivery',
		'limit_exceeded',
		'other'
	];
	private selectedReason: { text: string; value: string; } = { text: '', value: '' };
	private reasonsItems: { text: string; value: string; }[] = this.reasonsList.map(reason => {
		return {
			value: reason,
			text: this.$t(`order_management.decline_order_modal.declining_reason.${reason}`)
		};
	});

	/**
	 * Emit to decline the order with the selected cancellation note.
	 *
	 * @return {void}
	 */
	private declineOrder(): void {
		this.$emit('decline-order', this.selectedReason.value === 'other' ? this.customReason : this.selectedReason.text);
	}
}
