
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

const namespace = 'orders';

@Component<OrderCartPricing>({})
export default class OrderCartPricing extends Vue {
	@Getter('getSubtotal', { namespace }) private subtotal!: string;
	@Getter('getPromoDiscount', { namespace }) private promoDiscount!: string;
	@Getter('getMemberDiscount', { namespace }) private memberDiscount!: string;
	@Getter('getServiceCharge', { namespace }) private serviceCharge!: string | null;
	@Getter('getServiceChargeLabel', { namespace }) private serviceChargeLabel!: string;
	@Getter('getDeliveryCharge', { namespace }) private deliveryCharge!: string | null;
	@Getter('getTaxes', { namespace }) private taxes!: string;
	@Getter('getTaxBrackets', { namespace }) private taxBrackets!: any;
	@Getter('getVoucherAmount', { namespace }) private voucherAmount!: string;
	@Getter('getTotal', { namespace }) private total!: string;
	@Getter('getTip', { namespace }) private tip!: string;
	@Getter('getTotalWithTip', { namespace }) private totalWithTip!: string;

	private get taxesLabel(): string {
		if(this.taxBrackets && this.taxBrackets['GST'] && this.taxBrackets['PST']) {
			return `${this.$t('orders.cart.pricing.taxes')} (${this.$t('orders.cart.pricing.gst')} / ${this.$t('orders.cart.pricing.pst')})`;
		}
		return this.$t('orders.cart.pricing.taxes');
	}

	private get hasTip(): boolean {
		return Number(this.tip) > 0;
	}
}
