
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { isEqual } from 'lodash';
import { NavigationGuardNext, Route } from 'vue-router';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent, decodeEventSuite } from '@/utils/decoding';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';

@Component<SuiteSettings>({
	components: {
		InfoBanner,
		SuitesHeader,
		TextButton,
		WarningModal,
		FullPageSpinner
	}
})
export default class SuiteSettings extends Vue {
	@Action('fetchEvent', { namespace: 'events' }) private fetchEvent!: (eventId: string | number) => Promise<void>;
	@Action('fetchEventSuite', { namespace: 'eventSuites' }) private fetchEventSuite!: (payload: { eventSuiteId: string | number; eventId: string | number; }) => Promise<void>;
	@Action('fetchEquipment', { namespace: 'equipment' }) private fetchEquipment!: () => Promise<void>;
	@Action('updateEventSuite', { namespace: 'eventSuites' }) private updateEventSuite!: (payload: { eventSuite: EventSuite; eventId: string | number; }) => Promise<void>;
	@Action('deleteEventSuite', { namespace: 'eventSuites' }) private deleteEventSuite!: (payload: { id: string | number; eventId: string | number; }) => Promise<void>;
	@Getter('getSelectedEvent', { namespace: 'events' }) private event!: SuitesEvent;
	@Getter('getSelectedEventSuite', { namespace: 'eventSuites' }) private eventSuite!: EventSuite;
	@Getter('getEquipment', { namespace: 'equipment' }) private equipmentList!: Equipment[];

	private mutableSuite: EventSuite | null = null;
	private decodedEvent: SuitesEvent | null = null;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private isLoading: boolean = false;
	private warningModalProps: WarningModalProps = {
		open: false,
		confirmButtonText: this.$t('suite_assignments.warning_modal.continue_btn'),
		cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
		onClose: this.handleModalClose
	};
	private sortedEquipment: Equipment[] | undefined = undefined;

	private get breadcrumbsArray(): { text: string, to: string, exact: boolean }[] {
		if (!this.decodedEvent) {
			return [];
		}
		return [
			{
				text: `${this.decodedEvent.title} (${Vue.filter('formatDate')(this.event.start_date)})`,
				to: `/events/${this.event.id}`,
				exact: true
			},
			{
				text: 'Suite assignments',
				to: `/events/${this.event.id}/suites`,
				exact: true
			},
			{
				text: 'Suite settings',
				to: `/events/${this.event.id}/suites/${this.eventSuite.id}`,
				exact: true
			}];
	}

	// Merge all equipment with event suite equipment to get quantities from event suites
	private get mergedEquipmentList(): EquipmentWithQuantity[] | undefined {
		if (this.sortedEquipment) {
			const mergedEquipmentList = this.sortedEquipment.map((equipment) => {
				const eventSuiteEquipment = this.mutableSuite?.equipment?.find((eventSuiteEquipment) => eventSuiteEquipment.id === equipment.id);
				return {
					...equipment,
					quantity: eventSuiteEquipment?.quantity || 0
				};
			});
			return mergedEquipmentList;
		}
	}

	private async created(): Promise<void> {
		try {
			this.isLoading = true;
			await this.fetchEventSuite({ eventSuiteId: this.$route.params.suite_id, eventId: this.$route.params.event_id } );
			if (!this.event || Number(this.$route.params.event_id) !== this.event.id) {
				await this.fetchEvent(this.$route.params.event_id);
			}
			!this.equipmentList.length && await this.fetchEquipment();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_fetching', { errorMessage });
		}
		finally {
			if (this.eventSuite) {
				this.mutableSuite = decodeEventSuite(this.eventSuite);
				this.sortedEquipment = this.sortAllEquipmentByQuantity();
			}
			if (this.event) {
				this.decodedEvent = decodeEvent(this.event);
			}
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	// Check if event has been updated before leaving route and open warning modal if changes haven't been saved
	private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
		if (this.eventSuite && !isEqual(this.mutableSuite, decodeEventSuite(this.eventSuite))) {
			this.warningModalProps = {
				...this.warningModalProps,
				open: true,
				title: this.$t('suite_settings.unsaved_changes'),
				text: this.$t('suite_assignments.warning_modal.skip_assignments_text'),
				confirmButtonText: this.$t('suite_assignments.warning_modal.save_continue_btn'),
				cancelButtonText: this.$t('suite_assignments.warning_modal.discard_btn'),
				onConfirm: () => this.handleSave(to),
				onCancel: () => this.handleLeave(to)
			};
		}
		else {
			next();
		}
	}

	/**
	 * Copies the equipment list of the suite while removing some properties
	 * And add the other equipment
	 *
	 * @returns {Equipment[] | undefined} Equipment sorted by quantity
	 */
	private sortAllEquipmentByQuantity(): Equipment[] | undefined {
		if (this.mutableSuite) {
			// TODO: Find better way to handle unused vars
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const mergedEquipmentList: Equipment[] = this.mutableSuite?.equipment?.map(({ event_suite_equipment_id, quantity, ...rest }: EventSuiteEquipment) => rest);

			this.equipmentList.forEach((equipment: Equipment) => {
				const isAlreadyInMerged: EventSuiteEquipment|undefined = this.mutableSuite?.equipment?.find(eventSuiteEquipment => eventSuiteEquipment.id === equipment.id);
				if (!isAlreadyInMerged) {
					mergedEquipmentList.push(equipment);
				}
			});

			return mergedEquipmentList;
		}
	}

	/**
	 * Handle updating equipment quantity
	 *
	 * @param {EventSuiteEquipment} equipment The equipment to update
	 * @param {string} action The action to perform on the equipment quantity
	 * @param {number} quantity The quantity to set the equipment to, for the 'set' action
	 * @returns {void}
	 */
	private handleEquipmentChange(equipment: EventSuiteEquipment, action: 'increment' | 'decrement' | 'set', quantity?: number): void {
		if (this.mutableSuite) {
			// Get equipment from event suite and update if it exists
			const equipmentToUpdate = this.mutableSuite.equipment?.find((equipmentItem) => equipmentItem.id === equipment.id);
			if (equipmentToUpdate) {
				if (action === 'increment') {
					equipmentToUpdate.quantity++;
				}
				else if (action === 'decrement' && equipmentToUpdate.quantity > 0) {
					equipmentToUpdate.quantity--;
				}
				else if (action === 'set' && quantity) {
					equipmentToUpdate.quantity = quantity && quantity > 0 ? quantity : 0;
				}
			}
			// Add new equipment to event suite equipment
			else if (action !== 'decrement') {
				// Get first equipment with event suite equipment id and quantity of 0
				const equipmentWithAvailableId = this.mutableSuite.equipment.find((equipmentItem: EventSuiteEquipment) => equipmentItem.event_suite_equipment_id && !equipmentItem.quantity);

				this.mutableSuite.equipment?.push({
					id: equipment.id,
					name: equipment.name,
					quantity: quantity || 1,
					event_suite_equipment_id: equipmentWithAvailableId?.event_suite_equipment_id || null
				});

				// If equipment has event_suite_equipment_id reused, the remove it from event suite to prevent duplicate ids
				if (equipmentWithAvailableId) {
					this.mutableSuite.equipment = this.mutableSuite.equipment.filter((equipmentItem: EventSuiteEquipment) => equipmentItem.id !== equipmentWithAvailableId.id);
				}
			}

		}
	}

	/**
	 * Handle saving a suite, then navigate to suite assignments page
	 *
	 * @param {Route} to
	 * @returns {Promise<void>}
	 */
	private async handleSave(to?: Route): Promise<void> {
		try {
			if (this.mutableSuite) {
				this.isLoading = true;
				// Remove equipment with quantity of 0
				this.mutableSuite.equipment = this.mutableSuite.equipment?.filter((equipment) => equipment.quantity);
				await this.updateEventSuite({ eventSuite: this.mutableSuite, eventId: this.event.id });
				this.mutableSuite = decodeEventSuite(this.eventSuite);
				setTimeout(() => {
					this.isLoading = false;
					this.$router.push(to ? to.path : `/events/${this.event.id}/suites`);
				}, 500);
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_settings.error_updating', { errorMessage });
			this.isLoading = false;
		}
	}

	/**
	 * Handle deleting an event suite, close modal and navigate to suite assignments page
	 *
	 * @returns {Promise<void>}
	 */
	private async handleDelete(): Promise<void> {
		try {
			if (this.eventSuite) {
				await this.deleteEventSuite({ id: this.eventSuite.id, eventId: this.event.id });
				await this.fetchEvent(this.event.id);
				this.warningModalProps.open = false;
				this.$router.push(`/events/${this.event.id}/suites`);
			}
		}
		catch (errorMessage) {
			this.warningModalProps.open = false;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_deleting', { errorMessage });
		}
	}

	/** Handle leaving the page without saving changes
	 *
	 * @param {Route} to The route to navigate to
	 * @returns {void}
	 */
	private handleLeave(to: Route): void {
		this.warningModalProps.open = false;
		// Revert changes to updated event so that beforeLeaveRouter nav guard doesn't detect changes
		this.mutableSuite = decodeEventSuite(this.eventSuite);
		this.$router.push(to.path);
	}

	private handleModalClose(): void {
		this.warningModalProps.open = false;
	}

	/** Set delete modal props and open modal
	 *
	 * @returns {void}
	 */
	private openDeleteModal(): void {
		this.warningModalProps = {
			...this.warningModalProps,
			open: true,
			title: this.$t('suite_assignments.warning_modal.delete_suite_title'),
			text: this.$t('suite_assignments.warning_modal.delete_suite_text'),
			confirmButtonText: this.$t('suite_assignments.warning_modal.remove_btn'),
			cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
			onConfirm: () => this.handleDelete(),
			onCancel: this.handleModalClose
		};
	}
}
