import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { EventState, RootState } from '../types';
const namespaced: boolean = true;

export const state: EventState = {
	events: [],
	selectedEvent: null,
	deepEvent: null
};


export const Events: Module<EventState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Events;