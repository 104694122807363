
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import DeliveryAddress from './delivery/DeliveryAddress.vue';
import DeliveryCustom from './delivery/DeliveryCustom.vue';

const namespace: string = 'orders';

@Component<DeliveryInformation>({
	components: {
		DeliveryAddress,
		DeliveryCustom
	}
})
export default class DeliveryInformation extends Vue {
	@Action('setDeliveryInformation', { namespace }) setDeliveryInformation!: (delivery: DeliveryInfo) => void;
	@Getter('getDeliveryInformation', { namespace }) private deliveryInformation!: DeliveryInfo | null;
	@Prop({ type: Object, required: true }) private restaurantDeliveryInfo!: DeliveryInfo;
	@Prop({ type: String, required: false, default: '' }) private coordinatesErrorMsg!: string;

	/**
	 * Update the delivery information in the vuex store
	 *
	 * @param {DeliveryInfo} delivery
	 * @return {void}
	 */
	private handleDeliveryInfoUpdated(delivery: DeliveryInfo): void {
		this.setDeliveryInformation(delivery);
	}
}
