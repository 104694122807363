
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import DatePicker from '@/components/shared/DatePicker.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import WarningModal from '../shared/suites/WarningModal.vue';

const namespace: string = 'auth';

@Component<ClosedDates>({
	components: {
		DatePicker,
		IconButton,
		InfoBanner,
		TextButton,
		WarningModal
	}
})
export default class ClosedDates extends Vue {
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Prop({ type: Function, required: true }) private showToast!: (responseObj: any) => void;
	@Prop({ type: Number, required: true }) private tab!: number;
	@Watch('tab')
	private onTabChange(): void {
		// Remove any closed dates that do not have a date set when switching tabs
		if (this.tab === 0) {
			this.mutableHolidayHours = this.mutableHolidayHours.filter(holiday => holiday.date);
		}
	}

	private mutableHolidayHours: HolidayHours[] = [];
	private warningModalOpen: boolean = false;
	private selectedHolidayIndex: number | null = null;

	private get orderedDates(): HolidayHours[] {
		return this.mutableHolidayHours.sort((a, b) => {
			return a.date.localeCompare(b.date);
		});
	}

	/**
	 * The date of the selected holiday to remove for the warning modal
	 *
	 * @return {string}
	 */
	private get dateToRemove(): string {
		if (this.selectedHolidayIndex === null ) {
			return '';
		}
		const date = this.mutableHolidayHours[this.selectedHolidayIndex].date;
		if (!date) {
			return '';
		}
		return DateTime.fromISO(date).toFormat(this.$t('shared.date_picker.date_format'));
	}

	/**
	 * Disable the add date button if there is a date that is not set
	 *
	 * @return {boolean}
	 */
	private get disableAddDate(): boolean {
		return this.mutableHolidayHours.filter(holiday => holiday.date === '').length > 0;
	}

	/**
	 * The minimum date that can be selected in the datepicker based on the restaurant's timezone
	 *
	 * @return {string}
	 */
	private get minDate(): string {
		return DateTime.now().setZone(this.restaurant.menu_configuration?.zone || 'America/Toronto').toISODate();
	}

	/**
	 * All dates that are already set as closed
	 *
	 * @return {string[]}
	 */
	private get setDates(): string[] {
		return this.mutableHolidayHours.map(holiday => holiday.date);
	}

	private created(): void {
		this.mutableHolidayHours = this.restaurant.holiday_hours ? [...this.restaurant.holiday_hours] : [];
	}

	/**
	 * Disables dates in datepicker that are already set as closed
	 *
	 * @param {string} date
	 * @return {boolean}
	 */
	private disableDates(date: string): boolean {
		const formattedDate = new Date(date).toISOString().split('T')[0];
		return !this.setDates.includes(formattedDate);
	}

	/**
	 * Add a new closed date to the list and open the datepicker
	 *
	 * @return {void}
	 */
	private addClosedDate(): void {
		this.mutableHolidayHours.push({
			date: '',
			start_time: null,
			end_time: null,
			full_day: true
		});
		this.$nextTick(() => {
			const tempDatePickerRef = this.$refs.holidayHourTemp as DatePicker[];
			if (tempDatePickerRef && tempDatePickerRef.length > 0) {
				tempDatePickerRef[0].menu = true;
			}
		});
	}

	/**
	 * Remove a closed date from the list and close the modal
	 *
	 * @param {number} index
	 * @return {Promise<void>}
	 */
	private async removeClosedDate(index: number): Promise<void> {
		const tempHolidayHours = [...this.mutableHolidayHours];
		try {
			this.mutableHolidayHours.splice(index, 1);
			await this.updateHolidayHours();
		}
		catch (error) {
			// If the update fails, revert the changes
			this.mutableHolidayHours = tempHolidayHours;
		}
		finally {
			this.closeWarningModal();
		}
	}

	/**
	 * Handle the date change event
	 *
	 * @param {HolidayHours} holiday
	 * @return {Promise<void>}
	 */
	private async handleDateChange(holiday: HolidayHours): Promise<void> {
		const prevHoursLength = this.restaurant.holiday_hours?.length;
		if (holiday.start_time && holiday.end_time) {
			holiday.full_day = false;
		}
		await this.updateHolidayHours();
		this.mutableHolidayHours = this.restaurant.holiday_hours ? [...this.restaurant.holiday_hours] : [];
		this.showToast({
			color: 'success',
			message: !prevHoursLength || (prevHoursLength && prevHoursLength !== this.mutableHolidayHours.length)
				? this.$t('menu.editor.availability.holidays.success_message')
				: this.$t('menu.editor.availability.holidays.update_message')
		});

	}

	/**
	 * Update the restaurant with the new holiday hours
	 *
	 * @return {Promise<void>}
	 */
	private async updateHolidayHours(): Promise<void> {
		try {
			await this.updateRestaurant({
				restaurant: {
					...this.restaurant,
					holiday_hours: this.mutableHolidayHours.filter(holiday => holiday.date)
				}
			});
		}
		catch (error) {
			this.showToast({
				color: 'error',
				message: this.$t('menu.editor.availability.holidays.error_message')
			});
			throw error;
		}
	}

	private openWarningModal(date: string): void {
		this.selectedHolidayIndex = this.mutableHolidayHours.findIndex(holiday => holiday.date === date);
		this.warningModalOpen = true;
	}

	private closeWarningModal(): void {
		this.selectedHolidayIndex = null;
		this.warningModalOpen = false;
	}
}
