
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';

const namespace: string = 'equipment';

@Component<EquipmentListModal>({
	components: {
		StandardModal,
		TextButton,
		TableHeaders
	}
})
export default class EquipmentListModal extends Vue {
	@Action('addEquipment', { namespace }) private addEquipment!: (equipment: { name: string }) => Promise<void>;
	@Action('updateEquipment', { namespace }) private updateEquipment!: (equipment: Equipment) => Promise<void>;
	@Action('deleteEquipment', { namespace }) private deleteEquipment!: (equipmentId: number) => Promise<void>;
	@Getter('getEquipment', { namespace }) private equipmentList!: Equipment[];
	@Prop({ type: Boolean, required: true, default: '' }) private showModal!: boolean;
	// TODO: Replace watcher by v-if in the component (see change history modal for example)
	@Watch('showModal', { immediate: true })
	onShowModalUpdate(isModalShown: boolean) {
		isModalShown && this.resetMutableEquipment();
	}

	private isLoading: boolean = false;
	private mutableEquipment: EditableEquipment[] = [];
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('event_settings.equipment_list.table_header') }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get isAnyEditing(): boolean {
		return this.mutableEquipment.some(equip => equip.isEditing === true);
	}

	/**
	 * Set the mutable equipment to the equipment in the state
	 *
	 * @return {void}
	 */
	private resetMutableEquipment(): void {
		this.mutableEquipment = this.equipmentList.map(equipment => {
			return { ...equipment, isEditing: false };
		});
	}

	/**
	 * Add empty row to the table and focus on the input
	 *
	 * @return {void}
	 */
	private addRow(): void {
		this.mutableEquipment.push({
			name: '',
			isEditing: true
		});
		this.$nextTick(() => (this.$refs.equipmentInput as HTMLElement[])[0].focus());
	}

	/**
	 * Show input to edit equipment and focus on the input
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private openEditInput(index: number): void {
		this.mutableEquipment = this.mutableEquipment.map((equip, equipIndex) => {
			return {
				...equip, isEditing: equipIndex === index
			};
		});
		this.$nextTick(() => (this.$refs.equipmentInput as HTMLElement[])[0].focus());
	}

	/**
	 * Create or update equipment depending if the equipment has an id or not
	 * Prevent from submitting if no equipment name
	 * Prevent api call if equipment name is still the same
	 *
	 * @param {EditableEquipment} equipment
	 * @return {Promise<void>}
	 */
	private async savedEquipment(equipment: EditableEquipment): Promise<void> {
		if (!equipment.name) {
			return;
		}

		const savedEquipment = this.equipmentList.find(savedEquip => savedEquip.id === equipment.id);
		if (savedEquipment && savedEquipment.name === equipment.name) {
			return this.resetMutableEquipment();
		}

		this.isLoading = true;
		try {
			if (equipment.id) {
				await this.updateEquipment({ id: equipment.id, name: equipment.name });
			}
			else {
				await this.addEquipment({ name: equipment.name });
			}
			this.resetMutableEquipment();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = equipment.id
				? this.$t('shared.error_updating', { name: equipment.name, errorMessage })
				: this.$t('shared.error_creating', { name: equipment.name, errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Delete equipment if equipment id,
	 * Otherwise just remove the last item of mutableEquipment array
	 *
	 * @param {EditableEquipment} equipment
	 * @return {Promise<void>}
	 */
	private async removeEquipment(equipment: EditableEquipment): Promise<void> {
		if (equipment.id) {
			this.isLoading = true;
			try {
				await this.deleteEquipment(equipment.id);
				this.resetMutableEquipment();
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: equipment.name, errorMessage });
			}
			finally {
				setTimeout(() => {
					this.isLoading = false;
				}, 500);
			}
		}
		else {
			this.mutableEquipment.pop();
		}
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
