import { GetterTree } from 'vuex';
import { EventState, RootState } from '../types';

export const getters: GetterTree<EventState, RootState> = {
	getEvents(state): SuitesEvent[] {
		return state.events;
	},

	getEvent(state) {
		return (id: number): SuitesEvent => {
			return state.events.find((event: SuitesEvent) => event.id === id)!;
		};
	},

	getSelectedEvent(state) {
		return state.selectedEvent || null;
	},

	getDeepEvent(state) {
		return state.deepEvent || null;
	},

	/**
	 * Get upcoming events sorted by ascending date
	 * TODO: Remove sorting when backend handles sorting by date
	 *
	 * @param state
	 * @returns {SuitesEvent[]}
	 */
	getUpcomingEvents(state): SuitesEvent[] {
		return state.events
			.filter((event: SuitesEvent) => {
				return event.status !== 'past_event';
			})
			.sort((a: SuitesEvent, b: SuitesEvent) => {
				return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
			});
	},

	/**
	 * Get past events sorted by descending date
	 * TODO: Remove sorting when backend handles sorting by date
	 *
	 * @param state
	 * @returns {SuitesEvent[]}
	 */
	getPastEvents(state): SuitesEvent[] {
		return state.events
			.filter((event: SuitesEvent) => {
				return event.status === 'past_event';
			})
			.sort((a: SuitesEvent, b: SuitesEvent) => {
				return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
			});
	}
};