
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatValue } from '@/utils/valueValidation';

@Component<NameAndPricing>({})
export default class NameAndPricing extends Vue {
	@Prop({ type: Object, default: () => {} }) private target!: Menu | MenuSection;
	@Prop({ type: Boolean, default: true }) private isCreating!: boolean;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;

	private pricingOptions: RadioOptions[] = [
		{ name: 'A-la-carte', option: 1 },
		{ name: 'Prix-Fixe', option: 2 }
	];
	private pricingOptionSelected: number = 0;
	private mutableTarget: any = this.target;
	private formatTargetPrice = (price: any) => formatValue(price, '', 'price');

	private mounted(): void {
		if (this.isCreating || this.mutableTarget.type === 'a-la-carte') {
			Vue.set(this.mutableTarget, 'price', '');
		}
		if (!this.isCreating) {
			this.pricingOptionSelected = this.mutableTarget.type === 'a-la-carte' ? 1 : 2;
		}
	}

	/**
	 * Set the price to 0 if a-la-carte selected
	 * and then emit the event so the parent
	 * can update the price type.
	 *
	 * @return {void}
	 */
	private changePricingType(): void {
		Vue.set(this.mutableTarget, 'type', this.pricingOptions.filter((pricingOption: any) => pricingOption.option === this.pricingOptionSelected)[0].name.toString().toLowerCase());
	}
}
