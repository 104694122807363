
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<ServiceCharge>({
	components: {
		ValidationProvider
	}
})
export default class ServiceCharge extends Vue {
	@Prop({ type: Object, default: () => {} }) private restaurant!: Restaurant;
	@Prop({ type: String, default: '' }) private targetServiceCharge!: string;
	@Prop({ type: Number, default: 2 }) private targetServiceChargeTypeIsDollar!: number;
	@Prop({ type: Boolean, default: false }) private targetServiceChargeActivated!: boolean;

	private mutableServiceCharge: string = this.targetServiceCharge;
	private mutableServiceChargeTypeIsDollar: number = this.targetServiceChargeTypeIsDollar;
	private mutableServiceChargeActivated: boolean = this.targetServiceChargeActivated;

	private updateServiceCharge(): void {
		setTimeout(() => {
			this.$emit('update-service-charge', { charge: this.mutableServiceCharge, type: this.mutableServiceChargeTypeIsDollar, chargeActivated: this.mutableServiceChargeActivated });
		}, 100);
	}
}
