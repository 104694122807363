
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Tooltip from '@/components/shared/Tooltip.vue';
import { formatValue } from '@/utils/valueValidation';


@Component<AdditionalPrices>({
	components: {
		Tooltip
	}
})
export default class AdditionalPrices extends Vue {
	@Prop({ type: Object, default: () => {} }) private additionalPrices!: [];
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Watch('additionalPrices')
	onAdditionalPricesChange() {
		this.mutableAdditionalPrices = this.additionalPrices;
	}

	private mutableAdditionalPrices: IObjectKeys = this.additionalPrices;

	private formatPricingItemPrice = (price: any) => formatValue(price, '0.00', 'price');

	private addAdditionalPrice() {
		const priceID = Object.keys(this.mutableAdditionalPrices).pop()!.charCodeAt(0) + 1;
		const keyCode = String.fromCharCode(priceID) === 'X' ? String.fromCharCode(priceID + 1) : String.fromCharCode(priceID);
		this.mutableAdditionalPrices = Object.assign({}, this.mutableAdditionalPrices, { [keyCode]: '' });
	}

	/**
	 * Delete the object key/pair selected and make sure to re-order
	 * the keys.
	 *
	 * @param {string} key
	 * @return {void}
	 */
	private removeAdditionalPrice(key: string): void {
		const additionalPricesKeyIndex = Object.keys(this.mutableAdditionalPrices).indexOf(key);
		Vue.delete(this.mutableAdditionalPrices, key);
		let index = 0;

		for (const additionalPrice in this.mutableAdditionalPrices) {
			if (additionalPricesKeyIndex <= index) {
				this.mutableAdditionalPrices = this.renameKey(this.mutableAdditionalPrices, { [additionalPrice]: String.fromCharCode(additionalPrice.charCodeAt(0) - 1) });
			}
			index++;
		}
		this.mutableAdditionalPrices = Object.assign({}, this.mutableAdditionalPrices, this.mutableAdditionalPrices);
		this.setAdditionalPrices();
	}

	private setAdditionalPrices(): void {
		this.$emit('set-additional-prices', this.mutableAdditionalPrices);
	}

	/**
	 * Rename the object keys but keep the values
	 *
	 * @param {IObjectKeys} object
	 * @param {any} newKeys
	 * @return {IObjectKeys}
	 */
	private renameKey(object: IObjectKeys, newKeys: any): IObjectKeys {
		const keyValues = Object.keys(object).map((key: any) => {
			const newKey = newKeys[key] || key;
			return { [newKey]: object[key] };
		});
		return Object.assign({}, ...keyValues);
	}
}
