import { ActionTree } from 'vuex';
import { EventState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';

export const actions: ActionTree<EventState, RootState> = {

	/**
	 * Fetch events
	 *
	 * @returns {Promise<void>}
	 */
	async fetchEvents({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const events = await axiosInst.get('/catering/events');
			commit('SET_EVENTS', events.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch events with search query parameters
	 *
	 * @param {SearchEventsQueryParams} queryParams
	 * @returns {Promise<PaginatedResults<SuitesEvent> | undefined>}
	 */
	async fetchFilteredEvents(_, queryParams: SearchEventsQueryParams): Promise<PaginatedResults<SuitesEvent> | undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.get<PaginatedResults<SuitesEvent>>('/catering/events', {
				params: {
					search: queryParams.search,
					page: queryParams.page,
					eventTypeIds: JSON.stringify(queryParams.eventTypeIds),
					eventStatuses: JSON.stringify(queryParams.eventStatuses),
					dateRange: JSON.stringify(queryParams.dateRange),
					dateSortAsc: queryParams.dateSortAsc
				}
			});
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch an event
	 *
	 * @param {number} id - event id
	 * @returns {Promise<void>}
	 */
	async fetchEvent({ commit }, id: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.get<SuitesEvent>(`/catering/events/${id}`);
			commit('SET_SELECTED_EVENT', data);
			commit('UPDATE_EVENTS_LIST', data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch event with all data needed for production sheet
	 *
	 * @param {number} id - event id
	 * @param {boolean} includePendingOrders - should pending orders be included in the results
	 * @returns {Promise<void>}
	 */
	async fetchDeepEvent({ commit }, { id, includePendingOrders }: { id: number, includePendingOrders: boolean }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.get<DeepSuitesEvent>(`/catering/events/${id}/deep-get-event`, {
				params: {
					includePendingOrders
				}
			});
			commit('SET_DEEP_EVENT', data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create a new event
	 *
	 * @param {SuitesEvent} event
	 * @returns {Promise<SuitesEvent | void>}
	 */
	async createEvent({ dispatch }, event: SuitesEvent): Promise<SuitesEvent | void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const newEvent = await axiosInst.post('/catering/events', event);
			await dispatch('fetchEvents');
			return newEvent.data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update an event
	 *
	 * @param {SuitesEvent} event
	 * @returns {Promise<void>}
	 */
	async updateEvent({ commit }, event: SuitesEvent): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.put<SuitesEvent>(`/catering/events/${event.id}`, event);
			commit('SET_SELECTED_EVENT', data);
			commit('UPDATE_EVENTS_LIST', data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete an event
	 *
	 * @param {number} id
	 * @returns {Promise<void>}
	 */
	async deleteEvent({ commit, dispatch }, id: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/catering/events/${id}`);
			commit('SET_SELECTED_EVENT', null);
			await dispatch('fetchEvents');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Set selected event
	 *
	 * @param {SuitesEvent|null} event
	 * @return {void}
	 */
	setSelectedEvent({ commit }, event: SuitesEvent|null): void {
		commit('SET_SELECTED_EVENT', event);
	},

	/**
	 * Finalize event day orders for an event
	 *
	 * @param {number} id - event id
	 * @return {Promise<void>}
	 */
	async finalizeEventDayOrders({ commit }, id: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.get<SuitesEvent>(`/catering/events/${id}/finalize-orders`);
			commit('SET_SELECTED_EVENT', data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
