import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SuiteState, RootState } from '../types';
const namespaced: boolean = true;

export const state: SuiteState = {
	suites: []
};


export const Suites: Module<SuiteState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Suites;