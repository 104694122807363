
import { Component, Vue } from 'vue-property-decorator';
import MenuList from '@/components/MenuList.vue';
import { Getter } from 'vuex-class';

const namespace: string = 'auth';

@Component<Home>({
	components: {
		MenuList
	}
})

export default class Home extends Vue {
	@Getter('getTenantId', { namespace }) private tenantId!: string;
	@Getter('getRestaurants', { namespace }) private restaurants!: Tenant[];

	private get restaurant(): Tenant | undefined {
		return this.restaurants.find((restaurant) => restaurant.id === this.tenantId);
	}
}
