import Vue from 'vue';
import * as ExcelJS from 'exceljs';

const headerBackgroundColor: string = 'E3F2FF';
const tableBorderStyle: ExcelJS.BorderStyle = 'medium';

/**
 * Manually download the file by creating a <a> element, clicking it and removing it from the DOM
 *
 * @param {ExcelJS.Buffer} buffer - data to download
 * @param {string} [filename] - name of the file to download
 * @return {void}
 */
export function downloadFile(buffer: ExcelJS.Buffer, filename?: string): void {
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const url: string = URL.createObjectURL(blob);
	const a: HTMLAnchorElement = document.createElement('a');
	a.href = url;

	if (filename) {
		a.download = filename;
	}

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

/**
 * Add a border around a certain area of the sheet
 *
 * @param {ExcelJS.Worksheet} worksheet - The actual sheet/tab containing the table
 * @param {number} rowIndexStart
 * @param {number} rowIndexEnd
 * @return {void}
 */
export function addBorderAroundTable(worksheet: ExcelJS.Worksheet, rowIndexStart: number, rowIndexEnd: number): void {
	const colCount: number = worksheet.columnCount;

	// Add a top and bottom border for the first and last row
	for (let i = rowIndexStart; i <= rowIndexEnd; i++) {
		const row: ExcelJS.Row = worksheet.getRow(i);

		// Add a left and right border for each row
		row.getCell(1).border = { ...row.getCell(1).border, left: { style: tableBorderStyle } };
		row.getCell(colCount).border = { ...row.getCell(colCount).border , right: { style: tableBorderStyle } };

		// Add top border to the first row
		if (i === rowIndexStart) {
			row.eachCell({ includeEmpty: true }, cell => {
				cell.border = { ...cell.border, top: { style: tableBorderStyle } };
			});
		}

		// Add bottom border to the last row
		else if (i === rowIndexEnd) {
			row.eachCell({ includeEmpty: true }, cell => {
				cell.border = { ...cell.border, bottom: { style: tableBorderStyle } };
			});
		}
	}

	// Add borders to the first empty row is there is no data
	if (rowIndexEnd === 1) {
		const row: ExcelJS.Row = worksheet.getRow(2);
		row.getCell(1).border = { left: { style: tableBorderStyle } };
		row.getCell(colCount).border = { right: { style: tableBorderStyle } };
		row.eachCell({ includeEmpty: true }, cell => {
			cell.border = { ...cell.border, bottom: { style: tableBorderStyle } };
		});
	}
}

/**
 * Set the font size and the text alignment for each cell of the sheet or an area.
 *
 * @param {ExcelJS.Worksheet} worksheet - The actual sheet/tab containing the table
 * @param {number} fontSize
 * @param {ExcelJS.Alignment} alignment - Object with horizontal and vertical properties
 * @param {number} [startRowIndex = 1] - Starting row index
 * @return {void}
 */
export function setCellFontSizeAndAlignement(worksheet: ExcelJS.Worksheet, fontSize: number, alignment: Partial<ExcelJS.Alignment>, startRowIndex: number = 1): void {
	for (let i = startRowIndex; i <= worksheet.rowCount; i++) {
		const row: ExcelJS.Row = worksheet.getRow(i);
		row.eachCell({ includeEmpty: true }, (cell) => {
			cell.font = { ...cell.font, size: fontSize };
			cell.alignment = { ...alignment, wrapText: true };
		});
	}
}

/**
 * Align all the cells of specific columns (except the header) to a certain direction
 *
 * @param {ExcelJS.Worksheet} worksheet
 * @param {number[]} columns
 * @param {string} alignment
 * @return {void}
 */
export function alignColumns(worksheet: ExcelJS.Worksheet, columns: number[], alignment: 'left' | 'right' | 'center'): void {
	columns.forEach(column => {
		worksheet.getColumn(column).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
			if (rowNumber !== 1) {
				cell.alignment = { ...cell.alignment, horizontal: alignment };
			}
		});
	});
}

/**
 * Add styling to the vertical header (column): bold font and background color
 *
 * @param {ExcelJS.Worksheet} worksheet  - The actual sheet/tab containing the table
 * @param {number} [startRowIndex = 1] - The row where the header starts
 * @return {void}
 */
export function addStylingToVerticalHeader(worksheet: ExcelJS.Worksheet, startRowIndex: number = 1): void {
	worksheet.getColumn(1).eachCell({ includeEmpty: true }, (cell) => {
		if (Number(cell.row) >= startRowIndex) {
			cell.font = { ...cell.font, bold: true };
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: headerBackgroundColor }
			};

			cell.border = {
				...cell.border,
				bottom: { style: 'thin', color: { argb: 'ffffff' } }
			};
		}
	});
}

/**
 * Add styling to the horizontal header (row): bold font and background color
 *
 * @param {ExcelJS.Worksheet} worksheet  - The actual sheet/tab containing the table
 * @param {number} [rowIndex = 1] - The row where the header starts
 * @return {void}
 */
export function addStylingToHorizontalHeader(worksheet: ExcelJS.Worksheet, rowIndex: number = 1): void {
	worksheet.getRow(rowIndex).eachCell((cell) => {
		cell.font = { bold: true, size: 16 };
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: headerBackgroundColor }
		};
		cell.border = {
			...cell.border,
			left: { style: 'thin', color: { argb: 'ffffff' } },
			right: { style: 'thin', color: { argb: 'ffffff' } }
		};
	});
}

/**
 * Format the modifiers column as bullet points for each option.
 *
 * @param {OrderItem} item
 * @return {string}
 */
export function formatItemOptions(item: OrderItem): string {
	let output: string = '';

	item.orderOptions?.forEach((optionGroup: OptionGroup) => {
		optionGroup.values?.forEach((option: Option) => {
			if (!optionGroup.optionGroupType && optionGroup.name === 'Special Instructions') {
				output += `*** ${option.name}`;
			}

			else if (option.quantity! > 0) {
				output += `• ${Vue.filter('localize')(option, 'name')}`;
				if (option.quantity! > 1) {
					output += ` (x${option.quantity})`;
				}
				output += '\n';
			}

			// Sub-addons
			if (option.options?.length) {
				option.options.forEach((subOptionGroup: OptionGroup) => {
					subOptionGroup.values?.forEach((subOption: Option) => {
						output += `   ○ ${Vue.filter('localize')(subOption, 'name')}\n`;
					});
				});
			}
		});
	});

	return output;
}

/**
 * Format the custom questions column as bullet points for each question and answer.
 *
 * @param {Checkout} checkoutData
 * @return {string}
 */
export function formatCustomQuestions(checkoutData: Checkout): string {
	let output: string = '';

	checkoutData.questions?.forEach((question: AnsweredCustomQuestion) => {
		output += `• ${Vue.filter('localize')(question, 'question')}:\n`;
		output += `   ○ ${Vue.filter('localize')(question, 'answer')}\n`;
	});

	return output;
}