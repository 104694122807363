import { GetterTree } from 'vuex';
import { EquipmentState, RootState } from '../types';

export const getters: GetterTree<EquipmentState, RootState> = {
	getEquipment(state): Equipment[] {
		return state.equipment;
	},

	getEquipmentById(state) {
		return (id: number): Equipment => {
			return state.equipment.find((equipment: Equipment) => equipment.id === id)!;
		};
	}
};