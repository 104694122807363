import { MutationTree } from 'vuex';
import { SearchState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<SearchState> = {

	/**
	 * Set search items
	 *
	 * @param state
	 * @param searchItems
	 */
	SET_SEARCH_ITEMS(state, searchItems: object) {
		Vue.set(state, 'searchItems', searchItems);
	}
};