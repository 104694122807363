
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import '@/validation-rules';

const namespace: string = 'auth';

@Component<TaxRatesModal>({
	components: {
		ValidationObserver,
		ValidationProvider,
		StandardModal,
		TextButton,
		InfoBanner,
		BannerToast
	}
})
export default class TaxRatesModal extends Vue {
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Prop({ type: Object, default: () => {} }) private targetPresetTaxRateInfo!: PresetTaxes[];
	@Prop({ type: Object, default: () => {} }) private targetTaxRateInfo!: Taxes;
	@Prop({ type: Boolean, required: true, default: false }) private showModal!: boolean;

	$refs!: {observer: Validate};

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private mutablePresetTaxRateInfo: PresetTaxes[] = [{ name: '', value: '', is_default: false }];
	private mutableTaxRateInfo: Taxes | undefined = this.restaurant?.tax_rate;
	private loading: boolean = false;

	private get existingNames(): string[] {
		return this.mutablePresetTaxRateInfo.map((taxRate: PresetTaxes) => taxRate.name);
	}

	private created(): void {
		if (this.restaurant?.preset_tax_rates) {
			this.mutablePresetTaxRateInfo = this.restaurant.preset_tax_rates.map((taxRate) => ({
				...taxRate,
				isExisting: true // TODO Remove this when the backend is updated to treat tax rates deletion properly
			}));
		}
	}

	/**
	 * Get the dynamic label for the tax rate value field.
	 *
	 * @param {string} value
	 * @return {string}
	 */
	private dynamicLabel(value: string): string {
		const baseLabel: string = this.$t('advanced.tax_rates.tax_rate_value_label');
		const percentage: string = this.dynamicPercentage(value);
		return +percentage > 0 ? `${baseLabel} (${percentage}%)` : baseLabel;
	}

	/**
	 * Convert a decimal value to a percentage for display.
	 *
	 * @param {string} value
	 * @return {string}
	 */
	private dynamicPercentage(value: string): string {
		const numericValue: number = parseFloat(value);
		if (isNaN(numericValue)) {
			return '0';
		}

		// Determine the number of decimal places in the input. Default to 2 if none are found.
		const decimalPlaces: number = (value.split('.')[1] || '').length;
		const precision: number = decimalPlaces <= 2 ? 2 : decimalPlaces - 2;

		return (numericValue * 100).toFixed(precision);
	}

	/**
	 * Get the value rules for the tax rate
	 * TODO Remove this when the backend is updated to treat tax rates deletion properly
	 *
	 * @param {PresetTaxes} presetTaxRate
	 * @return {string}
	*/
	private getValueRules(presetTaxRate: PresetTaxes): string {
		return presetTaxRate.isExisting ? 'required|decimal|min_value:0|max_value:0.99' : 'decimal|min_value:0|max_value:0.99';
	}

	/**
	 * Add a tax rate
	 *
	 * @return {void}
	*/
	private addTaxRate(): void {
		this.mutablePresetTaxRateInfo.push({ name: '', value: '', is_default: false });
	}

	/**
	 * Set the is_default to false if the name and value are empty.
	 * This is just a small visual cue, it's nothing necessary to save or not
	 * this specific tax rate.
	 *
	 * @param {PresetTaxes} presetTaxRate
	 * @return {void}
	 */
	private taxRateUpdated(presetTaxRate: PresetTaxes): void {
		if(!presetTaxRate.name && !presetTaxRate.value) {
			presetTaxRate.is_default = false;
		}
	}

	/**
	 * Save the taxes information.
	 *
	 * @return {Promise<void>}
	*/
	private async save(): Promise<void> {
		this.loading = true;
		let saved: boolean = false;

		try {
			const isValid: boolean = await this.$refs.observer.validate();

			// Configuration invalid, show toast error
			if (!isValid) {
				if (!this.bannerToastInfo.showMessage) {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('advanced.tax_rates.error_invalid_settings');
				}
				return;
			}

			// We want to clear all the tax rates that are either missing a name, a value or both.
			this.mutablePresetTaxRateInfo = this.mutablePresetTaxRateInfo
				.filter((taxRate: PresetTaxes) => taxRate.name && taxRate.value)
				.map(({ isExisting, ...rest }) => rest);

			// Do not fire a restaurant update if there's no taxes passed. TODO update this when the backend is updated to treat tax rates deletion properly
			if(this.mutablePresetTaxRateInfo.length) {
				await this.updateRestaurant({
					restaurant: {
						id: this.restaurant.id,
						preset_tax_rates: this.mutablePresetTaxRateInfo
					}
				});
			}

			saved = true;
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.tax_rates.error_saving', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.loading = false;
				if (saved) {
					this.closeModal();
				}
			}, 500);
		}
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	*/
	private closeModal(): void {
		this.$emit('close');
	}
}
