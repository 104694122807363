import { MutationTree } from 'vuex';
import { EquipmentState } from '../types';
import { alphabeticalSort } from '@/utils/sort';
import Vue from 'vue';

export const mutations: MutationTree<EquipmentState> = {
	/**
	 * Set equipment
	 *
	 * @param {EquipmentState} state
	 * @param {Equipment[]} equipment
	 * @return {void}
	 */
	SET_EQUIPMENT(state: EquipmentState, equipment: Equipment[]) {
		Vue.set(state, 'equipment', equipment);
	},

	/**
	 * Add a new equipment
	 *
	 * @param {EquipmentState} state
	 * @param {Equipment} newEquipment
	 * @return {void}
	 */
	ADD_EQUIPMENT(state: EquipmentState, newEquipment: Equipment): void {
		Vue.set(state, 'equipment', [...state.equipment, newEquipment].sort(alphabeticalSort));
	},

	/**
	 * Update an equipment
	 *
	 * @param {EquipmentState} state
	 * @param {Equipment} updatedEquipment
	 * @return {void}
	 */
	UPDATE_EQUIPMENT(state: EquipmentState, updatedEquipment: Equipment): void {
		Vue.set(state, 'equipment', state.equipment.map(equipment => equipment.id === updatedEquipment.id ? updatedEquipment : equipment).sort(alphabeticalSort));
	},

	/**
	 * Delete an equipment
	 *
	 * @param {EquipmentState} state
	 * @param {number} equipmentId
	 * @return {void}
	 */
	DELETE_EQUIPMENT(state: EquipmentState, equipmentId: number): void {
		Vue.set(state, 'equipment', state.equipment.filter(equipment => equipment.id !== equipmentId));
	}
};