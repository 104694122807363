
import { Component, Prop, Vue } from 'vue-property-decorator';
import StatusIcon from '@/components/shared/suites/StatusIcon.vue';
import StatusLabel from '@/components/shared/suites/StatusLabel.vue';

@Component<EventRowItem>({
	components: {
		StatusIcon,
		StatusLabel
	}
})
export default class EventRowItem extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private event!: SuitesEvent;

	/**
	 * Filter out the event suites with email scheduled/sent status
	 * Return an object of the count of each status
	 *
	 * @return { {[key: string]: number} }
	 */
	// TODO: Rethink this logic once we implement pagination, search bar and other filters...
	private preorders: { [key: string]: number } = this.event.event_suites
		.filter(eventSuite => (eventSuite.status !== 'email_scheduled' && eventSuite.status !== 'email_sent'))
		.reduce((acc, eventSuite) => {
			return {
				...acc,
				[eventSuite.status]: acc[eventSuite.status] ? acc[eventSuite.status] + 1 : 1
			};
		}, {} as { [key: string]: number });

	/**
	 * Go to the clicked event's page
	 *
	 * @return {void}
	 */
	private goToEventPage(): void {
		this.$router.push(`/events/${this.event.id}`).catch(() => {});
	}
}
