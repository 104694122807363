import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { EquipmentState, RootState } from '../types';
const namespaced: boolean = true;

export const state: EquipmentState = {
	equipment: []
};


export const Equipment: Module<EquipmentState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Equipment;