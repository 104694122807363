import { ActionTree } from 'vuex';
import { EventSuiteState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';

export const actions: ActionTree<EventSuiteState, RootState> = {
	/**
	 * Fetch event suites for an event
	 *
	 * @param {number} eventId - The event id of the event suites
	 * @returns {Promise<void>}
	 */
	async fetchEventSuites({ commit }, eventId: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const eventSuites = await axiosInst.get(`/catering/events/${eventId}/event-suites`);
			commit('SET_EVENT_SUITES', eventSuites.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetches a single event suite from an event
	 *
	 * @param {number} eventSuiteId - The event suite id
	 * @param {number} eventId - The event id of the event suite
	 * @returns {Promise<void>}
	 */
	async fetchEventSuite({ commit }, { eventSuiteId, eventId }: { eventSuiteId: number, eventId: number }) {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const eventSuite = await axiosInst.get(`/catering/events/${eventId}/event-suites/${eventSuiteId}`);
			commit('SET_SELECTED_EVENT_SUITE', eventSuite.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetches an event suite from an event with all data needed for beo
	 *
	 * @param {number} eventSuiteId - The event suite id
	 * @param {number} eventId - The event id of the event suite
	 * @returns {Promise<void>}
	 */
	async fetchDeepEventSuite({ commit }, { eventSuiteId, eventId }: { eventSuiteId: number, eventId: number }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const eventSuite = await axiosInst.get(`/catering/events/${eventId}/event-suites/${eventSuiteId}/deep-get-event-suite`);
			commit('SET_SELECTED_EVENT_SUITE', eventSuite.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update event suite
	 *
	 * @param {EventSuite} eventSuite - Updated event suite object
	 * @param {number} eventId - The event id of the event suite
	 * @returns {Promise<void>}
	 */
	async updateEventSuite({ commit, dispatch }, { eventSuite, eventId }: { eventSuite: EventSuite, eventId: number }) {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const updatedEventSuite = await axiosInst.put(`/catering/events/${eventId}/event-suites/${eventSuite.id}`, eventSuite);
			commit('SET_SELECTED_EVENT_SUITE', updatedEventSuite.data);
			await dispatch('events/fetchEvent', eventId, { root: true });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Bulk update event suites
	 *
	 * @param {EventSuite[]} eventSuites - An array of updated event suites
	 * @param {number} eventId - The event id of the event suites
	 * @returns {Promise<void>}
	 */
	async bulkUpdateEventSuites({ dispatch }, { eventSuites, eventId }: { eventSuites: EventSuite[], eventId: number }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.post(`/catering/events/${eventId}/event-suites/bulk`, eventSuites);
			await dispatch('events/fetchEvent', eventId, { root: true });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete event suite
	 *
	 * @param {number} id - The id of the event suite
	 * @param {number} eventId - The event id of the event suite
	 * @returns {Promise<void>}
	 */
	async deleteEventSuite({ commit, dispatch }, { id, eventId }: { id: number, eventId: number }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/catering/events/${eventId}/event-suites/${id}`);
			commit('SET_SELECTED_EVENT_SUITE', null);
			await dispatch('events/fetchEvent', eventId, { root: true });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Sends a pre-order email to the customer
	 *
	 * @param {EventSuite} eventSuite - The event suite object
	 */

	async sendPreOrderEmail({ dispatch }, eventSuite: EventSuite): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const eventId = eventSuite.event_id;
			await axiosInst.post(`/catering/events/${eventId}/event-suites/${eventSuite.id}/send-pre-order-email`, eventSuite);
			await dispatch('events/fetchEvent', eventId, { root: true });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Finalize event day orders for an event suite
	 *
	 * @param {number} eventSuiteId - The event suite object
	 * @return {Promise<EventSuite | void>}
	 */
	async finalizeEventDayOrders({}, eventSuite: EventSuite): Promise<EventSuite | void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data } = await axiosInst.get<EventSuite>(`/catering/events/${eventSuite.event_id}/event-suites/${eventSuite.id}/finalize-orders`);
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
