
import { Component, Vue, Prop } from 'vue-property-decorator';

type ButtonType = 'primary' | 'secondary' | 'white';

@Component<TextButton>({})
export default class TextButton extends Vue {
	@Prop({ type: String, required: false, default: 'primary' }) private type!: ButtonType;
	@Prop({ type: String, required: false, default: '' }) private id!: string;
	@Prop({ type: String, required: true, default: '' }) private icon!: string;
	@Prop({ type: String, required: false, default: '' }) private to!: string;
	@Prop({ type: String, required: false, default: '' }) private href!: string;
	@Prop({ type: Boolean, required: false, default: false }) private loading!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;


	/**
	 * Emit click event when it isn't disabled nor loading
	 *
	 * @return {void}
	 */
	private click(): void {
		!this.loading && !this.disabled && this.$emit('click');
	}
}
