import { ActionTree } from 'vuex';
import { MenuState, RootState } from '../types';
import { state } from './menus';
import { state as authState } from '../auth/auth';
import axios, { AxiosInstance } from 'axios';
import { cleanAvailabilities, setAvailability } from '../../utils/availability';
import { formatMenu } from '@/utils/formatItem';
import { handleAllErrors } from '../../utils/errorHandling';
import { decodeMenu } from '@/utils/decoding';
import { DateTime } from 'luxon';
import i18n from '@/i18n';

export const actions: ActionTree<MenuState, RootState> = {

	/**
	 * Fetch menu groups
	 *
	 * @return {Promise<void>}
	 */
	async fetchMenuGroups({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menuGroups = await axiosInst.get('/menu-groups');
			commit('SET_MENU_GROUPS', menuGroups.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's menus
	 *
	 * @return {Promise<void>}
	 */
	async fetchMenus({ commit }): Promise<void> {
		commit('SET_FETCH_MENUS_COMPLETE', false);
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menus = await axiosInst.get('/menus/deep-get-menus');
			commit('SET_MENUS', menus.data);
			commit('SET_FETCH_MENUS_COMPLETE', true);
		}
		catch (error) {
			commit('SET_FETCH_MENUS_COMPLETE', true);
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's published menus
	 *
	 * @return {Promise<void>}
	 */
	async fetchPublishedMenus({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menus = await axiosInst.get<Menu[]>('/menus/get-published-menus');
			commit('SET_PUBLISHED_MENUS', menus.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's sections
	 *
	 * @param {Menu} menu
	 * @return {Promise<void>}
	 */
	async fetchSections({ commit }, menu: Menu): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const sections = (await axiosInst.get(`/menus/${menu.id}/sections/deep-get-sections`)).data;
			commit('SET_SECTIONS', { menu, sections });
			commit('SET_SELECTED_MENU_SECTIONS', { menu, sections });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's menu items
	 *
	 * @param {Menu} menu
	 * @return {Promise<void>}
	 */
	async fetchMenuItems({ commit }, menu: Menu): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const items = await axiosInst.get(`/menus/${menu.id}/items`);
			commit('SET_MENU_ITEMS', { menu, items: items.data });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's menu section items
	 *
	 * @param {Menu} menu
	 * @param {MenuSection} section
	 * @return {Promise<void>}
	 */
	async fetchMenuSectionItems({ commit }, { menu, section }: { menu: Menu, section: MenuSection}): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			section.menu_id = menu.id;
			const items = (await axiosInst.get(`/menus/${menu.id}/sections/${section.id}/items`)).data;
			commit('SET_SECTION_ITEMS', { section, items });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create menu group
	 *
	 * @param {MenuGroup} menuGroup
	 * @param {number[]} selectedMenus
	 * @param {File} imageFile
	 * @return {Promise<void>}
	 */
	async createMenuGroup({ dispatch, commit }, { menuGroup, selectedMenus, imageFile } : { menuGroup: MenuGroup, selectedMenus: number[], imageFile: File }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menuGroupPosted: MenuGroup = (await axiosInst.post('/menu-groups', { ...menuGroup, menus: selectedMenus })).data;
			if(imageFile) {
				await dispatch('uploadMenuGroupImage', { menuGroup: menuGroupPosted, image: imageFile });
			}
			dispatch('fetchMenuGroups');

			// Fetch menus
			const menus = await axiosInst.get('/menus/deep-get-menus');
			commit('SET_MENUS', menus.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Upload image to S3 and update the menu group's image accordingly
	 *
	 * @param {MenuGroup} menuGroup
	 * @param {any[]} image
	 * @return {Promise<void>}
	 */
	async uploadMenuGroupImage ({ dispatch }, { menuGroup, image } : { menuGroup: MenuGroup, image: any[] }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		const formData = new FormData();
		formData.append('file', image[0], image[0].name);

		try {
			await axiosInst.post(`/menu-groups/${menuGroup.id}/image`, formData, {
				headers: {
					'Content-Disposition': `form-data; name="file"; filename="${image[0].name}"`,
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		catch (error) {
			dispatch('fetchMenuGroups');
			handleAllErrors(error);
		}
	},

	/**
	 * Update a menu & its availabilities if any
	 *
	 * @param {MenuGroup} menuGroup
	 * @param {number[]} selectedMenus
	 * @param {File} imageFile
	 * @return {Promise<void>}
	 */
	async updateMenuGroup({ dispatch, commit }, { menuGroup, selectedMenus, imageFile } : { menuGroup: MenuGroup, selectedMenus: number[], imageFile: File }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menuGroupUpdated: MenuGroup = (await axiosInst.put(`/menu-groups/${menuGroup.id}`, { ...menuGroup, menus: selectedMenus }))?.data;
			if(imageFile) {
				await dispatch('uploadMenuGroupImage', { menuGroup: menuGroupUpdated, image: imageFile });
			}
			dispatch('fetchMenuGroups');

			// Fetch menus
			const menus = await axiosInst.get('/menus/deep-get-menus');
			commit('SET_MENUS', menus.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a menu
	 *
	 * @param {MenuGroup} menuGroup
	 * @return {Promise<void>}
	 */
	async deleteMenuGroup({ dispatch, commit }, menuGroup: MenuGroup): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/menu-groups/${menuGroup.id}`);
			dispatch('fetchMenuGroups');

			// Fetch menus
			const menus = await axiosInst.get('/menus/deep-get-menus');
			commit('SET_MENUS', menus.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Set selected menu and fetch the availability of that menu
	 *
	 * @param {Menu} selectedMenu
	 */
	async setSelectedMenu({ commit }, selectedMenu: Menu): Promise<void> {
		selectedMenu = decodeMenu(selectedMenu);
		if(selectedMenu.name && selectedMenu.availability) {
			selectedMenu.availability = setAvailability(selectedMenu.availability);
		}

		commit('SET_SELECTED_MENU', selectedMenu);
	},

	/**
	 * Create menu
	 *
	 * @param {Menu} menu
	 * @param {MenuAvailability} availability
	 * @param {File} imageFile
	 * @return {Promise<void>}
	 */
	async createMenu({ dispatch }, { menu, availability, imageFile }: { menu: Menu, availability: MenuAvailability[], imageFile: File }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menuPosted: Menu = (await axiosInst.post('/menus', menu)).data;
			if (availability.length) {

				// Temporary solution until API is updated.
				await axiosInst.post(`/menus/${menuPosted.id}/availability`, availability)
					.catch((error) => {
						dispatch('deleteMenu', menuPosted);
						handleAllErrors(error);
					});
			}
			if(imageFile) {
				await dispatch('uploadMenuImage', { menu: menuPosted, image: imageFile });
			}
			dispatch('fetchMenus');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Upload image to S3 and update the menu's image accordingly
	 *
	 * @param {Menu} menu
	 * @param {any[]} image
	 * @return {Promise<void>}
	 */
	async uploadMenuImage ({ dispatch }, { menu, image } : { menu: Menu, image: any[] }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const formData = new FormData();
		formData.append('file', image[0], image[0].name);

		try {
			await axiosInst.post(`/menus/${menu.id}/image`, formData, {
				headers: {
					'Content-Disposition': `form-data; name="file"; filename="${image[0].name}"`,
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		catch (error) {
			dispatch('fetchMenus');
			handleAllErrors(error);
		}
	},

	/**
	 * Update a menu & its availabilities if any
	 *
	 * @param {Menu} menu
	 * @param {MenuAvailability} availability
	 * @param {File} imageFile
	 * @return {Promise<void>}
	 */
	async updateMenu({ dispatch }, { menu, availability, imageFile } : { menu: Menu, availability: MenuAvailability[], imageFile: File }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const menuUpdated: Menu = (await axiosInst.put(`/menus/${menu.id}`, menu))?.data;
			if (availability?.length) {
				await axiosInst.post(`/menus/${menuUpdated.id}/availability`, availability);
			}
			if(imageFile) {
				await dispatch('uploadMenuImage', { menu: menuUpdated, image: imageFile });
			}
			dispatch('fetchMenus');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a menu
	 *
	 * @param {Menu} menu
	 * @return {Promise<void>}
	 */
	async deleteMenu ({ commit }, menu: Menu): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/menus/${menu.id}`);
			commit('SET_UPDATED_MENUS', { deletedMenuId: menu.id });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update menus availabilities if any
	 *
	 * @param {Menu} menu
	 * @param {MenuAvailability[]} availability
	 * @return {Promise<void>}
	 */
	async updateMenuAvailability({ dispatch }, { menu, availability } : { menu: Menu, availability: MenuAvailability[]}): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			if (availability.length) {
				await axiosInst.post(`/menus/${menu.id}/availability`, availability);
				dispatch('fetchMenus');
			}
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Copy the menu availability
	 *
	 * @param {Menu} menu
	 * @param {number[]} menuIds
	 * @return {Promise<void>}
	 */
	async copyMenuAvailability({ dispatch }, { menu, menuIds } : { menu: Menu, menuIds: number[]}): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const availability = cleanAvailabilities(menu.availability);
			if (availability.length && menuIds?.length) {
				await axiosInst.post(`/menus/${menu.id}/availability/copy`, { availability, menuIds });

				// Give time for the loading spinner UI
				setTimeout(() => {
					dispatch('fetchMenus');
				}, 800);
			}
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Duplicate a menu copy
	 *
	 * @param {Menu} menu - copying
	 * @return {Promise<void>}
	 */
	async duplicateMenu ({ dispatch }, menu: Menu): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const newMenuPayload = formatMenu({
				...menu,
				id: undefined,
				name: menu.name + i18n.t('shared.copy'),
				published_at: null,
				published: false
			} as Menu);
			const newMenu = formatMenu((await axiosInst.post('/menus/', newMenuPayload)).data) as Menu;
			if (menu.availability && menu.availability.length){
				await axiosInst.post(`/menus/${newMenu.id}/availability`, menu.availability);
			}
			if (menu.items){
				for (const item of menu.items) {
					await dispatch('items/duplicateMenuItem', { oldMenu: menu, newMenu: newMenu, item }, { root: true });
				}
			}
			if (menu.sections){
				for (const section of menu.sections) {
					await dispatch('sections/duplicateSection', { oldMenu : menu, newMenu: newMenu, section },{ root: true });
				}
			}
			dispatch('fetchMenus');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Copy selected menus to a selected list of restaurants
	 *
	 * @param {Menu[]} menus
	 * @param {number[]} restaurantIds
	 * @return {Promise<void>}
	 */
	async copyMenusToRestaurants({ }, { menus, restaurantIds } : { menus: Menu[], restaurantIds: number[]}): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.post('menus/replicate', { menus, restaurantIds });
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Pull all menus of each locations in the account that is integrated
	 *
	 * @return {Promise<any>}
	 */
	async pullAllMenus({ dispatch }): Promise<any> {
		try {
			if(authState.restaurants) {
				for (const restaurant of authState.restaurants) {
					if(restaurant.pos_integrated) {
						await dispatch('pullMenus', { id: restaurant.id, name: restaurant.name, pullAll: true });
					}
				}
				dispatch('fetchMenus');
			}
		}
		catch (error) {
			dispatch('fetchMenus');
			throw(error);
		}
	},

	/**
	 * Pull integrated menu(s) from Salata
	 * We start by making the API call, if the API call succeeds we simply fetch the menu
	 * again. However if the API call fails, it doesn't mean that the request failed to go
	 * through. If the API returns a 504, this most likely means that the Gateway timed out
	 * after 30 seconds. We cannot increase this timeout nor should we since we don't want
	 * requests to keep going for minutes for cost purposes. So, when the call returns a 504,
	 * we start an interval that can be ended by two conditions. 1: 5 minutes timeout if nothing
	 * works. 2: we keep fetching the menus, and checking if the updated_at date is now newer than
	 * the date before the pull, if it is, it means the pull was successful and we can now re-fetch.
	 *
	 * @param {MenuPullPayload} payload
	 * @return {Promise<any>}
	 */
	async pullMenus({ dispatch, commit }, payload: MenuPullPayload): Promise<any> {
		const axiosInst = axios.create({
			baseURL: process.env.VUE_APP_API_AUDIENCE,
			headers: {
				'X-RESTAURANT-ID': payload.id!,
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${authState.accessToken}`
			}
		});

		return await new Promise((resolve, reject) => {
			const localTime: string = DateTime.local().toISO();
			state.pulledLocationName = payload.name;
			commit('SET_FETCH_MENUS_COMPLETE', false);
			axiosInst.put(`/restaurants/upload-menu/${payload.id}`, { published_at: '2020-05-29 01:40:58' }) // Published_at date to trick event bridge
				.then(response => {
					// We let the pull all method fetch the menus once all the locations have been pulled
					if(!payload.pullAll) {
						dispatch('fetchMenus');
					}
					state.pulledLocationName = null;
					resolve(response);
				})
				.catch(error => {
					const tempError: any = error;

					// Service timeout from API Gateway
					if(tempError?.response?.data?.statusCode === 503 || tempError?.response?.data?.statusCode === 504 || tempError.message === 'Network Error') {
						// Grab updated_at date before pull and initiate counter (if no menus, we set the date to the local time)
						const currentUpdatedAt: string = state.menus[0] ? state.menus[0].updated_at! : localTime;
						let nextUpdatedAt: string = currentUpdatedAt;
						let counter: number = 0;

						// Start the interval and fetch the menus every 10 seconds until either we get to 5 minutes
						// or we have new menus
						const interval = setInterval(async () => {
							counter += 10; // set up seconds/minutes

							// Get fetched updated_at date
							const menus = await axiosInst.get('/menus');
							if(menus?.data[0]?.updated_at) {
								nextUpdatedAt = menus.data[0].updated_at;
							}

							// Check if we are at 5 minutes or the menu pull succeeded.
							if((DateTime.fromISO(currentUpdatedAt).toMillis() < DateTime.fromISO(nextUpdatedAt).toMillis()) || counter >= 300){
								clearInterval(interval);
								// We let the pull all method fetch the menus once all the locations have been pulled
								if(!payload.pullAll) {
									dispatch('fetchMenus');
								}
								state.pulledLocationName = null;

								// If we got here due to the 5 minutes timeout, then something did go wrong with the menu pull
								if(counter >= 300) {
									reject();
								}

								// If we got here because of the updated_at date difference, means we got a successful pull
								resolve('success');
							}
						}, 10000);
					}
					else {
						// We let the pull all method fetch the menus
						if(!payload.pullAll) {
							dispatch('fetchMenus');
						}
						state.pulledLocationName = null;
						reject(tempError);
					}
				});
		});
	},

	/**
	 * A call to the Remy to build the local menu
	 * On success a file will be written to the local file system, see the build-local-menu.ts in Remy for more info.
	 *
	 * @return {Promise<void>}
	 */
	async buildLocalMenu({}, restaurantId: string): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.get(`/menus/build-local-menu/${restaurantId}`);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Send CSV file to create the menus, sections and items.
	 * * The restaurant ID is part of the payload as it can be a restaurant other than the selected one
	 * * Send the Auth0 idToken in the Authorization header instead of the accessToken because we need the role metadata
	 *
	 * @param {{ restaurantId: string, file: File }} payload
	 * @return {Promise<{ [key: string]: number } | undefined>}
	 */
	async importCsv({ dispatch }, payload: { restaurantId: string, file: File }): Promise<{ [key: string]: number } | undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const formData = new FormData();
		formData.append('file', payload.file);

		try {
			const { data } = await axiosInst.post<{ [key: string]: number }>('/menus/csv-menus-import', formData, {
				headers: {
					'X-RESTAURANT-ID': payload.restaurantId,
					'Content-Disposition': `form-data; name="file"; filename="${payload.file.name}"`,
					'Content-Type': 'multipart/form-data',
					'Authorization': authState.idToken as string
				}
			});
			dispatch('fetchMenus');
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
