
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import EventsSearchFilters from '@/components/suites/all-events/EventsSearchFilters.vue';
import EventRowItem from '@/components/suites/all-events/EventRowItem.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'events';

type FiltersSubmitHandlerParams = { type: 'search'; value: string } | { type: 'eventTypeIds'; value: number[] } | { type: 'eventStatuses'|'dateRange'; value: string[] };

@Component<AllEvents>({
	components: {
		SuitesHeader,
		EventsSearchFilters,
		EventRowItem,
		TableHeaders,
		TextButton
	}
})
export default class AllEvents extends Vue {
	@Action('fetchFilteredEvents', { namespace }) private fetchFilteredEvents!: (searchQuery: SearchEventsQueryParams) => Promise<PaginatedResults<SuitesEvent>>;
	@Action('fetchEventTypes', { namespace: 'eventTypes' }) private fetchEventTypes!: Function;
	@Getter('getEventTypes', { namespace: 'eventTypes' }) private eventTypes!: EventType[];

	private isFetching: boolean = false;
	private totalPages: number = 1;
	private events: SuitesEvent[] = [];
	private searchQuery: SearchEventsQueryParams = {
		search: '',
		eventTypeIds: [],
		eventStatuses: [],
		dateRange: [],
		dateSortAsc: false,
		page: 1
	};
	private breadcrumbs: Breadcrumb[] = [{
		text: this.$t('events.header'),
		to: '/events',
		exact: true
	},
	{
		text: this.$t('all_events.header'),
		to: '/events/all',
		exact: true
	}];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get tableHeaders(): TableHeader[] {
		return [
			{ text: this.$t('all_events.table.status') },
			{ text: this.$t('all_events.table.title') },
			{ text: this.$t('all_events.table.date'), name: 'date', sort: this.searchQuery.dateSortAsc ? 'asc' : 'desc' },
			{ text: this.$t('all_events.table.type') },
			{ text: this.$t('all_events.table.pre_order_summary') }
		];
	}

	private created(): void {
		this.searchEvents();
	}

	/**
	 * Fetch the events with query parameters
	 *
	 * @return {Promise<void>}
	 */
	private async searchEvents(): Promise<void> {
		this.isFetching = true;
		try {
			const events = await this.fetchFilteredEvents(this.searchQuery);
			!this.eventTypes.length && await this.fetchEventTypes();
			setTimeout(() => {
				this.isFetching = false;
				this.events = events.rows;
				this.totalPages = events.count === 0 ? 1 : Math.ceil(events.count / 7);
			}, 500);

		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('events.error_fetching', { errorMessage });
			setTimeout(() => {
				this.isFetching = false;
				this.events = [];
				this.totalPages = 1;
			}, 500);
		}
	}

	/**
	 * Toggle the date sort flag and fetch filtered events
	 *
	 * @return {void}
	 */
	private toggleSort(): void {
		this.searchQuery.dateSortAsc = !this.searchQuery.dateSortAsc;
		this.searchEvents();
	}

	/**
	 * Update searchQuery object and reset pagination before fetching events
	 *
	 * @param {FiltersSubmitHandlerParams}
	 * @return {void}
	 */
	private filtersSubmitHandler({ type, value }: FiltersSubmitHandlerParams): void {
		this.searchQuery[type] = value as any;
		this.searchQuery.page = 1;
		this.searchEvents();
	}

	/**
	 * Increase or decrease page before fetching events
	 *
	 * @param {number} pageTarget -1 or 1 page
	 * @return {void}
	 */
	private pageNavigation(pageTarget: -1 | 1): void {
		this.searchQuery.page += pageTarget;
		this.searchEvents();
	}

	/**
	 * Clear filters and fetch events
	 *
	 * @return {void}
	 */
	private clearFilters(): void {
		this.searchQuery = {
			search: '',
			eventTypeIds: [],
			eventStatuses: [],
			dateRange: [],
			dateSortAsc: false,
			page: 1
		};
		this.searchEvents();
	}
}
