import { ActionTree } from 'vuex';
import { EventTypeState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance, AxiosResponse } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';
import { decodeNameOfEntity } from '@/utils/decoding';

export const actions: ActionTree<EventTypeState, RootState> = {
	/**
	 * Fetch event types
	 *
	 * @returns {Promise<void>}
	 */
	async fetchEventTypes({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data }: AxiosResponse = await axiosInst.get('/catering/event-types');
			commit('SET_EVENT_TYPES', data.map((eventType: EventType) => decodeNameOfEntity(eventType)));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create a new event type
	 *
	 * @param {{ name: string }} eventType
	 * @return {Promise<void>}
	 */
	// TODO: Update eventType typing after adding other properties
	async addEventType({ commit }, eventType: { name: string }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data }: AxiosResponse = await axiosInst.post('/catering/event-types', eventType);
			commit('ADD_EVENT_TYPE', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update an event type
	 *
	 * @param {EventType} eventType
	 * @return {Promise<void>}
	 */
	async updateEventType({ commit }, eventType: EventType): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const { id, name } = eventType;
		try {
			const { data }: AxiosResponse = await axiosInst.put(`/catering/event-types/${id}`, { name });
			commit('UPDATE_EVENT_TYPE', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
