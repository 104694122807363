
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import '@/validation-rules';

@Component<School>({
	components: {
		ValidationProvider,
		InfoBanner
	}
})
export default class School extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetDeliveryInfo!: Delivery;

	private mutableDeliveryInfo: Delivery = this.targetDeliveryInfo;
	private grades: string = '';
	private classrooms: string = '';

	private mounted(): void {
		if (this.mutableDeliveryInfo.grades) {
			this.grades = this.mutableDeliveryInfo.grades;
		}
		if (this.mutableDeliveryInfo.classrooms) {
			this.classrooms = this.mutableDeliveryInfo.classrooms;
		}
	}

	private updateDeliveryInfo(): void {
		this.mutableDeliveryInfo.grades = this.grades;
		this.mutableDeliveryInfo.classrooms = this.classrooms;
	}
}
