
// TODO: Add correct classes for error state, and prevent the styles from being removed when the input is focused
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
@Component<StyledGoogleAutocomplete>({
	components: {
		VueGoogleAutocomplete
	}
})
export default class StyledGoogleAutocomplete extends Vue {
	@Prop({ type: String, required: true }) private id!: string;
	@Prop({ type: String, required: false, default: '' }) private label!: string;
	@Prop({ type: String, required: true }) private country!: string;
	@Prop({ type: String, required: false, default: 'off' }) private autocomplete!: string;
	@Prop({ type: String, required: false, default: '' }) private errorMessages!: string;

	private inputState: string = 'inactive';
	private value: string = '';

	$refs!: {
		autocomplete: any
	};

	/**
	* Get the classes that vuetify uses for input field depending on the state
	*
	* @return {string}
	*/
	private get inputClasses(): string {
		const baseClass = 'v-input suites-input label-md theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined';

		switch (this.inputState) {
			case 'focused':
				return `${baseClass} v-input--is-focused primary--text`;
			case 'active':
				return `${baseClass} v-input--is-label-active v-input--is-dirty valid`;
			case 'error':
				return `${baseClass} v-input--is-label-active v-input--is-dirty`;
			default:
				return baseClass;
		}
	}

	/**
	 * Check if the input has an error and set the input state to error
	 *
	 * @return {boolean}
	 */
	private get hasError(): boolean {
		if (this.errorMessages.length > 0) {
			this.inputState = 'error';
			return true;
		}
		return false;
	}

	/**
	 * Create a watcher to watch for the value attr and update the value of the google autocomplete
	 * This ensures that the value attrs, that comes from the v-model when the component is used, has a value and can update the google autocomplete
	 * Disable the watcher after the value has been set, because we just want to set the value once
	 *
	 * @return {void}
	 */
	private created(): void {
		const valueWatcher = this.$watch('$attrs.value', (newVal: string) => {
			if (newVal) {
				this.value = newVal;
				this.$refs.autocomplete.update(this.value);
				this.inputState = 'active';
				// The this.$watch function returns an unwatch function, so we can call it to disable the watcher
				valueWatcher();
			}
		});
		// Disable the watcher after 1 second to prevent the watcher from running indefinitely
		setTimeout(() => {
			valueWatcher();
		}, 1000);
	}

	/**
	 * Update the input value on change and emit the input event
	 *
	 * @param {string} inputValue
	 * @return {void}
	 */
	private updateValue(inputValue: string): void {
		this.value = inputValue;
		this.$emit('input', this.value);
	}

	/**
	* Emit the place changed event with the place and placeData from the google autocomplete
	*
	* @param {any} place
	* @param {any} placeData
	* @return {void}
	*/
	private onPlaceChanged(place: any, placeData: any): void {
		this.value = placeData.formatted_address;
		this.$emit('placechanged', place, placeData);
	}

	/**
	* Update the state of the input field when it loses focus
	*
	* @return {void}
	*/
	private handleBlur(): void {
		this.inputState = this.value.length ? 'active' : 'inactive';

		// Reset the width of the label background because the label moves back to a placeholder position when inactive
		if (this.inputState === 'inactive') {
			const el = document.getElementById(`${this.id}-autocomplete`)!;
			el.querySelector('legend')?.style.setProperty('width', '0');
		}
	}

	/**
	* Sets the input state to focused and calculates the width of the label background based on the label width
	*
	* @return {void}
	*/
	private handleFocus(): void {
		this.inputState = 'focused';
		const el = document.getElementById(`${this.id}-autocomplete`)!;
		const labelBg = el.querySelector('legend');
		// Multiply the label width by 0.75 because vuetify applies a 0.75 scale transform to the label
		const labelWidth = ((el.querySelector('label.v-label')?.clientWidth || 0) * 0.75) + 5 + 'px';
		labelBg?.style.setProperty('width', labelWidth);
	}
}
