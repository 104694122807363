import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';

export const actions: ActionTree<{}, RootState> = {
	/**
	 * Fetch and return the change history
	 * We don't store it in a state to avoid showing non-updated data because of caching
	 *
	 * @param {ChangeHistoryFetchOptions} [options]
	 * @return {Promise<ChangeHistory[]>}
	 */
	async fetchChangeHistory({}, options?: ChangeHistoryFetchOptions): Promise<ChangeHistory[] | undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data } = await axiosInst.get<ChangeHistory[]>('/change-history', { params: options });
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
