import Vue from 'vue';
import store from './store';
import i18n from './i18n';
import { DateTime } from 'luxon';

Vue.filter('capitalize', (value: string) => {
	if (!value) {
		return '';
	}

	return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('formatDate', (value: string, format: string = 'dd/MM/yyyy') => {
	if (!value) {
		return '';
	}
	const locale = i18n.locale.substring(0,2);

	return DateTime.fromISO(value).setLocale(locale).toFormat(format);
});

Vue.filter('localize', (element: LocaleElement, field: string) => {
	const restaurant: Restaurant = store.getters['auth/getRestaurant'];
	const locales: RestaurantLocale[] | undefined = restaurant.locales;
	const locale = locales && locales.find((locale: RestaurantLocale) => {
		return locale.locale_short === i18n.locale.substring(0,2);
	})!;
	const elementLocalization: Localization | undefined = element.localization;

	if(locale && elementLocalization && i18n?.locale && elementLocalization[i18n.locale.substring(0,2)] && elementLocalization[i18n.locale.substring(0,2)][field]) {
		return elementLocalization[i18n.locale.substring(0,2)][field];
	}
	return element[field];
});