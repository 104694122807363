import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { valueSort } from './sort';
import i18n from '@/i18n';

/**
 * Sorts the options by order or price and parse the options to the correct format
 *
 * @param {OptionGroup[]} options
 */
function parseAndSortOptions(options: OptionGroup[]): void {
	// If the option group has an order, we have to sort them, otherwise keep going
	if(options.some(optionGroup => optionGroup.order )) {
		options.sort((a: OptionGroup, b: OptionGroup) => valueSort(a, b, 'order'));
	}
	options.map((optionGroup: OptionGroup) => {
		if(optionGroup.values?.length) {

			// Verify if the default price matches its scheduled price
			for (let index = 0; index < optionGroup.values.length; index++) {
				let option = optionGroup.values[index];

				option = getPriceAndAvailability(option) as Option;

				option.calories = Number.isInteger(option.calories) && option.calories! >= 0 ? option.calories : null;
				option.quantity = 0;
			}

			// If the options has an order, we have to sort them by it, otherwise sort
			// by price.
			if(optionGroup.values.some(option => option.order )) {
				optionGroup.values.sort((a: Option, b: Option) => valueSort(a, b, 'order'));
			}
			else {
				optionGroup.values.sort((a: Option, b: Option) => valueSort(a, b, 'price'));
			}

			// Repeat if more options (Treats it as an object, transform into an array for recursivity)
			// Also manipulation this right here in case we wanna change the structure to having more than
			// one option group per options, it'll be an easier change if we treat it already as an array.
			optionGroup.values.map((option: any) => {
				// If for some reason roll up isn't present, we set it to true as it is its default
				if(typeof option.roll_up === 'undefined') {
					option.roll_up = true;
				}

				if(option.sub_group) {
					option.options = [option.sub_group];
					parseAndSortOptions(option.options);
				}
			});
		}
	});
}

/**
 * Sort the options by order (DESC) and then price (ASC) if the order is not present
 *
 * @param {OptionGroup[] | undefined} options
 * @return {OptionGroup[] | undefined}
 */
function sortOption(options: OptionGroup[] | undefined): OptionGroup[] | undefined {
	if(options?.length) {

		// Sort the options by order (DESC) and then price (ASC) if the order is not present
		parseAndSortOptions(options);

		// Put the pricing option into its own array to be treated differently
		const pricingOption = options.find(option => option.type === 'pricing');
		if(pricingOption) {
			const addonsOption = options.filter(option => option.type !== 'pricing');
			const newOptionArray = [pricingOption, ... addonsOption];
			return newOptionArray;
		}
	}
	return options;
}

/**
 * Check if the item is available and update the price if there is a scheduled price
 *
 * @param {MenuItem | Option} item
 * @return {MenuItem | Option}
 */
function getPriceAndAvailability(item: MenuItem | Option): MenuItem | Option {
	const dateObj: DateTime = DateTime.local();
	// Get the item's schedule of the day
	const dayOfWeek: number = dateObj.weekday;

	if (item.price_schedule?.length) {
		const itemDaySchedule = item.price_schedule[dayOfWeek - 1];

		// Instantiate the date that will be used for scheduling to beginning of the day
		const today = dateObj.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		const todayDiff = dateObj.diff(today, ['minutes']);
		const scheduleIndex = Math.floor(todayDiff.minutes / 15);

		// We validate the item price
		if(itemDaySchedule) {
			if(item.additional_prices && Object.keys(item.additional_prices)) {
				const itemPriceKey: string = itemDaySchedule[scheduleIndex];
				const itemScheduledPrice = item.additional_prices[itemPriceKey];

				// If the item price key is X, this means the item is unavailable to order
				// If the price does not exists in the extra prices, we throw.
				// We check for undefined since null or 0 could be accepted
				if (itemPriceKey && itemPriceKey.toLowerCase() === 'x' || typeof itemScheduledPrice === 'undefined') {
					item.unavailable = true;
					return item;
				}

				// If the default price does not match, we set the price to its scheduled price
				if (Number(item.price) !== Number(itemScheduledPrice)) {
					item.price = Number(itemScheduledPrice).toFixed(2);
				}
			}
		}
	}

	return item;
}

function createItem(i: MenuItem, m: Menu, items: MenuItem[]) {
	if(i.published_at) {
		i = getPriceAndAvailability(i) as MenuItem;
		const menuItem: MenuItem = {
			id: i.id,
			menu_group_id: m.menu_group_id,
			menu_id: m.id!,
			section_id: i.section_id,
			name: i.name,
			description: i.description,
			ingredients: i.ingredients,
			slug: i.slug,
			sku: i.sku,
			category: i.category,
			calories: Number.isInteger(i.calories) && i.calories! >= 0 ? i.calories : null,
			image: i.image,
			price: i.price,
			promos: i.promos,
			tax_rate: i.tax_rate,
			tax_rebate_eligibility: i.tax_rebate_eligibility,
			price_unit: i.price_unit,
			price_type: i.price_type,
			allergens: i.allergens,
			diets: i.diets,
			price_schedule: i.price_schedule,
			additional_prices: i.additional_prices,
			options: sortOption(i.options!),
			sold_out: i.sold_out,
			unavailable: i.unavailable,
			alcoholic_beverage: i.alcoholic_beverage,
			order: i.order,
			localization: i.localization,
			// this doesn't do anything but typescript will complain that it's missing
			auto_restock: 'disabled'
		};
		items.push(menuItem);
	}
}

export function transformMenusResponse(menus: Menu[]) {
	if (menus) {
		const formattedMenus: Menu[] = [];
		menus = orderBy(menus, ['order']);
		menus.forEach((m: Menu): void => {
			const sections: MenuSection[] = [];
			m.sections = orderBy(m.sections, ['order']);

			const menu_items: MenuItem[] = [];

			if (m.items && m.items.length){
				m.items = orderBy(m.items, ['order']);

				m.items.forEach(( i: MenuItem): void => {
					createItem(i, m, menu_items);
				});
			}
			if (m.sections){
				m.sections.forEach((s: MenuSection): void => {
					let items: MenuItem[] = [];
					s.items = orderBy(s.items, ['order']);

					s.items.forEach((i: MenuItem): void => {
						createItem(i, m, items);
					});
					items = orderBy(items, ['order']);
					const menuSection: MenuSection = {
						id: s.id,
						name: s.name,
						slug: s.slug,
						order: s.order,
						price: s.price,
						description: s.description,
						localization: s.localization,
						type: s.type,
						items
					};

					sections.push(menuSection);
				});
			}
			const menu: Menu = {
				name: m.name,
				id: m.id,
				slug: m.slug,
				image: m.image,
				description: m.description,
				sections: sections,
				dine_in: m.dine_in,
				on_demand: m.on_demand,
				scheduled: m.scheduled,
				member_only: m.member_only,
				access_level: m.access_level ? m.access_level : 'unrestricted',
				availability: [],
				order: m.order,
				localization: m.localization,
				menu_group_id: m.menu_group_id,
				items: menu_items,
				allow_sections: m.allow_sections,
				max_orders_per_interval: m.max_orders_per_interval,
				ordering_interval: m.ordering_interval,
				last_call_time: m.last_call_time,
				estimated_prep_time: m.estimated_prep_time,
				price: m.price,
				required_notice: m.required_notice,
				type: m.type,
				published_at: m.published_at
			};
			formattedMenus.push(menu);
		});
		return formattedMenus;
	}
	else {
		throw Error(i18n.t('orders.error_parsing_menus'));
	}
}