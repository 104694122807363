
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ROLES, TOAST_INSTANCE } from '@/utils/constants';
import { decodeOrder } from '@/utils/decoding';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import OrderManagementList from '@/components/order-management/OrderManagementList.vue';
import OrderModal from '@/components/order-management/OrderModal.vue';
import BulkActionsModal from '@/components/order-management/BulkActionsModal.vue';
import CancelledOrdersModal from '@/components/order-management/CancelledOrdersModal.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'orders';

@Component<OrderManagement>({
	components: {
		SuitesHeader,
		OrderManagementList,
		OrderModal,
		BulkActionsModal,
		CancelledOrdersModal,
		FullPageSpinner,
		TextButton
	}
})
export default class OrderManagement extends Vue {
	@Action('fetchOrders', { namespace }) private fetchOrders!: (lastCreatedAt? : string) => Promise<{ newOrders: boolean, fetchDate: string }|undefined>;
	@Action('fetchMarketplaceOrders', { namespace }) private fetchMarketplaceOrders!: (lastCreatedAt? : string) => Promise<{ newOrders: boolean, fetchDate: string }|undefined>;
	@Getter('getCurrentOrders', { namespace }) private orders!: CurrentOrders;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isMarketplaceEnabled', { namespace: 'auth' }) private isMarketplaceEnabled!: boolean;
	@Getter('areNotificationSoundsAllowed', { namespace: 'auth' }) private areNotificationSoundsAllowed!: boolean;
	@Getter('allowBulkComplete', { namespace: 'auth' }) private allowBulkComplete!: boolean;
	@Getter('allowBulkCancel', { namespace: 'auth' }) private allowBulkCancel!: boolean;

	private intervalId: number = 0;
	private isDataFetching: boolean = false;
	private lastFetchDate: string|undefined = undefined;
	private modal: { [key: string]: boolean } = {
		order: false,
		cancelledOrders: false,
		bulkActions: false
	};
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private selectedOrder: Order | undefined = {} as Order;
	private roles: Roles = ROLES;

	/**
	 * Fetch the orders on creation, then every 10 seconds.
	 * The spinner only shows up for the first load, after that
	 * we don't need to, orders update in realtime and there is
	 * a small animation per section.
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		this.isDataFetching = true;
		this.fetchCurrentOrders();
		this.setFetchInterval();
	}

	/**
	 * Remove the fetch orders interval
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		clearInterval(this.intervalId);
	}

	/**
	 * Fetch orders and set the state.
	 *
	 * @param {{ id?: number; status?: string; }} orderInfo
	 * @return {Promise<void>}
	 */
	private async fetchCurrentOrders(orderInfo: { id?: number; status?: string; } = {}): Promise<void> {
		try {
			const response: { newOrders: boolean, fetchDate: string } | undefined = this.isMarketplaceEnabled ? await this.fetchMarketplaceOrders(this.lastFetchDate) : await this.fetchOrders(this.lastFetchDate);
			if (response?.newOrders && this.areNotificationSoundsAllowed) {
				const audio: HTMLAudioElement = new Audio(require('@/assets/audio/notification.ogg'));
				audio.play();
			}
			this.lastFetchDate = response?.fetchDate;
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_fetching_orders', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isDataFetching = false;
				if (orderInfo.id) {
					this.selectedOrder = decodeOrder((this.orders.inProgress as Order[]).find(order => order.id === orderInfo.id) as Order);
				}
				if (orderInfo.status && this.selectedOrder) {
					this.selectedOrder.status = orderInfo.status;
				}
			}, 500);
		}
	}

	/**
	 * Set interval to fetch orders every 10 seconds
	 *
	 * @return {Promise<void>}
	 */
	private async setFetchInterval(): Promise<void> {
		this.intervalId = setInterval(async () => {
			await this.fetchCurrentOrders();
		}, 10000);
	}

	/**
	 * Show modal for the specific order
	 *
	 * @param {Order} order
	 * @return {void}
	 */
	private showModal(order: Order): void {
		this.selectedOrder = decodeOrder(order);
		this.modal.order = true;
	}
}
