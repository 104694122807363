
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ParentOrderCard from '@/components/order-management/ParentOrderCard.vue';

@Component<OrderManagementList>({
	components: {
		ParentOrderCard
	}
})
export default class OrderManagementList extends Vue {
	@Prop({ type: Object, default: () => {} }) private orders!: CurrentOrders;
	@Watch('pendingOrdersAmount') private onPendingOrdersAmountChange(newValue: number, oldValue: number): void {
		if(newValue !== oldValue) {
			const pendingOrdersAmountLabel: HTMLElement = document.getElementById('pending-orders-amount-label') as HTMLElement;
			this.addLabelAnimation(pendingOrdersAmountLabel, 3);
		}
	}
	@Watch('inProgressOrdersAmount') private onInProgressOrdersAmountChange(newValue: number, oldValue: number): void {
		if(newValue !== oldValue) {
			const inProgressOrdersAmountLabel: HTMLElement = document.getElementById('in-progress-orders-amount-label') as HTMLElement;
			this.addLabelAnimation(inProgressOrdersAmountLabel, 3);
		}
	}
	@Watch('completedOrdersAmount') private onCompletedOrdersAmountChange(newValue: number, oldValue: number): void {
		if(newValue !== oldValue) {
			const completedOrdersAmountLabel: HTMLElement = document.getElementById('completed-orders-amount-label') as HTMLElement;
			this.addLabelAnimation(completedOrdersAmountLabel, 3);
		}
	}

	private showScrollToTopButton: boolean = false;

	private get pendingOrdersAmount(): number {
		return this.orders.pending.length;
	}

	private get inProgressOrdersAmount(): number {
		return this.orders.inProgress.length;
	}

	private get completedOrdersAmount(): number {
		return this.orders.completed.length;
	}

	/**
	 * On scroll event, check if the user has scrolled more than 140px, if so, show the scroll to top button
	 *
	 * @param {any} e
	 * @return {void}
	 */
	private onScroll(e: any): void {
		const max: number = 140;
		const top: number = window.pageYOffset || e.target.scrollTop || 0;
		this.showScrollToTopButton = top > max;
	}

	private scrollToTop(): void {
		this.$vuetify.goTo(0);
	}

	/**
	 * Add a little blink animation to the circle labels to show that there are new orders
	 *
	 * @param {HTMLElement} element
	 * @param {number} count
	 * @return {void}
	 */
	private addLabelAnimation(element: HTMLElement, count: number): void {
		if (count <= 0) {
			return;
		}

		element.classList.add('new-orders');
		setTimeout(() => {
			element.classList.remove('new-orders');
			setTimeout(() => {
				this.addLabelAnimation(element, count - 1);
			}, 400);
		}, 400);
	}
}
