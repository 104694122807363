
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ROLES } from '../../utils/constants';
import Auth from '@/services/auth/auth';

const namespace: string = 'auth';

@Component<Navbar>({})
export default class Navbar extends Vue {
	@Getter('getRestaurants', { namespace }) private restaurants!: Tenant[];
	@Getter('getRoleLevel', { namespace }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace }) private isPosIntegrated!: Boolean;
	@Getter('areSuitesEnabled', { namespace }) private areSuitesEnabled!: Boolean;
	@Getter('isCateringRestaurant', { namespace }) private isCateringRestaurant!: Boolean;
	@Getter('isMarketplaceEnabled', { namespace }) private isMarketplaceEnabled!: Boolean;
	@Getter('isOrderManagementEnabled', { namespace }) private isOrderManagementEnabled!: Boolean;
	@Getter('getMenus', { namespace: 'menus' }) private menus!: Menu[];
	@Watch('menus', { immediate: true })
	onMenusUpdate() {
		this.addMenuNavbarSettings();
	}

	private drawer: boolean = true;
	private mini: boolean = true;
	private menuSettings: object[] = [];
	private settings: object[] = [];
	private eventSettings: object[] = [
		{ id: 'event-setting', name: this.$t('navbar.btn_event_settings'), icon: 'mdi-cog', to: '/event-settings' }
	];
	private roles: Roles = ROLES;
	private groupOpened: { [key: string]: boolean } = {
		menu: true,
		event: true
	};

	private get logoutBtnText(): string {
		return window.B2BApp ? this.$t('navbar.btn_back_to_app') : this.$t('navbar.btn_logout');
	}

	private get generalSettings(): object[] {
		if (this.isCateringRestaurant) {
			return [
				{ id: 'orders-report', name: this.$t('navbar.btn_orders_report'), icon: 'mdi-clipboard-check-outline', to: '/orders-report' },
				...this.settings
			];
		}
		return this.settings;
	}

	/**
	 * On created, we have a few checks to do to see what to display
	 * depending on the role of the user.
	 *
	 * @return {void}
	 */
	private created(): void {
		if(this.roleLevel <= this.roles.manager) {
			this.settings.push(
				{ id: 'customization-setting', name: this.$t('navbar.btn_customizations'), icon: 'mdi-palette', to: '/customize' },
				{ id: 'advanced-setting', name: this.$t('navbar.btn_advanced'), icon: 'mdi-cogs', to: '/advanced' }
			);
		}

		// Member and above can navigate to restaurant list
		if(this.roleLevel <= this.roles.member) {
			if (this.restaurants && this.restaurants.length > 1) {
				this.settings.push({ id: 'restaurant-setting', name: this.$t('navbar.btn_location_list'), icon: 'mdi-storefront', to: '/locations' });
			}
		}
	}

	/**
	 * Add the addon/menus setting, then for the rest we have to check a few
	 * conditions. First off we check if their role is manager or better, if
	 * yes then they can navigate to availability (if menus) & replicate if
	 * menus & restaurants
	 *
	 * @return {void}
	 */
	private addMenuNavbarSettings(): void {
		this.menuSettings = [];
		this.menuSettings.push({ id: 'add-on-setting', name: this.$t('navbar.btn_add_ons'), icon: 'mdi-playlist-plus', to: '/add-ons' });

		if (this.menus && this.menus.length) {
			this.menuSettings.push({ id: 'availability-setting', name: this.$t('navbar.btn_availability'), icon: 'mdi-clock', to: '/availabilities' });

			if (this.roleLevel <= this.roles.enterprise && (!this.isPosIntegrated || this.roleLevel === this.roles.admin) && (this.restaurants && this.restaurants.length > 1)) {
				this.menuSettings.push({ id: 'replicate-menus-setting', name: this.$t('navbar.btn_replicate_menus'), icon: 'mdi-content-duplicate', to: '/replicate-menus' });
			}
		}
	}

	/**
	 * Go to menu or event list when clicking on the list group. We are
	 * also preventing the event propagation if the user clicked
	 * elsewhere than the arrow because we want to prevent the
	 * default expand/collapse of the list group behaviour. Wish
	 * Vuetify would allow more flexibility around that tbh.
	 *
	 * @param {any} event
	 * @param {string} type
	 * @return {void}
	 */
	private goToList(event: any, type: string): void {
		if(event.target.id !== `${type}-tab-chevron`) {
			// If the navbar is minimized, we still want the event to expand it
			if(this.mini) {
				this.resize(true);
				Vue.set(this, 'mini', false);
			}

			if(this.$route.path !== `/${type}s`) {
				this.$router.push(`/${type}s`).catch(() => {});
			}

			event.stopPropagation();
		}
	}

	/**
	 * Open the group when user clicks on chevron.
	 *
	 * @param {string} type
	 * @return {void}
	 */
	private openGroup(type: string): void {
		this.groupOpened[type] = !this.groupOpened[type];
	}

	/**
	 * Log the user out
	 *
	 * @return {void}
	 */
	private logout(): void {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
	}

	/**
	 * Resize event for the drawer, we want to move the dynamic main btn that is fixed
	 * so it stays centered
	 *
	 * @param {boolean} expand
	 * @return {void}
	 */
	private resize(expand: boolean): void {
		this.mini = !expand;

		setTimeout(() => {
			const dynamicBtn = document.getElementById('main-dynamic-btn');
			if(dynamicBtn) {
				const drawerWidth = document.getElementById('nav-drawer')!.offsetWidth;
				document.documentElement.style.setProperty('--dynamic-btn-left-value', `${drawerWidth}px`);
			}
		}, 200);
	}
}
