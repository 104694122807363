
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import SuiteOwnerModal from '@/components/suites/SuiteOwnerModal.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';

const namespace: string = 'suiteOwners';

@Component<SuiteOwners>({
	components: {
		SuitesHeader,
		TextButton,
		IconButton,
		FullPageSpinner,
		SuiteOwnerModal,
		TableHeaders
	}
})
export default class SuiteOwners extends Vue {
	@Action('fetchSuiteOwners', { namespace }) private fetchSuiteOwners!: () => Promise<void>;
	@Getter('getSuiteOwners', { namespace }) private suiteOwners!: SuiteOwner[];

	private isFetching: boolean = false;
	private showModal: boolean = false;
	private selectedOwner: number|undefined = undefined;
	private breadcrumbs: Breadcrumb[] = [{
		text: this.$t('event_settings.header'),
		to: '/event-settings',
		exact: true
	},
	{
		text: this.$t('event_settings.suite_owners.title'),
		to: '/event-settings/suite-owners',
		exact: true
	}];
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('event_settings.suite_owners.table_header_name') },
		{ text: this.$t('event_settings.suite_owners.table_header_company') },
		{ text: this.$t('event_settings.suite_owners.table_header_title') },
		{ text: this.$t('event_settings.suite_owners.table_header_email') },
		{ text: this.$t('event_settings.suite_owners.table_header_phone') },
		{ text: this.$t('event_settings.suite_owners.table_header_edit') }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	/**
	 * Fetch the suite owners if they haven't been fetched before
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		if (!this.suiteOwners.length) {
			this.isFetching = true;
			try {
				await this.fetchSuiteOwners();
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('event_settings.suite_owners.error_fetching', { errorMessage });
			}
			finally {
				setTimeout(() => {
					this.isFetching = false;
				}, 500);
			}
		}
	}

	/**
	 * Close suite owner modal
	 *
	 * @return {void}
	 */
	private toggleModal(ownerId: number|undefined): void {
		this.selectedOwner = ownerId;
		this.showModal = !this.showModal;
	}
}
