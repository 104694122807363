import { ActionTree } from 'vuex';
import { SectionState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { formatSection } from '@/utils/formatItem';
import { handleAllErrors } from '../../utils/errorHandling';
import { decodeSection } from '@/utils/decoding';
import i18n from '@/i18n';

export const actions: ActionTree<SectionState, RootState> = {
	setSelectedSection({ commit }, selectedSection: MenuSection): void {
		selectedSection = decodeSection(selectedSection);
		commit('SET_SELECTED_SECTION', selectedSection);
	},

	/**
	 * Create a menu section
	 *
	 * @return {Promise<void>}
	 */
	async createMenuSection({ dispatch }, { menu, section }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.post(`/menus/${menu.id}/sections`, section);
			dispatch('menus/fetchMenus', {}, { root: true });
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * Update a menu section
	 *
	 * @return {Promise<void>}
	 */
	async updateMenuSection({ dispatch }, { menu, section }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.put(`/menus/${menu.id}/sections/${section.id}`, section);
			dispatch('menus/fetchSections', menu, { root: true });
			// Do not refetch the sections if the menu is being moved
			if (menu.id === section.menu_id) {
				dispatch('menus/fetchMenuSectionItems', { menu, section }, { root: true });
			}
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a section
	 *
	 * @return {Promise<void>}
	 */
	async deleteSection ({ dispatch, commit }, { menu, section }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/menus/${menu.id}/sections/${section.id}`);
			commit('menus/SET_SELECTED_MENU_SECTIONS', { menu, sections: menu.sections, deletedSectionId: section.id }, { root: true });
		}
		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	},


	/**
	 * duplicates a section ( deep copy)
	 * @param dispatch
	 * @param oldMenu - copying from
	 * @param newMenu - copying to
	 * @param section - copying
	 */
	async duplicateSection ({ dispatch }, { oldMenu, newMenu, section }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const items = (await axiosInst.get(`/menus/${oldMenu.id}/sections/${section.id}/items`)).data as MenuItem[];
			const newSectionPayload = formatSection({
				...section,
				id: null,
				name: section.name + i18n.t('shared.copy'),
				menu_id: newMenu.id,
				published_at: null,
				published: false
			} as MenuSection);
			const newSection = formatSection((await axiosInst.post(`/menus/${newMenu.id}/sections/`, newSectionPayload)).data) as MenuSection;
			for (const item of items) {
				await dispatch('items/duplicateMenuSectionItem', { oldMenu: oldMenu, newMenu: newMenu, oldSection: section, newSection: newSection, item }, { root: true });
			}

			if (oldMenu.id === newMenu.id){
				dispatch('menus/fetchMenus', {}, { root: true });
			}
		}
 		catch (error) {
			dispatch('menus/fetchMenus', {}, { root: true });
			handleAllErrors(error);
		}
	}
};
