
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { getTimeArray } from '../../../utils/timeFunctions';
import { resetAvailabilities, resetAvailability, setTempWeekDays, initTempAvailability } from '../../../utils/availability';
import { WEEKDAYS } from '../../../utils/constants';
import { DateTime } from 'luxon';
import { cleanAvailabilities } from '../../../utils/availability';
import { setAvailability } from '../../../utils/availability';
import SmallModalWrapper from '@/components/shared/SmallModalWrapper.vue';
import CopyAvailability from '@/components/menu/form/CopyAvailability.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'menus';

@Component<Availability>({
	components: {
		SmallModalWrapper,
		CopyAvailability,
		TextButton
	}
})
export default class Availability extends Vue {
	@Action('updateMenuAvailability', { namespace }) private updateMenuAvailability!: (payload: any) => Promise<void>;
	@Prop({ type: Boolean, default: false }) private saveSection!: boolean;
	@Prop({ type: Boolean, default: false }) private showSaveBtn!: boolean;
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;
	@Prop({ type: String, default: '' }) private title!: string;
	@Prop({ type: Boolean, default: false }) private inEditorModal!: boolean;

	private dailyHours: boolean = false;
	private loading: boolean = false;
	private showCopyHoursDialog: boolean = false;
	private mutableMenu: Menu = this.menu;
	private timeAvailabilityArray: string[] = getTimeArray(15, 'minutes');
	private tempWeekDays: object[] = this.initWeekDays();

	/**
	 * Set the default availability if there is none.
	 * Otherwise check if the availability is all week.
	 * If it isn't, we need to check which day is set
	 * and their respective start_time/end_time.
	 *
	 * @return {void}
	 */
	private created(): void {
		if (this.mutableMenu.availability && this.mutableMenu.availability.length) {
			this.mutableMenu.availability = setAvailability(this.mutableMenu.availability);
			let availabilityConfig: any = setTempWeekDays(this.mutableMenu.availability, this.tempWeekDays);

			this.tempWeekDays = availabilityConfig.weekDays;
			if (!availabilityConfig.isAllWeek) {
				this.dailyHours = true;
			}
		}
		else {
			this.mutableMenu.availability = initTempAvailability();
		}
	}

	private initWeekDays(): object[] {
		return WEEKDAYS.map((day) => ({
			name: day.name,
			number: day.number,
			checked: true,
			start_time: null,
			end_time: null
		}));
	}

	private updateType(): void {
		this.mutableMenu.availability = resetAvailabilities(this.mutableMenu.availability!);
		this.tempWeekDays = this.initWeekDays();
	}

	private updateSingleDay(value: boolean, day: number): void {
		if (!value) {
			this.mutableMenu.availability![day - 1] = resetAvailability(this.mutableMenu.availability![day - 1]);
		}
	}

	/**
	 * Update the start_time/end_time of an availability.
	 * If it's for dailyHours we simply loop through
	 *
	 * @param {string} value
	 * @param {boolean} isAllWeek
	 * @param {boolean} isStartTime
	 * @param {number} day
	 */
	private updateTime(value: string, isAllWeek: boolean, isStartTime: boolean, day: number): void {
		if (isAllWeek) {
			this.mutableMenu.availability!.forEach((availability: MenuAvailability) => {
				availability.menu_id = this.mutableMenu.id;
				availability.start_time = isStartTime ? DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm') : availability.start_time;
				availability.end_time = isStartTime ? availability.end_time : DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm');
			});
		}
		else {
			this.mutableMenu.availability![day - 1].menu_id = this.mutableMenu.id;
			this.mutableMenu.availability![day - 1].start_time = isStartTime ? DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm') : this.mutableMenu.availability![day - 1].start_time;
			this.mutableMenu.availability![day - 1].end_time = isStartTime ? this.mutableMenu.availability![day - 1].end_time : DateTime.fromFormat(value, 'HH:mm').toFormat('HH:mm');
		}
	}

	/**
	 * Save the updated menu's availability (for Availabilities.vue)
	 *
	 * @return {Promise<void>}
	 */
	private async save(copiedAvailability: boolean = false): Promise<void> {
		this.loading = true;
		await this.updateMenuAvailability({ menu: this.menu, availability: cleanAvailabilities(this.mutableMenu.availability!) })
			.then(() => {
				const message = copiedAvailability ? this.$t('menu.editor.availability.success_message_save_and_copy', { menuName: this.menu.name }) : this.$t('menu.editor.availability.success_message_save', { menuName: this.menu.name });
				this.$emit('display-toast', { color: '#00B796', message, load: copiedAvailability });
			})
			.catch((errorMessage) => {
				this.$emit('display-toast', { color: 'primary', message: this.$t('menu.editor.availability.error_saving', { menuName: this.menu.name, errorMessage }) });
			});
		this.loading = false;
	}

	/**
	 * Send the success/error response to parent for the toast to show
	 * Close the dialog if the request was successful
	 *
	 * @param {any} responseObj
	 * @return {Promise<void>}
	 */
	private async copyConfirm(responseObj: any): Promise<void> {
		if(responseObj.success) {
			await this.save(true);
			this.showCopyHoursDialog = false;
		}
		else {
			this.$emit('display-toast', { color: responseObj.color, message: responseObj.message });

		}
	}
}
