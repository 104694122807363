var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"half-width"},[_c('div',{attrs:{"id":"custom-delivery-place-selection"}},[_c('validation-provider',{attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{ref:"location-select",class:['suites-input label-md', { 'error-input': errors[0], 'valid': _vm.selectedLocation.location }],attrs:{"id":"location-select","value":_vm.selectedLocation,"items":_vm.customDeliveryLocations,"item-text":location => location.localizedLocation,"item-value":location => {
						return {
							location: location.location,
							localizedLocation: location.localizedLocation,
							areas: location.areas
						}
					},"label":_vm.restaurantDeliveryInfo.location_label ? _vm.$options.filters.localize(_vm.restaurantDeliveryInfo, 'location_label') : _vm.$t('orders.guest_details.delivery.custom_location_label'),"menu-props":{ contentClass: 'suites-list' },"error-messages":errors[0],"outlined":""},on:{"focus":function($event){return _vm.opened('custom-delivery-place-selection')},"input":_vm.updateLocation}})]}}])})],1),_c('div',{attrs:{"id":"custom-delivery-area-selection"}},[_c('validation-provider',{attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{ref:"area-select",class:['suites-input label-md', { 'error-input': errors[0], 'valid': _vm.selectedArea }],attrs:{"id":"area-select","value":_vm.selectedArea,"items":_vm.areas,"item-text":area => area.localizedArea,"item-value":area => {
						return {
							area: area.area,
							localizedArea: area.localizedArea
						}
					},"disabled":!_vm.selectedLocation.location,"clearable":false,"label":_vm.restaurantDeliveryInfo.areas_label ? _vm.$options.filters.localize(_vm.restaurantDeliveryInfo, 'areas_label') : _vm.$t('orders.guest_details.delivery.custom_area_label'),"menu-props":{ contentClass: 'suites-list' },"error-messages":errors[0],"outlined":""},on:{"focus":function($event){return _vm.opened('custom-delivery-area-selection')},"input":_vm.updateArea}})]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }