import { MutationTree } from 'vuex';
import { EventSuiteState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<EventSuiteState> = {
	/**
	 * Set event suites
	 *
	 * @param {EventSuiteState} state
	 * @param {EventSuite[]} eventSuites
	 */
	SET_EVENT_SUITES(state, eventSuites: EventSuite[]) {
		Vue.set(state, 'eventSuites', eventSuites);
	},

	/**
	 * Set selected event suite
	 *
	 * @param state
	 * @param eventSuite
	 */
	SET_SELECTED_EVENT_SUITE(state, eventSuite: EventSuite) {
		Vue.set(state, 'selectedEventSuite', eventSuite);
	}
};