
import { Component, Vue, Prop } from 'vue-property-decorator';
import StandardModal from '@/components/shared/suites/StandardModal.vue';

type EmitType = 'cancel' | 'confirm' | 'close';

@Component<GenericCateringWarningOrderModal>({
	components: {
		StandardModal
	}
})
export default class GenericCateringWarningOrderModal extends Vue {
	@Prop({ type: Boolean, default: false }) private showModal!: boolean;
	@Prop({ type: Boolean, default: false }) private loading!: boolean;
	@Prop({ type: Boolean, default: false }) private nested!: boolean;
	@Prop({ type: String, required: false, default: '' }) private title!: string;
	@Prop({ type: String, required: false, default: '' }) private orderPaymentPreference!: string;
	@Prop({ type: String, required: false, default: '' }) private text!: string;
	@Prop({ type: String, required: false, default: '' }) private cancelButtonText!: string;
	@Prop({ type: String, required: false, default: '' }) private confirmButtonText!: string;

	private mutableShowModal: boolean = this.showModal;

	/**
	 * Emit cancel or confirm event if it isn't loading
	 *
	 * @param {EmitType} type
	 * @return {void}
	 */
	private emit(type: EmitType): void {
		!this.loading && this.$emit(type);
	}
}
