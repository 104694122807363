/**
 * Get preferred browser locale
 *
 * @param {object} options
 * @return {string}
 */
export default function getBrowserLocale(): string|undefined {
	const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
	const trimmedLocale = navigatorLocale.trim().split(/-|_/)[0];
	if (!navigatorLocale) {
		return undefined;
	}
	const locale: Locale|undefined = findSupportedLocale(navigatorLocale, trimmedLocale);
	return locale && locale.code;
}

/**
 * Get current supported locales
 *
 * @return {Locale[]}
 */
export function getSupportedLocales(): Locale[] {
	return [
		{
			code: 'en-CA',
			trimmed_code: 'en',
			name: 'English CA'
		},
		{
			code: 'fr-CA',
			trimmed_code: 'fr',
			name: 'Canadian French'
		}
	];
}

/**
 * We check if the full locale passed (IE: fr-CA) is supported, if not, we get the trimmed locale
 * (IE: fr) and we will fetch the closest file to their language. If both are undefined we will
 * fallback to the default fallback.
 *
 * @return {Locale[]}
 */
export function findSupportedLocale(localeCountryCode: string, trimmedLocaleCode: string): Locale|undefined {
	const supportedLocales: Locale[] = getSupportedLocales();
	const localeWithCountryCode: Locale|undefined = supportedLocales.find((locale: Locale) => {
		if(locale.code === localeCountryCode) {
			return locale;
		}
	});
	const trimmedLocale: Locale|undefined = supportedLocales.find((locale: Locale) => {
		if(locale.trimmed_code === trimmedLocaleCode) {
			return locale;
		}
	});
	return localeWithCountryCode ? localeWithCountryCode : trimmedLocale;
}