import { MutationTree } from 'vuex';
import { MenuState } from '../types';
import { transformMenusResponse } from '@/utils/formatMenus';
import Vue from 'vue';

export const mutations: MutationTree<MenuState> = {

	/**
	 * Set menu groups
	 *
	 * @param state
	 * @param {MenuGroup[]} menuGroups
	 */
	SET_MENU_GROUPS(state, menuGroups: MenuGroup[]) {
		Vue.set(state, 'menuGroups', menuGroups);
	},

	/**
	 * Set menus
	 *
	 * @param state
	 * @param menus
	 */
	SET_MENUS(state, menus: Menu[]) {
		Vue.set(state, 'menus', menus);
	},

	/**
	 * Set menus
	 *
	 * @param state
	 * @param {Menu[]} menus
	 */
	SET_PUBLISHED_MENUS(state, menus: Menu[]) {
		Vue.set(state, 'publishedMenus', transformMenusResponse(menus));
	},

	SET_UPDATED_MENUS(state, { menu, deletedMenuId }) {
		const updatedMenus = [...state.menus];
		if (deletedMenuId) {
			const deletedIndex = state.menus.findIndex((_menu) => _menu.id === deletedMenuId);
			updatedMenus.splice(deletedIndex, 1);
		}
		else {
			const updatedIndex = state.menus.findIndex((_menu) => _menu.id === menu.id);
			updatedMenus[updatedIndex] = menu;
		}
		Vue.set(state, 'menus', updatedMenus);
	},

	/**
	 * Set fetchMenusComplete
	 *
	 * @param state
	 * @param fetchMenusComplete
	 */
	SET_FETCH_MENUS_COMPLETE(state, fetchMenusComplete: boolean) {
		Vue.set(state, 'fetchMenusComplete', fetchMenusComplete);
	},

	/**
	 * Set selected menu
	 *
	 * @param state
	 * @param payload
	 */
	SET_SELECTED_MENU(state, selectedMenu: Menu) {
		Vue.set(state, 'selectedMenu', selectedMenu);
	},

	/**
	 * Set menu sections
	 *
	 * @param state
	 * @param payload
	 */
	SET_SECTIONS(state, payload) {
		const { menu, sections } = payload;
		Vue.set(menu, 'sections', sections);
	},

	/**
	 * Set updated menu sections
	 *
	 * @param state
	 * @param payload
	 */
	SET_SELECTED_MENU_SECTIONS(state, { menu, sections, deletedSectionId } ) {
		if (deletedSectionId) {
			const deletedIndex = sections.findIndex((section: MenuSection) => section.id === deletedSectionId);
			sections.splice(deletedIndex, 1);
		}
		const menuIndex = state.menus.findIndex((_menu) => _menu.id === menu.id);
		Vue.set(state.menus[menuIndex], 'sections', sections);
	},

	/**
	 * Set menu items
	 *
	 * @param state
	 * @param payload
	 */
	SET_MENU_ITEMS(state, { menu, items }) {
		// We need to actually find the menu by index instead of using the one passed in
		// the parameters in the event that we don't have a full menu object available.
		// In some cases, it is just a simple object with only its id, for example.
		const menuIndex = state.menus.findIndex((_menu) => _menu.id === menu.id);
		Vue.set(state.menus[menuIndex], 'items', items);
	},

	/**
	 * Set updated menu items, used after an update or delete operation
	 * to avoid having to do a whole menu refetch
	 *
	 * @param state
	 * @param payload
	 */
	SET_UPDATED_MENU_ITEMS(state, { menu, updatedItem, deletedItemId } ) {
		const updatedItems = [...menu.items];
		if (deletedItemId) {
			const deletedIndex = menu.items.findIndex((item: MenuItem) => item.id === deletedItemId);
			updatedItems.splice(deletedIndex, 1);
		}
		else {
			const updatedIndex = menu.items.findIndex((item: MenuItem) => item.id === updatedItem.id);
			updatedItems[updatedIndex] = updatedItem;
		}

		Vue.set(menu, 'items', updatedItems);
	},

	/**
	 * Set menu section items
	 *
	 * @param state
	 * @param payload
	 */
	SET_SECTION_ITEMS(state, { section, items }) {
		// We need to actually find the menu by index instead of using the one passed in
		// the parameters in the event that we don't have a full menu object available.
		// In some cases, it is just a simple object with only its id, for example.
		const menuIndex = state.menus.findIndex((_menu) => _menu.id === section.menu_id);
		const { sections } = state.menus[menuIndex];
		const sectionIndex = (sections || []).findIndex((_section) => _section.id === section.id);

		const stateMenu: Menu = state?.menus[menuIndex];
		const stateSection: MenuSection | object = stateMenu?.sections?.[sectionIndex] || {};
		Vue.set(stateSection, 'items', items);
	},

	/**
	 * Set updated menu section items, used after an update or delete operation
	 * to avoid having to do a whole menu refetch
	 *
	 * @param state
	 * @param payload
	 */
	SET_UPDATED_SECTION_ITEMS(state, { section, updatedSectionItem, deleteSectionItemId } ) {
		const updatedSectionItems: MenuItem[] = [...section.items];
		if (deleteSectionItemId) {
			const deletedIndex = section.items.findIndex((item: MenuItem) => item.id === deleteSectionItemId);
			updatedSectionItems.splice(deletedIndex, 1);
		}
		else {
			const updatedIndex = section.items.findIndex((item: MenuItem) => item.id === updatedSectionItem.id);
			updatedSectionItems[updatedIndex] = updatedSectionItem;
		}
		Vue.set(section, 'items', updatedSectionItems);
	},

	/**
	 * Set fetch menus error with the error message
	 *
	 * @param state
	 * @param errorMessage
	 */
	SET_FETCH_MENUS_ERROR(state, errorMessage: string) {
		Vue.set(state, 'fetchMenusError', errorMessage);
	}

};