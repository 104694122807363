
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { formatSection } from '../../utils/formatItem';
import { TOAST_INSTANCE } from '../../utils/constants';
import NameAndPricing from '@/components/shared/form/NameAndPricing.vue';
import SectionLocaleEditor from './SectionLocaleEditor.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'sections';

@Component<SectionEditor>({
	components: {
		NameAndPricing,
		SectionLocaleEditor,
		BannerToast
	}
})
export default class SectionEditor extends Vue {
	@Action('createMenuSection', { namespace }) private createMenuSection!: (payload: object) => Promise<void>;
	@Action('updateMenuSection', { namespace }) private updateMenuSection!: (payload: object) => Promise<void>;
	@Prop({ type: Boolean, default: true }) private isCreating!: boolean;
	@Prop({ type: Object, default: () => {} }) private section!: MenuSection;
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;

	private mutableSection: MenuSection = this.section;
	private loading: boolean = false;
	private areLocalesValid: boolean = true;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get valid(): boolean {
		let isValid = true;
		if (this.mutableSection.description) {
			isValid = this.mutableSection.description.length <= 255;
		}
		return !!(this.mutableSection.name && this.areLocalesValid && isValid);
	}

	/**
	 * If localization is enabled, check for each locale if their description is less than 255 characters in order to disable the save button.
	 *
	 * @return {void}
	 */
	private validateLocales(): void {
		if (this.mutableSection.localization && Object.keys(this.mutableSection.localization).length > 0) {
			this.areLocalesValid = !Object.values(this.mutableSection.localization).some(locale => locale.description.length > 255);
		}
		else {
			this.areLocalesValid = true;
		}
	}

	/**
	 * Save the section (either create or update)
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
		if (!this.mutableSection.price) {
			this.mutableSection.price = '0';
		}
		if (this.isCreating) {
			await this.createMenuSection({
				menu: this.menu,
				section: formatSection(this.mutableSection)
			})
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.section.error_creating_section', { errorMessage, name: this.mutableSection.name });
				});
		}
		else {
			await this.updateMenuSection({
				menu: this.menu,
				section: formatSection(this.mutableSection)
			})
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.section.error_updating_section', { errorMessage, name: this.mutableSection.name });
				});
		}
		this.loading = false;
	}
}
