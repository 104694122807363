import axios from 'axios';
import { Module } from 'vuex';
import { AuthState, RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

const namespaced: boolean = true;

export const state: AuthState = {
	tenantId: null,
	axiosInst: axios.create({
		headers: {
			'X-RESTAURANT-ID': '',
			'X-STAFF-EMAIL': '',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${null}`
		}
	}),
	restaurant: {} as Restaurant,
	restaurants: null,
	role: null,
	authenticated: false,
	accessToken: null,
	lastLogin: null,
	idToken: null,
	expiresAt: null,
	posIntegrated: false,
	staffEmail: null,
	employeeId: null
};

export const Auth: Module<AuthState, RootState> = {
	namespaced,
	state,
	mutations,
	getters,
	actions
};

export default Auth;
