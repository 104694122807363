
import { Component, Vue, Prop } from 'vue-property-decorator';
import TitleHeader from '@/components/shared/EditorHeader.vue';

@Component<ModalWrapper>({
	components: {
		TitleHeader
	}
})
export default class ModalWrapper extends Vue {
	@Prop({ type: Boolean, default: false }) private showDialog!: boolean;
	@Prop({ type: Boolean, default: false }) private hideLocaleDropdown!: boolean;

	private chosenLocale: RestaurantLocale|null = null;
	private mutableDialog: boolean = this.showDialog;

	private close(): void {
		this.$emit('close-modal', false);
	}

	private back(): void {
		this.$emit('back');
	}
}
