
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component<ExpansionPanel>({})
export default class ExpansionPanel extends Vue {
	@Prop({ type: Object, required: false, default: () => {} }) private panelProps!: object;
	@Prop({ type: Object, required: false, default: () => {} }) private headerProps!: object;
	@Prop({ type: Object, required: false, default: () => {} }) private contentProps!: object;
	@Prop({ type: Boolean, default: true }) private expanded!: boolean;

	private open: number = 1;

	private created(): void {
		this.open = this.expanded ? 0 : 1;
	}
}

