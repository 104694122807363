import { DateTime } from 'luxon';
import i18n from '@/i18n';

export default class Sceduling {
	constructor(scheduleRestrictions: ScheduleRestrictions, takeout: Pickup, modifyingOrder: boolean, restaurant: Restaurant) {
		this.orderInterval = scheduleRestrictions.max_order_interval;
		this.maxedOutIntervals = scheduleRestrictions.maxed_out_intervals.map((dateString: string) => DateTime.fromISO(dateString));
		this.requiredNotice = scheduleRestrictions.required_notice;
		this.scheduled = scheduleRestrictions.scheduled_ordering;
		this.prepTime = scheduleRestrictions.prep_time;
		this.holidayHours = scheduleRestrictions.holiday_hours;
		this.zone = restaurant.menu_configuration?.zone || 'America/Toronto';
		this.modifyingOrder = modifyingOrder;
		this.dueByTime = takeout.dueByTime!;
		if (modifyingOrder) {
			this._dueByDate = takeout.dueByDate!;
			// If the order is being modified, this is used to allow the initial scheduled time to be re-selected
			this.initialDateTimeSelection = DateTime.fromFormat(`${this.dueByDate} ${this.dueByTime}`, `yyyy-MM-dd ${i18n.t('orders.guest_details.takeout.timepicker_format')}`);
		}
		else {
			this._dueByDate = DateTime.fromISO(takeout.dueByDate!).plus({ minutes: (this.requiredNotice + this.orderInterval) }).toISO();
		}

		this.setWeeklyAvailabilities(scheduleRestrictions.weekly_availabilities);
		this.setDisabledDaysForDatePicker();
		this.generateTimeSeries();

		if (!this.timeOptions.length) {
			this.findNextEnabledDate();
		}
	}

	private _dueByDate: string;
	public orderInterval: number;
	public maxedOutIntervals: DateTime[];
	public requiredNotice: number;
	public scheduled: boolean;
	public prepTime: number;
	public holidayHours: HolidayHours[];
	public weeklyAvailabilities: (SchedulingRestrictionMenuAvailability | null)[] = [];
	public timeOptions: { value: string, disabled: boolean }[] = [];
	public dueByTime: string;
	public zone: string;
	public disabledDates: string[] = [];
	public disabledDays: number[] = [];
	public modifyingOrder: boolean;
	public minDate: string = DateTime.local().toISODate();
	public initialDateTimeSelection: DateTime | null = null;

	public get dueByDate(): string {
		return this._dueByDate;
	}

	/**
	 * When the due by date is changed generate a new time series for the date
	 *
	 * @param {string} value
	 */
	public set dueByDate(value: string) {
		this._dueByDate = value;
		this.generateTimeSeries();
	}

	/**
	* Set the weekly availabilities with the interval to prevent ordering
	* at the opening time. We have to check that the availability does not overlap
	* with the interval added.
	*
	* @param {SchedulingRestrictionMenuAvailability[]} tempWeeklyAvailabilities
	* @return {void}
	*/
	private setWeeklyAvailabilities(tempWeeklyAvailabilities: SchedulingRestrictionMenuAvailability[]): void {
		tempWeeklyAvailabilities.forEach(tempAvailability => {
			if(tempAvailability) {
				tempAvailability.start = DateTime.fromFormat(tempAvailability.start, 'hh:mm').plus({ minutes: this.orderInterval ? this.orderInterval : 0 }).toFormat('HH:mm');
				return this.weeklyAvailabilities.push(tempAvailability);
			}
			else {
				return this.weeklyAvailabilities.push(null);
			}
		});
	}

	/**
	* Get the minimum time for the order. We need to add the interval time and the required notice to the date selected.
	*
	* @return {void}
	*/
	private setDisabledDaysForDatePicker(): void {
		for (let index = 0; index < this.weeklyAvailabilities.length; index++) {
			this.checkIfDayHasAvailability(index);

			if (!this.weeklyAvailabilities[index]) {
				this.disabledDays.push((index + 1) % 7);
			}
		}

		if (this.holidayHours?.length) {
			this.disabledDates = this.holidayHours.reduce((acc: string[], holiday: HolidayHours) => {
				if (holiday.full_day) {
					acc.push(holiday.date);
				}
				return acc;
			}, []);
		}
	}

	/**
	* Check if the day has availability, if not we increment the date by one.
	*
	* @param {number} index
	* @return {void}
	*/
	private checkIfDayHasAvailability(index: number): void {
		const isHoliday = this.holidayHours?.find((holiday: HolidayHours) => this._dueByDate.includes(holiday.date));
		if(isHoliday) {
			this._dueByDate = DateTime.fromISO(this._dueByDate).set({ hour: 0, minute: 0 }).plus({ day: 1 }).toISO()!;
		}

		if(this.weeklyAvailabilities[index] && this.weeklyAvailabilities[index]!.day_of_week === DateTime.fromISO(this._dueByDate).weekday) {
			const orderTime = DateTime.fromISO(this._dueByDate).toFormat('T');

			// If this is the first time we get the current date
			// If the end date is the same as the current date, we use the end time from the pre order delivery time setting (if applicable)
			let endOfDayTime = this.weeklyAvailabilities[index]!.end;

			// If the end time is overlapping 12AM mark. We increment the value of hours and numbers with the start time.
			if(endOfDayTime < this.weeklyAvailabilities[index]!.start) {
				const [endHours, endMinutes] = endOfDayTime.split(':').map(Number);
				const [startHours, startMinutes] = this.weeklyAvailabilities[index]!.start.split(':').map(Number);
				endOfDayTime = `${endHours + startHours}:${endMinutes + startMinutes}`;
			}

			// If the order time is later than the end time, we push the date +1
			if(orderTime > endOfDayTime && this.weeklyAvailabilities[index]!.end != '00:00') {
				this._dueByDate = DateTime.fromISO(this._dueByDate).set({ hour: 0, minute: 0 }).plus({ day: 1 }).toISO()!;
			}
		}
	}

	/**
	* Get the next enabled date based on the disabled days. If the selected date is disabled
	* we will increment the date until we find an enabled date.
	*
	* @return {void}
	*/
	private findNextEnabledDate(): void {
		let enabledDate: boolean = false;
		let nextDate: DateTime = DateTime.fromISO(this._dueByDate!).plus({ days: 1 });

		while (!enabledDate) {
			const dayOfWeekDisabled = this.disabledDays.includes(nextDate.weekday % 7);
			const dateDisabled = this.disabledDates.some((holiday: string) => DateTime.fromISO(holiday).setZone(this.zone).hasSame(nextDate, 'day'));

			if (!dayOfWeekDisabled && !dateDisabled) {
				this._dueByDate = nextDate.toISO();
				this.generateTimeSeries();

				if (this.timeOptions.length) {
					this.minDate = nextDate.toISO();
					enabledDate = true;
				}
			}

			nextDate = nextDate.plus({ days: 1 });
		}
	}

	/**
	* Generate time series with the intervals gathered from the menu
	*
	* @return {void}
	*/
	private generateTimeSeries(): void {
		const timeSeries: string[] = [];
		const selectedDate = DateTime.fromISO(this._dueByDate!);

		// If the order is being modified and the selected date is in the past, set the time to the due by time
		if (this.modifyingOrder && selectedDate.toISODate() < DateTime.local().toISODate()) {
			const intialTimeSelection = this.initialDateTimeSelection!.toFormat(i18n.t('orders.guest_details.takeout.timepicker_format'));
			this.timeOptions = intialTimeSelection ? [{ value: intialTimeSelection, disabled: false }] : [];
			return;
		}

		// Find the availability for the selected date
		const availability = this.weeklyAvailabilities.find(a => a && a.day_of_week === selectedDate.weekday);
		if (!availability) {
			this.timeOptions = [];
			return;
		}

		// Parse the start and end times
		const [startHour, startMinute]: number[] = availability.start.split(':').map(Number);
		let [endHour, endMinute]: number[] = availability.end.split(':').map(Number);

		// If the end time is 00:00, set it to 24:00 instead
		if (endHour === 0 && endMinute === 0) {
			endHour = 24;
			endMinute = 0; // Avoid TS complaining about const
		}

		// Create DateTime objects for the start and end times
		let currentTime: DateTime = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: startHour, minute: startMinute, second: 0 });
		const endTime: DateTime = DateTime.fromObject({ year: 1970, month: 1, day: 1, hour: endHour, minute: endMinute, second: 0 });

		while (currentTime <= endTime) {
			timeSeries.push(currentTime.toFormat(i18n.t('orders.guest_details.takeout.timepicker_format')).padStart(4, '0'));
			currentTime = currentTime.plus({ minutes: this.orderInterval || 30 });
		}

		// Filter out any times that are past the local time, and any times that are equal to the end time since
		// the user should not be able to order at the closing time.
		this.timeOptions = timeSeries.filter(time => {
			const timeAsDateTime: DateTime = DateTime.fromFormat(time, i18n.t('orders.guest_details.takeout.timepicker_format')).set({ year: selectedDate.year, month: selectedDate.month, day: selectedDate.day });
			const tempTimeAsDateTime: string = timeAsDateTime.toFormat('T') === '00:00' ? '24:00' : timeAsDateTime.toFormat('T');
			const tempEndTime: string = endTime.toFormat('T') === '00:00' ? '24:00' : endTime.toFormat('T');

			return timeAsDateTime >= DateTime.local() && tempTimeAsDateTime < tempEndTime;
		})
			// Map the time options to an object with a value and a disabled property for the v-select component
			.map((time: string) => {
				return {
					value: time,
					disabled: !this.isTimeSelectable(time)
				};
			});
	}

	/**
	 * Checks a time interval to see if it is selectable or not
	 * Based on required_notice, current time, intervals, availabilities and more.
	 *
	 * @param {string} time
	 * @return {boolean}
	 */
	private isTimeSelectable(time: string): boolean {
		const currentDateTime: DateTime = DateTime.local();
		const timeAsDateTime: DateTime = DateTime.fromFormat(`${this._dueByDate.split('T')[0]} ${time}`, `yyyy-MM-dd ${i18n.t('orders.guest_details.takeout.timepicker_format')}`);
		const timeIn24Format: string = timeAsDateTime.toFormat('T');
		const isNotMaxedOut: boolean = !this.maxedOutIntervals.some(interval => {
			// If the time is not the initial selection, we want to make sure it's not in the maxed out intervals
			if (this.initialDateTimeSelection && !interval.equals(this.initialDateTimeSelection)) {
				return timeAsDateTime.equals(interval);
			}
		});
		const isAfterCurrentTime: boolean = timeAsDateTime > currentDateTime;
		const isWithinAvailabilityHours: boolean = this.isWithinAvailabilityHoursFunc(timeAsDateTime, timeIn24Format);
		const isNoticeLongEnough: boolean = !(this.requiredNotice && currentDateTime.plus({ minutes: this.requiredNotice }) > timeAsDateTime);

		return isNotMaxedOut && isAfterCurrentTime && isWithinAvailabilityHours && isNoticeLongEnough;
	}

	/**
	 * Check if the time is within availability hours for the current day
	 *
	 * @param {DateTime} timeAsDateTime
	 * @param {string} time24
	 * @param {string} overrideStartDate
	 * @param {string} overrideEndDate
	 * @return {boolean}
	*/
	private isWithinAvailabilityHoursFunc(timeAsDateTime: DateTime, time24: string, overrideStartDate?: string, overrideEndDate?: string): boolean {
		return this.weeklyAvailabilities.some(availability => {
			if (availability === null) {
				return false;
			}

			const availabilityStart = this.getAvailabilityStart(availability, overrideStartDate);
			const availabilityEnd = this.getAvailabilityEnd(availability, overrideEndDate);
			return (
				availability && (
					this.isWithinCurrentDayAvailability(timeAsDateTime, time24, availability, availabilityStart, availabilityEnd) ||
					this.isWithinPreviousDayAvailability(timeAsDateTime, time24, availability, availabilityStart, availabilityEnd)
				)
			);
		});
	}

	/**
	 * Get the availability start time, if the pre order start date is the same as the availability day,
	 * we want to use the start time of the pre order start date for the min selectable time. This of course
	 * is only applicable for pre-orders, for takeout we're just going to grab the start time of the availability.
	 *
	 * Note: this could mean that the order would be refused if for some reason the menu for the pre-order is
	 * not available for the start time of the event, but that's the suite operator's fault and do not require a fix.
	 *
	 * @param {SchedulingRestrictionMenuAvailability} availability
	 * @param {string} overrideStartDate
	 * @return {string}
	*/
	private getAvailabilityStart(availability: SchedulingRestrictionMenuAvailability, overrideStartDate?: string): string {
		return overrideStartDate && DateTime.fromISO(overrideStartDate).startOf('day').equals(DateTime.fromISO(this._dueByDate).startOf('day'))
			? DateTime.fromISO(overrideStartDate).toFormat('HH:mm')
			: availability.start;
	}

	/**
	 * Get the availability end time, if the pre order end date is the same as the availability day,
	 * we want to use the end time of the pre order end date for the max selectable time. This of course
	 * is only applicable for pre-orders, for takeout we're just going to grab the end time of the availability.
	 *
	 * Note: this could mean that the order would be refused if for some reason the menu for the pre-order is
	 * not available for the end time of the event, but that's the suite operator's fault and do not require a fix.
	 *
	 * @param {SchedulingRestrictionMenuAvailability} availability
	 * @param {string} dateSelected
	 * @param {string} overrideEndDate
	 * @return {string}
	*/
	private getAvailabilityEnd(availability: SchedulingRestrictionMenuAvailability, overrideEndDate?: string): string {
		return overrideEndDate && DateTime.fromISO(overrideEndDate).startOf('day').equals(DateTime.fromISO(this._dueByDate).startOf('day'))
			? DateTime.fromISO(overrideEndDate).toFormat('HH:mm')
			: availability.end;
	}

	/**
	 * Allow selection if the time is within availability hours for the current day
	 *
	 * @param {DateTime} timeAsDateTime
	 * @param {string} time24
	 * @param {SchedulingRestrictionMenuAvailability} availability
	 * @param {string} availabilityStart
	 * @param {string} availabilityEnd
	 * @return {boolean}
	*/
	private isWithinCurrentDayAvailability(timeAsDateTime: DateTime, time24: string, availability: SchedulingRestrictionMenuAvailability, availabilityStart: string, availabilityEnd: string): boolean {
		return timeAsDateTime.weekday === availability.day_of_week && (time24 >= availabilityStart && time24 < (availabilityEnd > availabilityStart && availabilityEnd != '00:00' ? availabilityEnd : '24:00'));
	}

	/**
	 * Allow selection if the time is within availability hours for the previous day if those hours extend past midnight
	 *
	 * @param {DateTime} timeAsDateTime
	 * @param {string} time24
	 * @param {SchedulingRestrictionMenuAvailability} availability
	 * @param {string} availabilityStart
	 * @param {string} availabilityEnd
	 * @return {boolean}
	*/
	private isWithinPreviousDayAvailability(timeAsDateTime: DateTime, time24: string, availability: SchedulingRestrictionMenuAvailability, availabilityStart: string, availabilityEnd: string): boolean {
		return timeAsDateTime.weekday === ((availability.day_of_week + 1) % 7) && availabilityEnd <= availabilityStart && (time24 < availabilityEnd);
	}
}