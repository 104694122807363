import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { ItemState, RootState } from '../types';
const namespaced: boolean = true;

export const state: ItemState = {
	selectedItem: {}
};


export const Items: Module<ItemState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Items;