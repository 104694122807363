// TODO SO FAR THIS LOGIC IS ONLY USED FOR promosApplied ARRAY. WE WILL AT SOME POINT USE IT FOR VISUAL PRICES.
/**
 * Compare item's promo
 *
 * @param {MenuItem | MenuOrderItem} item
 * @param {CheckoutMemberInfo} memberInfo
 * @param {string} [arrayName=options]
 * @param {boolean} [hasPricingOptions=false]
 * @return {void}
 */
export function compareItem(item: MenuItem | MenuOrderItem, memberInfo: CheckoutMemberInfo, arrayName: string = 'options', hasPricingOptions: boolean = false): void {
	// If the item is an alcoholic item, we do not apply any discount
	if (item.alcoholic_beverage) {
		return;
	}

	// If we are treating cart items, we do not want to treat promos if the item has a pricing option
	// If the item has promos we want to apply them if the user has them as well
	if(!hasPricingOptions && item.promos) {
		let tempPromoInfo: TemporaryPromoInfo = { promoFound: false };

		// Loop through the item/member promos
		item.promos.forEach((promo: string) => {
			tempPromoInfo = compareMemberPromosAndDiscountPrice(promo, memberInfo, tempPromoInfo.promoFound);
		});

		// Options
		compareOptions(item, memberInfo, arrayName);
	}
}

/**
 * Compare options and sub options promos
 *
 * @param {OrderOptionGroup | OptionGroup | Option} item
 * @param {CheckoutMemberInfo} memberInfo
 * @param {string} [arrayName=options]
 * @return {void}
 */
function compareOptions(item: OrderOptionGroup | OrderOption | Option, memberInfo: CheckoutMemberInfo, arrayName: string = 'options'): void {
	const optionGroups = item[arrayName];
	if(!optionGroups) {
		return;
	}

	optionGroups.forEach((optionGroup: OptionGroup | OrderOptionGroup) => {
		const options = optionGroup.values;
		if (!options) {
			return;
		}

		options.forEach((option: Option | OrderOption) => {
			if(option.promos) {
				let tempPromoInfo: TemporaryPromoInfo = { promoFound: false };

				// Loop through the option/member promos
				option.promos.forEach((promo: string) => {
					tempPromoInfo = compareMemberPromosAndDiscountPrice(promo, memberInfo, tempPromoInfo.promoFound);
				});
			}

			// Recursivity - compare sub options
			if(option.options) {
				compareOptions(option, memberInfo);
			}
		});
	});
}

/**
 * Loop through the member's promo array and compare them with the item's promo.
 *
 * @param {string} itemPromo
 * @param {CheckoutMemberInfo} memberInfo
 * @param {boolean} promoFound
 * @return {any}
 */
function compareMemberPromosAndDiscountPrice(itemPromo: string, memberInfo: CheckoutMemberInfo, promoFound: boolean): any {
	memberInfo.promos.forEach((memberPromo: MemberPromo) => {
		if(itemPromo === memberPromo.id) {
			memberInfo.promosApplied.add(memberPromo.id);
			promoFound = true;
		}
	});
	return { promoFound };
}