import { MutationTree } from 'vuex';
import { ItemState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<ItemState> = {

	/**
	 * Set the selected item
	 *
	 * @param state
	 * @param card
	 */
	SET_SELECTED_ITEM(state, selectedItem: MenuItem) {
		Vue.set(state, 'selectedItem', selectedItem);
	}
};