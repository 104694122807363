
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<AddButton>({})

export default class AddButton extends Vue {
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;
	private onClick (): void {
		if (this.disabled) {
			return;
		}

		this.$emit('add-btn-clicked');
	}
}
