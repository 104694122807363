import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SearchState, RootState } from '../types';

const namespaced: boolean = true;

export const state: SearchState = {
	searchString: '',
	searchItems: null
};


export const Menus: Module<SearchState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Menus;