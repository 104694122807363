
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ROLES } from '../../utils/constants';
import ActionSheetItem from './ActionSheetItem.vue';

@Component<ActionSheet>({
	components: {
		ActionSheetItem
	}
})
export default class ActionSheet extends Vue {
	@Prop({ type: Boolean, default: false }) private actionSheetOpened!: boolean;
	@Prop({ type: Object, default: () => {} }) private item!: Menu | MenuSection | MenuItem;
	@Prop({ type: Array, default: () => [] }) private actionsArray!: ActionItem[];
	@Prop({ type: Boolean, default: false }) private override!: boolean;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace: 'auth' }) private isPosIntegrated!: boolean;

	private mutableActionsArray: ActionItem[] = this.actionsArray;
	private roles: Roles = ROLES;

	private mounted(): void {
		if (this.item.published_at) {
			this.mutableActionsArray = this.mutableActionsArray.filter(mutableAction => mutableAction.buttonLabel !== this.$t('shared.actions_array.btn_publish'));
		}
		else {
			this.mutableActionsArray = this.mutableActionsArray.filter(mutableAction => mutableAction.buttonLabel !== this.$t('shared.actions_array.btn_archive'));
		}
	}

	/**
	 * Determines if action should be disabled based on role and if it's a POS integrated location
	 *
	 * @param {number | undefined} accessLevel - the lowest level role that is able to use this action, if undefined all roles can use the action
	 * @returns {boolean}
	 */
	private isItemDisabled(accessLevel: number | undefined): Boolean {
		if (accessLevel !== undefined && this.roleLevel > accessLevel && this.isPosIntegrated) {
			return true;
		}
		return false;
	}

	private closeActionSheet() : void {
		this.$emit('close-action-sheet');
	}
}
