
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component<MenuGroupCard>({})
export default class MenuGroupCard extends Vue {
	@Getter('getRestaurantById', { namespace: 'auth' }) private getRestaurantById!: (id: string) => Tenant;
	@Prop({ type: Object, default: () => {} }) private menuGroup!: MenuGroup;

	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;

	private get restaurantName(): string {
		if(this.menuGroup.restaurant_id) {
			const restaurant: Tenant = this.getRestaurantById(this.menuGroup.restaurant_id);
			if(restaurant) {
				return restaurant.name;
			}
			return this.$t('advanced.menu_groups.location_not_configured');
		}
		return this.$t('advanced.menu_groups.location_not_configured');
	}

	private showModal(): void {
		this.$emit('show-modal', this.menuGroup);
	}
}
