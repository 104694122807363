
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ROLES } from '../utils/constants';
import TitleHeader from '@/components/shared/Header.vue';
import ListItem from '@/components/shared/ListItem.vue';
import Auth from '@/services/auth/auth';

const namespace: string = 'auth';

@Component<SettingsList>({
	components: {
		TitleHeader,
		ListItem
	}
})
export default class SettingsList extends Vue {
	@Getter('getRestaurants', { namespace }) private restaurants!: Tenant[];
	@Getter('getRoleLevel', { namespace }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace }) private isPosIntegrated!: Boolean;
	@Getter('areSuitesEnabled', { namespace }) private areSuitesEnabled!: Boolean;
	@Getter('isCateringRestaurant', { namespace }) private isCateringRestaurant!: Boolean;
	@Getter('isMarketplaceEnabled', { namespace }) private isMarketplaceEnabled!: Boolean;
	@Getter('isOrderManagementEnabled', { namespace }) private isOrderManagementEnabled!: Boolean;
	@Getter('getMenus', { namespace: 'menus' }) private menus!: Menu[];
	@Watch('menus', { immediate: true })
	onMenusUpdate() {
		this.addMenuNavbarSettings();
	}

	private eventsGroupOpened: boolean = true;
	private menuGroupOpened: boolean = true;
	private menuSettings: object[] = [];
	private settings: object[] = [];
	private eventSettings: object[] = [
		{ id: 'event-setting', name: this.$t('navbar.btn_event_settings'), leftIcon: 'mdi-cog', rightIcon: 'mdi-chevron-right', to: '/event-settings' }
	];
	private roles: Roles = ROLES;

	private get logoutBtnText(): string {
		return window.B2BApp ? this.$t('navbar.btn_back_to_app') : this.$t('navbar.btn_logout');
	}

	/**
	 * On created, we have a few checks to do to see what to display
	 * depending on the role of the user.
	 *
	 * @return {void}
	 */
	private created(): void {
		this.isCateringRestaurant && this.settings.push({ id: 'orders-report', name: this.$t('navbar.btn_orders_report'), leftIcon: 'mdi-clipboard-check-outline', rightIcon: 'mdi-chevron-right', to: '/orders-report' });

		if(this.roleLevel <= this.roles.manager) {
			this.settings.push(
				{ id: 'customization-setting', name: this.$t('navbar.btn_customizations'), leftIcon: 'mdi-palette', rightIcon: 'mdi-chevron-right', to: '/customize' },
				{ id: 'advanced-setting', name: this.$t('navbar.btn_advanced'), leftIcon: 'mdi-cogs', rightIcon: 'mdi-chevron-right', to: '/advanced' }
			);
		}

		// Lead and above can navigate to order manager
		if(this.roleLevel <= this.roles.lead) {
			if (this.isMarketplaceEnabled || this.isOrderManagementEnabled) {
				this.settings.push({ id: 'order-management', name: this.$t('navbar.btn_order_management'), leftIcon: 'mdi-receipt-clock-outline', rightIcon: 'mdi-chevron-right', to: '/order-management' });
			}
		}

		// Member and above can navigate to restaurant list
		if(this.roleLevel <= this.roles.member) {
			if (this.restaurants && this.restaurants.length > 1) {
				this.settings.push({ id: 'restaurant-setting', name: this.$t('navbar.btn_location_list'), leftIcon: 'mdi-storefront', rightIcon: 'mdi-chevron-right', to: '/locations' });
			}
		}
	}

	/**
	 * Add the addon/menus setting, then for the rest we have to check a few
	 * conditions. First off we check if their role is manager or better, if
	 * yes then they can navigate to availability (if menus) & replicate if
	 * menus & restaurants
	 *
	 * @return {void}
	 */
	private addMenuNavbarSettings(): void {
		this.menuSettings = [];
		this.menuSettings.push({ id: 'add-on-setting', name: this.$t('navbar.btn_add_ons'), leftIcon: 'mdi-playlist-plus', rightIcon: 'mdi-chevron-right', to: '/add-ons' });

		if (this.menus && this.menus.length) {
			this.menuSettings.push({ id: 'availability-setting', name: this.$t('navbar.btn_availability'), leftIcon: 'mdi-clock', rightIcon: 'mdi-chevron-right', to: '/availabilities' });

			if (this.roleLevel <= this.roles.enterprise && (!this.isPosIntegrated || this.roleLevel === this.roles.admin) && (this.restaurants && this.restaurants.length > 1)) {
				this.menuSettings.push({ id: 'replicate-menus-setting', name: this.$t('navbar.btn_replicate_menus'), leftIcon: 'mdi-content-duplicate', rightIcon: 'mdi-chevron-right', to: '/replicate-menus' });
			}
		}
	}

	/**
	 * Go to specified page when clicking on the list group.
	 * We are also preventing the event propagation if the user clicked
	 * elsewhere than the arrow because we want to prevent the
	 * default expand/collapse of the list group behaviour. Wish
	 * Vuetify would allow more flexibility around that tbh.
	 *
	 * @param {any} event
	 * @param {string} page
	 * @return {void}
	 */
	goToList(event: any, page: string): void {
		if (event.target.id !== `${page}-tab-chevron`) {
			this.$router.push(`${page}s`).catch(() => {});
			event.stopPropagation();
		}
	}

	/**
	 * Log the user out
	 *
	 * @return {void}
	 */
	private logout(): void {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
	}
}
