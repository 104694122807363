
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { MENU_ITEM } from '../../utils/defaultTypeObjects';
import { decodeString } from '@/utils/decoding';
import ActionSheet from '@/components/shared/ActionSheet.vue';
import draggable from 'vuedraggable';

@Component<ListItem>({
	components: {
		ActionSheet,
		draggable
	}
})
export default class ListItem extends Vue {
	@Action('updateMenu', { namespace: 'menus' }) private updateMenu!: (payload: object) => Promise<void>;
	@Action('updateMenuSection', { namespace: 'sections' }) private updateMenuSection!: (payload: object) => Promise<void>;
	@Action('updateMenuItem', { namespace: 'items' }) private updateMenuItem!: (payload: object) => Promise<void>;
	@Action('updateMenuSectionItem', { namespace: 'items' }) private updateMenuSectionItem!: (payload: object) => Promise<void>;
	@Action('updateOptionGroupAndOptions', { namespace: 'options' }) private updateOptionGroupAndOptions!: (payload: object) => Promise<void>;
	@Getter('isMarketplaceEnabled', { namespace: 'auth' }) private isMarketplaceEnabled!: boolean;

	@Prop({ type: Boolean, default: false }) private isDraggable!: boolean;
	@Prop({ type: String, default: '' }) private categoryTitle!: string;
	@Prop({ type: Array, default: () => [] }) private items!: MenuItem[];
	@Prop({ type: Array, default: () => [] }) private actionsArray!: ActionItem[];
	@Prop({ type: Boolean, default: false }) private noIcon!: boolean;
	@Prop({ type: String, default: '' }) private type!: ActionItemType|string;
	@Prop({ type: Boolean, default: false }) private showIds!: boolean;
	get mutableItems () {
		return [...this.items];
	}

	set mutableItems (v: any) {
		// do nothing.
		// This only exists because we need the mutableItems to have a getter in order
		// to properly re-render upon update. Without the setter, vue complains.
	}

	private actionSheetOpened: boolean = false;
	private item: MenuItem = MENU_ITEM;

	private openActionSheet(id: number): void {
		this.actionSheetOpened = true;
		this.item = this.items.find((item: MenuItem) => item.id === id)!;
		document.documentElement.classList.add('modal-open');
	}

	private closeActionSheet(): void {
		this.actionSheetOpened = false;
		document.documentElement.classList.remove('modal-open');
	}

	private async drag (evt: any): Promise<void> {
		const { newIndex, oldIndex } = evt;
		const itemsCopy: MenuItem[] = [...this.items];

		if (evt.newIndex !== evt.oldIndex) {
			// Prevent going from 0 to negative
			itemsCopy[oldIndex].order = itemsCopy[newIndex].order >= 0 ? itemsCopy[newIndex].order : 0;

			switch (this.type) {
				case 'menu':
					return this.updateMenu({ menu: itemsCopy[oldIndex] });
				case 'sections':
					const section = itemsCopy[oldIndex];
					return this.updateMenuSection({
						menu: { id: section.menu_id },
						section
					});
				case 'items':
					const item = itemsCopy[oldIndex];
					if (item.section_id) {
						return this.updateMenuSectionItem({
							menu: { id: item.menu_id },
							section: { id: item.section_id },
							item,
							refetch: true
						});
					}
					else {
						return this.updateMenuItem({
							menu: { id: item.menu_id },
							item,
							refetch: true
						});
					}
				case 'add-ons':
					return this.updateOptionGroupAndOptions({
						id: itemsCopy[oldIndex].id,
						body: itemsCopy[oldIndex]
					});
			}
		}
	}

	private decode(str: string): string {
		return decodeString(str);
	}
}
