
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<SmallModalWrapper>({})
export default class SmallModalWrapper extends Vue {
	@Prop({ type: Boolean, default: false }) private showDialog!: boolean;
	@Prop({ type: String, default: '' }) private title!: string;

	private mutableDialog: boolean = this.showDialog;

	private close(): void {
		this.$emit('close-modal', false);
	}
}
