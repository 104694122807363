import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { OrdersState, RootState } from '../types';

const namespaced: boolean = true;

export const state: OrdersState = {
	orders: [],
	currentOrders: {} as CurrentOrders,
	selectedOrder: {} as Order,
	orderDetails: {
		type: '',
		tableArea: '',
		tableLocation: '',
		tableNum: '',
		takeoutType: '',
		checkNum: ''
	},
	// item selected from the menu
	selectedItem: null,
	// item to be added to the order
	orderItem: null,
	editingItem: false,
	modifyingOrder: false,
	orderValidationErrors: {},
	orderItemPrice: 0,
	cart: {
		items: [],
		cartIndex: 0
	},
	checkout: {
		pickup: null,
		delivery: {
			type: '',
			address: ''
		},
		contact: {
			full_name: '',
			email: '',
			phone_number: ''
		},
		loyaltyProgram: null,
		memberInfo: null,
		questions: null
	},
	costs: {
		subtotal: 0,
		subtotalDiscounted: 0,
		taxBrackets: {},
		taxes: 0,
		tip: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar'
		},
		serviceCharge: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar',
			label: ''
		},
		deliveryCharge: {
			percentageValue: 0,
			amount: 0,
			type: 'dollar'
		},
		voucher: {
			code: '',
			balance: 0,
			amount: 0
		},
		discount: {
			percentageValue: 0,
			amount: 0,
			type: 'percentage',
			code: ''
		},
		memberDiscount: {
			preTax: false,
			amount: 0
		},
		total: 0,
		totalWithTip: 0
	}
};

export const Orders: Module<OrdersState, RootState> = {
	namespaced,
	state,
	mutations,
	getters,
	actions
};

export default Orders;