
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

const namespace: string = 'menus';

@Component<CopyAvailability>({})
export default class CopyAvailability extends Vue {
	@Action('copyMenuAvailability', { namespace }) private copyMenuAvailability!: (payload: any) => Promise<void>;
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;

	private loading: boolean = false;
	private mutableMenuIds: number[] = [];

	/**
	 * List of menus but with the menu that is copying stripped from the list
	 *
	 * @param {Menu[]} menus
	 * @return {Menu[]}
	 */
	private menuList(menus: Menu[]): Menu[] {
		return menus.filter((tempMenu: Menu) => tempMenu.id !== this.menu.id);
	}

	/**
	 * Push or remove the menu depending on if it was selected or unselected
	 * from the id list that we will copy the availability to.
	 *
	 * @param {boolean} value
	 * @param {number} id
	 * @return {void}
	 */
	private onCheckboxChange(value: boolean, id: number): void {
		value ? this.mutableMenuIds.push(id) : this.mutableMenuIds.splice(this.mutableMenuIds.indexOf(id), 1);
	}

	/**
	 * Copy the menu's availability to the other menus that was selected.
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
		await this.copyMenuAvailability({ menu: this.menu, menuIds: this.mutableMenuIds })
			.then(() => {
				this.$emit('copied', { success: true, color: '#00B796', message: this.$t('menu.editor.availability.success_message_copy', { menuName: this.menu.name }) });
			})
			.catch((errorMessage) => {
				this.$emit('copied', { success: false, color: 'primary', message: this.$t('menu.editor.availability.error_copy', { menuName: this.menu.name, errorMessage }) });
			});
		this.loading = false;
	}
}
