
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { OPTION } from '../../utils/defaultTypeObjects';
import { valueSort } from '../../utils/sort';
import { TOAST_INSTANCE } from '../../utils/constants';
import { formatOptionGroup } from '../../utils/formatItem';
import TitleHeader from '@/components/shared/EditorHeader.vue';
import ListItem from '@/components/shared/ListItem.vue';
import AddonEditor from '@/components/add-ons/AddOnEditor.vue';
import AddonsLocaleEditor from './AddonsLocaleEditor.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'options';

@Component<AddOnOptionGroupEditor>({
	components: {
		TitleHeader,
		ListItem,
		AddonsLocaleEditor,
		AddonEditor,
		BannerToast
	}
})

export default class AddOnOptionGroupEditor extends Vue {
	@Action('createOptionGroup', { namespace }) private createOptionGroupAction!: (args: object) => Promise<void>;
	@Action('updateOptionGroupAndOptions', { namespace }) private updateOptionGroupAndOptionsAction!: (args: object) => Promise<void>;
	@Getter('getSelectedAddOnOptionGroup', { namespace }) private createdAddOnOptionGroup!: OptionGroup;
	@Prop({ type: Object, default: () => {} }) private optionGroup!: OptionGroup;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;
	@Prop({ type: Boolean, default: true }) private isCreating!: boolean;
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;

	private mutableOptionGroup: OptionGroup = this.optionGroup;
	private addOns: Option[] = this.optionGroup.values as Option[];
	private loading: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private mutableAddOns: Option[] = this.addOns?.length
		? [...JSON.parse(JSON.stringify(this.addOns.sort((a, b) => valueSort(a, b, 'price')))), { ...OPTION }]
		: [{ ...OPTION }];

	private addOnsBatch: Batch = {
		removed: [],
		updated: this.addOns || [],
		added: this.addOns ? [] : [{ ...OPTION }]
	};

	private addAddOnForm(): void {
		this.mutableAddOns.push({ ...OPTION });
		this.$emit('set-add-ons-edited', this.mutableAddOns);
	}

	/**
	 * Push the removed addon into the removed array
	 * and remove it from the UI as well
	 *
	 * @param {number} index
	 * @return {void}
	 */
	private removeAddOnForm(index: number): void {
		const existingAddOnId: number|undefined = this.mutableAddOns[index]?.id;
		if (existingAddOnId) {
			this.addOnsBatch.removed.push(existingAddOnId);
		}
		this.mutableAddOns.splice(index, 1);
		this.$emit('set-add-ons-edited', this.mutableAddOns);
	}

	/**
	 * Update option group and options
	 *
	 * @param {Option[]} completedAddOns
	 * @return {Promise<void>}
	 */
	private updateAddOnOption(completedAddOns: Option[]): Promise<void> {
		// Remove the addOns that do not have an id, those are to be *added*, as opposed to *updated*
		this.addOnsBatch.updated = completedAddOns.filter((updatedAddOn: Option) => updatedAddOn.id);

		// Check which add-ons were actually updated. The ones that are unchanged will be removed.
		if (this.addOnsBatch.updated.length) {
			const existingAddOnsById: any = this.addOns.reduce((addOnByIdObj, current: Option) => {
				return {
					...addOnByIdObj,
					[current.id!]: current
				};
			}, {});

			// TODO: refactor to check clone instead
			this.addOnsBatch.updated = this.addOnsBatch.updated.filter((addOn: Option) => {
				return (addOn.price !== existingAddOnsById[addOn.id!].price || addOn.name !== existingAddOnsById[addOn.id!].name || addOn.calories !== existingAddOnsById[addOn.id!].calories || addOn.max_quantity !== existingAddOnsById[addOn.id!].max_quantity || addOn.localization !== existingAddOnsById[addOn.id!].localization);
			});
		}

		// The ones that don't have an id are to be added:
		this.addOnsBatch.added = completedAddOns.filter(addOn => !addOn.id);

		return this.updateOptionGroupAndOptionsAction({
			id: this.optionGroup.id,
			optionsBatch: this.addOnsBatch,
			body: formatOptionGroup(this.mutableOptionGroup)
		});
	}

	/**
	 * Save the option group with the options (addons)
	 * We filter out any addons that are incomplete
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
		const completedAddOns: Option[] = this.mutableAddOns.filter((addOn: Option) => addOn.price && addOn.name);

		if (this.isCreating) {
			await this.createOptionGroupAction({ options: completedAddOns, body: formatOptionGroup(this.mutableOptionGroup) })
				.then(() => {
					// Automatically select a created add on option group
					if (this.$listeners.select) {
						this.$emit('select', this.createdAddOnOptionGroup.id);
					}
				})
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_creating', { name: this.mutableOptionGroup.name, errorMessage });
				});
		}
		else {
			await this.updateAddOnOption(completedAddOns)
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_updating', { name: this.mutableOptionGroup.name, errorMessage });
				});
		}
		this.loading = false;
	}
}
