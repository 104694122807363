
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent, decodeEventSuite } from '@/utils/decoding';
import { addBorderAroundTable, addStylingToHorizontalHeader, addStylingToVerticalHeader, downloadFile, formatItemOptions, setCellFontSizeAndAlignement } from '@/utils/exceljs-helpers';
import { DateTime } from 'luxon';
import * as ExcelJS from 'exceljs';
import ExpansionPanel from '@/components/shared/suites/panels/ExpansionPanel.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import OrderItemsList from '@/components/suites/beo/OrderItemsList.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import SuitesPanel from '@/components/shared/suites/panels/SuitesPanel.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

@Component<Beo>({
	components: {
		ExpansionPanel,
		FullPageSpinner,
		OrderItemsList,
		SuitesHeader,
		SuitesPanel,
		TextButton
	}
})
export default class Beo extends Vue {
	@Action('fetchDeepEventSuite', { namespace: 'eventSuites' }) private fetchDeepEventSuite!: (payload: { eventSuiteId: string, eventId: string }) => Promise<void>;
	@Action('fetchEvent', { namespace: 'events' }) private fetchEvent!: (eventId: string) => Promise<void>;
	@Getter('getSelectedEventSuite', { namespace: 'eventSuites' }) private suite!: EventSuite;
	@Getter('getSelectedEvent', { namespace: 'events' }) private event!: SuitesEvent;
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;

	private order: OrderSimplified = {} as OrderSimplified;
	private suiteNotes: { label: String, value: String }[] = [];
	private decodedSuite: EventSuite | null = null;
	private decodedEvent: SuitesEvent | null = null;
	private isDataFetching: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;

	private get breadcrumbsArray(): Breadcrumb[] {
		if (!this.decodedEvent || !this.decodedSuite) {
			return [];
		}
		return [
			{
				text: this.$t('events.header'),
				to: '/events',
				exact: true
			},
			{
				text: `${this.decodedEvent.title} (${Vue.filter('formatDate')(this.event.start_date)})`,
				to: `/events/${this.event.id}`,
				exact: true
			},
			{
				text: this.$t('beo.title', { name: this.decodedSuite ? this.decodedSuite.suite.name : '' }),
				to: `/events/${this.event.id}/suites/${this.suite.id}/beo`,
				exact: true
			}
		];
	}

	/**
	 * Get the delivery time for the order. If the delivery time is a date,
	 * format it, otherwise return the basic delivery time.
	 *
	 * @return {string | null}
	 */
	private get preOrderDeliveryTime(): string | null {
		const deliveryTime: DateTime = DateTime.fromISO(this.order.suites!.orderDeliveryTime);
		if(this.order?.groupedItems) {
			if(deliveryTime.isValid) {
				return this.$t('beo.summary.delivery_times.datetime.title', { date: deliveryTime.setLocale(this.$i18n.locale).toFormat(this.$t('beo.summary.delivery_times.datetime.date_format')) });
			}
			return this.$t(`beo.summary.delivery_times.basic.${this.order?.suites?.orderDeliveryTime}`);
		}
		return null;
	}

	// Fetch as set deep event suite and event data
	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			await this.fetchDeepEventSuite({ eventSuiteId: this.$route.params.suite_id, eventId: this.$route.params.event_id });

			if (!this.event || this.event.id !== Number(this.$route.params.event_id)) {
				await this.fetchEvent(this.$route.params.event_id);
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_details.error_fetching_event', { errorMessage });
		}
		finally {
			if (this.suite) {
				this.decodedSuite = decodeEventSuite(this.suite);

				// Set order if there is one and set suite notes
				if (this.decodedSuite.orders.length) {
					this.order = this.decodedSuite.orders[0].data;
				}
				this.suiteNotes = [
					{
						label: `${this.$t('suite_settings.additional_notes.dietary_restrictions_label')}<span class="title-sm">( ${this.$t('beo.by_manager')} )</span>`,
						value: this.decodedSuite.dietary_restrictions
					},
					{
						label: `${this.$t('suite_settings.additional_notes.special_requirements_label')}<span class="title-sm">( ${this.$t('beo.by_manager')} )</span>`,
						value: this.decodedSuite.special_requirements
					},
					{
						label: `${this.$t('suite_settings.additional_notes.header')}<span class="title-sm">( ${this.$t('beo.by_suite_owner')} )</span>`,
						value: this.decodedSuite.pre_order_additional_notes
					}
				];
			}

			if (this.event) {
				this.decodedEvent = decodeEvent(this.event);
			}

			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	/**
	 * Export the event details and pre-order summary to an Excel file.
	 *
	 * @return {void}
	 */
	private exportData(): void {
		const workbook = new ExcelJS.Workbook();
		this.createEventDetailsExcelTab(workbook);
		this.createPreOrdersSummaryExcelTab(workbook);

		// Save the workbook to a file
		workbook.xlsx.writeBuffer().then(buffer => {
			downloadFile(buffer, this.$t('beo.spreadsheet.file_name', {
				eventName: this.decodedEvent?.title || '',
				eventDate: DateTime.fromISO(this.decodedEvent!.start_date).toFormat('yyyy-MM-dd HH-mm')
			}));
		});
	}

	/**
	 * Create the first tab in the Excel file with the event details.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createEventDetailsExcelTab(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('beo.production_sheet.spreadsheet.details_tab'), {
			pageSetup: { orientation: 'landscape' }
		});
		worksheet.columns = [{ width: 40 }, { width: 50 }];

		// First event details table
		const firstTableData = [
			[this.$t('beo.production_sheet.spreadsheet.event_name_col'), this.decodedEvent?.title || ''],
			[this.$t('beo.production_sheet.spreadsheet.start_date_col'), Vue.filter('formatDate')(this.decodedEvent?.start_date, this.$t('beo.production_sheet.spreadsheet.date_format'))],
			[this.$t('beo.spreadsheet.scheduled_for'), this.preOrderDeliveryTime || ''],
			[this.$t('beo.spreadsheet.suite_name'), this.decodedSuite?.suite.name || ''],
			[this.$t('beo.production_sheet.spreadsheet.manager_name_col'), this.decodedEvent?.manager_info?.name || 'N/A'],
			[this.$t('beo.production_sheet.spreadsheet.manager_phone_col'), this.decodedEvent?.manager_info?.phone_number || 'N/A'],
			[this.$t('beo.production_sheet.spreadsheet.manager_email_col'), `${this.decodedEvent?.manager_info?.email || 'N/A'}`],
			[this.$t('beo.spreadsheet.suite_owner_name'), `${this.decodedSuite?.suite_owner?.name || 'N/A'}`],
			[this.$t('beo.spreadsheet.suite_owner_phone'), `${this.decodedSuite?.suite_owner?.phone_number || 'N/A'}`],
			[this.$t('beo.spreadsheet.suite_owner_email'), `${this.decodedSuite?.suite_owner?.email || 'N/A'}`],
			[this.$t('beo.tab_limit.header'), this.decodedSuite?.tab_limit ? this.$t('beo.tab_limit.limit', { limit: this.decodedSuite?.tab_limit }) : this.$t('beo.tab_limit.inactive')]
		];

		worksheet.addRows(firstTableData);
		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		addStylingToVerticalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);

		// Second event details table (in the same sheet)
		const secondTableData = [
			[this.$t('beo.spreadsheet.manager_instructions'), this.suiteNotes[0].value || ''],
			[this.$t('beo.spreadsheet.manager_requirements'), this.suiteNotes[1].value || ''],
			[this.$t('beo.spreadsheet.owner_requests'), this.suiteNotes[2].value || '']
		];
		if (this.order.checkout?.questions?.length) {
			this.order.checkout.questions.forEach((questionObj: any) => {
				secondTableData.unshift([Vue.filter('localize')(questionObj, 'question'), Vue.filter('localize')(questionObj, 'answer')]);
			});
		}

		const startRowIndex = worksheet.rowCount + 2; // Adding 2 to leave one empty row between tables
		worksheet.insertRows(startRowIndex, secondTableData);
		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' }, startRowIndex);
		addStylingToVerticalHeader(worksheet, startRowIndex);
		addBorderAroundTable(worksheet, startRowIndex, worksheet.rowCount);
	}

	/**
	 * Create the second tab in the Excel file with the pre-ordered items summary.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createPreOrdersSummaryExcelTab(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('beo.summary.title'), {
			pageSetup: { orientation: 'landscape' }
		});

		worksheet.columns = [
			{ header: this.$t('beo.production_sheet.spreadsheet.item_category_col'), key: 'category', width: 25 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_name_col')], key: 'name', width: 25 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_quantity_col')], key: 'quantity', width: 10 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_modifiers_col')], key: 'options', width: 50 }
		];

		const preOrdersData = this.order.groupedItems?.map((section: OrderItemGroup) => {
			const sectionData = section.items.map((item) => {
				return {
					name: Vue.filter('localize')(item, 'name'),
					quantity: item.quantity,
					options: formatItemOptions(item),
					category: Vue.filter('localize')(section, 'name') || ''
				};
			});
			return sectionData;
		}).flat();

		if (preOrdersData) {
			worksheet.addRows(preOrdersData);
		}

		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		addStylingToHorizontalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);
	}
}
