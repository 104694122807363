import i18n from '@/i18n';
import auth0 from 'auth0-js';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { Service } from '../base.service';
import { Storage } from './storage';

const CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID;

class Auth extends Service {
	private options: any;
	private $storage: Storage;

	constructor() {
		super();

		this.options = {
			private: ['access_token', 'id_token', 'expires_at', 'restaurants', 'tenantId', 'role', 'staffEmail', 'hasPins', 'pinEntered', 'pinAttempts']
		};
		this.$storage = new Storage();
		this.init();
	}

	public async init() {
		this.options.private.forEach((key: string) => {
			this.$storage.syncUniversal(key);
		});
		await this.$storage.setAxiosInstance();
	}

	public getKey() {
		return '';
	}

	public async resetPassword(email: string) {
		return await new Promise((resolve, reject) => {
			this.getWebAuth().changePassword({ connection: 'Username-Password-Authentication', email: email }, (error: any, response) => {
				if (!error) {
					resolve(response);
				}
				else {
					reject(({
						status: 401,
						error: 'Error resetting password in, Try again later.'
					}));
				}
			});
		});
	}

	public async login({ username, password }: { username: string; password: string }) {
		await new Promise((resolve, reject) => {
			this.getWebAuth().login(
				{
					username,
					password,
					realm: 'Username-Password-Authentication'
				},
				(err, authResult) => {
					if (err) {
						const ex = ({
							status: 403,
							error: err.description
						});
						console.error(err);
						reject(ex);
					}
					else {
						resolve(authResult);
					}
				}
			);
		});
	}

	public async loginAppUser(tokens: LoginTokens): Promise<boolean> {
		try {
			await this.setStorage(tokens.accessToken, tokens.idToken, {});
			return true;
		}
		catch (error) {
			throw i18n.t('auth.error_no_locations_account');
		}
	}

	public async handleAuthentication(): Promise<string> {
		return await new Promise(async (resolve, reject) => {
			this.getWebAuth().parseHash({ hash: window.location.hash }, async (error: any, authResult) => {
				if (!error && authResult) {
					const {
						accessToken,
						idToken,
						idTokenPayload: {
							given_name: name,
							email: email
						}
					} = authResult;

					if (!accessToken || !accessToken.length) {
						return reject(i18n.t('auth.error_auth_not_found'));
					}

					const lastLogin = {
						name,
						email,
						lastUsedConnection: 'Username-Password-Authentication'
					};

					try {
						await this.setStorage(accessToken, idToken!, lastLogin);
					}
					catch (error) {
						reject(i18n.t('auth.error_no_locations_account'));
					}
					resolve(email);
				}
				else {
					if (error.errorDescription === 'Unable to configure verification page.') {
						this.$storage.setUniversal('cross_origin_failed', 'true');
					}
					reject(({
						status: 401,
						error: error.errorDescription ? i18n.t('auth.error_logging_in_service') : i18n.t('auth.error_no_locations_account')
					}));
				}
			});
		});
	}

	/**
	 * Set storage values
	 *
	 * @return {void}
	 */
	private async setStorage(accessToken: string, idToken: string, lastLogin: object): Promise<any> {
		try {
			const idTokenDecoded: any = jwt_decode(idToken!);
			const accessTokenDecoded: any = jwt_decode(accessToken!);
			const role: Role = idTokenDecoded[`${process.env.VUE_APP_API_AUTH0_AUDIENCE}/app_metadata`].role;
			const hasPins: boolean = !!(idTokenDecoded[`${process.env.VUE_APP_API_AUTH0_AUDIENCE}/app_metadata`].pins?.length);
			const restaurants: AuthRestaurants[] = idTokenDecoded[`${process.env.VUE_APP_API_AUTH0_AUDIENCE}/app_metadata`].restaurants;

			if (!restaurants || (restaurants && !restaurants.length)) {
				throw ('No locations');
			}

			this.$storage.setUniversal('access_token', accessToken);
			this.$storage.setUniversal('id_token', idToken as string);
			this.$storage.setUniversal('expires_at', accessTokenDecoded.exp);
			this.$storage.setUniversal('tenantId', restaurants[0].id);
			this.$storage.setUniversal('staffEmail', idTokenDecoded.email);
			await this.$storage.setAxiosInstance();
			this.$storage.setUniversal('role', role ? role.toLocaleLowerCase() : 'manager');
			this.$storage.setUniversal('hasPins', hasPins.toString());
			role === 'staff' && this.$storage.setUniversal('pinEntered', 'false');
			this.$storage.setUniversal('lastLogin', JSON.stringify(lastLogin));
			await this.$storage.setRestaurants(restaurants);
			this.$storage.setUniversal('restaurants', JSON.stringify(this.$storage.getState('restaurants')));
			await this.$storage.fetchRestaurant();
			this.$storage.setUniversal('cross_origin_failed', 'false');
		}
		catch (error) {
			throw (error);
		}
	}

	/**
	 * Set the tenant id that was selected in the cookies and local storage
	 * so we keep the selected restaurant on reload
	 *
	 * @param {string} tenantId
	 * @return {void}
	 */
	public setTenantIdInStorage(tenantId: string): void {
		this.$storage.setUniversal('tenantId', tenantId);
	}

	/**
	 * Clear storage/cookies and logout the user which
	 * redirects to the login page
	 *
	 * @return {void}
	 */
	public logout(): void {
		this.clear();
		this.getWebAuth().logout({
			clientID: CLIENT_ID,
			returnTo: window.location.origin + '/login'
		});
	}

	/**
	 * Clear all the user storage
	 *
	 * @return {Promise<void>}
	 */
	public clear(): Promise<void> {
		for (const key of Object.values(this.options.private as string[])) {
			this.$storage.setUniversal(key, null);
		}
		return Promise.resolve();
	}

	/**
	 * Clear single sign on
	 *
	 * @return {Promise<void>}
	 */
	public clearSSO(): Promise<void> {
		this.$storage.setUniversal('lastLogin', null);
		return Promise.resolve();
	}

	/**
	 * Validate if pin exist is in the user's app_metadata
	 *
	 * @param {string} pin - The pin to validate
	 * @return {boolean}
	 */
	public validatePin(pin: string): boolean {
		const idTokenDecoded: any = jwt_decode(this.idToken);
		const userPins: string[] = idTokenDecoded[`${process.env.VUE_APP_API_AUTH0_AUDIENCE}/app_metadata`].pins;
		if (!userPins?.length) {
			return false;
		}
		return userPins.includes(pin);
	}


	// ---------------------------------------------------------------
	// User getters
	// ---------------------------------------------------------------
	get accessToken(): string {
		return this.$storage.getUniversal('access_token');
	}

	get idToken(): string {
		return this.$storage.getUniversal('id_token');
	}

	get expiresAt(): string {
		return this.$storage.getUniversal('expires_at');
	}

	get lastLogin(): string {
		return JSON.parse(this.$storage.getUniversal('lastLogin'));
	}

	get loggedIn(): boolean {
		return (!!this.accessToken && !!this.idToken && Number(this.expiresAt) > (DateTime.local().toMillis() / 1000));
	}

	get crossOriginFailed(): boolean {
		return !!this.$storage.getUniversal('cross_origin_failed');
	}

	// ---------------------------------------------------------------
	// Utils
	// ---------------------------------------------------------------

	private getWebAuth() {
		return new auth0.WebAuth({
			domain: process.env.VUE_APP_AUTH0_CLIENT_DOMAIN!,
			redirectUri: window.location.origin + '/login/callback',
			clientID: CLIENT_ID!,
			responseType: 'token id_token',
			scope: 'openid email profile',
			audience: process.env.VUE_APP_API_AUTH0_AUDIENCE,
			leeway: 60
		});
	}
}

export default new Auth();
