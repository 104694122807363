
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getTimeArray } from '../../../utils/timeFunctions';
import { WEEKDAYS } from '@/utils/constants';
import Tooltip from '@/components/shared/Tooltip.vue';


@Component<PriceAvailability>({
	components: {
		Tooltip
	}
})
export default class PriceAvailability extends Vue {
	@Prop({ type: Object, default: () => {} }) private additionalPrices!: IObjectKeys;
	@Prop({ type: Array, default: () => [] }) private priceScheduling!: [];
	@Prop({ type: Boolean, default: true }) private isPricingAvailabilityAllWeek!: boolean;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Watch('additionalPrices')
	onAdditionalPricesChange() {
		this.setAdditionalPricesArray();
	}

	private isAllWeek: boolean = this.isPricingAvailabilityAllWeek;
	private weekdays: object[] = WEEKDAYS;
	private startTimeAvailabilityArray: string[] = getTimeArray(60, 'minutes');
	private endTimeAvailabilityArray: string[] = getTimeArray(60, 'minutes', false, true);
	private mutableAdditionalPrices: string[] = [];
	private scheduledPriceArray: ScheduleObject[] = this.priceScheduling;

	private created(): void {
		this.setAdditionalPricesArray();
		// Set up availability if none
		if (!this.scheduledPriceArray || !this.scheduledPriceArray.length) {
			this.resetPriceAvailability();
		}
	}

	/**
	 * Since the additional prices are stored as a JSON object
	 * We need to convert it into an array to allow the v-select
	 * to display key values
	 *
	 * @return {string[]}
	 */
	private setAdditionalPricesArray(): void {
		let tempArray: string[] = [];
		for (const additionalPrice in this.additionalPrices) {
			const additionalPriceString = `${additionalPrice} ($${this.additionalPrices[additionalPrice]})`;
			tempArray.push(additionalPriceString);

			// We have to update the price chosen string if any are chosen when a price is changed
			// otherwise the selector will not find the value in it's values.
			if (this.scheduledPriceArray && this.scheduledPriceArray.length) {
				this.scheduledPriceArray.forEach((element: ScheduleObject) => {
					const scheduledPrice: ScheduleObject = element;

					scheduledPrice.values.forEach(value => {
						if (value.priceChosen.charAt(0) === additionalPrice) {
							value.priceChosen = additionalPriceString;
						}
					});
				});
			}
		}
		this.mutableAdditionalPrices = tempArray;

		// Add X in the array to allow the user to put an item unavailable for a time
		this.mutableAdditionalPrices.push('X (Unavailable)');
	}

	private removePriceSchedule(elementIndex: number, index: number): void {

		// If -1 it means it's for all week so we wanna update all 7 days
		if (elementIndex === -1) {
			this.scheduledPriceArray.forEach((element: ScheduleObject) => {
				element.values.splice(index, 1);
			});
		}
		else {
			this.scheduledPriceArray[elementIndex].values.splice(index, 1);
		}
	}

	private addPriceSchedule(elementIndex: number): void {

		// If -1 it means it's for all week so we wanna update all 7 days
		if (elementIndex === -1) {
			this.scheduledPriceArray.forEach((element: ScheduleObject) => {
				element.values.push({
					start: '',
					end: '',
					priceChosen: ''
				});
			});
		}
		else {
			this.scheduledPriceArray[elementIndex].values.push(
				{
					start: '',
					end: '',
					priceChosen: ''
				}
			);
		}
	}

	/**
	 * Update the {key} of a scheduled item's price
	 *
	 * @param {string} value
	 * @param {string} key
	 * @param {number} day
	 */
	private updateScheduleElement(value: string, key: string, elementIndex: number, index: number): void {

		// If -1 it means it's for all week so we wanna update all 7 days
		if (elementIndex === -1) {
			this.scheduledPriceArray.forEach((element: ScheduleObject) => {
				const currentObj = element.values[index];
				currentObj[key] = value;
			});
		}
		else {
			const currentObj = this.scheduledPriceArray[elementIndex].values[index];
			currentObj[key] = value;
		}
		this.setPriceAvailability();
	}

	/**
	 * If the all week switch is triggered, the type changes
	 * and we need to apply some changes to the schedule array
	 *
	 * @return {void}
	 */
	private updateType(): void {
		this.resetPriceAvailability();
	}

	private resetPriceAvailability(): void {
		this.scheduledPriceArray = [];
		for (let index = 1; index < 8; index++) {
			this.scheduledPriceArray.push({
				day: index,
				values: [
					{
						start: '',
						end: '',
						priceChosen: ''
					}
				]
			});
		}
		this.setPriceAvailability();
	}

	private setPriceAvailability(): void {
		this.$emit('set-price-availability', this.scheduledPriceArray);
	}
}
