
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import TableHeaders from '@/components/shared/suites/TableHeaders.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';

const namespace = 'changeHistory';

@Component<ChangeHistoryModal>({
	components: {
		StandardModal,
		TextButton,
		TableHeaders,
		InfoBanner
	}
})
export default class ChangeHistoryModal extends Vue {
	@Action('fetchChangeHistory', { namespace }) private fetchChangeHistory!: (options?: ChangeHistoryFetchOptions) => Promise<ChangeHistory[]>;
	@Prop({ type: Object, required: false }) private options!: ChangeHistoryFetchOptions | undefined;
	@Prop({ type: Boolean, required: true, default: false }) private showModal!: boolean;

	private isLoading: boolean = false;
	private changeHistory: ChangeHistory[] = [];
	private tableHeaders: TableHeader[] = [
		{ text: this.$t('change_history_modal.date_column') },
		{ text: this.$t('change_history_modal.user_column') },
		{ text: this.$t('change_history_modal.activity_column') }
	];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private async created(): Promise<void> {
		await this.getChangeHistory();
	}

	private get formattedChangeHistory(): ChangeHistory[] {
		return this.changeHistory.map(changelog => {
			if (changelog.activity.includes('[||]')) {
				// Separate activity i18n path from the values, and build the activityValues object
				const [activity, values] = changelog.activity.split('[||]');
				const activityValues: ChangeActivityValues = this.buildActivityValues(values);

				return {
					...changelog,
					activity,
					activityValues
				};
			}
			return changelog;
		});
	}


	/**
	 * Fetch change history
	 *
	 * @return {Promise<void>}
	 */
	private async getChangeHistory(): Promise<void> {
		this.isLoading = true;
		try {
			this.changeHistory = await this.fetchChangeHistory(this.options);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('change_history_modal.error_fetching', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Build the object of changelog activity values
	 * Each key/value pair is separated by '[&]' and a key and value are separated by '[=]'
	 *
	 * @param {string} acivityValuesString - The string of values
	 * @return {ChangeActivityValues} - Object which keys are variables for the locales files
	 */
	private buildActivityValues(acivityValuesString: string): ChangeActivityValues {
		const output: ChangeActivityValues = {};
		acivityValuesString.split('[&]').forEach(keyValuePair => {
			const [key, value] = keyValuePair.split('[=]') as [keyof ChangeActivityValues, string];
			output[key] = value;
		});
		return output;
	}

	/**
	 * Emit event to close modal
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}
}
