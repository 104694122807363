
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { DateTime } from 'luxon';
import { TOAST_INSTANCE } from '@/utils/constants';
import Scheduling from '@/utils/scheduling';
import DatePicker from '@/components/shared/DatePicker.vue';

const namespace: string = 'orders';

const DEFAULT_TAKEOUT_INFORMATION = {
	dueByDate: '',
	dueByTime: '',
	notes: '',
	prepTime: 0
};

@Component<TakeoutInformation>({
	components: {
		DatePicker,
		ValidationProvider
	}
})
export default class TakeoutInformation extends Vue {
	@Action('fetchSchedulingRestrictions', { namespace }) private fetchSchedulingRestrictions!: (items: MenuOrderItem[]) => Promise<ScheduleRestrictions>;
	@Action('setTakeoutInformation', { namespace }) private setTakeoutInformation!: (takeoutInformation: Pickup) => void;
	@Getter('getTakeoutInformation', { namespace }) private takeoutInformation!: Pickup;
	@Getter('getOrderDetails', { namespace }) private orderDetails!: string;
	@Getter('getCartItems', { namespace }) private items!: MenuOrderItem[];
	@Getter('getModifyingOrder', { namespace }) private modifyingOrder!: boolean;
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	private takeout: Pickup = DEFAULT_TAKEOUT_INFORMATION;
	private scheduling: Scheduling | null = null;
	private fetchingSchedulingInfo: boolean = true;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	/**
	 * Format dueByDate to expected format for the datepicker
	 *
	 * @return {string}
	 */
	private get dateSelectorFormat(): string {
		if (this.takeout.dueByDate) {
			return DateTime.fromISO(this.takeout.dueByDate).toISODate();
		}
		return '';
	}

	/**
	 * Format estimated_prep_time gathered from the menu to display
	 * the time to the user.
	 *
	 * @return {void}
	 */
	private get prepTimeString(): string {
		const prepTime = this.takeout?.prepTime;
		if (!prepTime) {
			return '';
		}
		return prepTime < 60 || !this.takeout?.prepTime ? `${prepTime}min` : `${Math.floor(prepTime / 60)}h ${prepTime % 60}min`;
	}

	$refs!: {
		observer: Validate;
	};

	/**
	 * Set the takeout date if it's null and get the scheduling
	 * restrictions from the menus/restaurant.
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		this.$emit('fetching-scheduling-info', true);
		if (this.takeoutInformation) {
			this.takeout = this.takeoutInformation;
		}
		if (!this.takeoutInformation?.dueByDate){
			this.takeout.dueByDate = DateTime.local().toISO();
		}
		await this.getSchedulingRestrictions();
	}

	/**
	 * When a user selects a date from the datepicker, update the date and reset the time
	 *
	 * @param {string} date
	 * @return {void}
	 */
	private updateTakeoutDate(date: string): void {
		if (this.scheduling) {
			this.scheduling.dueByDate = date;
			this.takeout.dueByDate = date;
			this.scheduling.dueByTime = '';
			this.takeout.dueByTime = '';
			this.setTakeoutInformation(this.takeout);
		}
	}

	/**
	* Get all the scheduling restrictions from the menu/restaurant
	* and generate the time series with the interval.
	*
	* @return {Promise<void>}
	*/
	private async getSchedulingRestrictions(): Promise<void> {
		try {
			const scheduleRestrictions: ScheduleRestrictions = await this.fetchSchedulingRestrictions(this.items);
			this.takeout.scheduled = scheduleRestrictions.scheduled_ordering;
			this.takeout.prepTime = scheduleRestrictions.prep_time;
			this.scheduling = new Scheduling(scheduleRestrictions, this.takeout, this.modifyingOrder, this.restaurant);
		}
		catch(error) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.guest_details.error_fetching_scheduling_information');
		}
		finally {
			setTimeout(() => {
				this.$emit('fetching-scheduling-info', false);
				this.fetchingSchedulingInfo = false;
			}, 500);
		}
	}

	/**
	 * Determine if a date is allowed to be selected in the datepicker
	 * The datepicker component will call this function for each date rendered
	 *
	 * @param {string} date - From the datepicker component in YYYY-MM-DD format
	 * @return {boolean}
	 */
	public dateAllowed(date: string): boolean {
		const dateAsDateTimeObj = DateTime.fromISO(date);

		if (this.scheduling) {
			// Allow the user to reselect the initial date if it's the same day, initalDateTimeSelection will only be set if the user is modifying an order
			if (this.scheduling.initialDateTimeSelection && dateAsDateTimeObj.hasSame(this.scheduling.initialDateTimeSelection, 'day')) {
				return true;
			}
			// If the date is in the past, disable it, original date excluded
			if (DateTime.local().toISODate() > dateAsDateTimeObj.toISODate()) {
				return false;
			}
			// Disable dates with no menu availability
			if (this.scheduling.disabledDays.includes(dateAsDateTimeObj.toJSDate().getDay())) {
				return false;
			}
			// Disable closed dates
			return !this.scheduling.disabledDates.includes(date);
		}
		return true;
	}
}
