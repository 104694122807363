
import { Action } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TOAST_INSTANCE } from '../../utils/constants';
import ModalWrapper from '@/components/shared/ModalWrapper.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

@Component<MoveModal>({
	components: {
		ModalWrapper,
		BannerToast
	}
})

export default class MoveModal extends Vue {
	@Action('updateMenuSection', { namespace: 'sections' }) private updateMenuSection!: (payload: object) => Promise<void>;
	@Action('updateMenuSectionItem', { namespace: 'items' }) private updateMenuSectionItem!: (payload: object) => Promise<void>;
	@Action('updateMenuItem', { namespace: 'items' }) private updateMenuItem!: (payload: object) => Promise<void>;

	@Prop({ type: Boolean, default: false }) private show!: boolean;
	@Prop({ type: String, default: false }) private title!: string;
	@Prop({ type: String, default: '' }) private type!: string; // One of: section, menuItem, sectionItem
	@Prop({ type: Number, default: 0 }) private originMenuId!: number;
	@Prop({ type: Object, default: {} }) private mover!: MenuSection | MenuItem;
	@Prop({ type: Array, default: [] }) private destinations!: (Menu | MenuSection | MenuItem)[];

	private publishedDestinations = this.destinations.filter((item) => !!item.published_at);
	private unpublishedDestinations = this.destinations.filter((item) => !item.published_at);
	private destinationId: number|null = null;

	private showSections: boolean = false;

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private stateReset (): void {
		this.showSections = false;
		this.destinationId = null;
		this.publishedDestinations = this.destinations.filter((item) => !!item.published_at);
		this.unpublishedDestinations = this.destinations.filter((item) => !item.published_at);
	}

	private closeModal (saved: boolean = false): void {
		// Call go-back to reset any changed states
		this.stateReset();
		this.$emit('close-modal', saved);
	}

	private onClickDestination (id: number): void {
		if (this.type !== 'sectionItem' || this.showSections) {
			this.destinationId = this.destinationId === id ? null : id;
		}
		else {
			this.showSections = true;
			const destinationMenu: any = this.destinations.find((_menu) => _menu.id === id);
			this.publishedDestinations = destinationMenu.sections.filter((item: any) => !!item.published_at && (this.mover as MenuItem).section_id !== item.id);
			this.unpublishedDestinations = destinationMenu.sections.filter((item: any) => !item.published_at && (this.mover as MenuItem).section_id !== item.id);
		}
	}

	private moveActionCalls (): Promise<void> {
		if (this.type === 'section') {
			return this.updateMenuSection({
				menu: { id: this.originMenuId },
				section: {
					id: this.mover.id,
					menu_id: this.destinationId
				}
			});
		}
		else if (this.type === 'sectionItem') {
			return this.updateMenuSectionItem({
				menu: { id: this.originMenuId },
				section: { id: (this.mover as MenuItem).section_id },
				item: {
					id: this.mover.id,
					section_id: this.destinationId
				},
				refetch: true
			});
		}
		else if (this.type === 'menuItem') {
			return this.updateMenuItem({
				menu: { id: this.originMenuId },
				item: {
					id: this.mover.id,
					menu_id: this.destinationId
				},
				refetch: true
			});
		}

		return Promise.reject(this.$t('shared.move_dialog.error_invalid_object'));
	}

	private onClickMove (): Promise<void> {
		return this.moveActionCalls()
			.then(() => {
				this.closeModal(true);
			})
			.catch((errorMessage: string) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.move_dialog.error_invalid_object', { moverName: this.mover.name, errorMessage });
			});
	}
}
