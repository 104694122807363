
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getOrderStatus, getOrderColorStatus, getOrderTimeLeft } from '@/utils/getters';
import { Getter } from 'vuex-class';

@Component<OrderCard>({})
export default class OrderCard extends Vue {
	@Getter('isMarketplaceEnabled', { namespace: 'auth' }) private isMarketplaceEnabled!: Boolean;
	@Prop({ type: Object, default: () => {} }) private order!: Order;

	private get orderStatus(): string {
		return getOrderStatus(this.order.status);
	}

	private get orderColorStatus(): string {
		return getOrderColorStatus(this.order.status);
	}

	private get timeLeft(): { text: string; color: string } {
		return getOrderTimeLeft(this.order);
	}

	private showModal(): void {
		this.$emit('show-modal', this.order);
	}
}
