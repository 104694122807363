
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<DiscountInformation>({
	components: {
		ValidationProvider
	}
})
export default class DiscountInformation extends Vue {
	@Prop({ type: Boolean, default: false }) private targetDiscountEnabled!: boolean;
	@Prop({ type: Object, default: () => {} }) private targetDiscountInfo!: DiscountInfo;

	private mutableDiscountInfo: DiscountInfo | any = this.targetDiscountInfo;
	private mutableDiscountEnabled: boolean = this.targetDiscountEnabled;

	private updateDiscountInfo(): void {
		if (!this.mutableDiscountEnabled) {
			Vue.set(this, 'mutableDiscountInfo', {});
		}
		else {
			this.mutableDiscountInfo = {
				codes: [
					{ name: '', value: '' }
				]
			};
		}
		this.$emit('update-discount-info', { enabled: this.mutableDiscountEnabled, info: this.mutableDiscountInfo });
	}

	private removeDiscountCode(index: number): void {
		this.mutableDiscountInfo!.codes.splice(index, 1);
	}

	private addDiscountCode(): void {
		this.mutableDiscountInfo!.codes.push({ name: '', value: '' });
	}
}
