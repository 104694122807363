
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '../../utils/constants';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'auth';

@Component<EditorHeader>({
	components: {
		BannerToast
	}
})
export default class EditorHeader extends Vue {
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Prop({ type: String, default: '' }) private title!: string;
	@Prop({ type: Boolean, default: false }) private hideLocaleDropdown!: boolean;

	private languageSelected: RestaurantLocale = {} as RestaurantLocale;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private created(): void {
		if(this.restaurant.locales && this.restaurant.locales.length > 1) {
			this.restaurant.locales.sort((a: RestaurantLocale, b: RestaurantLocale) => Number(b.is_default) - Number(a.is_default));
			this.languageSelected = this.restaurant.locales.find((locale: RestaurantLocale) => {
				return locale.is_default;
			})!;
		}
	}

	/**
	 * Handle the left button action, currently is only
	 * a close
	 *
	 * @return {void}
	*/
	private handleLeftAction(): void {
		this.$emit('close-modal', false);
	}

	/**
	 * Update the chosen locale on click. This will tell
	 * the children that the language chosen changed and
	 * to display the proper fields depending on the locale
	 * chosen.
	 *
	 * @return {void}
	 */
	private updateSelectedLocale(): void {
		this.$emit('update-selected-locale', this.languageSelected);
	}
}
