
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ActionSheetItem>({})
export default class ActionSheetItem extends Vue {
  	@Prop({ type: String, default: '' }) private iconName!: string;
	@Prop({ type: String, default: '' }) private buttonLabel!: string;
	@Prop({ type: Function, default: Function }) private onClick!: Function;
	@Prop({ type: Function, default: Function }) private closeActionSheet!: Function;
	@Prop({ type: Boolean, default: false }) private isLastItem!: boolean;
	@Prop({ type: Object, default: () => {} }) private item!: object;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;

  	private _onClick (evt: object) {
		if (this.disabled) {
			return;
	  	}

		if (typeof this.onClick === 'function') {
			this.onClick(evt, this.item);
		}
		this.closeActionSheet();
	}
}
