
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE, ROLES } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import MenuGroupList from '@/components/menu-groups/MenuGroupList.vue';
import MenuGroupModal from '@/components/menu-groups/MenuGroupModal.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';

const namespace: string = 'menus';

@Component<MenuGroups>({
	components: {
		SuitesHeader,
		InfoBanner,
		TextButton,
		MenuGroupList,
		MenuGroupModal,
		FullPageSpinner
	}
})
export default class MenuGroups extends Vue {
	@Action('fetchMenuGroups', { namespace }) private fetchMenuGroups!: () => Promise<void>;
	@Action('updateRestaurant', { namespace: 'auth' }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('getMenuGroups', { namespace }) private menuGroups!: MenuGroup[];

	private isDataFetching: boolean = false;
	private isRestaurantUpdating: boolean = false;
	private displayModal: boolean = false;
	private mutableRestaurant: Restaurant = {} as Restaurant;
	private selectedMenuGroup: MenuGroup | undefined = {} as MenuGroup;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;

	private get breadcrumbsArray(): Breadcrumb[] {
		return [
			{
				text: this.$t('advanced.header'),
				to: '/advanced',
				exact: true
			},
			{
				text: this.$t('advanced.menu_groups.header'),
				to: '/advanced/menu-groups',
				exact: true
			}
		];
	}

	/**
	 * Fetch the menu groups if they haven't been fetched before.
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		this.mutableRestaurant = this.restaurant;
		if(!this.mutableRestaurant.marketplace_hub) {
			this.$router.push('/menus').catch(() => {});
		}

		this.isDataFetching = true;
		try {
			await this.fetchMenuGroups();
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.menu_groups.error_fetching', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	/**
	 * Update the restaurant multiple mids flag.
	 *
	 * @return {Promise<void>}
	 */
	private async setRestaurantMultipleMidsFlag(): Promise<void> {
		try {
			this.isRestaurantUpdating = true;
			await this.updateRestaurant({ restaurant: { id: this.mutableRestaurant.id, multiple_mids: this.mutableRestaurant.multiple_mids } });
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.menu_groups.error_updating_location', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.isRestaurantUpdating = false;
			}, 500);
		}
	}

	private showModal(menuGroup?: MenuGroup): void {
		this.selectedMenuGroup = menuGroup;
		this.displayModal = true;
	}
}
