import vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import Component from 'vue-class-component';
import App from './App.vue';
import './filters';
import i18n from './i18n';
import router from './router';
import Auth from './services/auth/auth';
import store from './store';
import { ROLES } from './utils/constants';

Vue.config.productionTip = false;

/**
 * Make sure the user is logged in before entering routes
 * Make sure the user has the role to access specific routes
 * Make sure the menu and/or sections exist before entering specific routes
 * Make sure the suites are enables before entering suites routes
 * Make sure the event and/or suite exist before entering specific routes
 *
 * @param {Route} to - route to go to
 * @param {Route} from - route user is from
 * @param {Route} next - allow to proceed to the route
 * @return {void}
 */
// eslint-disable-next-line max-lines-per-function
router.beforeResolve(async (to, from, next) => {
	let authenticated: boolean = Auth.loggedIn;

	// Silent login for B2B app
	if (window.B2BApp) {
		const accessToken: string | undefined = window.B2BApp.getAccessToken();
		const idToken: string | undefined = window.B2BApp.getIdToken();
		const employeeId: string | undefined = window.B2BApp.getEmployeeId?.();

		if(accessToken && idToken) {
			authenticated = await store.dispatch('auth/loginAppUser', { accessToken, idToken, employeeId });
		}
	}

	if (!authenticated) {
		store.commit('auth/SET_AUTHENTICATED', false);
		store.dispatch('auth/clear');
		if (!['Login', 'LoginCallback'].includes(to.name ?? '')) {
			return next({ path: '/login', replace: true });
		}
		next();
	}
	else {
		store.commit('auth/SET_AUTHENTICATED', true);

		// If user is already logged in, prevent them from going to the form
		if (['Login', 'LoginCallback'].includes(to.name ?? '')) {
			return next({ path: '/menus', replace: true });
		}

		// If the user is a staff member, they should only have access to the create order page
		if ((store.getters['auth/getRoleLevel']) === ROLES.staff && to.name !== 'CreateOrder') {
			return next({ path: '/order-management/create', replace: true });
		}

		// If user is not a manager or better, they should not access those pages.
		if (!(store.getters['auth/getRoleLevel'] <= ROLES.manager) &&
			['Customize', 'Advanced', 'ReplicateMenus', 'MenuGroups'].includes(to.name ?? '')) {
			return next({ path: '/menus', replace: true });
		}

		// If user is not a lead or better, they should not access those pages.
		if (!(store.getters['auth/getRoleLevel'] <= ROLES.lead) &&
			['OrderManagement'].includes(to.name ?? '')) {
			return next({ path: '/menus', replace: true });
		}

		// If menus not fetched, fetch them and wait
		// Don't fetch menus if user is a staff member, they only have access to the create order page which already fetches menus
		if (!store.getters['menus/getFetchMenusComplete'] && store.getters['auth/getRoleLevel'] !== ROLES.staff) {
			await store.dispatch('menus/fetchMenus')
				.catch((errorMessage: string) => {
					store.commit('menus/SET_FETCH_MENUS_ERROR', errorMessage);
				});
		}
		else {
			// Set fetch menus complete to true, to prevent inifinite loading spinner for staff
			store.commit('menus/SET_FETCH_MENUS_COMPLETE', true);
		}

		// Guard to paths with menu and/or section ids
		if (['Sections', 'MenuItems', 'SectionItems'].includes(to.name ?? '')) {
			if (to.params.menu_id) {
				const menu = store.getters['menus/getMenu'](+to.params.menu_id);

				// If path has menu_id but menu doesn't exist, redirect to not found
				if (!menu) {
					return next({ path: '/not-found', replace: true });
				}
				// If path also has section_id, check section exists
				if (menu && to.params.section_id) {
					const section = store.getters['sections/getSection'](+to.params.menu_id, +to.params.section_id);
					if (!section) {
						return next({ path: '/not-found', replace: true });
					}
				}
			}
		}

		// If accessing events page while suites aren't enabled or they don't have the role required, redirect to menus
		if ((!store.getters['auth/areSuitesEnabled'] || !(store.getters['auth/getRoleLevel'] <= ROLES.manager)) &&
			['Events', 'AllEvents', 'EventSettings', 'EventDetails', 'SuiteAssignments', 'SuiteSettings', 'Beo', 'ProductionSheet', 'EventDetailsEdit', 'CreateEvent', 'SuiteOwners', 'EventTypes'].includes(to.name ?? '')) {
			return next({ path: '/menus', replace: true });
		}

		// Only catering restaurants can access the orders report page
		if (to.name === 'OrdersReport' && !store.getters['auth/isCateringRestaurant']) {
			return next({ path: '/menus', replace: true });
		}

		// Only marketplace hubs or locations with the order_management flag can access the order management page
		if (to.name === 'OrderManagement' && !store.getters['auth/isOrderManagementEnabled'] && !store.getters['auth/isMarketplaceEnabled']) {
			return next({ path: '/menus', replace: true });
		}

		// Guard for pages with a event_id and/or suite_id
		if (from.name === null && ['EventDetails', 'SuiteAssignments', 'SuiteSettings', 'Beo', 'ProductionSheet', 'EventDetailsEdit'].includes(to.name ?? '')) {
			if (to.params.event_id) {
				// If no events, fetch and wait
				if (!store.getters['events/getEvents'].length) {
					await store.dispatch('events/fetchEvents');
				}

				// If no event, redirect to not found
				const event: SuitesEvent|undefined = store.getters['events/getEvent'](+to.params.event_id);
				if (!event) {
					return next({ path: '/not-found', replace: true });
				}

				if (event && to.params.suite_id) {
					// If suite_id is not in event_suites array of that event, redirect to not found
					const eventSuite = event.event_suites.find(suite => suite.id === +to.params.suite_id);
					if (!eventSuite) {
						return next({ path: '/not-found', replace: true });
					}
				}
			}
		}

		next();
	}
}),

// Register hooks for route navigation guards
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate'
]);

new Vue({
	vuetify,
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#menu-management');
