var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.fetchingSchedulingInfo)?_c('section',[_c('h2',{staticClass:"title-md mb-4"},[_vm._v(_vm._s(_vm.orderDetails.type === 'take-out' ? _vm.$t('orders.guest_details.takeout.header_takeout') : _vm.$t('orders.guest_details.takeout.header_catering')))]),(_vm.takeout.scheduled)?_c('div',[_c('date-picker',{ref:"datepicker-field",attrs:{"value":_vm.dateSelectorFormat,"id":"datepicker-field","rules":"required","textFieldProps":{
				appendIcon: 'mdi-chevron-down',
				contentClass: 'label-md suites-input',
				label: _vm.$t(`orders.guest_details.takeout.${_vm.orderDetails.takeoutType === 'pickup' ? 'pickup' : 'delivery'}_date_label`),
				name: 'due-by-date',
				outlined: true,
				prependInnerIcon: 'mdi-calendar-month-outline',
			},"datePickerProps":{
				allowedDates: _vm.dateAllowed,
			}},on:{"input":_vm.updateTakeoutDate}}),_c('validation-provider',{attrs:{"mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{class:['suites-input label-md', { 'error-input': errors[0], 'valid': _vm.takeout.dueByTime }],attrs:{"id":"timepicker-field","items":_vm.scheduling.timeOptions,"item-text":"value","label":_vm.$t(`orders.guest_details.takeout.${_vm.orderDetails.takeoutType === 'pickup' ? 'pickup' : 'delivery'}_time_label`),"clearable":false,"prepend-inner-icon":"mdi-clock-time-four-outline","appendIcon":"mdi-chevron-down","menu-props":{ contentClass: 'suites-list' },"error-messages":errors[0],"outlined":""},on:{"change":function($event){return _vm.setTakeoutInformation(_vm.takeout)}},model:{value:(_vm.takeout.dueByTime),callback:function ($$v) {_vm.$set(_vm.takeout, "dueByTime", $$v)},expression:"takeout.dueByTime"}})]}}],null,false,3155451607)})],1):(!_vm.takeout.scheduled)?_c('div',[_c('v-select',{staticClass:"suites-input label-md",attrs:{"value":_vm.$t('orders.guest_details.takeout.asap_label'),"id":"asap-field","items":[_vm.$t('orders.guest_details.takeout.asap_label')],"label":_vm.$t(`orders.guest_details.takeout.${_vm.orderDetails.takeoutType === 'pickup' ? 'pickup' : 'delivery'}_time_label`),"clearable":false,"prepend-inner-icon":"mdi-clock-time-four-outline","appendIcon":"mdi-chevron-down","menu-props":{ contentClass: 'suites-list' },"messages":_vm.$t('orders.guest_details.takeout.estimated_prep_time', { prepTime: _vm.prepTimeString }),"outlined":"","disabled":""},on:{"change":function($event){return _vm.setTakeoutInformation(_vm.takeout)}}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }