import { GetterTree } from 'vuex';
import { SuiteOwnerState, RootState } from '../types';

export const getters: GetterTree<SuiteOwnerState, RootState> = {
	getSuiteOwners(state): SuiteOwner[] {
		return state.suiteOwners;
	},

	getSuiteOwnerById(state) {
		return (id: number): SuiteOwner => {
			return state.suiteOwners.find((suiteOwner: SuiteOwner) => suiteOwner.id === id)!;
		};
	}
};