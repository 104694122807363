
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { ValidationProvider } from 'vee-validate';
import browserLocale from '@/utils/i18n-helpers';
import '@/validation-rules';

@Component<DateTimePicker>({
	components: {
		ValidationProvider
	}
})
export default class DateTimePicker extends Vue {
	@Prop({ type: Object, required: false, default: () => {} }) private textFieldProps!: object;
	@Prop({ type: Object, required: false, default: () => {} }) private datePickerProps!: object;
	@Prop({ type: Object, required: false, default: () => {} }) private timePickerProps!: object;
	@Prop({ type: String, required: false, default: '350' }) private maxWidth!: string;
	@Prop({ type: String, required: false, default: 'yyyy/MM/dd T' }) private displayDateTimeFormat!: string;
	@Prop({ type: String, required: false, default: '' }) private rules!: string;
	@Prop({ type: String, required: false, default: '' }) private id!: string;
	@Prop({ type: String, required: false }) private confirmButtonText!: string;
	@Prop({ type: String, required: false }) private cancelButtonText!: string;
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;
	@Watch('dateAsISO')
	onDateAsISOChange() {
		this.$emit('input', this.dateAsISO);
	}
	@Watch('menu')
	onMenuChange() {
		if (!this.menu) {
			this.$nextTick(() => {
				this.$refs.textField.blur();
			});
		}
	}
	@Watch('$attrs.value')
	onValueChange() {
		if (this.$attrs.value) {
			this.setDateAndTime(this.$attrs.value);
		}
	}

	$refs!: {
		textField: any;
	};

	private menu: boolean = false;
	private date: string = '';
	private time: string = '00:00';
	private activeTab: number = 0;
	private locale: string = browserLocale() || '';

	private created(): void {
		if (this.$attrs.value) {
			this.setDateAndTime(this.$attrs.value);
		}
	}

	private setDateAndTime(date: string): void {
		const dateTime = DateTime.fromISO(date);
		this.date = dateTime.toISODate();
		this.time = dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
	}

	private get dateAsISO(): string {
		if (this.date && this.time) {
			return DateTime.fromISO(this.date + 'T' + this.time).toUTC().toISO();
		}
		return '';
	}

	private get formattedDate(): string {
		if (this.date && this.time) {
			return DateTime.fromISO(this.date + 'T' + this.time).toFormat(this.displayDateTimeFormat);
		}
		return '';
	}

	private dateSelected(date: string): void {
		this.activeTab = 1;
		this.$emit('date-selected', date);
	}

	private timeSelected(date: string): void {
		this.$emit('time-selected', date);
	}

	private handleClear(): void {
		this.date = '';
		this.time = '00:00';
		this.activeTab = 0;
	}

	private handleConfirm(): void {
		this.menu = false;
		this.$emit('input', this.dateAsISO);
	}

	// Keeps the text field focused when the date time picker is open
	private handleBlur(): void {
		if (this.menu) {
			this.$refs.textField.focus();
		}
	}
}
