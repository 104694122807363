
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Tooltip from '@/components/shared/Tooltip.vue';
import AdditionalPrices from './AdditionalPrices.vue';
import PriceAvailability from './PriceAvailability.vue';


@Component<PriceScheduling>({
	components: {
		Tooltip,
		AdditionalPrices,
		PriceAvailability
	}
})
export default class PriceScheduling extends Vue {
	@Prop({ default: '' }) private defaultPrice!: string;
	@Prop({ type: Object, default: () => {} }) private additionalPrices!: object;
	@Prop({ type: Array, default: () => [] }) private priceScheduling!: string[];
	@Prop({ type: Boolean, default: true }) private isPricingAvailabilityAllWeek!: boolean;
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Watch('defaultPrice')
	onDefaultPriceChange() {
		if (this.showPriceSchedule) {
			if (this.mutableAdditionalPrices) {
				Vue.set(this.mutableAdditionalPrices, 'A', this.defaultPrice);
			}
			else {
				this.mutableAdditionalPrices = {
					A: this.defaultPrice
				};
			}
		}
	}

	private mutableAdditionalPrices: IObjectKeys | null = this.additionalPrices ? this.additionalPrices : null;
	private mutablePriceSchedule: string[] | null = this.priceScheduling;
	private showPriceSchedule: boolean = false;

	private created(): void {
		if (this.mutablePriceSchedule) {
			this.mutablePriceSchedule.forEach((priceSchedule: any) => {
				priceSchedule.values.forEach((value: any) => {
					if (value.priceChosen) {
						this.showPriceSchedule = true;
					}
				});
			});

			if (!this.mutableAdditionalPrices) {
				this.mutableAdditionalPrices = {
					A: this.defaultPrice
				};

				// We need to make sure that the item editor component has at least the default
				// price in the object
				this.$emit('set-additional-prices', this.mutableAdditionalPrices);
			}
		}
	}

	private setAdditionalPrices(childAdditionalPrices: IObjectKeys): void {
		this.mutableAdditionalPrices = Object.assign({}, childAdditionalPrices);
		this.$emit('set-additional-prices', this.mutableAdditionalPrices);
	}

	private setPriceAvailability(childPriceAvailability: ScheduleObject[]): void {
		this.$emit('set-price-availability', childPriceAvailability);
	}

	/**
	 * If the switch is set to off, clear the additional prices and schedule
	 * If it is set to true, check if there is additional prices, if not set
	 * the first key pair to the default price.
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private updatePricesAndSchedule(value: boolean): void {
		if (value) {
			this.mutableAdditionalPrices = {
				A: this.defaultPrice
			};
			this.$emit('set-additional-prices', this.mutableAdditionalPrices);
		}
		else {
			this.mutableAdditionalPrices = null;
			this.mutablePriceSchedule = null;
			this.$emit('set-additional-prices', null);
			this.$emit('set-price-availability', null);
		}
	}
}
