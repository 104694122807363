import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
	theme: {
		themes: {
			light: {
				primary: '#003366',
				primaryOpacity25: '#BFCCD9',
				secondary: '#ef4142',
				secondaryOpacity25: '#FBCFD0',
				statusGreen: '#52B498',
				statusGreenOpacity25: '#D1E9E3',
				statusRed: '#D9534F',
				statusRedOpacity25: '#F2DEDE',
				statusYellow: '#F0AD4E',
				statusYellowOpacity25: '#FDF1D9'
			}
		}
	}
};

export default new Vuetify(opts);