import { GetterTree } from 'vuex';
import { OrdersState, RootState } from '../types';
import { decodeString } from '@/utils/decoding';
import i18n from '@/i18n';

export const getters: GetterTree<OrdersState, RootState> = {
	getCurrentOrders(state: OrdersState): CurrentOrders | null {
		return state.currentOrders;
	},

	getSelectedOrder(state: OrdersState): Order | null {
		return state.selectedOrder;
	},

	getModifyingOrder(state: OrdersState): boolean {
		return state.modifyingOrder;
	},

	getOrderType(state: OrdersState): string {
		return state.orderDetails.type;
	},

	getOrderDetails(state: OrdersState): OrderDetails {
		return state.orderDetails;
	},

	getSelectedItem(state: OrdersState): MenuItem | null {
		return state.selectedItem;
	},

	getEditingItem(state: OrdersState): boolean {
		return state.editingItem;
	},

	getOrderItem(state: OrdersState): MenuOrderItem | null {
		return state.orderItem;
	},

	getOrderItemOptions(state: OrdersState): OrderOptionGroup[] | null {
		if (!state.orderItem) {
			return null;
		}
		return state.orderItem.orderOptions as OrderOptionGroup[];
	},

	getOrderItemPrice(state: OrdersState): number {
		return state.orderItem?.orderPrice || 0;
	},

	getCart(state: OrdersState): Cart {
		return state.cart;
	},

	getCartItems(state: OrdersState): MenuOrderItem[] {
		return state.cart.items;
	},

	getCartItemsCount(state: OrdersState): number {
		return state.cart.items.length;
	},

	getCosts(state: OrdersState): any {
		return state.costs;
	},

	getSubtotal(state): string {
		return state.costs.subtotal.toFixed(2);
	},

	getPromoDiscount(state): string {
		return state.costs.discount.amount.toFixed(2);
	},

	isMemberDiscountPreTax(state): boolean {
		return state.costs.memberDiscount && state.costs.memberDiscount.preTax;
	},

	getMemberDiscount(state): string {
		return state.costs.memberDiscount && state.costs.memberDiscount.amount.toFixed(2);
	},

	getVoucherAmount(state): string {
		return state.costs.voucher.amount.toFixed(2);
	},

	getSubtotalDiscounted(state): string {
		return state.costs.subtotalDiscounted.toFixed(2);
	},

	getTaxes(state): string {
		return state.costs.taxes.toFixed(2);
	},

	getTaxBrackets(state): any {
		return state.costs.taxBrackets;
	},

	getServiceCharge(state): string | null {
		if(state.costs.serviceCharge.amount > 0) {
			return state.costs.serviceCharge.amount.toFixed(2);
		}
		return null;
	},

	getServiceChargeLabel(state): string {
		if(state.costs.serviceCharge.label) {
			return state.costs.serviceCharge.label;
		}
		return i18n.t('orders.cart.pricing.service_fee');
	},

	getDeliveryCharge(state): string | null {
		if(state.costs.deliveryCharge.amount > 0) {
			return state.costs.deliveryCharge.amount.toFixed(2);
		}
		return null;
	},

	getTip(state): string {
		return state.costs.tip.amount.toFixed(2);
	},

	getTotal(state): string {
		return state.costs.total.toFixed(2);
	},

	getTotalWithTip(state): string {
		return state.costs.totalWithTip.toFixed(2);
	},

	getCheckoutInfo(state): Checkout {
		return state.checkout;
	},

	getTakeoutInformation(state): Pickup | null {
		return state.checkout.pickup;
	},

	getDeliveryInformation(state): DeliveryInfo | null {
		return state.checkout.delivery;
	},

	getContactInformation(state): CheckoutContactInfo | null {
		return state.checkout.contact;
	},

	getCheckoutAnsweredQuestions(state): AnsweredCustomQuestion[] | undefined | null {
		return state.checkout.questions;
	},

	getNotes(state): string {
		if (state.checkout.pickup?.notes) {
			return decodeString(state.checkout.pickup.notes);
		}
		return '';
	},

	getOrderValidationErrors(state): { [key: string]: string[] }{
		return state.orderValidationErrors;
	}
};