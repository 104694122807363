
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatValue } from '@/utils/valueValidation';
import Tooltip from '@/components/shared/Tooltip.vue';


@Component<AddonEditor>({
	components: {
		Tooltip
	}
})
export default class AddonEditor extends Vue {
	@Prop({ type: Array, default: true }) private pricingItems!: [];
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;
	@Prop({ type: Boolean, default: false }) private customQuantities!: Boolean;

	private allowCustomQuantities = this.customQuantities;

	private get pricingArray() {
		if (this.disabled) {
			return this.pricingItems.filter((item: any) => (item.price));
		}
		return this.pricingItems;
	}

	private get cols() {
		return this.allowCustomQuantities ? { md: 3, xs: 6 } : { md: 4, xs: 4 };
	}

	private formatPricingItemPrice = (price: any) => formatValue(price, '', 'price');

	private formatPricingItemCalories = (calories: number) => formatValue(calories, null, 'calories');

	private addAddOnForm (): void {
		this.$emit('add-pricing-item');
	}

	private removeAddOnForm (index: number): void {
		this.$emit('remove-pricing-item', index);
	}

	private updateCustomQuantities(): void {
		if(!this.allowCustomQuantities) {
			this.pricingItems.forEach((item: any) => {
				item.max_quantity = 1;
			});
		}

		this.$emit('update-custom-quantities', this.allowCustomQuantities);
	}
}
