import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { EventSuiteState, RootState } from '../types';
const namespaced: boolean = true;

export const state: EventSuiteState = {
	eventSuites: [],
	selectedEventSuite: null
};


export const EventSuites: Module<EventSuiteState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default EventSuites;