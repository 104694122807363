
import { Component, Prop, Vue } from 'vue-property-decorator';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';

@Component<RequestDepositModal>({
	components: {
		StandardModal,
		TextButton,
		InfoBanner
	}
})
export default class RequestDepositModal extends Vue {
	@Prop({ type: String, required: true }) private email!: string;
	@Prop({ type: Boolean, required: true }) private loading!: boolean;

	private amount: number | null = null;

	/**
	 * Emit to request a deposit
	 *
	 * @return {void}
	 */
	private requestDeposit(): void {
		this.$emit('request-deposit', +this.amount!);
	}
}
