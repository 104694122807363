
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { Validate } from '@/types';
import Availability from '@/components/menu/form/Availability.vue';

const namespace: string = 'menus';

@Component<MenuHours>({
	components: {
		ValidationObserver,
		Availability
	}
})
export default class MenuHours extends Vue {
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Prop({ type: Function, required: true }) private showToast!: (responseObj: any) => void;

	private showSaveBtn: boolean = false;
	private menuSelected: any = null;

	$refs!: {observer: Validate};

	private mounted(): void {
		this.positionSaveButton();
	}

	/**
	 * Position save button depending on the drawer size when changing
	 * menu tab
	 *
	 * @return {void}
	 */
	private positionSaveButton(): void {
		this.showSaveBtn = false;
		setTimeout(() => {
			const drawer = document.getElementById('nav-drawer');
			if(drawer) {
				document.documentElement.style.setProperty('--dynamic-btn-left-value', `${drawer.offsetWidth}px`);
			}
			this.showSaveBtn = true;
		}, 400);
	}
}
