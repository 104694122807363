import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import menus from './menus/menus';
import sections from './sections/sections';
import items from './items/items';
import options from './options/options';
import auth from './auth/auth';
import search from './search/search';
import suites from './suites/suites';
import events from './events/events';
import eventSuites from './event-suites/event-suites';
import eventTypes from './event-types/event-types';
import suiteOwners from './suite-owners/suite-owners';
import equipment from './equipment/equipment';
import changeHistory from './change-history/change-history';
import orders from './orders/orders';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
	modules: {
		auth,
		menus,
		sections,
		items,
		options,
		search,
		suites,
		events,
		eventSuites,
		eventTypes,
		suiteOwners,
		equipment,
		changeHistory,
		orders
	}
};
export default new Vuex.Store<RootState>(store);