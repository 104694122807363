
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';

const namespace: string = 'orders';

@Component<ContactInformation>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})
export default class ContactInformation extends Vue {
	@Action('setContactInformation', { namespace }) setContactInformation!: (contact: Contact) => void;
	@Getter('getContactInformation', { namespace }) contactInfo!: Contact;
	@Getter('getOrderType', { namespace }) orderType!: string;
	@Getter('getContactFieldsConfig', { namespace: 'auth' }) contactFieldsConfig!: ContactFieldsConfig;
	@Watch('contact.phone_number')
	onPhoneNumberInput(value: string, prevValue: string): void {
		if (this.contact) {
			// If the user typed more than 1 character in 1 input, could be autofill
			// TODO: Need a better way to handle autofill/autocomplete and area codes in the future
			this.contact.phone_number = this.formatPhoneNumber(this.contact.phone_number, (value.length - prevValue.length) > 1);
		}
	}

	private contact: Contact = {
		full_name: '',
		email: '',
		phone_number: ''
	};

	$refs!: {
		observer: Validate
	};

	private created(): void {
		if (this.contactInfo) {
			this.contact = this.contactInfo;
		}
	}

	/**
	* Format the phone number - remove non-digital characters and add dashes to the respective positions.
	* Remove the +1 area code if autofill.
	* TODO: This is only checking for autofills with +1 area codes, not the best in the long term
	*
	* @param {string} phoneNumber
	* @param {boolean} mayBeAutofill - is the input potentially from autofill
	* @return {string}
	*/
	private formatPhoneNumber(phoneNumber: string, mayBeAutofill: boolean = false): string {
		let temp = phoneNumber.replace(/\D/g, '');
		if (mayBeAutofill && temp.length === 11 && temp[0] === '1') {
			temp = temp.substring(1);
		}
		if (temp) {
			return temp.match(/\d{3}(?=\d{2,3})|\d+/g)?.join('-') || '';
		}
		return '';
	}
}
