
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<SettingDescriber>({})
export default class SettingDescriber extends Vue {
	@Prop({ type: String, default: '' }) private header!: string;
	@Prop({ type: String, default: '' }) private description!: string;
	@Prop({ type: String, default: '' }) private extraInformation!: string;
	@Prop({ type: String, default: 'mdi-information-outline' }) private extraInformationIcon!: string;
}
