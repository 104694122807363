
import { Component, Prop, Vue } from 'vue-property-decorator';
import SettingDescriber from '@/components/shared/SettingDescriber.vue';

@Component<CustomMenuBrowseStyles>({
	components: {
		SettingDescriber
	}
})
export default class CustomMenuBrowseStyles extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetMenuConfig!: MenuConfiguration;

	private mutableMenuConfiguration: MenuConfiguration = this.targetMenuConfig;
	private gridView: boolean = !!(this.mutableMenuConfiguration.browse_menu_style && this.mutableMenuConfiguration.browse_menu_style === 'gallery');;

	private changeBrowseStyle(value: boolean): void {
		value ? Vue.set(this.mutableMenuConfiguration, 'browse_menu_style', 'gallery') : Vue.set(this.mutableMenuConfiguration, 'browse_menu_style', 'list');
	}
}
