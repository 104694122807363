import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { MenuState, RootState } from '../types';
const namespaced: boolean = true;

export const state: MenuState = {
	menuGroups: [],
	menus: [],
	publishedMenus: [],
	selectedMenu: {},
	fetchMenusError: '',
	fetchMenusComplete: false,
	pulledLocationName: null
};


export const Menus: Module<MenuState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Menus;