
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';

@Component<CsvImportModal>({
	components: {
		StandardModal
	}
})
export default class CsvImportModal extends Vue {
	@Action('importCsv', { namespace: 'menus' }) private importCsv!: (payload: { restaurantId: string, file: File }) => Promise<{ [key: string]: number }>;
	@Prop({ type: String, required: true }) private restaurantId!: string;

	private isLoading: boolean = false;
	private showSummary: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private file: File | null = null;
	private importSummary: { [key: string]: number } = {
		created: 0,
		skipped: 0,
		errors: 0
	};

	private async importFile(): Promise<void> {
		if (this.file?.type !== 'text/csv') {
			return;
		}

		this.isLoading = true;
		this.showSummary = false;
		try {
			this.importSummary = await this.importCsv({
				restaurantId: this.restaurantId,
				file: this.file!
			});
			setTimeout(() => {
				this.isLoading = false;
				this.showSummary = true;
				this.file = null;
			}, 500);
		}
		catch (errorMessage) {
			setTimeout(() => {
				this.isLoading = false;
				this.bannerToastInfo.color = '#8E0D2A';
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('csv_import.error_import', { errorMessage });
			}, 500);
		}
	}
}
