
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component<QuantityInput>({})
export default class QuantityInput extends Vue {
	@Prop({ type: String, required: true }) private id!: string;
	@Prop({ type: Number, required: true }) private quantity!: number;
	@Prop({ type: Boolean, required: false, default: false }) private disabled!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private disableDecrement!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private disableIncrement!: boolean;
	@Prop({ type : String, default: 'label-md' }) private valueClass!: string;
}
