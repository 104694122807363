
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TOAST_INSTANCE } from '@/utils/constants';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import EventDetailsForm from '@/components/suites/events/EventDetailsForm.vue';

@Component<CreateEvent>({
	components: {
		EventDetailsForm,
		SuitesHeader,
		ValidationObserver,
		ValidationProvider
	}
})
export default class CreateEvent extends Vue {
	@Action('createEvent', { namespace: 'events' }) private createEvent!: Function;

	private newEvent: SuitesEvent | null = null;
	private loading: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private async handleCreate(formData: SuitesEvent) {
		this.loading = true;

		try {
			const createdEvent = await this.createEvent(formData);
			this.loading = false;

			if (createdEvent && !createdEvent.event_suites.length) {
				this.$router.push(`/events/${createdEvent.id}`);
			}
			else if (createdEvent) {
				this.$router.push(`/events/${createdEvent.id}/suites`);
			}
		}
		catch (errorMessage) {
			this.loading = false;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('create_event.create_event_error', { errorMessage });
		}
	}
}
