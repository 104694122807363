import Auth from '@/services/auth/auth';
import axios, { AxiosInstance } from 'axios';
import { ActionTree } from 'vuex';
import { handleAllErrors } from '../../utils/errorHandling';
import { AuthState, RootState } from '../types';
import { state } from './auth';
import { decodeGroupedOrderItems, decodeOrder } from '@/utils/decoding';
import { ROLES } from '@/utils/constants';
import i18n from '@/i18n';

export const actions: ActionTree<AuthState, RootState> = {

	/**
	 * Update axios instance with the tenant id and token
	 *
	 * @return {void}
	 */
	updateAxiosInstance({ commit, dispatch }): void {
		const axiosInst = axios.create({
			baseURL: process.env.VUE_APP_API_AUDIENCE,
			headers: {
				'X-RESTAURANT-ID': state.tenantId!,
				'X-STAFF-EMAIL': state.staffEmail!,
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${state.accessToken}`,
				'Locale': i18n.locale
			}
		});
		// Setup an interceptor to rebuild the local menu when a post, put or delete request is made
		if (process.env.NODE_ENV === 'development') {
			axiosInst.interceptors.response.use(response => {
				if (response.statusText === 'OK' && response.config && (response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'delete')) {
					dispatch('menus/buildLocalMenu', state.restaurant.id, { root: true });
				}
				return response;
			}, error => {
				return Promise.reject(error);
			});
		}
		commit('SET_AXIOS_INSTANCE', axiosInst);
	},

	/**
	 * Set restaurants info needed from the backend
	 *
	 * @return {Promise<void>}
	 */
	async getRestaurantsInfo({ commit }, authRestaurants: AuthRestaurants[]): Promise<void> {
		const axiosInst: AxiosInstance = state.axiosInst;
		try {
			const restaurants = await axiosInst.post('/restaurants/list', authRestaurants);
			commit('SET_RESTAURANTS', JSON.stringify(restaurants.data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant
	 *
	 * @return {Promise<void>}
	 */
	async fetchRestaurant({ commit }, { tenantId }): Promise<void> {
		const axiosInst: AxiosInstance = state.axiosInst;
		try {
			if(state.accessToken && state.tenantId) {
				const restaurant = await axiosInst.get(`/restaurants/${tenantId}`);
				commit('SET_RESTAURANT', restaurant.data);
				commit('SET_POS_INTEGRATED');
			}
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update a restaurant
	 *
	 * @param {Restaurant} restaurant
	 * @return {Promise<void>}
	 */
	async updateRestaurant({ commit }, { restaurant }): Promise<void> {
		const axiosInst: AxiosInstance = state.axiosInst;
		try {
			const restaurantUpdated: Restaurant = (await axiosInst.put(`/restaurants/${restaurant.id}`, { ...restaurant, force_publish: true })).data;
			commit('SET_RESTAURANT', restaurantUpdated);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Upload image to S3 and update the a restaurant's image accordingly
	 *
	 * @return {Promise<void>}
	 */
	async uploadRestaurantImage ({ }, { restaurant, image }) {
		const axiosInst: AxiosInstance = state.axiosInst;

		const formData = new FormData();
		formData.append('file', image[0], image[0].name);

		try {
			await axiosInst.post(`/restaurants/${restaurant.id}/image`, formData, {
				headers: {
					'Content-Disposition': `form-data; name="file"; filename="${image[0].name}"`,
					'Content-Type': 'multipart/form-data'
				}
			});
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Set the tenantId
	 *
	 * @param {LoginCredentials} credentials
	 * @return {Promise<void>}
	 */
	async setTenant({ commit, dispatch }, tenantId: string): Promise<void> {
		commit('SET_TENANT_ID', tenantId);
		Auth.setTenantIdInStorage(tenantId);
		dispatch('updateAxiosInstance');
		if (state.roleLevel !== ROLES.staff) {
			await dispatch('menus/fetchMenus', {}, { root: true });
		}
	},

	/**
	 * Login the user with credentials
	 *
	 * @param {LoginCredentials} credentials
	 * @return {Promise<void>}
	 */
	login({ }, credentials: LoginCredentials): Promise<void> {
		return Auth.login({ username: credentials.username!, password: credentials.password! });
	},

	/**
	 * Login the user with tokens provided from the app by injection
	 *
	 * @param {LoginTokens} tokens
	 * @return {Promise<void>}
	 */
	async loginAppUser({ commit }, tokens: LoginTokens): Promise<boolean> {
		const isAuthenticated = await Auth.loginAppUser({ accessToken: tokens.accessToken, idToken: tokens.idToken });
		commit('SET_AUTHENTICATED', isAuthenticated);
		commit('SET_EMPLOYEE_ID', tokens.employeeId);
		return isAuthenticated;
	},


	/**
	 * Authenticate the logged in user with info from url
	 *
	 * @return {Promise<void>}
	 */
	async authenticate({ dispatch, commit }): Promise<void> {
		try {
			const loggedInEmail = await Auth.handleAuthentication();
			commit('SET_STAFF_EMAIL', loggedInEmail);
			commit('SET_AUTHENTICATED', true);
			dispatch('updateAxiosInstance');
		}
		catch (error) {
			commit('SET_AUTHENTICATED', false);
			throw error;
		}
	},

	/**
	 * Send reset password request
	 *
	 * @param {string} email
	 */
	async resetPassword({ }, email: string): Promise<any> {
		return await new Promise((resolve, reject) => {
			Auth.resetPassword(email)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},

	/**
	 * Logout the user and clear all storage
	 *
	 * @return {void}
	 */
	logout({ commit }): void {
		commit('authenticated', false);
		Auth.logout();
	},

	/**
	 * Clear tokens and user info from local storage,
	 * cookies and vuex store
	 *
	 * @return {void}
	 */
	clear({ commit, dispatch }): void {
		Auth.clear();
		commit('SET_ACCESS_TOKEN', null);
		commit('SET_ID_TOKEN', null);
		commit('SET_EXPIRES_AT', null);
		commit('SET_AUTHENTICATED', false);
		// TODO - add a prop to the user metadata so they can choose their default location
		commit('SET_TENANT_ID', null);
		commit('SET_RESTAURANTS', null);
		commit('SET_STAFF_EMAIL', null);
		dispatch('updateAxiosInstance');
	},

	/**
	 * Clear single sign on from local storage,
	 * cookies and vuex store
	 *
	 * @return {void}
	 */
	clearSSO({ commit }): void {
		Auth.clearSSO();
		commit('SET_LAST_LOGIN', null);
	},

	/**
	 * Fetch orders report of the restaurant
	 *
	 * @param {[string, string]} dateRange
	 * @return {Promise<{ groupedItems: OrderItemGroup[], orders: EventSuiteOrder[]} | undefined>}
	 */
	async fetchOrdersReport({}, dateRange: [string, string]): Promise<{ groupedItems: OrderItemGroup[], orders: EventSuiteOrder[]} | undefined> {
		const axiosInst: AxiosInstance = state.axiosInst;
		try {
			const { data } = await axiosInst.get<{ groupedItems: OrderItemGroup[], orders: EventSuiteOrder[]}>('/orders/report', { params: {
				startDate: dateRange[0],
				endDate: dateRange[1]
			} });
			return {
				groupedItems: decodeGroupedOrderItems(data.groupedItems),
				orders: data.orders.map(decodeOrder)
			};
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};