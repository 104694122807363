import { formatValue } from '@/utils/valueValidation';

export function formatSection(menuSection: MenuSection): MenuSection {
	menuSection = {
		id: menuSection.id ? menuSection.id : undefined,
		name: menuSection.name,
		slug: menuSection.slug,
		description: menuSection.description,
		type: 'a-la-carte',
		price: formatValue(+menuSection.price, '0.00', 'price') as string,
		localization: menuSection.localization ? menuSection.localization : undefined
	};
	return menuSection;
}

export function formatMenu(menu: Menu): Menu {
	menu = {
		id: menu.id ? menu.id : undefined,
		allow_sections: menu.allow_sections,
		image: menu.image,
		description: menu.description,
		dine_in: menu.dine_in,
		max_orders_per_interval: menu.max_orders_per_interval ? parseInt((menu.max_orders_per_interval as any)) : 0,
		name: menu.name,
		on_demand: menu.on_demand,
		ordering_interval: menu.ordering_interval,
		last_call_time: menu.last_call_time,
		estimated_prep_time: menu.estimated_prep_time,
		price: formatValue(+menu.price, '0.00', 'price') as string,
		required_notice: menu.required_notice,
		scheduled: menu.scheduled,
		member_only: menu.member_only,
		access_level: menu.access_level ? menu.access_level : 'unrestricted',
		slug: menu.slug,
		type: 'a-la-carte',
		localization: menu.localization ? menu.localization : undefined,
		published_at: menu.published_at
	};
	return menu;
}

export function formatItem(menuItem: MenuItem): MenuItem {
	menuItem = {
		id: menuItem.id,
		section_id: menuItem.section_id ? menuItem.section_id : undefined,
		menu_id: menuItem.menu_id,
		name: menuItem.name,
		description: menuItem.description,
		ingredients: menuItem.ingredients,
		slug: menuItem.slug,
		sku: menuItem.sku,
		category: menuItem.category,
		calories: menuItem.calories,
		price: formatValue(+menuItem.price, '0.00', 'price') as string,
		price_unit: menuItem.price_unit,
		price_type: menuItem.price_type,
		image: menuItem.image,
		includes: menuItem.includes,
		allergens: menuItem.allergens,
		diets: menuItem.diets,
		is_hidden:  menuItem.is_hidden,
		sold_out: menuItem.sold_out,
		featured_item: menuItem.featured_item,
		upsell_item: menuItem.upsell_item,
		auto_restock: menuItem.auto_restock,
		alcoholic_beverage: menuItem.alcoholic_beverage,
		order: menuItem.order,
		additional_prices: menuItem.additional_prices,
		price_schedule: menuItem.price_schedule,
		tax_rebate_eligibility: menuItem.tax_rebate_eligibility,
		tax_rate: menuItem.tax_rate,
		localization: menuItem.localization ? menuItem.localization : undefined,
		published_at: menuItem.published_at
	};
	return menuItem;
}

export function formatOptionGroup (addOnOptionGroup: OptionGroup): OptionGroup {
	return {
		id: addOnOptionGroup.id ? addOnOptionGroup.id : undefined,
		name: addOnOptionGroup.name,
		type: addOnOptionGroup.type || 'addons',
		allow_multiple_selection: !!addOnOptionGroup.allow_multiple_selection,
		required: !!addOnOptionGroup.required,
		limit: typeof addOnOptionGroup.limit === 'number' ? addOnOptionGroup.limit : null,
		description: addOnOptionGroup.description,
		custom_quantities: addOnOptionGroup.custom_quantities,
		localization: addOnOptionGroup.localization ? addOnOptionGroup.localization : undefined
	};
}

export function formatOrderItem(item: MenuItem | OrderItem): MenuOrderItem {
	return {
		id: item.id,
		menu_id: item.menu_id,
		section_id: item.section_id,
		name: item.name,
		localization: item.localization,
		slug: item.slug,
		sku: item.sku ? item.sku : null,
		category: item.category ? item.category : null,
		price: item.price,
		orderPrice: item.orderPrice ?? +(item.price || 0),
		alcoholic_beverage: item.alcoholic_beverage,
		tax_rebate_eligibility: item.tax_rebate_eligibility ? item.tax_rebate_eligibility : 'eligible',
		quantity: item.quantity ? item.quantity : 1,
		description: item.description,
		ingredients: item.ingredients,
		price_unit: item.price_unit,
		price_type: item.price_type,
		image: item.image,
		auto_restock: item.auto_restock,
		order: item.order,
		orderOptions: item.options ? item.options.map((optionGroup: OptionGroup) => formatOrderOptionGroup(optionGroup)) : []
	};
}

/**
* Formats the option group to the OrderOptionGroup type
*
* @param {OptionGroup} optionGroup
* @return {OrderOptionGroup}
*/
export function formatOrderOptionGroup(optionGroup: OptionGroup): OrderOptionGroup {
	return {
	   id: optionGroup.id,
	   name: optionGroup.name,
	   localization: optionGroup.localization,
	   optionGroupSku: optionGroup.sku ? optionGroup.sku : null,
	   optionGroupType: optionGroup.type,
	   requiredOption: optionGroup.required,
	   multipleOption: optionGroup.allow_multiple_selection,
	   customQuantities: optionGroup.custom_quantities,
	   limit: optionGroup.limit || null,
	   order: optionGroup.order,
	   memo: false,
	   values: optionGroup.values ? formatOrderOptions(optionGroup.values) : []
	};
}

/**
* Formats the option group to the OrderOptionGroup type
*
* @param {Option[]} options
* @return {OrderOption[]}
*/
export function formatOrderOptions(options: Option[]): OrderOption[] {
	return options.map((option: Option) => {
	   return {
		   id: option.id,
		   name: option.name,
		   localization: option.localization,
		   calories: option.calories,
		   product_id: option.product_id ? option.product_id : null,
		   sku: option.sku ? option.sku : null,
		   category: option.category ? option.category : null,
		   price: option.price,
		   tax_rate: option.tax_rate ? option.tax_rate : null,
		   promos: option.promos,
		   roll_up: option.roll_up,
		   order: option.order,
		   quantity: typeof option.quantity === 'number' ? option.quantity : 0,
		   max_quantity: option.max_quantity,
		   default_selection: option.default_selection,
		   options: option.sub_group
		   		? [formatOrderOptionGroup(option.sub_group)]
				: option.options
					? option.options.map((optionGroup: OptionGroup) => formatOrderOptionGroup(optionGroup))
					: []
	   };
	});
}

/**
 * Format the order options for comparision to increase quantity or not. We are formatting time
 * to compare them with JSON.stringify. The reason we are doing this is because we have multiple
 * identifiers that may or may not change in the future, this is the easiest way to future proof
 * any changes.
 *
 * @param {OrderOptionGroup[] | null | undefined} orderOptions
 * @return {Omit<OrderOptionGroup, 'price'>[] | undefined}
 */
export function formatOrderOptionsToCompare(orderOptions: OrderOptionGroup[] | null | undefined): OrderOptionGroup[] | undefined {
	if(orderOptions?.length) {
		// Order option groups
		const tempOrderOptionGroups = orderOptions!.map((orderOptionGroup: OrderOptionGroup) => {

			// Options
			const tempOptions = orderOptionGroup.values!.map((option: OrderOption) => {
				let tempSubOptionGroups = option.options;

				// Only compare sub options if there are selected sub options
				const hasSelectedSubOptions = tempSubOptionGroups?.some((subOptionGroup: OrderOptionGroup) => subOptionGroup.values.length);

				// Sub options
				if (hasSelectedSubOptions) {
					tempSubOptionGroups = formatOrderOptionsToCompare(tempSubOptionGroups);
				}

				// Return options
				return {
					id: option.id,
					sku: option.sku,
					name: option.name,
					localization: option.localization,
					options: hasSelectedSubOptions ? tempSubOptionGroups : undefined,
					quantity: option.quantity,
					price: option.price
				};
			});

			// Return order option group
			return {
				id: orderOptionGroup.id,
				name: orderOptionGroup.name,
				optionGroupType: orderOptionGroup.optionGroupType,
				optionGroupSku: orderOptionGroup.optionGroupSku,
				localization: orderOptionGroup.localization,
				memo: orderOptionGroup.memo,
				values: tempOptions
			};
		});
		return tempOrderOptionGroups;
	}
	return undefined;
}