
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { ROLES, TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent } from '@/utils/decoding';
import EventDetailsTable from '@/components/suites/events/EventDetailsTable.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';
import PastEventDetailsTable from '@/components/suites/events/PastEventDetailsTable.vue';
import EmailPreOrderModal from '@/components/suites/EmailPreOrderModal.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import StatusLabel from '@/components/shared/suites/StatusLabel.vue';
import ChangeHistoryModal from '@/components/shared/ChangeHistoryModal.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';

const namespace: string = 'events';

@Component<EventDetails>({
	components: {
		EventDetailsTable,
		FullPageSpinner,
		PastEventDetailsTable,
		EmailPreOrderModal,
		ChangeHistoryModal,
		SuitesHeader,
		TextButton,
		StatusLabel,
		WarningModal
	}
})
export default class EventDetails extends Vue {
	@Action('fetchEvent', { namespace }) private fetchEvent!: (eventId: string) => Function;
	@Action('finalizeEventDayOrders', { namespace }) private finalizeEventDayOrders!: (eventId: number) => Promise<void>;
	@Getter('getSelectedEvent', { namespace }) private event!: SuitesEvent;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;

	private decodedEvent: SuitesEvent | null = null;
	private eventSuiteToSendPreOrder: EventSuite | null = null;
	private isDataFetching: boolean = false;
	private isFinalizingOrders: boolean = false;
	private showChangeHistory: boolean = false;
	private showFinalizeOrdersModal: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;
	private changeHistoryOptions: ChangeHistoryFetchOptions = {
		type: 'event',
		type_id: Number(this.$route.params.event_id)
	};

	// Formatted event date for the pre-order email schedule section
	private get preOrderEmailDate() {
		return DateTime.fromISO(this.event.start_date).minus({ days: this.event.pre_order_email_days }).toISO();
	}

	private get breadcrumbsArray(): Breadcrumb[] {
		if (!this.decodedEvent) {
			return [];
		}
		return [
			{
				text: this.$t('events.header'),
				to: '/events',
				exact: true
			},
			{
				text: `${this.decodedEvent.title} (${Vue.filter('formatDate')(this.event.start_date)})`,
				to: `/events/${this.event.id}`,
				exact: true
			}
		];
	}

	private get isPastEvent(): boolean {
		return this.event.status === 'past_event';
	}

	// Calculates the time until the pre-order lock date and returns time left in weeks, days, and hours
	private get timeToPreOrderLock(): string {
		const today = DateTime.now();
		const preOrderLockDate = DateTime.fromISO(this.event.start_date).minus({ days: this.event.order_lock_period });

		const timeUntilLock = preOrderLockDate.diff(today, ['weeks', 'days', 'hours']).toObject();

		let formattedTimeUntilLock = '';
		if (timeUntilLock.weeks) {
			formattedTimeUntilLock += `${timeUntilLock.weeks}${this.$t('event_details.week_abbr')}, `;
		}
		if (timeUntilLock.days) {
			formattedTimeUntilLock += `${timeUntilLock.days}${this.$t('event_details.day_abbr')}, `;
		}
		if (timeUntilLock.hours) {
			formattedTimeUntilLock += `${Math.floor(timeUntilLock.hours)}${this.$t('event_details.hour_abbr')}`;
		}
		else {
			return '';
		}

		return `${formattedTimeUntilLock} ${this.$t('event_details.left')}`;
	}

	// Counts and groups event suite
	private get suiteStatuses(): SuiteStatus[] {
		if (!this.event.event_suites.length) {
			return [];
		}
		return this.event.event_suites.reduce((acc: SuiteStatus[], eventSuite: EventSuite) => {
			if ((eventSuite.status === 'email_scheduled' || eventSuite.status === 'email_sent') || (this.isPastEvent && (eventSuite.status !== 'completed' && eventSuite.status !== 'accepted' && eventSuite.status !== 'pending' && eventSuite.status !== 'cancelled' && eventSuite.status !== 'refunded'))) {
				return acc;
			}
			if (acc.findIndex((statuses) => eventSuite.status === statuses.status) === -1) {
				acc.push({
					quantity: 1,
					status: eventSuite.status
				});
			}
			else {
				acc[acc.findIndex((statuses) => eventSuite.status === statuses.status)].quantity++;
			}
			return acc;
		}, []);
	}

	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			if (!this.event || Number(this.$route.params.event_id) !== this.event.id) {
				await this.fetchEvent(this.$route.params.event_id);
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_details.error_fetching_event', { errorMessage });
		}
		finally {
			if (this.event) {
				this.decodedEvent = decodeEvent(this.event);
			}

			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	/**
	 * Opens pre-order modal when send email button is clicked
	 *
	 * @param {EventSuite} eventSuite - event suite of the suite owner to send email to
	 * @returns {void}
	 */
	private handleSendEmail(eventSuite: EventSuite): void {
		this.eventSuiteToSendPreOrder = eventSuite;
	}

	/**
	 * Updates decoded event event when pre-order email is sent
	 *
	 * @returns {void}
	 */
	private handleEmailSent(): void {
		this.decodedEvent = decodeEvent(this.event);
		this.eventSuiteToSendPreOrder = this.decodedEvent.event_suites.find((eventSuite) => eventSuite.id === this.eventSuiteToSendPreOrder?.id) || null;
	}

	/**
	 * Handle error when sending pre-order email from pre-order modal
	 *
	 * @param {string} errorMessage - error message from the backend
	 * @returns {void}
	 */
	private handleEmailError(errorMessage: string): void {
		this.bannerToastInfo.showMessage = true;
		this.bannerToastInfo.message = this.$t('event_details.error_sending_email', { errorMessage });
	}

	/**
	 * Finalize event day orders for all event suites for selected event
	 * WIP: Backend doesn't support this feature yet, this is here for a future improvement
	 *
	 * @return {void}
	 */
	private async handleFinalizeOrders() {
		this.isFinalizingOrders = true;
		try {
			throw new Error('Not implemented');
			await this.finalizeEventDayOrders(this.event.id);
			this.showFinalizeOrdersModal = false;
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_details.error_finalizing_orders', { errorMessage });
		}
		finally {
			this.isFinalizingOrders = false;
		}
	}
}
