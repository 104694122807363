
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrderItem from './OrderItem.vue';

@Component<OrderItemsList>({
	components: {
		OrderItem
	}
})
export default class OrderItemsList extends Vue {
	@Prop({ type: Array, required: true }) items!: OrderItemGroup[];
}
