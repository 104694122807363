
import { DateTime } from 'luxon';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

const namespace: string = 'menus';

@Component<PageLoader>({})
export default class PageLoader extends Vue {
	@Getter('pulledLocationName', { namespace }) private pulledLocationName!: string;
	@Watch('pulledLocationName', { immediate: true })
	onpulledLocationName() {
		if(this.pulledLocationName) {
			this.secondsCounter = 1;
			this.interval = setInterval(() => {
				this.secondsCounter++;
				this.secondsFormatted = DateTime.fromSeconds(this.secondsCounter).toFormat('mm:ss');
			}, 1000);
		}
		else {
			clearInterval(this.interval);
		}
	}

	private secondsCounter: number = 1;
	private secondsFormatted: string = DateTime.fromSeconds(1).toFormat('mm:ss');
	private interval: number = 0;
}
