
import { Component, Vue, Prop } from 'vue-property-decorator';
import SuiteOwnerModal from '@/components/suites/SuiteOwnerModal.vue';

@Component<EventSuiteListItem>({
	components: {
		SuiteOwnerModal
	}
})
export default class EventSuiteListItem extends Vue {
	@Prop({ type: Object, required: true }) private event!: SuitesEvent;
	@Prop({ type: Object, required: true }) private eventSuite!: EventSuite;
	@Prop({ type: Array, required: true }) private suiteOwners!: SuiteOwner[];
	@Prop({ type: Array, required: true }) private menus!: Menu[];
	@Prop({ type: Function, required: true }) private openDeleteModal!: (suiteId: number) => void;

	private showSuiteOwnerModal: boolean = false;

	private get selectedMenuIds(): number[] {
		return this.eventSuite.menus.map(menu => menu.id);
	}

	private get publishedAndSelectedMenus(): Menu[] {
		return this.menus.filter(menu => menu.published_at || this.eventSuite.menus.some(eventMenu => eventMenu.id === menu.id));
	}

	/**
	 * Handle changes to menus assignments
	 *
	 * @param {number[]} menuIds Array selected menu ids
	 * @returns {void}
	 */
	private handleMenusChange(menuIds: number[]) {
		const newMenuSelections: EventSuiteMenu[] = [];

		// get the event suite menu ids for reuse
		const availableEventSuiteMenuIds = this.eventSuite.menus
			.filter(menu => menu.event_suite_menu_id)
			.map(menu => menu.event_suite_menu_id);

		menuIds.forEach((menuId: number, index: number) => {
			const menu = this.menus.find(menu => menu.id === menuId);
			if (menu) {
				newMenuSelections.push({
					id: menuId,
					name: menu.name,
					event_suite_menu_id: availableEventSuiteMenuIds[index] || null,
					slug: menu.slug
				});
			}
		});

		this.eventSuite.menus = newMenuSelections;
		this.$emit('menu-change', { menus: newMenuSelections, eventSuiteId: this.eventSuite.id });
	}

	private handleSuiteOwnerCreate(suiteOwner: SuiteOwner): void {
		this.$emit('suite-owner-change', { suiteOwnerId: suiteOwner.id, eventSuiteId: this.eventSuite.id });
		this.showSuiteOwnerModal = false;
	}

	private openNewSuiteOwnerModal(): void {
		this.showSuiteOwnerModal = true;
	}
}
