
import { Component, Vue } from 'vue-property-decorator';
import SettingsList from '@/components/SettingsList.vue';

@Component<Settings>({
	components: {
		SettingsList
	}
})
export default class Settings extends Vue {

	private mounted(): void {

	}
}
