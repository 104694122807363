
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextButton from '@/components/shared/suites/TextButton.vue';

@Component<SelectableAddOnOptionGroupsList>({
	components: {
		TextButton
	}
})
export default class SelectableAddOnOptionGroupsList extends Vue {
	@Prop({ type: Array, default: [] }) private allAddOnOptionGroups!: OptionGroup[];
	@Prop({ type: Array, default: [] }) private itemAddOnOptionGroupIds!: number[];
	@Prop({ type: Boolean, default: false }) private disabled!: Boolean;

	private mutableItemAddOnOptionGroupIds: number[] = this.itemAddOnOptionGroupIds;
	private expandedOptionGroupIds: number[] = [];

	private get sortedAddOnOptionGroups (): OptionGroup[] {
		const selectedOptionGroups: OptionGroup[] = [];
		const unselectedOptionGroups: OptionGroup[] = [];

		this.allAddOnOptionGroups.forEach((addOnOptionGroup: OptionGroup) => {
			if (addOnOptionGroup.id && this.itemAddOnOptionGroupIds.includes(addOnOptionGroup.id)) {
				selectedOptionGroups.push(addOnOptionGroup);
			}
			else {
				unselectedOptionGroups.push(addOnOptionGroup);
			}
		});

		return [...selectedOptionGroups, ...unselectedOptionGroups];
	}

	private onChangeAddOnsCheckbox(value: boolean, id: number): void {
		value ?	this.mutableItemAddOnOptionGroupIds.push(id) : this.mutableItemAddOnOptionGroupIds.splice(this.mutableItemAddOnOptionGroupIds.indexOf(id), 1);
		this.$emit('select', this.mutableItemAddOnOptionGroupIds);
	}

	private toggleExpandedOptions(addOnOptionGroupId: number): void {
		if (this.expandedOptionGroupIds.includes(addOnOptionGroupId)) {
			this.expandedOptionGroupIds.splice(this.expandedOptionGroupIds.indexOf(addOnOptionGroupId), 1);
		}
		else {
			this.expandedOptionGroupIds.push(addOnOptionGroupId);
		}
	}
}
