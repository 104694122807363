
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import IconButton from '@/components/shared/suites/IconButton.vue';
import StatusLabel from '@/components/shared/suites/StatusLabel.vue';

@Component<EventDetailsTable>({
	components: {
		IconButton,
		StatusLabel
	}
})
export default class EventDetailsTable extends Vue {
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	@Prop({ type: Array, required: true }) private eventSuites!: EventSuite[];
	@Prop({ type: String, required: true }) private eventStatus!: string;

	private disableEmailButton(eventSuite: EventSuite): boolean {
		return eventSuite.status === 'completed' || eventSuite.status === 'accepted' || eventSuite.status === 'not_configured' || this.eventStatus === 'pre_order_locked' || this.eventStatus === 'past_event';
	}

	private disableLoginAsButton(eventSuite: EventSuite): boolean {
		return eventSuite.status === 'completed' || eventSuite.status === 'accepted' || eventSuite.status === 'not_configured' || eventSuite.status === 'refunded' || eventSuite.status === 'cancelled';
	}

	private loginAsUrl(eventSuite: EventSuite): string {
		const menuList = eventSuite.menus.map(menu => menu.slug).join(',');

		return `${process.env.VUE_APP_MENU_DOMAIN}/${this.restaurant.slug}?menuList=${menuList}&eventSuite=${eventSuite.id}&suiteOperator=true`;
	}
}
