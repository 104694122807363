import store from '@/store';
import { parse as parseCookie } from 'cookie';
import { state as authState } from '@/store/auth/auth';
import Cookies from 'js-cookie';

/**
 * Check if the value is set or not
 *
 * @param {string | null} value
 */
export function isUnset(value: string | null | undefined): boolean {
	return typeof value === 'undefined' || value === null;
}

/**
 * Check if the value is set
 *
 * @param {string | null} value
 */
export function isSet(value: string | null | undefined): boolean {
	return !isUnset(value);
}

// converts the property name in snake case to the mutation name in pascal case
const MUTATION_MAPPING: { [key: string]: string } = {
	access_token: 'SET_ACCESS_TOKEN',
	id_token: 'SET_ID_TOKEN',
	expires_at: 'SET_EXPIRES_AT',
	restaurants: 'SET_RESTAURANTS',
	tenantId: 'SET_TENANT_ID',
	role: 'SET_ROLE',
	staffEmail: 'SET_STAFF_EMAIL'
};

export class Storage {

	// ------------------------------------
	// Universal
	// ------------------------------------

	/**
	 * Set all the values in each important storages.
	 * 1. Local vuex store
	 * 2. Cookie storage
	 * 3. Local storage
	 *
	 * @param {string} key
	 * @param {string | null} value
	 */
	public setUniversal(key: string, value: string | null): string | null {
		this.setState(key, value);
		this.setCookie(key, value);
		this.setLocalStorage(key, value);
		return value;
	}

	/**
	 * Get all the values in each important storages.
	 * 1. Local vuex store
	 * 2. Cookie storage
	 * 3. Local storage
	 *
	 * @param {string} key
	 * @param {string | null} value
	 */
	public getUniversal(key: string): string {
		let value: any = this.getState(key);

		if (isUnset(value)) {
			value = this.getCookie(key);
		}

		if (isUnset(value)) {
			value = this.getLocalStorage(key);
		}

		return value;
	}

	/**
	 * Sync all storage (vuex, local storage, cookie)
	 *
	 * @param {string} key
	 * @param {string} defaultValue
	 */
	public syncUniversal(key: string, defaultValue?: string): string {
		let value: any = this.getUniversal(key);

		if (isUnset(value) && isSet(defaultValue)) {
			value = defaultValue;
		}

		if (isSet(value)) {
			this.setUniversal(key, value);
		}

		return value;
	}

	/**
	 * Set the axios instance with access token and tenantId
	 *
	 * @return {Promise<void>}
	 */
	public async setAxiosInstance(): Promise<void> {
		await store.dispatch('auth/updateAxiosInstance');
	}

	/**
	 * Set the restaurant
	 *
	 * @return {Promise<void>}
	 */
	public async setRestaurants(restaurants: AuthRestaurants[]): Promise<void> {
		await store.dispatch('auth/getRestaurantsInfo', restaurants);
	}

	/**
	 * Fetch the tenant id restaurant information
	 *
	 * @return {Promise<void>}
	 */
	public async fetchRestaurant(): Promise<void> {
		await store.dispatch('auth/fetchRestaurant', { tenantId: this.getState('tenantId') });
	}

	// ------------------------------------
	// Vuex store
	// ------------------------------------

	/**
	 * Set vuex state
	 *
	 * @param {string} key
	 * @param {string | null} value
	 * @return {string | null | void}
	 */
	public setState(key: string, value: string | null): string | null | void {
		if (key in MUTATION_MAPPING) {
			store.commit(`auth/${MUTATION_MAPPING[key]}`, value);
			return value;
		}
	}

	/**
	 * Get vuex state
	 *
	 * @param {string} key
	 * @return {string | null}
	 */
	public getState(key: string): string | null {
		if (store) {
			return authState[key];
		}
		else {
			return null;
		}
	}

	// ------------------------------------
	// Local storage
	// ------------------------------------

	/**
	 * Set local storage with key/value pair
	 * We also check if local storage is undefined to skip it
	 * if we are on the prod
	 *
	 * @param {string} key
	 * @param {string | null } value
	 * @return {string | null | undefined}
	 */
	public setLocalStorage(key: string, value: string | null): string | null | undefined {
		if (typeof localStorage === 'undefined') {
			return;
		}
		if (isUnset(value)) {
			localStorage.removeItem(key);
		}
		else {
			localStorage.setItem(key, value as string);
		}

		return value;
	}

	/**
	 * Get local storage
	 *
	 * @param {string} key
	 * @return {string | boolean | null| undefined}
	 */
	public getLocalStorage(key: string): string | boolean | null | undefined {
		if (typeof localStorage === 'undefined') {
			return;
		}

		const value = localStorage.getItem(key);
		if (value === 'false') {
			return false;
		}

		return value;
	}

	// ------------------------------------
	// Cookies
	// ------------------------------------

	/**
	 * Set cookie
	 *
	 * @param {string} key
	 * @param {string | null} value
	 * @param {object} options
	 * @return {string | null}
	 */
	public setCookie(key: string, value: string | null, options: object = {}): string | null {
		if (isUnset(value)) {
			Cookies.remove(key, options);
		}
		else {
			Cookies.set(key, value as string, options);
		}
		return value;
	}

	/**
	 * Get cookie by key
	 *
	 * @param {string} key
	 * @return {string | boolean}
	 */
	public getCookie(key: string): string | boolean {
		const cookieStr = document.cookie;

		const cookies = parseCookie(cookieStr || '') || {};
		const value = cookies[key];
		if (value === 'false') {
			return false;
		}
		return value;
	}
}
