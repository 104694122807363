
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Debounce } from '@/utils/decorators';
import { TOAST_INSTANCE } from '../../utils/constants';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'search';

@Component<MenuEditor>({
	components: {
		BannerToast
	}
})
export default class MenuEditor extends Vue {
	@Action('searchContent', { namespace }) private searchContent!: (searchString: string | null) => Promise<void>;
	@Getter('getSearchItems', { namespace }) private searchItems!: SearchItem[];

	private searchString: string = '';
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private loading: boolean = false;

	/**
	 * Search the restaurant content (menus, sections, items)
	 * with string provided after a certain amount of time
	 * to prevent fetching every character typed.
	 *
	 * @return {Promise<void>}
	 */
	@Debounce(500)
	private async search(): Promise<void> {
		this.loading = true;
		await this.searchContent(this.searchString)
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.search_dialog.error_searching', { errorMessage });
			});
		this.loading = false;
	}

	/**
	 * Close modal and redirect the user to the item's list
	 *
	 * @return {void}
	 */
	private selectSearchItem(item: SearchItem): void {
		this.$emit('close-modal');
		if (item.type !== 'menu') {
			this.$router.push(item.to!);
		}
	}
}
