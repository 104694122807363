
import { Component, Vue, Prop } from 'vue-property-decorator';
import MenuGroupCard from '@/components/menu-groups/MenuGroupCard.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';

@Component<MenuGroupList>({
	components: {
		MenuGroupCard,
		InfoBanner
	}
})
export default class MenuGroupList extends Vue {
	@Prop({ type: Array, default: () => [] }) private menuGroups!: MenuGroup[];
}
