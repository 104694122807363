
import { Getter, Action } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TOAST_INSTANCE, ROLES } from '../utils/constants';
import { cloneDeep, isEqual, isNil } from 'lodash';
import omitBy from 'lodash/omitBy';
import TitleHeader from '@/components/shared/Header.vue';
import ListItem from '@/components/shared/ListItem.vue';
import AddButton from '@/components/shared/AddButton.vue';
import MoveModal from '@/components/shared/MoveModal.vue';
import ModalWrapper from '@/components/shared/ModalWrapper.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import SectionEditor from '@/components/section/SectionEditor.vue';

const namespace: string = 'sections';

@Component<SectionList>({
	components: {
		TitleHeader,
		ListItem,
		AddButton,
		ModalWrapper,
		SectionEditor,
		PageLoader,
		ConfirmationModal,
		MoveModal,
		BannerToast
	}
})
export default class SectionList extends Vue {
	@Action('fetchMenus', { namespace: 'menus' }) private fetchMenus!: () => Promise<Menu[]>;
	@Getter('getMenus', { namespace: 'menus' }) private menusData!: Menu[];
	@Action('duplicateSection', { namespace }) private duplicateSection!: (payload: object) => Promise<void>;
	@Action('fetchSections', { namespace: 'menus' }) private fetchSections!: (menu: Menu) => Promise<MenuSection[]>;
	@Action('deleteSection', { namespace }) private deleteSection!: (payload: object) => Promise<void>;
	@Action('updateMenuSection', { namespace }) private updateMenuSection!: (payload: object) => Promise<void>;
	@Action('setSelectedSection', { namespace }) private setSelectedSection!: (section: MenuSection | object) => Promise<void>;
	@Getter('getSelectedSection', { namespace }) private selectedSection!: MenuSection;
	@Getter('getFetchMenusComplete', { namespace: 'menus' }) private fetchMenusComplete!: boolean;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace: 'auth' }) private isPosIntegrated!: boolean;
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;

	private showDialog: boolean = false;
	private isCreating: boolean = false;
	private showDeleteModal: boolean = false;
	private showMoveModal: boolean = false;
	private loading: boolean = false;

	private showConfirmationModal: string|null = null;
	private itemToDelete: MenuSection = {} as MenuSection;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;
	private selectedLocale: RestaurantLocale|null = null;

	private moveSection: MenuSection = {} as MenuSection;
	private sectionToCopy: MenuSection = {} as MenuSection;

	private selectedSectionBeforeEditSnapshot: MenuSection = {} as MenuSection;

	private actionsArray: ActionItem[] = [
		{
			iconName: 'mdi-archive-arrow-down',
			buttonLabel: this.$t('shared.actions_array.btn_archive'),
			onClick: async (evt: object, section: MenuSection): Promise<void> => {
				await this.updateMenuSection({ menu: this.menu, section: { ...section, published: false } })
					.catch((errorMessage: string) => {
						this.bannerToastInfo.showMessage = true;
						this.bannerToastInfo.message = this.$t('shared.error_archiving', { name: section.name, errorMessage });
					});
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-archive-arrow-up',
			buttonLabel: this.$t('shared.actions_array.btn_publish'),
			onClick: async (evt: object, section: MenuSection): Promise<void> => {
				this.publishAttempt(section);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-pencil',
			buttonLabel: this.$t('shared.actions_array.btn_edit'),
			onClick: (evt: object, section: MenuSection): void => {
				this.openSectionEditor(false, section);
				this.setSelectedSection(section);
			}
		},
		{
			iconName: 'mdi-file-multiple',
			buttonLabel: this.$t('shared.actions_array.btn_duplicate'),
			onClick: async (evt: object, section: MenuSection): Promise<void> => {
				await this.duplicateSection({ oldMenu: this.menu, newMenu: this.menu, section })
					.catch((errorMessage: string) => {
						this.bannerToastInfo.showMessage = true;
						this.bannerToastInfo.message = this.$t('shared.error_duplicating', { name: section.name, errorMessage });
					});
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-arrow-right',
			buttonLabel: this.$t('shared.actions_array.btn_move'),
			onClick: (evt: object, section: MenuSection): Promise<void> => {
				return this.openMoveModal(section);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-delete',
			buttonLabel: this.$t('shared.actions_array.btn_delete'),
			onClick: (evt: object, section: MenuSection): void => {
				this.setItemToDeleteName(section);
			},
			accessLevel: this.roles.enterprise
		},
		{
			iconName: 'mdi-window-close',
			buttonLabel: this.$t('shared.actions_array.btn_cancel'),
			onClick: null
		}
	];

	private get sections(): MenuSection[] {
		return (this.menu?.sections || []).map(
			(section: MenuSection): MenuSection => {
				return { to: { name: 'SectionItems', params: { menu_id: this.menu.id, section_id: section.id } }, ...section };
			}
		);
	}

	private get publishedSections(): MenuSection[] {
		return this.sections.filter(section => section.published_at);
	}

	private get unpublishedSections(): MenuSection[] {
		return this.sections.filter(section => !section.published_at);
	}

	/**
	 * Attempt to publish the section. We throw a warning if the section has children
	 * to warn the user that all of its content will be published.
	 *
	 * @param {MenuSection} section
	 * @return {void}
	 */
	private publishAttempt(section: MenuSection): void {
		this.sectionToCopy = section;
		if(section.items && section.items.length) {
			this.showConfirmationModal = 'confirmPublish';
		}
		else {
			this.publish();
		}
	}

	/**
	 * Publish the section
	 *
	 * @return {void}
	 */
	private async publish(): Promise<void> {
		if(this.sectionToCopy) {
			await this.updateMenuSection({ menu: this.menu, section: { ...this.sectionToCopy, published: true } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_publishing', { name: this.sectionToCopy.name, errorMessage });
				});
			this.showConfirmationModal = null;
		}
	}

	/**
	 * Cancel closing of modal through the confirmation close modal
	 *
	 * @return {void}
	 */
	private cancelCloseModal(): void {
		this.showConfirmationModal = null;
	}

	/**
	 * Check if needs to show the confirm close modal to the user
	 *
	 * @return {void}
	 */
	private confirmClose(saved: boolean = false) {
		this.selectedSectionBeforeEditSnapshot.price = this.selectedSection.price;
		const selectedSectionToCompare = omitBy(this.selectedSection, isNil);
		const selectedSectionBeforeEditSnapshotToCompare = omitBy(this.selectedSectionBeforeEditSnapshot, isNil);
		this.selectedLocale = null;
		if (saved || isEqual(selectedSectionToCompare, selectedSectionBeforeEditSnapshotToCompare)) {
			this.close(saved);
		}
		else {
			this.showConfirmationModal = 'confirmClose';
		}
	}

	private async openMoveModal (section: MenuSection) {
		this.showMoveModal = true;
		this.moveSection = section;
	}

	private async closeMoveModal (saved: boolean = false) {
		this.showMoveModal = false;
		if (saved) {
			// The move action changes multiple sections across different menus, which is why
			// we need to refetch the menus, not just the seconds.
			await this.fetchMenus()
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.section.error_fetching_sections', { errorMessage });
				});
		}
	}

	/**
	 * Open Section Modal
	 *
	 * @return {void}
	 */
	private openSectionEditor(creating: boolean, section: MenuSection) {
		this.showDialog = true;
		this.isCreating = creating;

		if (this.isCreating) {
			// Setup the snapshot to match the initial values of the selectedSection
			this.selectedSectionBeforeEditSnapshot.price = '';
		}
		else {
			this.selectedSectionBeforeEditSnapshot = cloneDeep(section);
		}

		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Set the item name for the deletion modal
	 *
	 * @return {void}
	 */
	private setItemToDeleteName(section: MenuSection): void {
		this.showDeleteModal = true;
		this.itemToDelete = section;
	}

	/**
	 * Close the SectionEditor modal
	 *
	 * @return {void}
	 */
	private async close(saved: boolean): Promise<void> {
		this.showConfirmationModal = null;
		this.showDialog = false;
		this.isCreating = false;

		// Remove the selected section from the store
		this.setSelectedSection({});
		this.selectedSectionBeforeEditSnapshot = {} as MenuSection;
		document.documentElement.classList.remove('modal-open');

		// Re-fetch the sections if the modal was simply closed
		if (!saved) {
			await this.fetchSections(this.menu)
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.section.error_fetching_sections', { errorMessage });
				});
		}
	}

	private cancel(): void {
		this.showDeleteModal = false;
		this.itemToDelete = {} as MenuSection;
	}

	/**
	 * User confirmed deletion of the item, so we delete it
	 * and close the modal
	 *
	 * @return {Promise<void>}
	 */
	private async confirm(): Promise<void> {
		this.loading = true;
		await this.deleteSection({ menu: this.menu, section: this.itemToDelete })
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: this.itemToDelete.name, errorMessage });
			});
		this.itemToDelete = {} as MenuSection;
		this.loading = false;
		this.showDeleteModal = false;
	}
}
