
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FONTS } from '@/utils/constants';

@Component<CustomFont>({})
export default class CustomFont extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetMenuConfig!: MenuConfiguration;

	private mutableMenuConfiguration: MenuConfiguration = this.targetMenuConfig;
	private fonts: string[] = FONTS;

	private useDefaultFonts(): void {
		if (this.mutableMenuConfiguration.use_default_fonts) {
			this.mutableMenuConfiguration.primary_font = '';
			this.mutableMenuConfiguration.secondary_font = '';
		}
	}

}
