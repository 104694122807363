
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { getOrderStatus, getOrderType } from '@/utils/getters';
import ExpansionPanel from '@/components/shared/suites/panels/ExpansionPanel.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';
import OrderCartItem from './OrderCartItem.vue';
import OrderCartPricing from './OrderCartPricing.vue';
import OrderInfo from '@/components/order-management/OrderInfo.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace = 'orders';

@Component<OrderCart>({
	components: {
		ExpansionPanel,
		IconButton,
		OrderCartItem,
		OrderCartPricing,
		OrderInfo,
		TextButton
	}
})
export default class OrderCart extends Vue {
	@Action('validateCart', { namespace }) validateCart!: () => void;
	@Getter('getOrderDetails', { namespace }) private orderDetails!: OrderDetails;
	@Getter('getOrderType', { namespace }) private orderType!: string;
	@Getter('getCart', { namespace }) private cart!: Cart;
	@Getter('getCosts', { namespace }) private costs!: Costs;
	@Getter('getModifyingOrder', { namespace }) private modifyingOrder!: boolean;
	@Getter('getSelectedOrder', { namespace }) private order!: Order;
	@Getter('getRestaurant', { namespace: 'auth' }) restaurant!: Restaurant;
	@Prop({ type: Boolean, default: false }) private isOpen!: boolean;
	@Prop({ type: Boolean, default: false }) private isUserFromAPP!: boolean;
	@Prop({ type: Boolean, default: false }) private tableInfoFetchedByApp!: boolean;

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private get orderTypeLocalized(): string {
		// BOWLERO ONLY - They have different order type naming conventions...
		if(this.restaurant.group === 'bowlero') {
			if(this.orderType === 'dine-in' && this.orderDetails.menuManagerLaneReservation) {
				return this.$t('orders.cart.header_lane_res');
			}
			else if(this.orderType === 'dine-in') {
				return this.$t('orders.cart.header_retail');
			}
		}
		return this.$t(`orders.cart.header_${this.orderType.replace('-', '_')}`);
	}

	private get orderTypeInfo(): ParentOrderType {
		return getOrderType(this.order);
	}

	private get orderStatus(): string {
		return getOrderStatus(this.order.status);
	}

	private async handleCartValidation(): Promise<void> {
		if (this.cart.items.length) {
			try {
				await this.validateCart();
				this.$emit('open-guest-details');
			}
			catch (error) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('orders.cart.cart_validation_error', { error });
			}
		}
		else {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.cart.empty_order_error');
		}
	}
}
