
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import OrderOptions from './OrderOptionGroup.vue';

const namespace = 'orders';

@Component<OrderModifiers>({
	components: {
		OrderOptions
	}
})
export default class OrderModifiers extends Vue {
	@Action('setOrderItemOptions', { namespace }) setOrderItemOptions!: (optionGroups: OrderOptionGroup[]) => void;
	@Getter('getOrderItemOptions', { namespace }) orderItemOptions!: OrderOptionGroup[];
	@Getter('getOrderItem', { namespace }) orderItem!: OrderItem;
	@Getter('getEditingItem', { namespace }) editingItem!: boolean;
	@Prop({ type: Array, required: true }) private optionGroups!: OptionGroup[];

	private requiredOptionErrors: number[] = [];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	/**
	 * Returns the ids of the required option groups
	 *
	 * @return {number[]}
	 */
	private get requiredOptions(): number[] {
		if (!this.optionGroups || !this.optionGroups.length) {
			return [];
		}
		return this.optionGroups
			.filter((option: OptionGroup) => option.requiredOption)
			.map((option: OptionGroup) => option.id) as number[];
	}

	/**
	 * Updates the orderOptionGroups and sets orderItemOptions when an option is updated
	 *
	 * @param {OrderOptionGroup} optionGroup - The updated option group
	 * @return {void}
	 */
	private handleOptionUpdated(optionGroup: OrderOptionGroup): void {
		const tempOrderItemOptions = [...this.orderItemOptions];
		const index = tempOrderItemOptions.findIndex((optGroup: OrderOptionGroup) => optGroup.id === optionGroup.id);
		if (optionGroup.values.length && index > -1) {
			tempOrderItemOptions[index] = optionGroup;
		}
		else if (optionGroup.values.length) {
			tempOrderItemOptions.push(optionGroup);
		}
		else if (index > -1) {
			tempOrderItemOptions.splice(index, 1);
		}
		this.setOrderItemOptions(tempOrderItemOptions);
	}

	/**
	 * Validates if all required options have been selected
	 * This function is called from the parent component
	 *
	 * @return {void}
	 */
	public validateRequiredOptions(): void {
		// Get an array of refs for each option group
		const optionGroupRefs = this.optionGroups.map((optionGroup: OptionGroup) => this.$refs[`optionGroup-${optionGroup.id}`]);
		const requiredOptionErrors: number[] = [];

		// Loop through each option group ref and call validateOptionGroup function in each component
		optionGroupRefs.forEach((optionGroupRef: any) => {
			if (optionGroupRef && optionGroupRef[0]) {
				const optionGroupError = optionGroupRef[0].validateOptionGroup();
				if (Array.isArray(optionGroupError)) {
					requiredOptionErrors.push(...optionGroupError);
				}
				else if (optionGroupError) {
					requiredOptionErrors.push(optionGroupError);
				}
			}
		});
		this.requiredOptionErrors = requiredOptionErrors;
		if (requiredOptionErrors.length) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.item_viewer.required_options_error');
			this.scrollToOption(`optionGroup-${requiredOptionErrors[0]}`);
		}
		else {
			this.$emit('options-validated');
		}
	}

	/**
	 * Scrolls to the option group with the given id
	 *
	 * @param {string} option - The id of the option group
	 * @return {void}
	 */
	private scrollToOption(option: string): void {
		const sectionGroup = document.getElementById(option);
		if (sectionGroup) {
			sectionGroup.scrollIntoView({ behavior: 'smooth' });
		}
	}
}
