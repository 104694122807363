
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import '@/validation-rules';

@Component<LoginForm>({
	components: {
		ValidationObserver,
		ValidationProvider
	}
})
export default class LoginForm extends Vue {
	@Prop({ type: Object, default: () => {} }) private loginCredentials!: LoginCredentials;
	@Prop({ type: Boolean, default: false }) private loading!: boolean;
	@Prop({ type: Boolean, default: false }) private forgotPassword!: boolean;
	@Prop({ type: String, default: null }) private errorMsg!: string | null;

	$refs!: {observer: Validate};

	private mutableLoginCredentials: LoginCredentials = this.loginCredentials;
	private showPassword: boolean = false;

	private async submit(): Promise<void> {
  		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			this.$emit('submit');
		}
	}

	private forgotPasswordToggle(): void {
		this.$emit('forgot-password-toggle');
	}
}
