
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { NavigationGuardNext, Route } from 'vue-router';
import { isEqual } from 'lodash';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent } from '@/utils/decoding';
import EventDetailsForm from '@/components/suites/events/EventDetailsForm.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import FullPageSpinner from '@/components/shared/suites/FullPageSpinner.vue';

@Component<EditEvent>({
	components: {
		EventDetailsForm,
		SuitesHeader,
		TextButton,
		ValidationObserver,
		ValidationProvider,
		WarningModal,
		FullPageSpinner
	}
})
export default class EditEvent extends Vue {
	@Action('fetchEvent', { namespace: 'events' }) private fetchEvent!: (eventId: number | string) => Function;
	@Action('updateEvent', { namespace: 'events' }) private updateEvent!: (event: SuitesEvent) => Function;
	@Action('deleteEvent', { namespace: 'events' }) private deleteEvent!: (eventId: number) => Function;
	@Getter('getSelectedEvent', { namespace: 'events' }) private event!: SuitesEvent;

	private mutableEvent: SuitesEvent | null = null;
	private loading: boolean = false;
	private isDataFetching: boolean = true;
	private isSubmitting: boolean = false;
	private nextPage: Route | null = null;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private warningModalProps: WarningModalProps = {
		open: false,
		loading: false,
		confirmButtonText: this.$t('suite_assignments.warning_modal.continue_btn'),
		cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
		onClose: this.handleModalClose
	};

	$refs!: {eventDetailsForm: EventDetailsForm};

	private get breadcrumbsArray() {
		if (!this.mutableEvent) {
			return [];
		}
		return [
			{
				text: `${this.mutableEvent.title} (${Vue.filter('formatDate')(this.event.start_date)})`,
				to: `/events/${this.event.id}`,
				exact: true
			},
			{
				text: this.$t('edit_event.header'),
				to: `/events/${this.event.id}/edit`,
				exact: true
			}
		];
	}

	private async created(): Promise<void> {
		try {
			this.isDataFetching = true;
			await this.fetchEvent(this.$route.params.event_id);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('suite_assignments.error_fetching', { errorMessage });
		}
		finally {
			if (this.event) {
				this.mutableEvent = decodeEvent(this.event);
			}
			setTimeout(() => {
				this.isDataFetching = false;
			}, 500);
		}
	}

	// Check if event has been updated before leaving route and open warning modal if changes haven't been saved
	private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
		if (this.event && !isEqual(decodeEvent(this.event), this.mutableEvent)) {
			this.warningModalProps = {
				...this.warningModalProps,
				open: true,
				title: this.$t('suite_settings.unsaved_changes'),
				text: this.$t('suite_assignments.warning_modal.skip_assignments_text'),
				confirmButtonText: this.$t('suite_assignments.warning_modal.save_continue_btn'),
				cancelButtonText: this.$t('suite_assignments.warning_modal.discard_btn'),
				onConfirm: () => this.submitForm(to),
				onCancel: () => this.handleLeave(to)
			};
		}
		else {
			next();
		}
	}

	/**
	 * Merge form data with existing event data on form change, ensures that event suites are not overwritten or duplicated
	 *
	 * @param {SuitesEvent} formData The form data to merge with existing event data
	 * @returns {void}
	 */
	private mergeFormData(formData: SuitesEvent): void {
		if (this.mutableEvent) {
			const updatedSuites = [
				// Removes any suites that have been deselected (not in formData)
				...this.mutableEvent.event_suites.filter((eventSuite: EventSuite) => {
					return formData.event_suites.find((suite: { suite_id: number }) => suite.suite_id === eventSuite.suite_id);
				}),
				// Filter out suites that are already in the event
				...formData.event_suites.filter((suite: { suite_id: number }) => {
					return !this.mutableEvent?.event_suites.find((eventSuite: EventSuite) => eventSuite.suite_id === suite.suite_id);
				})
			];

			this.mutableEvent = {
				...this.mutableEvent,
				...formData,
				event_suites: updatedSuites
			};
		}
	}

	/**
	 * Calls handleSubmit in EventDetailsForm component to validate form and emit submit event
	 *
	 * @param {Route} to
	 * @returns {void}
	 */
	private submitForm(to?: Route): void {
		if (to) {
			this.nextPage = to;
		}
		this.$refs.eventDetailsForm.handleSubmit();
	}

	/**
	 * Handle saving changes to event, update event and navigate to other page
	 *
	 * @returns {void}
	 */
	private async handleSave(): Promise<void> {
		this.nextPage ? this.warningModalProps.loading = true : this.loading = true;
		try {
			await this.updateEvent(this.mutableEvent as SuitesEvent);
			this.mutableEvent = decodeEvent(this.event);
			setTimeout(() => {
				if (this.nextPage) {
					this.$router.push(this.nextPage.path);
					this.warningModalProps.loading = false;
				}
				else {
					this.$router.push(`/events/${this.event.id}`);
					this.loading = false;
				}
			}, 500);
		}
		catch (errorMessage) {
			this.loading = false;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('edit_event.error_updating', { errorMessage });
		}
	}

	/**
	 * Handle deleting an event, set mutableEvent to null, close modal and navigate to events page
	 *
	 * @returns {Promise<void>}
	 */
	private async handleDelete(): Promise<void> {
		this.loading = true;
		try {
			await this.deleteEvent(this.event.id);
			this.mutableEvent = null;
			this.$router.push('/events');
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('edit_event.error_deleting', { errorMessage });
		}
		finally {
			this.handleModalClose();
			this.loading = false;
		}
	}

	private handleModalClose(): void {
		this.warningModalProps.open = false;
		this.nextPage = null;
	}

	/** Set warning modal props for delete event suite modal and open modal
	 *
	 * @returns {void}
	 */
	private openDeleteModal(): void {
		this.warningModalProps = {
			...this.warningModalProps,
			open: true,
			title: this.$t('edit_event.delete_event.section_header'),
			text: this.$t('edit_event.delete_event.modal_text'),
			confirmButtonText: this.$t('edit_event.delete_event.delete_btn'),
			cancelButtonText: this.$t('suite_assignments.warning_modal.cancel_btn'),
			onConfirm: this.handleDelete,
			onCancel: this.handleModalClose
		};
	}

	/** Handle leaving the page without saving changes
	 *
	 * @param {Route} to The route to navigate to
	 * @returns {void}
	 */
	private handleLeave(to: Route): void {
		this.warningModalProps.open = false;
		// Revert changes to updated event so that beforeLeaveRouter nav guard doesn't detect changes
		this.mutableEvent = decodeEvent(this.event);
		this.$router.push(to.path);
	}
}
