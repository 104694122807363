import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SectionState, RootState } from '../types';
const namespaced: boolean = true;

export const state: SectionState = {
	selectedSection: {}
};


export const Sections: Module<SectionState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default Sections;