
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeEvent, decodeGroupedOrderItems, decodeDeliveryTimes } from '@/utils/decoding';
import { DateTime } from 'luxon';
import { addBorderAroundTable, addStylingToHorizontalHeader, addStylingToVerticalHeader, downloadFile, formatItemOptions, setCellFontSizeAndAlignement } from '@/utils/exceljs-helpers';
import * as ExcelJS from 'exceljs';
import ExpansionPanel from '@/components/shared/suites/panels/ExpansionPanel.vue';
import OrderItemsList from '@/components/suites/beo/OrderItemsList.vue';
import OrderItemsListItem from '@/components/suites/beo/OrderItem.vue';
import SuitesHeader from '@/components/shared/suites/SuitesHeader.vue';
import SuitesPanel from '@/components/shared/suites/panels/SuitesPanel.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'events';

@Component<ProductionSheet>({
	components: {
		ExpansionPanel,
		OrderItemsListItem,
		OrderItemsList,
		SuitesHeader,
		SuitesPanel,
		TextButton
	}
})
export default class ProductionSheet extends Vue {
	@Action('fetchDeepEvent', { namespace }) private fetchDeepEvent!: ({ id, includePendingOrders }: { id: string, includePendingOrders: boolean }) => Promise<void>;
	@Getter('getDeepEvent', { namespace }) private deepEvent!: DeepSuitesEvent;
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;

	private decodedEvent: SuitesEvent | null = null;
	private itemSections: OrderItemGroup[] = [];
	private suites: { [key: string]: EventSuite[] } = {};
	private isDataFetching: boolean = false;
	private includePendingOrders: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;

	private get breadcrumbsArray(): Breadcrumb[] {
		if (!this.decodedEvent) {
			return [];
		}
		return [
			{
				text: this.$t('events.header'),
				to: '/events',
				exact: true
			},
			{
				text: `${this.decodedEvent.title} (${Vue.filter('formatDate')(this.decodedEvent.start_date)})`,
				to: `/events/${this.decodedEvent.id}`,
				exact: true
			}
		];
	}

	private created(): void {
		this.getDeepEventData();
	}

	/**
	 * Fetch deep event data and set the data.
	 *
	 * @return {Promise<void>}
	 */
	private async getDeepEventData(): Promise<void> {
		try {
			this.isDataFetching = true;
			await this.fetchDeepEvent({ id: this.$route.params.event_id, includePendingOrders: this.includePendingOrders });
			// Set event, items and suites if orders exist, otherwise redirect to event details page
			if (this.deepEvent) {
				this.decodedEvent = decodeEvent(this.deepEvent.event);
				this.suites = decodeDeliveryTimes(this.deepEvent.deliveryTimes);
				this.itemSections = this.deepEvent.itemSections?.length ? decodeGroupedOrderItems(this.deepEvent.itemSections) : [];
			}
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('event_details.error_fetching_event', { errorMessage });
		}
		finally {
			setTimeout(() => this.isDataFetching = false, 500);
		}
	}

	/**
	 * Get the delivery time for the order. If the delivery time is a date,
	 * format it, otherwise return the basic delivery time.
	 *
	 * @param {string} orderDeliveryTime
	 * @return {string}
	 */
	private getOrderDeliveryTime(orderDeliveryTime: string): string {
		const deliveryTime = DateTime.fromFormat(orderDeliveryTime, 'yyyy-MM-dd\'T\'HH:mm');
		if(deliveryTime.isValid) {
			return this.$t('beo.summary.delivery_times.datetime.title', { date: deliveryTime.toFormat(this.$t('beo.summary.delivery_times.datetime.date_format')) });
		}
		return this.$t(`beo.summary.delivery_times.basic.${orderDeliveryTime}`);
	}

	/**
	 * Export the event details and pre-order summary to an Excel file.
	 *
	 * @return {void}
	 */
	private exportData(): void {
		const workbook = new ExcelJS.Workbook();
		this.createEventDetailsExcelTab(workbook);
		this.createPreOrdersSummaryExcelTab(workbook);

		// Save the workbook to a file
		workbook.xlsx.writeBuffer().then(buffer => {
			downloadFile(buffer, this.$t('beo.production_sheet.spreadsheet.file_name', {
				eventName: this.decodedEvent?.title || '',
				eventDate: DateTime.fromISO(this.decodedEvent!.start_date).toFormat('yyyy-MM-dd HH-mm')
			}));
		});
	}

	/**
	 * Create the first tab in the Excel file with the event details.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createEventDetailsExcelTab(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('beo.production_sheet.spreadsheet.details_tab'), {
			pageSetup: { orientation: 'landscape' }
		});
		worksheet.columns = [{ width: 40 }, { width: 50 }];

		const eventDetailsData = [
			[this.$t('beo.production_sheet.spreadsheet.event_name_col'), this.decodedEvent?.title || ''],
			[this.$t('beo.production_sheet.spreadsheet.start_date_col'), Vue.filter('formatDate')(this.decodedEvent?.start_date, this.$t('beo.production_sheet.spreadsheet.date_format'))],
			[this.$t('beo.production_sheet.spreadsheet.manager_name_col'), this.decodedEvent?.manager_info?.name || 'N/A'],
			[this.$t('beo.production_sheet.spreadsheet.manager_phone_col'), this.decodedEvent?.manager_info?.phone_number || 'N/A'],
			[this.$t('beo.production_sheet.spreadsheet.manager_email_col'), `${this.decodedEvent?.manager_info?.email || 'N/A'}`]
		];

		worksheet.addRows(eventDetailsData);
		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		addStylingToVerticalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);
	}

	/**
	 * Create the second tab in the Excel file with the pre-ordered items summary.
	 *
	 * @param {ExcelJS.Workbook} workbook
	 * @return {void}
	 */
	private createPreOrdersSummaryExcelTab(workbook: ExcelJS.Workbook): void {
		const worksheet = workbook.addWorksheet(this.$t('beo.summary.title'), {
			pageSetup: { orientation: 'landscape' }
		});

		worksheet.columns = [
			{ header: this.$t('beo.production_sheet.spreadsheet.item_category_col'), key: 'category', width: 25 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_name_col')], key: 'name', width: 25 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_quantity_col')], key: 'quantity', width: 10 },
			{ header: [this.$t('beo.production_sheet.spreadsheet.item_modifiers_col')], key: 'options', width: 50 }
		];

		const preOrdersData = this.itemSections.map((section: OrderItemGroup) => {
			const sectionData = section.items.map((item) => {
				return {
					name: this.$options.filters?.localize(item, 'name'),
					quantity: item.quantity,
					options: formatItemOptions(item),
					category: this.$options.filters?.localize(section, 'name') || ''
				};
			});
			return sectionData;
		}).flat();

		worksheet.addRows(preOrdersData);
		setCellFontSizeAndAlignement(worksheet, 14, { vertical: 'top', horizontal: 'left' });
		addStylingToHorizontalHeader(worksheet);
		addBorderAroundTable(worksheet, 1, worksheet.rowCount);
	}
}
