import { ActionTree } from 'vuex';
import { OptionState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance } from 'axios';
import { handleAllErrors } from '../../utils/errorHandling';
import { decodeOption, decodeOptionGroup } from '@/utils/decoding';

export const actions: ActionTree<OptionState, RootState> = {

	setSelectedAddOnOptionGroup({ commit }, selectedAddOnOptionGroup: OptionGroup): void {
		selectedAddOnOptionGroup = decodeOptionGroup(selectedAddOnOptionGroup);
		commit('SET_SELECTED_ADD_ON_OPTION_GROUP', selectedAddOnOptionGroup);
	},

	/**
	 * Fetch restaurant's add-on option groups
	 *
	 * @return {Promise<void>}
	 */
	async fetchAddOnOptionGroups ({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			let addOnOptionGroups: OptionGroup[] = (await axiosInst.get('/options?type=addons')).data;
			addOnOptionGroups = addOnOptionGroups.map((addOnOptionGroup: OptionGroup) => decodeOptionGroup(addOnOptionGroup));
			commit('SET_ADD_ON_OPTION_GROUPS', addOnOptionGroups);

			let addOns: Option[] = addOnOptionGroups.map(((optionGroup: OptionGroup) => optionGroup.values as Option[])).flat();
			addOns = addOns.map((addOn: Option) => decodeOption(addOn));
			commit('SET_ADD_ONS', addOns);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch restaurant's option groups (both addon and pricing types) by item
	 *
	 * @return {Promise<void>}
	 */
	async fetchOptionGroupsByItem ({ commit }, { menuId, sectionId, itemId }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			let optionGroups: OptionGroup[] = [];
			if (sectionId) {
				optionGroups = (await axiosInst.get(`/menus/${menuId}/sections/${sectionId}/items/${itemId}/options`)).data;
			}
			else {
				optionGroups = (await axiosInst.get(`/menus/${menuId}/items/${itemId}/options`)).data;
			}
			optionGroups = optionGroups.map((optionGroup: OptionGroup) => decodeOptionGroup(optionGroup));
			commit('SET_ITEM_OPTION_GROUPS', optionGroups);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	clearItemOptionGroups ({ commit }) {
		commit('SET_ITEM_OPTION_GROUPS', []);
	},

	async createItemOptionGroupAssociation ({}, { menuId, sectionId, itemId, optionGroupIdsArray }) {
		const axiosInst: AxiosInstance = authState.axiosInst;
		await Promise.all(
			optionGroupIdsArray.map((id: number) => {
				return sectionId
					? axiosInst.post(`/menus/${menuId}/sections/${sectionId}/items/${itemId}/options/${id}`)
					: axiosInst.post(`/menus/${menuId}/items/${itemId}/options/${id}`);
			})
		);
	},

	async deleteItemOptionGroupAssociation ({}, { menuId, sectionId, itemId, optionGroupIdsArray }) {
		const axiosInst: AxiosInstance = authState.axiosInst;
		await Promise.all(
			optionGroupIdsArray.map((id: number) => {
				return sectionId
					? axiosInst.delete(`/menus/${menuId}/sections/${sectionId}/items/${itemId}/options/${id}`)
					: axiosInst.delete(`/menus/${menuId}/items/${itemId}/options/${id}`);
			})
		);
	},

	/**
	 * Create restaurant's option group
	 *
	 * @return {Promise<void>}
	 */
	async createOptionGroup ({ commit, dispatch }, { options, body, associationParams }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const optionPosted: Menu = (await axiosInst.post('/options', body)).data;
			if (options?.length) {
				// Temporary solution until API is updated.
				await axiosInst.post(`/options/${optionPosted.id}/values/bulk`, options)
					.catch((error) => {
						dispatch('deleteOptionGroup', optionPosted);
						throw (error);
					});

			}
			if (associationParams) {
				const { menuId, sectionId, itemId } = associationParams;
				dispatch('createItemOptionGroupAssociation', { menuId, sectionId, itemId, optionGroupIdsArray: [optionPosted.id] });
			}
			commit('SET_SELECTED_ADD_ON_OPTION_GROUP', optionPosted);
			dispatch('fetchAddOnOptionGroups');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update a restaurant's option group
	 *
	 * @return {Promise<void>}
	 */
	async updateOptionGroupAndOptions ({ dispatch }, { id, optionsBatch, body }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			// TODO: options batch on the backend?
			if (optionsBatch) {

				const { removed, updated, added } = optionsBatch;

				await Promise.all([
					...removed.map((removedOptionId: number) => axiosInst.delete(`/options/${id}/values/${removedOptionId}`)),
					...updated.map((updatedOption: Option) => axiosInst.put(`/options/${id}/values/${updatedOption.id}`, updatedOption)),
					axiosInst.post(`/options/${id}/values/bulk`, added)
				]);
			}

			if (body) {
				// Update the option group
				// The force_publish flag is required to trigger a menu rebuild when updating an option group
				await axiosInst.put(`/options/${id}`, { ...body, force_publish: true });
			}
			dispatch('fetchAddOnOptionGroups');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a restaurant's option group
	 *
	 * @return {Promise<void>}
	 */
	async deleteOptionGroup ({ dispatch }, { id }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			await axiosInst.delete(`/options/${id}`);
			dispatch('fetchAddOnOptionGroups');
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
