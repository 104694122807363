
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<ConfirmationModal>({})
export default class ConfirmationModal extends Vue {
	@Prop({ type: Boolean, default: false }) private showConfirmation!: boolean;
	@Prop({ type: Boolean, default: false }) private loading!: boolean;
	@Prop({ type: String, default: '' }) private header!: string;
	@Prop({ type: String, default: '' }) private text!: string;
	@Prop({ type: String, default: '' }) private cancelButtonText!: string;
	@Prop({ type: String, default: '' }) private confirmButtonText!: string;

	private mutableDialog: boolean = this.showConfirmation;

	private cancel(): void {
		this.$emit('cancel');
	}

	private confirm(): void {
		this.$emit('confirm');
	}
}
