
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import { ValidationObserver } from 'vee-validate';
import { Validate } from '@/types';
import PageLoader from '@/components/shared/PageLoader.vue';
import TitleHeader from '@/components/shared/Header.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import ServiceCharge from '@/components/advanced/ServiceCharge.vue';
import DeliveryModal from '@/components/advanced/DeliveryModal.vue';
import TaxRatesModal from '@/components/advanced/TaxRatesModal.vue';
import CustomQuestionsModal from '@/components/advanced/CustomQuestionsModal.vue';
import DiscountInfo from '@/components/advanced/DiscountInfo.vue';
import OrderOptions from '@/components/advanced/OrderOptions.vue';
import GenericTableLocationsComponent from '@/components/advanced/GenericTableLocations.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'auth';

@Component<Advanced>({
	components: {
		ValidationObserver,
		PageLoader,
		TitleHeader,
		TextButton,
		ServiceCharge,
		DeliveryModal,
		TaxRatesModal,
		DiscountInfo,
		OrderOptions,
		GenericTableLocationsComponent,
		CustomQuestionsModal,
		BannerToast
	}
})
export default class Advanced extends Vue {
	@Action('fetchRestaurant', { namespace }) private fetchRestaurant!: (payload: any) => Promise<Restaurant>;
	@Action('updateRestaurant', { namespace }) private updateRestaurant!: (restaurant: Restaurant | object) => Promise<void>;
	@Getter('getRestaurant', { namespace }) private restaurant!: Restaurant;
	@Getter('getTenantId', { namespace }) private tenantId!: string;
	@Getter('isPosIntegrated', { namespace }) private isPosIntegrated!: boolean;
	@Getter('isOrderManagementEnabled', { namespace }) private isOrderManagementEnabled!: boolean;
	@Getter('isMarketplaceEnabled', { namespace }) private isMarketplaceEnabled!: boolean;

	$refs!: {observer: Validate};

	// Loaders / toast
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private fetchingLoader: boolean = false;
	private loading: boolean = false;

	// Service charge
	private serviceChargeActivated: boolean = false;
	private serviceCharge: string|null = this.restaurant?.service_charge ? this.restaurant?.service_charge : null;
	private serviceChargeTypeIsDollar: number = 2;

	// Delivery
	private deliveryEnabled: boolean = false;
	private deliveryChargeTypeIsDollar: number = 2;
	private deliveryInfo: DeliveryInfo|any|null = this.restaurant?.delivery ? this.restaurant?.delivery : {};
	private showDeliveryModal: boolean = false;

	// Discount
	private discountEnabled: boolean = false;
	private discountInfo: DiscountInfo|any = this.restaurant?.discount ? this.restaurant?.discount : {};

	// Order Options
	private orderOptions: OrderOptions|any = this.restaurant?.order_options ? this.restaurant?.order_options : {};

	// Generic Table Locations
	private genericTableLocationsEnabled: boolean = false;
	private genericTableLocations: GenericTableLocations|null = this.restaurant?.generic_table_locations ? this.restaurant.generic_table_locations : null;

	// Customs Questions
	private showCustomQuestionsModal: boolean = false;

	// Tax rates
	private showTaxRatesModal: boolean = false;

	// Order Manager Settings
	private menuManagerConfig: MenuManagerConfig = this.restaurant?.menu_manager_config || {} as MenuManagerConfig;

	/**
	 * Fetch the restaurant on load
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		await this.getRestaurant();
	}

	private mounted(): void {
		this.positionSaveButton();
	}

	private async getRestaurant(): Promise<void> {
		this.fetchingLoader = true;
		await this.fetchRestaurant({
			tenantId: this.tenantId
		})
			.catch((errorMessage) => {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('shared.error_fetching_menus', { errorMessage });
			});
		this.setServiceCharge();
		this.setDeliveryInfo();
		this.setDiscount();
		this.setOrderOptions();
		this.setMenuManagerConfig();
		this.setGenericTableLocations();
		setTimeout(() => {
			this.fetchingLoader = false;
		}, 500);
	}

	/**
	 * Set service charge info depending if null or not
	 *
	 * @return {void}
	 */
	private setServiceCharge(): void {
		if (this.restaurant.service_charge) {
			this.serviceChargeActivated = true;
			this.serviceCharge = this.restaurant.service_charge.slice(0, - 1);
			this.serviceChargeTypeIsDollar = this.restaurant.service_charge.slice(this.restaurant.service_charge.length - 1) === '$' ? 0 : 1;
		}
		else {
			this.serviceChargeActivated = false;
			this.serviceCharge = null;
			this.serviceChargeTypeIsDollar = 2;
		}
	}

	/**
	 * Set delivery info depending on if null or not
	 *
	 * @return {void}
	 */
	private setDeliveryInfo(): void {
		if (this.restaurant.delivery && this.restaurant.delivery.type) {
			this.deliveryEnabled = true;
			this.deliveryInfo = this.restaurant.delivery;
			if (this.restaurant.delivery.delivery_charge) {
				this.deliveryChargeTypeIsDollar = this.restaurant.delivery.delivery_charge!.slice(this.restaurant.delivery.delivery_charge!.length - 1) === '$' ? 0 : 1;
				this.deliveryInfo.delivery_charge = this.restaurant.delivery.delivery_charge;
			}
		}
		else {
			this.deliveryEnabled = false;
			this.deliveryInfo = {};
		}
	}

	/**
	 * Set discount info depending on if null or not
	 *
	 * @return {void}
	 */
	private setDiscount(): void {
		this.discountInfo = this.restaurant.discount;

		if (this.discountInfo && this.discountInfo.codes && this.discountInfo.codes.length) {
			this.discountEnabled = true;

			this.discountInfo.codes.forEach((code: DiscountInfoCodes) => {
				code.value = code.value.slice(0, -1);
			});
		}
		else {
			this.discountEnabled = false;
			this.discountInfo = {};
		}
	}

	/**
	 * Set order options
	 *
	 * @return {void}
	 */
	private setOrderOptions(): void {
		this.orderOptions = this.restaurant.order_options ? this.restaurant.order_options : {};
	}

	/**
	 * Set configuration of menu manager
	 *
	 * @return {void}
	 */
	private setMenuManagerConfig(): void {
		this.menuManagerConfig = this.restaurant.menu_manager_config || {} as MenuManagerConfig;
	}

	/**
	 * Set discount info depending on if null or not
	 *
	 * @return {void}
	 */
	private setGenericTableLocations(): void {
		this.genericTableLocations = this.restaurant.generic_table_locations;
		this.genericTableLocationsEnabled = !!(this.genericTableLocations && this.genericTableLocations.type);
	}

	/**
	 * Save the advanced settings
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
  		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			let tempServiceCharge = this.getServiceCharge();
			this.setDiscountForSave();
			await this.updateRestaurant({
				restaurant: {
					...this.restaurant,
					service_charge: tempServiceCharge,
					discount: this.discountInfo,
					generic_table_locations: this.genericTableLocations,
					order_options: this.orderOptions,
					menu_manager_config: this.menuManagerConfig
				}
			})
				.then(() => {
					this.bannerToastInfo.color = '#00B796';
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('advanced.success');
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.color = 'primary';
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('advanced.error_updating', { restaurantName: this.restaurant.name, errorMessage });
				});
			await this.getRestaurant();
		}
		this.loading = false;
	}

	private getServiceCharge(): string|null {
		if (this.serviceChargeActivated && this.serviceCharge && (this.serviceChargeTypeIsDollar === 0 || this.serviceChargeTypeIsDollar === 1)) {
			return this.serviceCharge + (this.serviceChargeTypeIsDollar === 0 ? '$' : '%');
		}
		return null;
	}
	/**
	 * We have to add the symbol in front of its value before saving (%, $)
	 * **FOR NOW IT'S ONLY %**
	 *
	 * @return {void}
	 */
	private setDiscountForSave(): void {
		if (this.discountInfo && this.discountInfo.codes && this.discountInfo.codes.length) {

			// Clear out any empty values
			this.discountInfo.codes = this.discountInfo.codes.filter((code: DiscountInfoCodes) => {
				return code.name && code.value;
			});

			// Add the symbol
			this.discountInfo.codes.forEach((code: DiscountInfoCodes) => {
				code.value = `${code.value}%`;
			});
		}
	}

	/**
	 * Update service charge from event
	 *
	 * @param {any} serviceObject
	 * @return {void}
	 */
	private updateServiceCharge(serviceObject: any): void {
		this.serviceChargeActivated = serviceObject.chargeActivated;
		this.serviceCharge = serviceObject.charge;
		this.serviceChargeTypeIsDollar = serviceObject.type;
	}

	/**
	 * Update discount info from event
	 *
	 * @param {any} discountObject
	 * @return {void}
	 */
	private updateDiscountInfo(discountObject: any): void {
		this.discountEnabled = discountObject.enabled;
		this.discountInfo = discountObject.info;
	}

	/**
	 * Update order options from event
	 *
	 * @param {OrderOptions} orderOptions
	 * @return {void}
	 */
	private updateOrderOptions(orderOptionsObject: any): void {
		this.orderOptions = orderOptionsObject;
	}

	/**
	 * Update discount info from event
	 *
	 * @param {any} discountObject
	 * @return {void}
	 */
	private updateGenericTableLocations(genericTableLocationsObject: any): void {
		this.genericTableLocationsEnabled = genericTableLocationsObject.enabled;
		this.genericTableLocations = genericTableLocationsObject.locations;
	}

	/**
	 * Save delivery info
	 *
	 * @param {DeliveryInfoEventObject} deliveryInfoEventObject
	 * @return {Promise<void>}
	 */
	private async updateDeliveryInfo(deliveryInfoEventObject: DeliveryInfoEventObject): Promise<void> {
		this.deliveryEnabled = deliveryInfoEventObject.enabled;
		this.deliveryChargeTypeIsDollar = deliveryInfoEventObject.type;
		this.deliveryInfo = deliveryInfoEventObject.info;
		this.showDeliveryModal = false;
	}

	/**
	 * Position save button depending on the drawer on load
	 *
	 * @return {void}
	 */
	private positionSaveButton(): void {
		setTimeout(() => {
			const drawer = document.getElementById('nav-drawer');
			if(drawer) {
				document.documentElement.style.setProperty('--dynamic-btn-left-value', `${drawer.offsetWidth}px`);
			}
		}, 200);
	}
}
