
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ROLES } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'orders';

@Component<OrderSubmittedModal>({
	components: {
		StandardModal,
		TextButton
	}
})
export default class OrderSubmittedModal extends Vue {
	@Action('resetOrderState', { namespace }) resetOrderState!: () => void;
	@Action('setOrderDetails', { namespace }) setOrderDetails!: (orderDetails: OrderDetails) => void;
	@Getter('getOrderDetails', { namespace }) orderDetails!: OrderDetails;
	@Getter('getModifyingOrder', { namespace }) isModifyingOrder!: boolean;
	@Getter('getRoleLevel', { namespace: 'auth' }) roleLevel!: number;
	@Prop({ type: Boolean, required: true }) showSubmittedModal!: boolean;

	private roles: Roles = ROLES;

	private get isAppWebView(): boolean {
  		return !!window.B2BApp;
	}

	/**
	 * Reset the order state and open order details modal
	 *
	 * @return {void}
	 */
	private newOrder(): void {
		this.resetOrderState();
		this.$emit('new-order');
	}

	/**
	 * Back to app (go back to the open tabs list)
	 * No need to validate if the app is in webview or not, as this function
	 * wouldn't be hit if the app wasn't in webview.
	 *
	 * @return {void}
	 */
	private backToApp(): void {
		window.B2BApp.backToApp();
	}

	/**
	 * Create a new order with the same details as the current order
	 *
	 * @return {void}
	 */
	private reorder(): void {
		const orderDetailsCopy = { ...this.orderDetails };
		this.resetOrderState();
		this.setOrderDetails(orderDetailsCopy);
		this.$emit('reorder');
	}
}
