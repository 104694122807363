
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ROLES } from '@/utils/constants';
import { Validate, Provider } from '@/types';
import StandardModal from '@/components/shared/suites/StandardModal.vue';

interface OrderAdditionalField {
	label: string;
	type: string;
	name: string;
	options?: any[];
	disabled?: boolean;
	onChange?: (value: any) => void;
}

const namespace = 'orders';

@Component<OrderDetailsModal>({
	components: {
		StandardModal,
		ValidationObserver,
		ValidationProvider
	}
})
export default class OrderDetailsModal extends Vue {
	@Action('setOrderDetails', { namespace }) setOrderDetails!: (orderDetails: OrderDetails) => void;
	@Action('resetOrderDetails', { namespace }) resetOrderDetails!: () => void;
	@Action('setCosts', { namespace }) setCosts!: () => void;
	@Getter('getOrderDetails', { namespace }) orderDetails!: OrderDetails;
	@Getter('getCartItems', { namespace }) cartItems!: OrderItem[];
	@Getter('getRoleLevel', { namespace: 'auth' }) roleLevel!: number;
	@Prop({ type: String, required: true }) private modalType!: string;
	@Prop({ type: Array, required: true }) private orderTypeOptions!: { label: string, value: 'dine-in' | 'take-out' | 'generic-catering' }[];
	@Prop({ type: Object, required: true }) private restaurant!: Restaurant;

	$refs!: {
		observer: Validate,
		tableLocation: Provider[],
		tableArea: Provider[],
		takeoutType: Provider[]
	};

	private genericTableLocationsOptions: any = [];
	private mutableOrderDetails: OrderDetails = {
		type: '',
		tableArea: '',
		tableLocation: '',
		tableNum: '',
		takeoutType: '',
		checkNum: ''
	};
	private roles: Roles = ROLES;
	private chosenOrderType: 'dine-in' | 'lane-res' | 'take-out' | 'generic-catering' | '' = '';

	/**
	 * Get additional required fields based on the order type
	 *
	 * @return {OrderAdditionalField[]}
	 */
	private get orderTypeAdditionalFields(): OrderAdditionalField[] {
		if (this.mutableOrderDetails.type === 'dine-in') {
			return this.getDineInAdditionalFields();
		}
		else if (this.mutableOrderDetails.type === 'take-out') {
			return this.getTakeOutAdditionalFields();
		}
		return [];
	}

	private get hideCancelButton(): boolean {
		return this.modalType === 'create' && this.roleLevel === this.roles.staff && !window.B2BApp;
	}

	private get cancelButton(): { text: string, color: string, icon?: string }{
		if (window.B2BApp && this.modalType === 'create') {
			return {
				text: this.$t('navbar.btn_back_to_app'),
				icon: 'mdi-logout',
				color: 'white'
			};
		}
		else if (this.modalType === 'create') {
			return {
				text: this.$t('orders.details.cancel_btn'),
				color: 'white'
			};
		}
		return {
			text: this.$t('orders.details.delete_btn'),
			color: 'primary'
		};
	}

	private created(): void {
		if (this.modalType === 'edit') {
			// We need to set the generic table locations for the select field before editing
			// or else the select field will not have the correct options and the value will not be displayed
			this.setGenericTableLocations(this.orderDetails.tableLocation);
		}
		this.mutableOrderDetails = { ...this.orderDetails };
		this.getInitialOrderType();
	}

	/**
	 * Set the order type if there is only one option
	 * FOR BOWLERO ONLY - Hard set to dine-in no matter what other types there is.
	 * (Bowlero menus will always have dine-in, thus dine-in will be the first option at all times)
	 *
	 * @return {void}
	 */
	private getInitialOrderType(): void {
		if (this.orderTypeOptions.length === 1 || this.restaurant.group === 'bowlero') {
			this.chosenOrderType = this.orderTypeOptions[0].value;
			this.mutableOrderDetails.type = this.orderTypeOptions[0].value;
		}
	}

	/**
	 * Select order type and reset all additional fields when the order type changes
	 * The reason we can't use v-model here is because the order type does not neccessarily mean
	 * we're updating the actual order object with the type. A good example to look at is lane-res.
	 * Lane-res is a dine-in order type with a flag for lane-res.
	 *
	 * @return {void}
	 */
	private selectOrderType(): void {
		// Don't want to keep the lane reservation flag in the order object if it's not a lane reservation
		this.mutableOrderDetails.menuManagerLaneReservation = undefined;
		switch (this.chosenOrderType) {
			case 'generic-catering':
				this.mutableOrderDetails.type = 'generic-catering';
				break;
			case 'take-out':
				this.mutableOrderDetails.type = 'take-out';
				break;
			case 'lane-res': // FOR BOWLERO ONLY
				this.mutableOrderDetails.type = 'dine-in';
				this.mutableOrderDetails.menuManagerLaneReservation = true;
				break;
			default:
				this.mutableOrderDetails.type = 'dine-in';
				break;
		}

		this.mutableOrderDetails = {
			...this.mutableOrderDetails,
			tableArea: '',
			tableLocation: '',
			tableNum: '',
			takeoutType: '',
			checkNum: ''
		};
	}

	/**
	 * Get the additional fields for dine-in orders
	 * Either generic table locations or a table number input
	 *
	 * @return {OrderAdditionalField[]}
	 */
	private getDineInAdditionalFields(): OrderAdditionalField[] {
		if (this.restaurant.generic_table_locations?.custom) {
			const tableLocations = this.restaurant.generic_table_locations.custom.map((table: GenericTableLocations) => {
				return table.location;
			});
			// Set the table location if there is only one option
			if (tableLocations.length === 1) {
				this.mutableOrderDetails.tableLocation = tableLocations[0];
				this.setGenericTableLocations(tableLocations[0]);
			}
			return [
				{
					label: this.$t('orders.details.seating_area_label'),
					type: 'select',
					name: 'tableLocation',
					options: tableLocations,
					onChange: (value: string) => {
						Vue.set(this.mutableOrderDetails, 'tableLocation', value);
						Vue.set(this.mutableOrderDetails, 'tableNum', '');
						Vue.set(this.mutableOrderDetails, 'tableArea', '');
						Vue.set(this.mutableOrderDetails, 'checkNum', '');
						this.setGenericTableLocations(value);
						this.$refs.tableLocation[0].syncValue(value);
					}
				},
				{
					label: this.$t('orders.details.seating_details_label'),
					type: 'select',
					name: 'tableArea',
					options: this.genericTableLocationsOptions,
					disabled: !this.genericTableLocationsOptions.length,
					onChange: (value: string) => {
						Vue.set(this.mutableOrderDetails, 'tableArea', value);
						Vue.set(this.mutableOrderDetails, 'checkNum', '');
						// Find the table number based on the selected area
						const location = this.restaurant.generic_table_locations.custom!.find((table: GenericTableLocations) => table.location === this.mutableOrderDetails.tableLocation);
						const tableNum = location.areas.split(',').find((area: string) => area.includes(value))!.split(':')[1];
						Vue.set(this.mutableOrderDetails, 'tableNum', tableNum);
						this.$refs.tableArea[0].syncValue(value);
					}
				}
			];
		}

		return [
			{
				label: this.$t('orders.details.table_number_label'),
				name: 'tableNum',
				type: 'input',
				onChange: (value: string) => {
					this.mutableOrderDetails.tableNum = value;
				}
			}
		];
	}

	/**
	 * Set the generic table locations for the select field from restaurant settings
	 *
	 * @param {string} locationName
	 * @return {void}
	 */
	private setGenericTableLocations(locationName: string): void {
		if (!locationName) {
			return;
		}
		const location = this.restaurant.generic_table_locations?.custom!.find((table: GenericTableLocations) => table.location === locationName);
		const areas: string[] = [];
		if (location && location.areas?.length) {
			location.areas.split(',').reduce((object: any, value: string) => {
				let strParts = value.split(':');
				if(strParts[0] && strParts[1]) {
					areas.push(strParts[0].trim());
				}
				return object;
			}, {});
		}
		this.genericTableLocationsOptions = areas;
	}

	/**
	 * Get the additional fields for take-out orders
	 *
	 * @return {OrderAdditionalField[]}
	 */
	private getTakeOutAdditionalFields(): OrderAdditionalField[] {
		const takeoutTypes = [
			{
				text: this.$t('orders.details.pickup'),
				value: 'pickup'
			}
		];

		// Need to check the object keys here because if delivery was setup at some point then disabled
		// There will still be a delivery object, but it will be empty
		if (this.restaurant.delivery && Object.keys(this.restaurant.delivery).length) {
			takeoutTypes.push({
				text: this.$t('orders.details.delivery'),
				value: 'delivery'
			});
		}

		// Set the takeout type if there is only one option
		if (takeoutTypes.length === 1) {
			this.mutableOrderDetails.takeoutType = takeoutTypes[0].value;
		}

		return [
			{
				label: this.$t('orders.details.takeout_type_label'),
				type: 'select',
				name: 'takeoutType',
				options: takeoutTypes,
				onChange: (value: string) => {
					Vue.set(this.mutableOrderDetails, 'takeoutType', value);
					this.$refs.takeoutType[0].syncValue(value);

				}
			}
		];
	}

	/**
	 * Handles what happens when the modal cancel button is clicked
	 * If editing details, reset the order details modal
	 * If user is on the app, go back to the app
	 * If creating a new order and user isn't a staff role, reset and redirect to order management
	 *
	 * @return {void}
	 */
	private handleModalCancel(): void {
		if (this.modalType === 'edit') {
			this.mutableOrderDetails = {
				type: '',
				tableArea: '',
				tableLocation: '',
				tableNum: '',
				takeoutType: '',
				checkNum: '',
				menuManagerLaneReservation: undefined
			};
			this.$emit('delete-order');
			this.getInitialOrderType();
		}
		else if (window.B2BApp) {
			window.B2BApp.backToApp();
		}
		else if (this.roleLevel < this.roles.staff) {
			this.resetOrderDetails();
			this.$router.push('/order-management').catch(() => {});
		}
	}

	/**
	 * Validate the form, then set the order details and close the modal
	 *
	 * @return {Promise<void>}
	 */
	private async handleModalConfirm(): Promise<void> {
		const isValid = await this.$refs.observer.validate();

		if (isValid) {
			let updateCosts = false;

			// If there are items in the cart and the takeout type has changed, update the costs
			if (this.cartItems && this.cartItems.length && this.orderDetails.type === 'take-out' && this.mutableOrderDetails.takeoutType !== this.orderDetails.takeoutType) {
				updateCosts = true;
			}
			this.setOrderDetails(this.mutableOrderDetails);

			if (updateCosts) {
				await this.setCosts();
			}
			this.$emit('confirm');
		}
	}

	/**
	 * Close the modal if editing order details, otherwise cancel (reset and redirect)
	 * If the user is staff do nothing, this prevents the modal from closing when clicking outside the modal
	 * Staff shouldn't be able to close the modal without filling out the form
	 *
	 * @return {void}
	 */
	private handleModalClose(): void {
		if (this.modalType === 'edit') {
			this.$emit('close');
		}
		else if (this.roleLevel < this.roles.staff) {
			this.handleModalCancel();
		}
		return;
	}
}
