
import { Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ItemTaxOverwrite>({})
export default class ItemTaxOverwrite extends Vue {
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	@Prop({ type: Object, default: () => {} }) private itemTaxRates!: Taxes;

	private showTaxOverwrite: boolean = false;

	private get localizedPresetTaxRates(): PresetTaxes[] {
		return this.restaurant.preset_tax_rates.map((taxRate) => {
			const localizedName = this.getLocalizedTaxName(taxRate.name);
			return {
				...taxRate,
				localizedName
			};
		});
	}

	private get defaultTaxesApplied(): string {
		return this.restaurant.preset_tax_rates
			.filter((taxRate: PresetTaxes) => taxRate.is_default) // Filter by is_default
			.map((taxRate: PresetTaxes) => this.getLocalizedTaxName(taxRate.name)) // Map to localized names
			.join(', '); // Join the names with a comma
	}

	/**
	 * Get selected tax rates
	 * We have to build the preset tax rates as an array of objects
	 *
	 * @return {PresetTaxes[]}
	 */
	private get selectedTaxRates(): PresetTaxes[] {
		if (!this.itemTaxRates) {
			return []; // Return an empty array if no tax rates are defined
		}
		return Object.keys(this.itemTaxRates).map((key) => {
			const rate = this.restaurant.preset_tax_rates.find((preset) => preset.name === key);
			if (rate) {
				return {
					...rate,
					localizedName: this.getLocalizedTaxName(rate.name)
				};
			}
			return { name: key, value: this.itemTaxRates[key], is_default: false, localizedName: key };
		});
	}

	private created(): void {
		if(this.itemTaxRates) {
			this.showTaxOverwrite = true;
		}
	}

	/**
	 * Get localized tax name
	 * *Special case for Quebec where PST is QST
	 *
	 * @param {string} taxName
	 * @return {string}
	 */
	private getLocalizedTaxName(taxName: string): string {
		if (taxName === 'PST' && this.restaurant.address?.match(/, (QC|Québec|Quebec)/)) {
			return this.$t('orders.cart.pricing.qst');
		}
		return this.$te(`orders.cart.pricing.${taxName.toLowerCase()}`) ? this.$t(`orders.cart.pricing.${taxName.toLowerCase()}`) : taxName;
	}

	private updateItemTaxRates(): void {
		if (!this.showTaxOverwrite) {
			this.$emit('update-item-tax-rates', null);
		}
	}

	/**
	 * Handle tax rate selection change and reduce it to a key-value pair
	 *
	 * @param {PresetTaxes[]} selected
	 * @return {void}
	 */
	private handleTaxRateSelection(selected: PresetTaxes[]): void {
		const updatedTaxRates = selected.reduce((acc: Record<string, string>, tax) => {
			acc[tax.name] = tax.value;
			return acc;
		}, {});
		this.$emit('update-item-tax-rates', Object.keys(updatedTaxRates).length === 0 ? null : updatedTaxRates);
	}
}
