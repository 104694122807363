import { GetterTree } from 'vuex';
import { SectionState, RootState } from '../types';
import { state as menuState } from '../menus/menus';

export const getters: GetterTree<SectionState, RootState> = {
	getSelectedSection(state): MenuSection|object {
		return state.selectedSection;
	},

	getSection() {
		return (menuId: number, sectionId: number): MenuSection => {
			const tempMenu: Menu = menuState.menus.find((menu: Menu) => menu.id === menuId)!;
			return tempMenu?.sections?.find((section: MenuSection) => section.id === sectionId)!;
		};
	}
};