
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { isEqual } from 'lodash';
import pick from 'lodash/pick';
import { Debounce } from '@/utils/decorators';
import { DateTime } from 'luxon';
import DatePicker from '@/components/shared/DatePicker.vue';

@Component<EventsSearchFilters>({
	components: { DatePicker }
})
export default class EventsSearchFilters extends Vue {
	@Getter('getEventTypes', { namespace: 'eventTypes' }) private eventTypes!: EventType[];
	@Prop({ type: Boolean, required: true }) private disabled!: boolean;
	@Prop({ type: Object, required: true }) private query!: SearchEventsQueryParams;

	$refs!: {
		'date-picker': any;
	};

	private search: string = '';
	private eventTypeIds: number[] = [];
	private eventStatuses: string[] = [];
	private dateRange: string[] = [];
	private eventStatusesOptions: { [key: string]: string }[] = [
		{ text: this.$t('event_details.pre_order_status.pre_order_active'), value: 'pre_order_active' },
		{ text: this.$t('event_details.pre_order_status.pre_order_locked'), value: 'pre_order_locked' },
		{ text: this.$t('event_details.pre_order_status.past_event'), value: 'past_event' },
		{ text: this.$t('event_details.pre_order_status.setup_incomplete'), value: 'setup_incomplete' }
	];

	private get showClearButton(): boolean {
		const comparisonObject = pick(this.query, [
			'search',
			'eventTypeIds',
			'eventStatuses',
			'dateRange',
			'dateSortAsc'
		]);

		return !isEqual(comparisonObject, {
			search: '',
			eventTypeIds: [],
			eventStatuses: [],
			dateRange: [],
			dateSortAsc: false
		});
	}

	private created(): void {
		this.search = this.query.search;
		this.eventTypeIds = this.query.eventTypeIds;
		this.eventStatuses = this.query.eventStatuses;
		this.dateRange = this.query.dateRange;
	}

	/**
	 * Filter date picker's dates to allow only dates within 1 month range if a first date is selected
	 *
	 * @param {string} startDate - first date selected in the date range picker
	 * @return {boolean}
	 */
	private maxRange(startDate: string): boolean {
		 if (this.dateRange[0]) {
			const selectedDate = DateTime.fromISO(startDate);
			const minDate = selectedDate.minus({ month: 1 });
			const maxDate = selectedDate.plus({ month: 1 });

			return (
				selectedDate >= minDate &&
				selectedDate <= maxDate &&
				this.dateRange[0] >= minDate.toISODate() &&
				this.dateRange[0] <= maxDate.toISODate()
			);
		}

		return true;
	}

	/**
	 * Submit the date range if the input is complete
	 *
	 * @return {void}
	 */
	private handleDateInput(): void {
		if (this.dateRange.length !== 1) {
			this.$emit('submit', { type: 'dateRange', value: this.dateRange });
		}
	}

	/**
	 * Emit event to update search query data and fetch events
	 *
	 * @param {string} type
	 * @return {void}
	 */
	@Debounce(500)
	private submit(type: 'search'|'eventTypeIds'|'eventStatuses'|'dateRange'): void {
		this.$emit('submit', { type, value: this[type] });
	}

	/**
	 * Set all inputs to their initial values and emit clear filters event
	 *
	 * @return {void}
	 */
	private clearFilters(): void {
		this.search = '';
		this.eventTypeIds = [];
		this.eventStatuses = [];
		this.dateRange = [];
		if (this.$refs['date-picker']) {
			this.$refs['date-picker'].dateRange = [];
		};
		this.$emit('clear');
	}
}
