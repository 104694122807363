
import { Component, Vue, Prop } from 'vue-property-decorator';
import QuantityInput from '@/components/shared/suites/QuantityInput.vue';

@Component<OrderDetails>({
	components: { QuantityInput }
})
export default class OrderDetails extends Vue {
	@Prop({ type: Boolean, required: true }) private isEditing!: boolean;
	@Prop({ type: Boolean, required: true }) private loading!: boolean;
	@Prop({ type: Number, required: true }) private loadingIndex!: number;
	@Prop({ type: Object, required: true }) private order!: Order;
	@Prop({ type: Array, required: true }) private items!: OrderItem[];

	private get subtotal(): number | undefined {
		return this.order.data?.costs?.subtotal;
	}

	private get discount(): number | undefined {
		return this.order.data?.costs?.discount?.amount;
	}

	private get memberDiscount(): number | undefined {
		return this.order.data?.costs?.memberDiscount?.amount;
	}

	private get taxes(): number | undefined {
		return this.order.data?.costs?.taxes;
	}

	private get serviceCharge(): number | undefined {
		return this.order.data?.costs?.serviceCharge?.amount;
	}

	private get deliveryCharge(): number | undefined {
		return this.order.data?.costs?.deliveryCharge?.amount;
	}

	private get voucher(): number | undefined {
		return this.order.data?.costs?.voucher?.amount;
	}

	private get total(): number | undefined {
		return this.order.data?.costs?.total;
	}

	private get tip(): number | undefined {
		return this.order.data?.costs?.tip?.amount;
	}

	private get totalWithTip(): number | undefined {
		return this.order.data?.costs?.totalWithTip;
	}

	private get isAnyLoading(): boolean {
		return this.loading || this.loadingIndex > -1;
	}

	/**
	 * Get the option price depending of its quantity
	 *
	 * @param {Option} optionValue
	 * @param {number} itemQuantity
	 * @return {string}
	 */
	private getOptionTotalPrice(optionValue: Option, itemQuantity: number, parentOptionQuantity?: number): string {
		const { quantity, price } = optionValue;
		if (quantity && price) {
			return parentOptionQuantity ? (quantity * Number(price) * itemQuantity * parentOptionQuantity).toFixed(2) : (quantity * Number(price) * itemQuantity).toFixed(2);
		}
		else if (price) {
			return parentOptionQuantity ? (Number(price) * itemQuantity * parentOptionQuantity).toFixed(2) : (Number(price) * itemQuantity).toFixed(2);
		}
		return '';
	}
}
