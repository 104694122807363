
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import QuantityInput from '@/components/shared/suites/QuantityInput.vue';

const namespace = 'orders';

@Component<OrderOptions>({
	// Name is required for recursive components, they won't render in production without it
	name: 'OrderOptions',
	components: {
		QuantityInput
	}
})
export default class OrderOptions extends Vue {
	@Getter('getSelectedItem', { namespace }) private item!: MenuOrderItem;
	@Getter('getOrderItemOptions', { namespace }) private orderItemOptions!: OrderOptionGroup[];
	@Getter('getEditingItem', { namespace }) private isEditing!: boolean;
	@Prop({ type: Object, required: true }) private optionGroup!: OrderOptionGroup;
	@Prop({ type: Boolean, required: false }) private hasError!: boolean;
	@Prop({ type: Object, required: false }) private parentOption!: OrderOption;
	@Prop({ type: Boolean, required: false }) private subOption!: boolean;

	private mutableOptionGroup: OrderOptionGroup = {} as OrderOptionGroup;
	private subOptionErrors: number[] = [];
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	/**
	 * Returns the option group with the selected options and sub options
	 * If an option has a quantity > 0 or is a default selection, it is included
	 * This is what is sent the the parent component when an option is updated
	 *
	 * @return {OrderOptionGroup}
	 */
	private get orderOptionGroup(): OrderOptionGroup {
		return {
			...this.mutableOptionGroup,
			values: this.mutableOptionGroup.values.map((option: OrderOption) => {
				// Map through options so that the sub options are also included
				if (option.quantity || option.default_selection) {
					return {
						...option,
						// Check quantity of option again here because default selections will always be included in the options,
						// but we don't want to include their sub options if they're not selected
						options: option.options && option.quantity ? option.options.map((subOptionGroup: OrderOptionGroup) => {
							return {
								...subOptionGroup,
								values: subOptionGroup.values.filter((subOption: OrderOption) => subOption.quantity)
							};
						}) : undefined
					};
				}
				return option;
			}).filter((option: OrderOption) => option.quantity || option.default_selection)
		};
	}

	/**
	 * Checks for if the number of selected options is at the maximum allowed
	 *
	 * @return {boolean}
	 */
	private get optionsAtMax(): boolean {
		if (!this.optionGroup.limit) {
			return false;
		}
		let selectedCount = 0;
		this.orderOptionGroup.values.forEach((option: OrderOption) => {
			if (option.quantity) {
				selectedCount += option.quantity;
			}
		});

		return selectedCount >= this.optionGroup.limit;
	}

	/**
	 * Checks if a pricing option is selected
	 *
	 * @return {boolean}
	 */
	private get pricingOptionSelected(): boolean {
		if (this.optionGroup.optionGroupType !== 'pricing') {
			return false;
		}
		return this.orderOptionGroup.values.length !== 0;
	}

	private created(): void {
		this.mutableOptionGroup = { ...this.optionGroup };

		if (this.isEditing) {
			const selectedOptions = this.orderItemOptions.find((optionGroup: OrderOptionGroup) => optionGroup.id === this.optionGroup.id)?.values;
			if (selectedOptions) {
				this.setSelectedOptions(selectedOptions, this.mutableOptionGroup);
			}
		}
		// Don't set default options if the option group is being edited because the selected options should overwite the defaults
		if (!this.isEditing && this.mutableOptionGroup.values.some((option: OrderOption) => option.default_selection)) {
			this.setDefaultOptions();
		}
	}

	/**
	 * Sets the options and sub options that were selected when the item was added to the cart
	 * This is needed because the selected options don't have every option and sub option, only the selected ones
	 * So we need to set the selected options on the mutableOptionGroup, which is the option group from the menu item
	 * This is used when editing an item to set the selected options
	 *
	 * @return {void}
	 */
	private setSelectedOptions(selectedOptions: OrderOption[], mutableOptionGroup: OrderOptionGroup): void {
		for (const option of selectedOptions) {
			const mutableOption = mutableOptionGroup.values.find((opt: OrderOption) => opt.id === option.id);
			if (mutableOption) {
				mutableOption.quantity = option.quantity;
			}
			// Set selected sub options if the option has any
			if (option.options?.length && mutableOption?.options?.length) {
				option.options.forEach((selectedSubOptionGroup: OrderOptionGroup) => {
					const mutableSubOptionGroup = mutableOption.options!.find((subOptGroup: OrderOptionGroup) => subOptGroup.id === selectedSubOptionGroup.id);
					this.setSelectedOptions(selectedSubOptionGroup.values, mutableSubOptionGroup!);
				});
			}
		}
		this.onOptionUpdated();
	}

	/**
	 * Sets any default options for the option group
	 *
	 * @return {void}
	 */
	private setDefaultOptions(): void {
		this.mutableOptionGroup.values.forEach((option: OrderOption) => {
			if (option.default_selection) {
				option.quantity = 1;
			}
		});

		this.onOptionUpdated();
	}

	/**
	 * Validate that the option group has the required options selected
	 * This gets called from OrderModifiers.vue, which loops through each option group and calls this method
	 * If any required options are not selected, the option group id is returned and is pushed to an array in OrderModifiers.vue
	 * An array of option group ids will only be returned if multiple sub options are required and none are selected (which I don't think we support, but I handle it just in case)
	 * If nothing is returned, then the option group is valid
	 *
	 * @return {number | number[] | void}
	 */
	public validateOptionGroup(): number | number[] | void {
		// Filter out any unselected default options (0 quantity)
		const selectedOptions = this.orderOptionGroup.values.filter((option: OrderOption) => option.quantity);

		// If an option group has selected options it's valid, so then validate the sub options
		if (selectedOptions.length) {
			const subOptionError = this.validateSubOptions(selectedOptions);
			if (subOptionError) {
				return subOptionError;
			}
		}
		// Return an error if the option group is required and no options are selected
		else if (this.orderOptionGroup.requiredOption) {
			return this.mutableOptionGroup.id!;
		}
	}

	/**
	 * Validate all sub option groups for the each selected option
	 *
	 * @param {OrderOption[]} options - The selected options
	 * @return {number[] | void}
	 */
	private validateSubOptions(options: OrderOption[]): number[] | void {
		const subOptionErrors: number[] = [];
		options.forEach((option: OrderOption) => {
			// Check if the option has sub options
			if (option.options && option.options.length) {
				// Loop through each sub option group
				option.options.forEach((subOptionGroup: OrderOptionGroup) => {
					// Check if the sub option group is required
					if (subOptionGroup.requiredOption) {
						// Check if any sub options are selected
						const selectedSubOptions = subOptionGroup.values.filter((subOption: OrderOption) => subOption.quantity);
						if (!selectedSubOptions.length) {
							subOptionErrors.push(subOptionGroup.id!);
						}
					}
				});

			}
		});
		if (subOptionErrors.length) {
			this.subOptionErrors = subOptionErrors;
			return subOptionErrors;
		}
		else {
			this.subOptionErrors = [];
		}
	}

	/**
	 * Update the selected options when an option is selected
	 * This primarily works by incrementing/decrementing/setting the quantity of the selected option
	 * This `orderOptionGroup` computed property checks the mutableOptionGroup's options for their quantity,
	 * and returns any options with a quantity greater than 0
	 *
	 * @param {OrderOption} option
	 * @return {void}
	 */
	private updateSelectedOptions(option: OrderOption): void {
		// If the option group is multiselect then increment/decrement the selected option
		if (this.mutableOptionGroup.multipleOption) {
			// If the quantity is > 0 then it means the option is selected, so unselect it
			if (option.quantity) {
				option.quantity = 0;
			}
			else {
				if (this.optionsAtMax) {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('orders.item_viewer.max_options_selected', { limit: this.optionGroup.limit });
					return;
				}
				option.quantity++;
			}
		}
		else {
			// The radio buttons should work like checkboxes, so we need to check if the option is already selected so we don't increment it after resetting all quantities
			const removeOption = !!(this.orderOptionGroup.values.find((opt: OrderOption) => (opt.id === option.id && opt.quantity)));

			// Reset all quantities to 0
			this.mutableOptionGroup.values.forEach((opt: OrderOption) => {
				opt.quantity = 0;
			});

			// Increment the selected option if it's not already selected
			if (!removeOption) {
				option.quantity++;
			}
		}
		this.onOptionUpdated();
	}

	/**
	 * When a suboption is selected update the parent option
	 *
	 * @param {OrderOptionGroup} optionGroup
	 * @param {OrderOption} parentOption - The option
	 * @return {void}
	 */
	private updateSubOption(optionGroup: OrderOptionGroup, parentOption: OrderOption): void {
		// Find the option to update sub options for
		const optionToUpdate = this.mutableOptionGroup.values.find((option: OrderOption) => option.id === parentOption.id);

		if (optionToUpdate && optionToUpdate.options) {
			// Find the sub option group to update
			let subOption = optionToUpdate.options.find((subOptionGroup: OrderOptionGroup) => subOptionGroup.id === optionGroup.id);

			if (subOption) {
				subOption = optionGroup;
			}
			this.onOptionUpdated();
		}
	}
	/**
	 * Increase the quantity of the selected option
	 *
	 * @param {OrderOption} option
	 * @return {void}
	 */
	private increaseQuantity(option: OrderOption): void {
		// Check if the selected options are at the maximum allowed
		if (this.optionsAtMax) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('orders.item_viewer.max_options_selected', { limit: this.optionGroup.limit });
			return;
		}
		// Check if the option quantity is at the maximum allowed
		if (option.quantity === option.max_quantity) {
			return;
		}
		option.quantity++;
		this.onOptionUpdated();
	}

	/**
	 * Decrease the quantity of the selected option, if the quantity is greater than 0
	 *
	 * @param {OrderOption} option
	 * @return {void}
	 */
	private decreaseQuantity(option: OrderOption): void {
		if (option.quantity >= 0) {
			option.quantity--;
			this.onOptionUpdated();
		}
	}

	/**
	 * Remove the pricing options from the order
	 * This happens when the default pricing option is selected, so there shouldn't be any pricing options selected
	 *
	 * @return {void}
	 */
	private removePricingOptions(): void {
		this.mutableOptionGroup.values.forEach((opt: OrderOption) => {
			opt.quantity = 0;
		});
		this.onOptionUpdated();
	}

	/**
	 * Emit the option-updated event to the parent component
	 * For regular options this event is handled in OrderModifiers.vue
	 * For sub-options this event is handled in the updateSubOption methond in this component
	 * Which then emits this event again to OrderModifiers.vue
	 *
	 * @return {void}
	 */
	private onOptionUpdated(): void {
		if (this.subOption) {
			this.$emit('option-updated', this.mutableOptionGroup, this.parentOption);
		}
		else {
			this.$emit('option-updated', this.orderOptionGroup);
		}
	}
}

