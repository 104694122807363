import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { EventTypeState, RootState } from '../types';
const namespaced: boolean = true;

export const state: EventTypeState = {
	eventTypes: []
};


export const EventTypes: Module<EventTypeState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
};

export default EventTypes;