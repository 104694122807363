
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { cleanAvailabilities } from '../../utils/availability';
import { formatMenu } from '../../utils/formatItem';
import { TOAST_INSTANCE } from '../../utils/constants';
import NameAndPricing from '@/components/shared/form/NameAndPricing.vue';
import FileUpload from '@/components/shared/form/FileUpload.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import Availability from './form/Availability.vue';
import MenuType from './form/MenuType.vue';
import MenuLocaleEditor from './form/MenuLocaleEditor.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'menus';

@Component<MenuEditor>({
	components: {
		NameAndPricing,
		FileUpload,
		Availability,
		MenuType,
		MenuLocaleEditor,
		Tooltip,
		ConfirmationModal,
		BannerToast
	}
})
export default class MenuEditor extends Vue {
	@Action('createMenu', { namespace }) private createMenu!: (payload: object) => Promise<void>;
	@Action('updateMenu', { namespace }) private updateMenu!: (payload: object) => Promise<void>;
	@Prop({ type: Boolean, default: true }) private isCreating!: boolean;
	@Prop({ type: Boolean, default: true }) private disabled!: boolean;
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;

	private mutableMenu: Menu = this.menu;
	private loading: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	private initialAllowSectionsValue = this.menu.allow_sections;
	private showDisableSectionsConfirmModal: boolean = false;
	private showEnableSectionsConfirmModal: boolean = false;

	// Image upload
	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;
	private mutableImageFile: string | null = null;

	private get valid(): boolean {
		return 	!!this.mutableMenu.name
			// && !!this.mutableMenu.type **TEMPORARY DEFAULT TO A LA CARTE**
			&& (this.mutableMenu.dine_in
				|| this.mutableMenu.on_demand
				|| this.mutableMenu.scheduled);
		// && (this.mutableMenu.type === 'a-la-carte'
		// 	|| (!isFalseyButNotZero(this.mutableMenu.price)
		// 		&& Number(this.mutableMenu.price) >= 0));

	}

	private get sectionsDisabled(): boolean {
		return !this.mutableMenu.allow_sections;
	}

	private set sectionsDisabled(value: boolean) {
		this.mutableMenu.allow_sections = !value;
	}

	private get memberOnly(): boolean {
		return this.mutableMenu.member_only;
	}

	private set memberOnly(value: boolean) {
		this.mutableMenu.member_only = value;
	}

	/**
	 * Makes necessary checks for warnings or confirmation modals before
	 * saving. If nothing else needs to be shown, saves immediately
	 *
	 * @return {void}
	 */
	private preSave (): void {
		if (!this.isCreating && this.initialAllowSectionsValue !== this.mutableMenu.allow_sections) {
			const hasItems: boolean = !!this.mutableMenu?.items?.length;
			const hasSections: boolean = !!this.mutableMenu?.sections?.length;
			if (this.mutableMenu.allow_sections && hasItems) {
				this.showEnableSectionsConfirmModal = true;
			}
			else if (hasSections) {
				this.showDisableSectionsConfirmModal = true;
			}
			else {
				this.save();
			}
		}
		else {
			this.save();
		}
	}

	/**
	 * Closes off all confirmation modals
	 *
	 * @return {void}
	 */
	private cancelConfirmModal (): void {
		this.showEnableSectionsConfirmModal = false;
		this.showDisableSectionsConfirmModal = false;
	}

	/**
	 * Default dine_in to true if we are creating a menu
	 *
	 * @return {void}
	 */
	private created(): void {
		if (this.isCreating) {
			this.mutableMenu.allow_sections = true;
		}
	}

	/**
	 * Save the menu (either create or update)
	 *
	 * @return {Promise<void>}
	 */
	private async save(): Promise<void> {
		this.loading = true;
		if (this.isCreating) {
			await this.createMenu({
				menu: formatMenu(this.mutableMenu),
				availability: cleanAvailabilities(this.mutableMenu.availability!),
				imageFile: this.mutableImageFile
			})
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = `Error when trying to create ${this.mutableMenu.name}: ${errorMessage}`;
				});
		}
		else {
			await this.updateMenu({
				menu: formatMenu(this.mutableMenu),
				availability: cleanAvailabilities(this.mutableMenu.availability!),
				imageFile: this.mutableImageFile
			})
				.then(() => {
					this.$emit('close-modal', true);
				})
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = `Error when trying to update ${this.mutableMenu.name}: ${errorMessage}`;
				});
		}
		this.loading = false;
	}
}
