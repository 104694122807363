
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SectionList from '@/components/SectionList.vue';

const namespace: string = 'menus';

@Component<Sections>({
	components: {
		SectionList
	}
})
export default class Sections extends Vue {
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Getter('getMenu', { namespace }) private getMenu!: (id: number) => Menu;
	@Prop({ type: Number, default: 0 }) private menu_id!: number;

	private get menu(): Menu {
		return this.getMenu(this.menu_id);
	}
}
