
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

const namespace = 'orders';

@Component<OrderMenuCard>({})
export default class OrderMenuCard extends Vue {
	@Action('setSelectedItem', { namespace }) private setSelectedItem!: (item: MenuItem) => void;
	@Prop({ type: Object, required: true }) private item!: MenuItem;

	private get imageURL(): string {
		if (process.env.NODE_ENV === 'development') {
			return `${this.item.image}`;
		}
		return `${process.env.VUE_APP_S3_BUCKET_BASE_URL}/${this.item.image}`;
	}

	/**
	 * Set the selected item if it is not sold out
	 *
	 * @return {void}
	 */
	private itemSelected(): void {
		if (this.item.sold_out || this.item.unavailable) {
			return;
		}
		this.setSelectedItem(this.item);
	}
}
