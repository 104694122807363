
import { Component, Vue } from 'vue-property-decorator';
import RestaurantList from '@/components/RestaurantList.vue';

@Component<Restaurants>({
	components: {
		RestaurantList
	}
})
export default class Restaurants extends Vue {
}
