
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { ROLES } from '@/utils/constants';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';


@Component<OrderModalActions>({
	components: {
		StandardModal,
		TextButton,
		WarningModal
	}
})
export default class OrderModalActions extends Vue {
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Prop({ type: Boolean, required: true }) private isSuitesCateringEditing!: boolean;
	@Prop({ type: Boolean, required: true }) private disableSave!: boolean;
	@Prop({ type: Number, required: true }) private loadingItemIndex!: number;
	@Prop({ type: Array, required: true }) private initialOrderItems!: OrderItem[];
	@Prop({ type: Object, required: true }) private order!: Order;
	@Prop({ type: Object, required: true }) private loading!: { [key: string]: boolean };

	private roles: Roles = ROLES;

	private get isAnyLoading(): boolean {
		return Object.values(this.loading).some(value => value === true) || this.loadingItemIndex > -1;
	}

	// Check for suites catering orders if the event preordering is locked or not
	private get isOrderSuitesCateringAndNotLockedYet(): boolean {
		if (this.order.type === 'catering') {
			const { eventLockPeriod, eventStartDate } = this.order.data.suites!;
			const today: DateTime = DateTime.local();
			const preOrderLockDate: DateTime = DateTime.fromISO(eventStartDate).minus({ days: eventLockPeriod });
			if (today < preOrderLockDate) {
				return true;
			}
		}
		return false;
	}

	private redirectToOrderDetails(): void {
		this.$router.push(`/order-management/${this.order.id}`).catch(() => {});
	}
}
