import Auth from '@/services/auth/auth';
import i18n from '@/i18n';

export function handleAllErrors (error: any): Error {
	const errorResponseData = error?.response?.data;
	// Lambda authorizer returned a 403 meaning the user token is either invalid or expired, log them out
	if (error?.response?.status === 403 && error?.code === 'ERR_BAD_REQUEST' && error?.name === 'AxiosError') {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
		throw (i18n.t('shared.error_timed_out'));
	}
	else if (errorResponseData) {
		return handleAPIError(errorResponseData);
	}
	else {
		throw (error);
	}
}

/**
 * Throw the respective error depending on the status code.
 * There are specific error codes that we wanna return a
 * personalized message, others not.
 *
 * @param {APIError} error
 */
export function handleAPIError (error: APIError): Error {
	// Check if the error is type of string and is a network
	// failure, meaning there is something wrong with the session.
	// IE: token has expired.
	if (error.message === 'Network Error') {
		error = {
			statusCode: 401,
			error: 'Forbidden',
			message: i18n.t('shared.error_timed_out')
		};
	}

	if (error.statusCode === 401) {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
		throw (i18n.t('shared.error_timed_out'));
	}
	if (error.statusCode === 403) {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
		throw (i18n.t('shared.error_tenant_not_recognized'));
	}
	if (error.statusCode === 500) {
		if (error.code === 2305) {
			throw ('preauth_failed');
		}
		if (error.code === 2308) {
			throw (i18n.t('shared.error_order_cannot_be_completed_before_due_date'));
		}
		if (error.code === 2501) {
			throw (i18n.t('shared.error_request_deposit_amount_exceeds_limit', { requestedAmount: error.requestedAmount ? +error.requestedAmount?.toFixed(2) : 0, outstandingAmount: error.requestLimit ? +error.requestLimit?.toFixed(2) : 0 }));
		}
		throw (i18n.t('shared.error_internal_server_error'));
	}
	throw (typeof error.message === 'string' ? error.message : error.message[0]);
}