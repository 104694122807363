
import { Getter, Action } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TOAST_INSTANCE, ROLES } from '../utils/constants';
import { cloneDeep, isEqual, isNil } from 'lodash';
import omitBy from 'lodash/omitBy';
import TitleHeader from '@/components/shared/Header.vue';
import ListItem from '@/components/shared/ListItem.vue';
import AddButton from '@/components/shared/AddButton.vue';
import ModalWrapper from '@/components/shared/ModalWrapper.vue';
import MoveModal from '@/components/shared/MoveModal.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';
import ItemEditor from '@/components/item/ItemEditor.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'items';

@Component<ItemList>({
	components: {
		TitleHeader,
		ListItem,
		AddButton,
		ModalWrapper,
		ItemEditor,
		ConfirmationModal,
		MoveModal,
		BannerToast
	}
})
export default class ItemList extends Vue {
	@Action('fetchMenus', { namespace: 'menus' }) private fetchMenus!: () => Promise<Menu[]>;
	@Getter('getMenus', { namespace: 'menus' }) private menusData!: Menu[];
	@Action('deleteMenuItem', { namespace }) private deleteMenuItem!: (payload: object) => Promise<void>;
	@Action('deleteMenuSectionItem', { namespace }) private deleteMenuSectionItem!: (payload: object) => Promise<void>;
	@Action('duplicateMenuSectionItem', { namespace }) private duplicateMenuSectionItem!: (payload: object) => Promise<void>;
	@Action('duplicateMenuItem', { namespace }) private duplicateMenuItem!: (payload: object) => Promise<void>;
	@Action('setSelectedItem', { namespace }) private setSelectedItem!: (item: MenuItem | object) => Promise<void>;
	@Action('updateMenuSectionItem', { namespace }) private updateMenuSectionItem!: (payload: object) => Promise<void>;
	@Action('updateMenuItem', { namespace }) private updateMenuItem!: (payload: object) => Promise<void>;
	@Getter('getSelectedItem', { namespace }) private selectedItem!: MenuItem;
	@Getter('getFetchMenusComplete', { namespace: 'menus' }) private fetchMenusComplete!: boolean;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Getter('isPosIntegrated', { namespace: 'auth' }) private isPosIntegrated!: boolean;
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;
	@Prop({ type: Object, default: () => {} }) private section!: MenuSection;
	private showDialog: boolean = false;
	private showConfirmCloseModal: boolean = false;
	private isCreating: boolean = false;
	private showDeleteModal: boolean = false;
	private hasBeenEdited: boolean = false;
	private loading: boolean = false;
	private showMoveModal: boolean = false;

	private itemToDelete: MenuItem = {} as MenuItem;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;
	private moveItem: MenuItem = {} as MenuItem;
	private selectedLocale: RestaurantLocale|null = null;

	private selectedItemBeforeEditSnapshot: MenuItem = {} as MenuItem;

	private actionsArray: ActionItem[] = [
		{
			iconName: 'mdi-archive-arrow-down',
			buttonLabel: this.$t('shared.actions_array.btn_archive'),
			onClick: async (evt: object, item: MenuItem): Promise<void> => {
				this.archiveItem(item);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-archive-arrow-up',
			buttonLabel: this.$t('shared.actions_array.btn_publish'),
			onClick: async (evt: object, item: MenuItem): Promise<void> => {
			 	this.publishItem(item);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-pencil',
			buttonLabel: this.$t('shared.actions_array.btn_edit'),
			onClick: (evt: object, item: MenuItem): void => {
				this.openItemEditor(false, item);
				this.setSelectedItem(item);
			}
		},
		{
			iconName: 'mdi-file-multiple',
			buttonLabel: this.$t('shared.actions_array.btn_duplicate'),
			onClick: async (evt: object, item: MenuItem): Promise<void> => {
				this.duplicateItem(item);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-arrow-right',
			buttonLabel: this.$t('shared.actions_array.btn_move'),
			onClick: (evt: object, item: MenuItem): void => {
				this.openMoveModal(item);
			},
			accessLevel: this.roles.admin
		},
		{
			iconName: 'mdi-delete',
			buttonLabel: this.$t('shared.actions_array.btn_delete'),
			onClick: (evt: object, item: MenuItem): void => {
				this.setItemToDeleteName(item);
			},
			accessLevel: this.roles.enterprise
		},
		{
			iconName: 'mdi-window-close',
			buttonLabel: this.$t('shared.actions_array.btn_cancel'),
			onClick: null
		}
	];

	private get type (): string {
		return this.section ? 'sectionItem' : 'menuItem';
	}

	private get getAvailableMoveDestinations(): Menu[] {
		return this.menusData.filter((_menu) => (
			this.type === 'sectionItem'
				? _menu.allow_sections && _menu.sections?.length
				: !_menu.allow_sections && _menu.id !== this.menu.id
		));
	}

	private get items(): MenuItem[] {
		if (this.section?.items) {
			return this.section.items;
		}
		else {
			return (this.menu?.items) || [];
		}
	}

	private async isAdmin() {
		return this.roleLevel === this.roles.admin;
	}

	private get publishedItems(): MenuItem[] {
		return this.items
			.filter(item => item.published_at)
			.map(item => ({ ...item, menu_id: this.menu.id as number }));
	}

	private get unpublishedItems(): MenuItem[] {
		return this.items.filter(item => !item.published_at);
	}

	private async closeMoveModal (saved: boolean = false) {
		this.showMoveModal = false;
		if (saved) {
			this.loading = true;
			// The move action changes multiple sections across different menus, which is why
			// we need to refetch the menus, not just the sections.
			await this.fetchMenus()
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('menu.item.error_fetching_items', { errorMessage });
				});
			this.loading = false;
		}
	}

	private async openMoveModal (item: MenuItem) {
		this.showMoveModal = true;
		this.moveItem = item;
	}

	/**
	 * Open Item Modal
	 * @return {void}
	 */
	private openItemEditor(creating: boolean, item: MenuItem) {
		this.showDialog = true;
		this.isCreating = creating;

		if (!this.isCreating) {
			// Gotta validate a few things for isEqual checker
			this.selectedItemBeforeEditSnapshot = cloneDeep(item);
			if(this.selectedItemBeforeEditSnapshot.ingredients === '') {
				delete (this.selectedItemBeforeEditSnapshot as any).ingredients;
			}
			if(this.selectedItemBeforeEditSnapshot.price_unit === '') {
				delete (this.selectedItemBeforeEditSnapshot as any).price_unit;
			}
			if(!this.selectedItemBeforeEditSnapshot.additional_prices) {
				this.selectedItemBeforeEditSnapshot.additional_prices = { A: this.selectedItemBeforeEditSnapshot.price };
			}
		}
		else {
			this.selectedItemBeforeEditSnapshot = { price_type: this.$t('menu.item.editor.default_price.type_default_value') } as MenuItem;
		}


		document.documentElement.classList.add('modal-open');
	}

	/**
	 * Cancel closing of modal through the confirmation close modal
	 *
	 * @return {void}
	 */
	private cancelCloseModal(): void {
		this.showConfirmCloseModal = false;
	}

	/**
	 * Set the item name for the deletion modal
	 *
	 * @return {void}
	 */
	private setItemToDeleteName(item: MenuItem): void {
		this.showDeleteModal = true;
		this.itemToDelete = item;
	}

	/**
	 * Check if needs to show the confirm close modal to the user
	 *
	 * @return {void}
	 */
	private confirmClose(saved: boolean = false) {
		const selectedItemToCompare = omitBy(this.selectedItem, isNil);
		const selectedItemBeforeEditSnapshotToCompare = omitBy(this.selectedItemBeforeEditSnapshot, isNil);
		this.selectedLocale = null;
		if (saved || (!this.hasBeenEdited && isEqual(selectedItemToCompare, selectedItemBeforeEditSnapshotToCompare))) {
			this.close();
		}
		else {
			this.showConfirmCloseModal = true;
		}
	}

	/**
	 * Close the ItemEditor modal
	 *
	 * @return {void}
	 */
	private close() {
		this.showConfirmCloseModal = false;
		this.hasBeenEdited = false;
		this.showDialog = false;
		this.isCreating = false;

		// Remove the selected item from the store
		this.setSelectedItem({});
		document.documentElement.classList.remove('modal-open');
	}

	private cancel(): void {
		this.showDeleteModal = false;
		this.itemToDelete = {} as MenuItem;
	}


	private async archiveItem(item: MenuItem): Promise<void> {
		if (this.section) {
			await this.updateMenuSectionItem({ menu: this.menu, section: this.section, item: { id: item.id, published: false } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_archiving', { name: item.name, errorMessage });
				});
		}
		else {
			await this.updateMenuItem({ menu: this.menu, item: { id: item.id, published: false } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_archiving', { name: item.name, errorMessage });
				});
		}
	}

	private async publishItem(item: MenuItem): Promise<void> {
		if (this.section) {
			await this.updateMenuSectionItem({ menu: this.menu, section: this.section, item: { id: item.id, published: true } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_publishing', { name: item.name, errorMessage });
				});
		}
		else {
			await this.updateMenuItem({ menu: this.menu, item: { id: item.id, published: true } })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_publishing', { name: item.name, errorMessage });
				});
		}
	}

	private async duplicateItem(item: MenuItem): Promise<void>{
		if (this.section) {
			await this.duplicateMenuSectionItem({ oldMenu: this.menu, newMenu: this.menu, oldSection: this.section, newSection: this.section, item })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_duplicating', { name: item.name, errorMessage });
				});
		}
		else {
			await this.duplicateMenuItem({ oldMenu: this.menu, newMenu: this.menu, item })
				.catch((errorMessage: string) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_duplicating', { name: item.name, errorMessage });
				});
		}
	}

	/**
	 * User confirmed deletion of the item, so we delete it
	 * and close the modal
	 *
	 * @return {Promise<void>}
	 */
	private async confirm(): Promise<void> {
		this.loading = true;
		if (this.section) {
			await this.deleteMenuSectionItem({ menu: this.menu, section: this.section, item: this.itemToDelete })
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: this.itemToDelete.name, errorMessage });
				});
		}
		else {
			await this.deleteMenuItem({ menu: this.menu, item: this.itemToDelete })
				.catch((errorMessage) => {
					this.bannerToastInfo.showMessage = true;
					this.bannerToastInfo.message = this.$t('shared.error_deleting', { name: this.itemToDelete.name, errorMessage });
				});
		}
		this.itemToDelete = {} as MenuItem;
		this.loading = false;
		this.showDeleteModal = false;
	}
}
