
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import StyledGoogleAutocomplete from '@/components/shared/StyledGoogleAutocomplete.vue';
import '@/validation-rules';

@Component<DeliveryDefault>({
	components: {
		StyledGoogleAutocomplete,
		ValidationProvider
	}
})
export default class DeliveryDefault extends Vue {
	@Prop({ type: Object, required: true }) private restaurantDeliveryInfo!: AddressDelivery;
	@Prop({ type: Object, required: true }) private deliveryInformation!: DeliveryInfo;
	@Prop({ type: String, required: false, default: '' }) private coordinatesErrorMsg!: string;

	private delivery: DeliveryInfo = {
		type: 'address',
		address: '',
		extra: ''
	};
	private autocompleteFilledUp: boolean = false;

	/**
	 * Set the temporary delivery information to the
	 * vuex store values if there are any to prevent
	 * refilling the same information again, also verifying
	 * if there are lat/lng to make sure that we can compare
	 * the distance.
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if (this.deliveryInformation) {
			const splitAddress = this.deliveryInformation.address?.split('. Extra (Apt,Suite,Etc.): ');
			if (splitAddress?.length) {
				const address = splitAddress[0];
				const extra = splitAddress[1] || '';
				this.delivery = {
					...this.deliveryInformation,
					address,
					extra
				};
			}
			else {
				this.delivery = this.deliveryInformation;
			}
			this.delivery.type = 'address';

			if(this.delivery.lat && this.delivery.lng) {
				this.autocompleteFilledUp = true;
			}
		}
	}

	/**
	 * Send event to the parent to update the delivery
	 * information in the vuex store
	 *
	 * @return {void}
	 */
	private updateDeliveryInfo(): void {
		this.$emit('delivery-info-updated', this.delivery);
	}


	/**
	 * Clear selection on input to force user to
	 * select an address from the autocomplete
	 *
	 * @return {void}
	 */
	private clearDeliverySelection(): void {
		if(this.delivery.address) {
			this.delivery = {
				type: 'address',
				address: '',
				extra: ''
			};
			this.updateDeliveryInfo();
		}
	}

	/**
	 * Deconstruct the address object returned by
	 * the autocomplete.
	 *
	 * @param {any} addressData - Address data returned by google autocomplete
	 * @param {any} placeResultData - Place data returned by google autocomplete
	 * @return {void}
	 */
	private autofillAddress(addressData: any, placeResultData: any): void {
		this.delivery.address = placeResultData.formatted_address;
		this.delivery.country = addressData.country;
		this.delivery.lat = addressData.latitude;
		this.delivery.lng = addressData.longitude;
		this.delivery.city = addressData.locality;
		this.delivery.province = this.filterProvince(addressData.administrative_area_level_1);
		this.delivery.postalCode = addressData.postal_code;
		this.delivery.streetName = addressData.route;
		this.delivery.streetNumber = addressData.street_number;
		this.delivery.radius = this.restaurantDeliveryInfo.radius;
		this.updateDeliveryInfo();
	}

	/**
	* When the location found
	* @param {any} addressData - Data of the found location
	* @param {any} placeResultData - PlaceResult object
	* @return {void}
	*/
	private getAddressData(addressData: any, placeResultData: any): void {
		this.autocompleteFilledUp = true;
		this.autofillAddress(addressData, placeResultData);
	}

	/**
	 * Filter provinces from short_name to long_name because
	 * autocomplete return the long_name only in the array object
	 * without variables so it's more optimal to have a switch case
	 * with the short_name than to loop through the 8 values returned
	 *
	 * @param {string} provinceShortName
	 * @return {string}
	 */
	private filterProvince(provinceShortName: string): string {
		switch (provinceShortName.toLowerCase()) {
			case 'ab':
				return this.$t('utils.province_filters.ab');
			case 'bc':
				return this.$t('utils.province_filters.bc');
			case 'mb':
				return this.$t('utils.province_filters.mb');
			case 'nb':
				return this.$t('utils.province_filters.nb');
			case 'nl':
				return this.$t('utils.province_filters.nl');
			case 'ns':
				return this.$t('utils.province_filters.ns');
			case 'nt':
				return this.$t('utils.province_filters.nt');
			case 'on':
				return this.$t('utils.province_filters.on');
			case 'pe':
				return this.$t('utils.province_filters.pe');
			case 'qc':
				return this.$t('utils.province_filters.qc');
			case 'sk':
				return this.$t('utils.province_filters.sk');
			case 'yt':
				return this.$t('utils.province_filters.yt');
			default:
				return '';
		}
	}
}
