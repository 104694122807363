import { MutationTree } from 'vuex';
import { OptionState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<OptionState> = {

	/**
	 * Update the add-ons
	 *
	 * @param state
	 * @param card
	 */
	SET_ADD_ONS(state, addOns: Option[]) {
		Vue.set(state, 'addOns', addOns);
	},

	/**
	 * Update the add-on option groups
	 *
	 * @param state
	 * @param card
	 */
	SET_ADD_ON_OPTION_GROUPS(state, addOnOptionGroups: OptionGroup[]) {
		Vue.set(state, 'addOnOptionGroups', addOnOptionGroups);
	},

	/**
	 * Update the selected add-on option group
	 *
	 * @param state
	 * @param card
	 */
	SET_SELECTED_ADD_ON_OPTION_GROUP(state, selectedAddOnOptionGroup: OptionGroup) {
		Vue.set(state, 'selectedAddOnOptionGroup', selectedAddOnOptionGroup);
	},

	SET_ITEM_OPTION_GROUPS(state, itemOptionGroups) {
		Vue.set(state, 'itemOptionGroups', itemOptionGroups);
	}
};