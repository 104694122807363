
import { Component, Prop, Vue } from 'vue-property-decorator';
import SettingDescriber from '@/components/shared/SettingDescriber.vue';

@Component<CustomCardStyles>({
	components: {
		SettingDescriber
	}
})
export default class CustomCardStyles extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetMenuConfig!: MenuConfiguration;

	private mutableMenuConfiguration: MenuConfiguration = this.targetMenuConfig;
	private cardOptions: RadioOptions[] = [
		{ name: this.$t('customize.card_styles.vertical_card_style_rb_label'), value: 'vertical', image: 'vertical-card.svg', option: 1 },
		{ name: this.$t('customize.card_styles.horizontal_card_style_rb_label'), value: 'horizontal', image: 'horizontal-card.svg', option: 2 }
	];
	private cardOptionSelected: number = this.mutableMenuConfiguration.card_style === 'horizontal' ? 2 : 1;

	private changeCardOption(): void {
		Vue.set(this.mutableMenuConfiguration, 'card_style', this.cardOptions.filter((cardOption: RadioOptions) => cardOption.option === this.cardOptionSelected)[0].value?.toLowerCase());
	}
}
