import { MutationTree } from 'vuex';
import { SuiteState } from '../types';
import { alphabeticalSort } from '@/utils/sort';
import Vue from 'vue';

export const mutations: MutationTree<SuiteState> = {
	/**
	 * Set suites
	 *
	 * @param {SuiteState} state
	 * @param {Suite[]} suites
	 */
	SET_SUITES(state: SuiteState, suites: Suite[]) {
		Vue.set(state, 'suites', suites);
	},

	/**
	 * Add a new suite
	 *
	 * @param {SuiteState} state
	 * @param {Suite} newSuite
	 * @return {void}
	 */
	ADD_SUITE(state: SuiteState, newSuite: Suite): void {
		Vue.set(state, 'suites', [...state.suites, newSuite].sort(alphabeticalSort));
	},

	/**
	 * Update a suite
	 *
	 * @param {SuiteState} state
	 * @param {Suite} updatedSuite
	 * @return {void}
	 */
	UPDATE_SUITE(state: SuiteState, updatedSuite: Suite): void {
		Vue.set(state, 'suites', state.suites.map(suite => suite.id === updatedSuite.id ? updatedSuite : suite).sort(alphabeticalSort));
	},

	/**
	 * Delete a suite
	 *
	 * @param {SuiteState} state
	 * @param {number} suiteId
	 * @return {void}
	 */
	DELETE_SUITE(state: SuiteState, suiteId: number): void {
		Vue.set(state, 'suites', state.suites.filter(suites => suites.id !== suiteId));
	}
};