import { GetterTree } from 'vuex';
import { EventTypeState, RootState } from '../types';

export const getters: GetterTree<EventTypeState, RootState> = {
	getEventTypes(state): EventType[] {
		return state.eventTypes;
	},

	getEventType(state) {
		return (id: number): EventType => {
			return state.eventTypes.find((eventType: EventType) => eventType.id === id)!;
		};
	}
};