
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import '@/validation-rules';

@Component<Stadium>({
	components: {
		ValidationProvider,
		InfoBanner
	}
})
export default class Stadium extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetDeliveryInfo!: DeliveryInfo;

	private mutableDeliveryInfo: any = this.targetDeliveryInfo;
	private sections: string = this.mutableDeliveryInfo.sections ? this.mutableDeliveryInfo.sections : '';
	private rows: string = this.mutableDeliveryInfo.rows ? this.mutableDeliveryInfo.rows : '';
	private seats: string = this.mutableDeliveryInfo.seats ? this.mutableDeliveryInfo.seats : '';

	private updateDeliveryInfo(): void {
		this.mutableDeliveryInfo.sections = this.sections;
		this.mutableDeliveryInfo.rows = this.rows;
		this.mutableDeliveryInfo.seats = this.seats;
	}
}
