
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import '@/validation-rules';

@Component<Address>({
	components: {
		ValidationProvider,
		VueGoogleAutocomplete,
		InfoBanner
	}
})
export default class Address extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetDeliveryInfo!: DeliveryInfo;

	private countries: { label: string, value: string }[] = [
		{ label: this.$t('advanced.delivery.address.country_canada'), value: 'CA' },
		{ label: this.$t('advanced.delivery.address.country_usa'), value: 'US' }
	];
	private mutableDeliveryInfo: any = this.targetDeliveryInfo;

	/**
	* Location chosen, now populate
	*
	* @param {Object} addressData - Data of the found location
	* @param {Object} placeResultData - PlaceResult object
	* @return {void}
	*/
	private getAddressData(addressData: any, placeResultData: any): void {
		this.mutableDeliveryInfo.address = placeResultData.formatted_address;
		this.mutableDeliveryInfo.lat = addressData.latitude;
		this.mutableDeliveryInfo.lng = addressData.longitude;
	}

	/**
	* When the country gets changed, we empty the address in order
	* to keep the address in the right country.
	*
	* @return {void}
	*/
	private countryChanged(): void {
		this.mutableDeliveryInfo.address = '';
	}
}
