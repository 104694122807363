
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ROLES, TOAST_INSTANCE } from './utils/constants';
import Navbar from '@/components/navigation/Navbar.vue';
import PageLoader from '@/components/shared/PageLoader.vue';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'menus';

@Component<Index>({
	components: {
		Navbar,
		PageLoader,
		BannerToast
	}
})
export default class Index extends Vue {
	@Action('fetchRestaurant', { namespace: 'auth' }) private fetchRestaurant!: (payload: any) => Promise<void>;
	@Getter('getFetchMenusComplete', { namespace }) private getFetchMenusComplete!: boolean;
	@Getter('getFetchMenusError', { namespace }) private getFetchMenusError!: string;
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;
	@Getter('getTenantId', { namespace:'auth' }) private tenantId!: string;
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Watch('fetchMenusError')
	async onFetchMenusError(errorMessage: string) {
		if (errorMessage) {
			await Vue.nextTick();
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('shared.error_fetching_menus', { errorMessage });
		}
	}

	@Watch('tenantId', { immediate: true })
	onTenantIdChange() {
		this.fetchPosIntegrated();
	}

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private roles: Roles = ROLES;

	private get isLoggedIn(): boolean {
		return this.isAuthenticated;
	}

	private get fetchMenusError(): string {
		return this.getFetchMenusError;
	}

	private mounted () {
		const isDevEnv = process.env.NODE_ENV !== 'production';
		// Remove context menu from touch screens
		if (!isDevEnv &&
			(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md)
		) {
			window.oncontextmenu = function(event: any) {
				event.preventDefault();
				event.stopPropagation();
				return false;
			};
		}
	}

	private async fetchPosIntegrated(): Promise<void> {
		if (this.tenantId) {
			await this.fetchRestaurant({ tenantId: this.tenantId });
		}
	}
}
