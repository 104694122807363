
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ROLES } from '@/utils/constants';
import IconButton from '@/components/shared/suites/IconButton.vue';

@Component<SuitesHeader>({
	components: {
		IconButton
	}
})
export default class SuitesHeader extends Vue {
	@Getter('getRoleLevel', { namespace: 'auth' }) private roleLevel!: number;
	@Prop({ type: String, required: true, default: '' }) private title!: string;
	@Prop({ type: Array, required: false, default: () => [] }) private breadcrumbs!: { text: string; to: string; }[];
	@Prop({ type: Boolean, required: false, default: false }) private showBackButton!: boolean;

	private roles: Roles = ROLES;
}
