import { render, staticRenderFns } from "./CustomTheme.vue?vue&type=template&id=455e4c38&scoped=true"
import script from "./CustomTheme.vue?vue&type=script&lang=ts"
export * from "./CustomTheme.vue?vue&type=script&lang=ts"
import style0 from "./CustomTheme.vue?vue&type=style&index=0&id=455e4c38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "455e4c38",
  null
  
)

export default component.exports