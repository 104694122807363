
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
	getIntervalArray,
	getNoticeTimesArray,
	getTotalMinutes,
	minutesToHours
} from '../../../utils/timeFunctions';
import Tooltip from '@/components/shared/Tooltip.vue';

@Component<MenuType>({
	components: {
		Tooltip
	}
})
export default class MenuType extends Vue {
	@Prop({ type: Object, default: () => {} }) private menu!: Menu;
	@Prop({ type: Boolean, default: true }) private isCreating!: boolean;

	private mutableMenu: Menu = this.menu;
	private isTakeout: boolean = true;

	// Time arrays for select
	private estimatedPrepTimeItems = getIntervalArray(5, 0, 60 * 6);
	private lastCallItems = getIntervalArray(10, 10, 60);
	private orderNoticeItems = getNoticeTimesArray();
	private orderIntervalItems = getIntervalArray(5, 0, 60);

	// Minutes to time value for v-select if editing
	private estimatedPrepTimeValue: string = '';
	private lastCallValue: string = '';
	private requiredNoticeValue: string = '';
	private orderingIntervalValue: string = '';

	/**
	 * If the menu is getting edited, we need to verify if any
	 * take out config is true to put the switch on and then
	 * we assign the minutes into the correct time-array value.
	 *
	 * @return {void}
	 */
	private created(): void {
		if (!this.isCreating){
			if (this.mutableMenu.on_demand || this.mutableMenu.scheduled) {
				this.estimatedPrepTimeValue = minutesToHours(this.mutableMenu.estimated_prep_time);
				this.lastCallValue = minutesToHours(this.mutableMenu.last_call_time);
				this.requiredNoticeValue = minutesToHours(this.mutableMenu.required_notice);
				this.orderingIntervalValue = minutesToHours(this.mutableMenu.ordering_interval);
			}
			else {
				this.isTakeout = false;
			}
		}
		else {
			Vue.set(this.mutableMenu, 'dine_in', false);
		}
	}


	/**
	 * If value is false, we need to reset all the settings
	 * of the takeout config
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private updateTakeout(value: boolean): void {
		if (!value) {
			Vue.set(this.mutableMenu, 'on_demand', false);
			Vue.set(this.mutableMenu, 'scheduled', false);
		}
	}

	/**
	 * If value is false, we need to reset all the settings
	 * of the on demand config
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private updateOnDemand(value: boolean): void {
		if (value) {
			Vue.set(this.mutableMenu, 'scheduled', false);
			this.updateScheduled(false);
		}
	}

	private updateEstimatedPrepTime(value: string): void {
		this.mutableMenu.estimated_prep_time = getTotalMinutes(value);
	}

	private updateLastCall(value: string): void {
		this.mutableMenu.last_call_time = getTotalMinutes(value);
	}

	/**
	 * If value is false, we need to reset all the settings
	 * of the schedule config
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private updateScheduled(value: boolean): void {
		if (value) {
			Vue.set(this.mutableMenu, 'on_demand', false);
			this.updateOnDemand(false);
		}
	}

	private updateRequiredNotice(value: string): void {
		this.mutableMenu.required_notice = getTotalMinutes(value);
	}

	private updateOrderingInterval(value: string): void {
		this.mutableMenu.ordering_interval = getTotalMinutes(value);
	}
}
