import i18n from '@/i18n';
import { MutationTree } from 'vuex';
import { DateTime } from 'luxon';
import { OrdersState } from '../types';
import { state as authState } from '../auth/auth';
import { formatOrderItem, formatOrderOptionsToCompare } from '@/utils/formatItem';
import { sortOrderOptions } from '@/utils/sort';
import { compareItem } from '@/utils/membership-helpers';
import Vue from 'vue';

/**
 * Calculate order item price, including options and sub-options
 *
 * @param {MenuOrderItem} orderItem
 * @return {number}
 */
function calculateOrderItemPrice(orderItem: MenuOrderItem): number {
	let itemPrice = +(orderItem.price || 0);
	const optionGroups: OrderOptionGroup[] | null = orderItem.orderOptions;
	let optionsPrice: number = 0;

	if (optionGroups && optionGroups.length) {
		// Set the base price of the item if a pricing option is present
		const pricingOption = optionGroups.find((option: OrderOptionGroup) => option.optionGroupType === 'pricing');
		if (pricingOption) {
			itemPrice = +(pricingOption.values[0].price || 0);
		}

		// Calculate the price of the options
		optionsPrice = optionGroups.reduce((acc: number, optionGroup: OrderOptionGroup) => {
			let optionPrice = 0;

			// Ignore pricing options when calculation option price
			if (optionGroup.optionGroupType !== 'pricing') {
				optionGroup.values.forEach((option: OrderOption) => {
					if (option.price) {
						optionPrice += +(option.price) * option.quantity;
					}

					// If sub-options are present, calculate their price and add it to the option price
					if (option.options) {
						option.options.forEach((subOptionGroup: OrderOptionGroup) => {
							subOptionGroup.values.forEach((subOption: OrderOption) => {
								if (subOption.price) {
									optionPrice += +(subOption.price) * subOption.quantity;
								}
							});
						});
					}
				});
			}
			return acc + optionPrice;
		}, 0);
	}

	return (itemPrice + optionsPrice) * orderItem.quantity;
}

export const mutations: MutationTree<OrdersState> = {
	SET_CURRENT_ORDERS(state: OrdersState, currentOrders: CurrentOrders) {
		Vue.set(state, 'currentOrders', currentOrders);
	},

	SET_ORDER_INFO(state: OrdersState, order: Order) {
		const { checkout, config, costs, items } = order.data;
		let deliveryInfo = checkout.delivery;

		// Reset delivery info if the delivery type has changed
		if (deliveryInfo?.type !== authState.restaurant.delivery?.type) {
			deliveryInfo = {
				type: authState.restaurant.delivery?.type,
				address: ''
			};
		}

		Vue.set(state, 'selectedOrder', order);
		Vue.set(state, 'orderDetails',{
			type: order.type,
			tableNum: config.tableInfo.tableNum,
			tableLocation: config.tableInfo.tableLocation,
			tableArea: config.tableInfo.tableArea,
			checkNum: config.checkNum
		});
		Vue.set(state, 'cart', {
			items: items.map((item: OrderItem, index: number) => {
				return {
					...item,
					cartIndex: index
				};
			}),
			cartIndex: items.length
		});
		Vue.set(state, 'checkout', {
			pickup: {
				...checkout.pickup,
				dueByDate: order.pickup_time ? DateTime.fromISO(order.pickup_time).toISODate() : null,
				dueByTime: order.pickup_time ? DateTime.fromISO(order.pickup_time).toFormat(i18n.t('orders.guest_details.takeout.timepicker_format')) : null
			},
			delivery: deliveryInfo,
			contact: {
				email: checkout.contact.email,
				phone_number: checkout.contact.phone_number,
				full_name: checkout.contact.full_name
			},
			questions: checkout.questions
		});
		Vue.set(state, 'costs', costs);
	},

	SET_ORDER_VALIDATION_ERRORS(state: OrdersState, errors: { [key: string]: string[] }) {
		Vue.set(state, 'orderValidationErrors', errors);
	},

	SET_MODIFYING_ORDER(state: OrdersState, modifyingOrder: boolean) {
		Vue.set(state, 'modifyingOrder', modifyingOrder);
	},

	SET_EDITING_ITEM(state: OrdersState, editingItem: boolean) {
		Vue.set(state, 'editingItem', editingItem);
	},

	SET_ORDER_DETAILS(state: OrdersState, orderDetails: OrderDetails) {
		const { type, takeoutType } = orderDetails;
		Vue.set(state, 'orderDetails', orderDetails);

		if (type === 'take-out') {
			Vue.set(state.checkout, 'pickup', { delivery: takeoutType === 'delivery' });
		}
	},

	SET_SELECTED_ITEM(state: OrdersState, selectedItem: MenuItem) {
		Vue.set(state, 'selectedItem', selectedItem);
	},

	SET_ORDER_ITEM(state: OrdersState, payload: { selectedItem: MenuItem | MenuOrderItem; formatItem: boolean }) {
		const { selectedItem, formatItem } = payload;
		let orderItem = selectedItem;
		if (formatItem) {
			orderItem = formatOrderItem({ ...selectedItem as MenuItem, options: [] });
		}
		Vue.set(state, 'orderItem', orderItem);
	},

	SET_ORDER_ITEM_OPTIONS(state: OrdersState, orderItemOptions: OrderOptionGroup[]) {
		if (!state.orderItem) {
			return;
		}
		Vue.set(state.orderItem, 'orderOptions', orderItemOptions);
		Vue.set(state.orderItem, 'orderPrice', calculateOrderItemPrice(state.orderItem));
	},

	SET_ORDER_ITEM_QUANTITY(state: OrdersState, quantity: number) {
		if (!state.orderItem) {
			return;
		}
		Vue.set(state.orderItem, 'quantity', quantity);
		Vue.set(state.orderItem, 'orderPrice', calculateOrderItemPrice(state.orderItem));
	},

	ADD_TO_CART(state: OrdersState, specialInstructions: string) {
		if (!state.orderItem) {
			return;
		}

		const orderItem = state.orderItem!;
		const items = state.cart.items;

		if (specialInstructions) {
			const memoOption = orderItem.orderOptions?.find((option: OrderOptionGroup) => option.memo);
			if (memoOption) {
				memoOption.values[0].name = specialInstructions;
			}
			else {
				state.orderItem.orderOptions!.push({
					name: i18n.t('orders.item_viewer.special_instructions'),
					memo: true,
					values: [
						{
							name: specialInstructions,
							price: '0',
							quantity: 1,
							roll_up: true
						}
					]
				});
			}
		}
		orderItem.orderOptions = sortOrderOptions(orderItem.orderOptions as OrderOptionGroup[]);

		if(items.length) {
			let existInArray = false;
			let orderOptionsSame = false;
			let prevCartIndex = -1;
			/**
			 * We loop through the items to check if the item already exists in the array,
			 * from there we check if the order options are the same, if they are we simply increase
			 * the quantity.
			 */
			for (let index = 0; index < items.length; index++) {
				if (items[index].id === orderItem.id) {
					existInArray = true;
					const tempItemOrderOptions = formatOrderOptionsToCompare(orderItem.orderOptions);
					const itemToCompareOrderOptions = formatOrderOptionsToCompare(items[index].orderOptions as OrderOptionGroup[]);
					if (JSON.stringify(tempItemOrderOptions) === JSON.stringify(itemToCompareOrderOptions)) {

						// If we are editing an item, we don't want to compare the item being edited with itself
						if (!state.editingItem || (state.editingItem && items[index].cartIndex !== orderItem.cartIndex)) {
							orderOptionsSame = true;
							prevCartIndex = orderItem.cartIndex!;
							// Update order item quantity, price and id, if the item already exists in the cart
							orderItem.quantity += items[index].quantity;
							orderItem.orderPrice = items[index].orderPrice! + orderItem.orderPrice!;
							orderItem.cartIndex = items[index].cartIndex;
							items.splice(index, 1, orderItem);
							break;
						}
					}
				}
			}

			if (state.editingItem) {
				// If the item doesn't have the same options as another item in the cart, we find and replace the item being edited in the cart with the updated item
				if (!orderOptionsSame) {
					const index = items.findIndex((item: MenuOrderItem) => item.cartIndex === orderItem.cartIndex);
					if (index !== -1) {
						items.splice(index, 1, orderItem);
					}
				}
				// If the item has the same options as another item, we remove item being edited from the cart because we just increase the quantity of the existing item
				else {
					const index = items.findIndex((item: MenuOrderItem) => item.cartIndex === prevCartIndex);
					if (index !== -1) {
						items.splice(index, 1);
					}
				}
			}

			/**
			 * If the item exists in the array, we check if the options were the same
			 * so we don't add the item twice
			 */
			if(existInArray) {
				// Don't add a new item if we are editing an existing item
				if(!orderOptionsSame && !state.editingItem) {
					orderItem.cartIndex = state.cart.cartIndex++;
					state.cart.items.push(orderItem);
				}
			}

			/**
			 * If the item does not exists in the array, we simply add it
			 */
			else {
				orderItem.cartIndex = state.cart.cartIndex++;
				state.cart.items.push(orderItem);
			}
		}

		/**
		 * Array empty so we simply add to it
		 */
		else {
			orderItem.cartIndex = state.cart.cartIndex++;
			state.cart.items.push(orderItem);
		}
	},

	REMOVE_FROM_ORDER(state: OrdersState, index: number) {
		const updatedItems = state.cart.items.filter((item: MenuOrderItem) => {
			return item.cartIndex !== index;
		});
		Vue.set(state.cart, 'items', updatedItems);
	},

	SET_COSTS(state: OrdersState, payload: any) {
		Vue.set(state, 'items', payload.orderItems);
		Vue.set(state, 'costs', payload.costs);
	},

	/**
	 * Set tax rate of restaurant
	 *
	 * @param {OrdersState} state
	 * @param {Restaurant} restaurant
	 * @return {void}
	 */
	SET_RESTAURANT_CHARGES(state: OrdersState, restaurant: Restaurant): void {
		if(restaurant.service_charge) {
			// Get the value without the symbol ($, %)
			Vue.set(state.costs.serviceCharge, 'type', restaurant.service_charge.slice(restaurant.service_charge.length - 1) === '$' ? 'dollar' : 'percentage');

			// Set the percentage value for display purposes
			if(state.costs.serviceCharge.type === 'percentage') {
				Vue.set(state.costs.serviceCharge, 'percentageValue', +restaurant.service_charge.slice(0, -1));
			}
			Vue.set(state.costs.serviceCharge, 'label', restaurant.order_options?.service_charge_label || '');
		}
		if(restaurant.delivery && restaurant.delivery.delivery_charge) {
			const deliveryCharge = restaurant.delivery.delivery_charge;
			// Get the value without the symbol ($, %)
			Vue.set(state.costs.deliveryCharge, 'type', deliveryCharge.slice(deliveryCharge.length - 1) === '$' ? 'dollar' : 'percentage');

			// Set the percentage value for display purposes
			if(state.costs.deliveryCharge.type === 'percentage') {
				Vue.set(state.costs.deliveryCharge, 'percentageValue', +deliveryCharge.slice(0, -1));
			}
		}
	},

	SET_TAKEOUT_INFORMATION(state: OrdersState, takeoutInfo: Pickup) {
		Vue.set(state.checkout, 'pickup', takeoutInfo);
	},

	SET_DELIVERY_INFORMATION(state: OrdersState, deliveryInfo: DeliveryInfo) {
		Vue.set(state.checkout, 'delivery', deliveryInfo);
	},

	SET_CONTACT_INFORMATION(state: OrdersState, contactInfo: Contact) {
		Vue.set(state.checkout, 'contact', contactInfo);
	},

	SET_CHECKOUT_ANSWERED_QUESTIONS(state: OrdersState, answeredQuestions: AnsweredCustomQuestion[]) {
		Vue.set(state.checkout, 'questions', answeredQuestions);
	},

	SET_NOTES(state: OrdersState, notes: string) {
		if (!state.checkout.pickup) {
			Vue.set(state.checkout, 'pickup', { notes });
		}
		Vue.set(state.checkout, 'pickup', { ...state.checkout.pickup, notes });
	},

	/**
	 * Set the membership program info
	 *
	 * @param {OrdersState} state
	 * @param {CheckoutMemberInfo} payload
	 * @return {void}
	 */
	SET_MEMBER_INFO(state: OrdersState, payload: CheckoutMemberInfo): void {
		Vue.set(state.checkout, 'memberInfo', payload);
		Vue.set(state.checkout.memberInfo, 'promosApplied', new Set<string>());
		const { contact, memberInfo } = state.checkout;

		// Set the contact info from what we can with the ELB hit
		if(memberInfo && memberInfo.phone && contact) {
			if(!contact.full_name) {
				contact.full_name = memberInfo.firstName ? `${memberInfo.firstName}` : '';
				contact.full_name += memberInfo.lastName ? ` ${memberInfo.lastName}` : '';
			}
			if(!contact.email) {
				contact.email = memberInfo.email ? memberInfo.email : '';
			}
		}
	},

	/**
	 * Set the member prices on items/options that contains the same promo
	 * of the member's payload.
	 * TODO - ACTUALLY APPLY THE MEMBER PRICES, CURRENTLY THIS IS ONLY USED TO FILL UP THE promosApplied ARRAY
	 *
	 * @param {OrdersState} state
	 * @param {Menu[]} menus
	 * @return {void}
	 */
	SET_MEMBER_PRICES(state: OrdersState, menus: Menu[]): void {
		if(state.checkout.memberInfo?.promos?.length) {
			// Set cart items' member price
			if(state.cart?.items?.length) {
				state.cart.items.forEach((item: MenuOrderItem) => {
					// We need to know if the cart item has pricing options because if it does we don't want to manipulate the price directly from the item
					const hasPricingOptions = item.orderOptions?.some((orderOption: OrderOptionGroup) => orderOption.optionGroupType === 'pricing') || false;
					compareItem(item, state.checkout.memberInfo!, 'orderOptions', hasPricingOptions);
				});
			}

			// Set menu items' member price
			menus.forEach((menu: Menu) => {
				// Section items
				if(menu.sections?.length) {
					menu.sections.forEach((section: MenuSection) => {
						if(section.items?.length) {
							section.items.forEach((item: MenuItem) => {
								compareItem(item, state.checkout.memberInfo!);
							});
						}
					});
				}
				// Menu items
				else if (menu.items?.length) {
					menu.items.forEach((item: MenuItem) => {
						compareItem(item, state.checkout.memberInfo!);
					});
				}
			});
		}
		Vue.set(state, 'memberMenus', menus);
	},


	RESET_ITEM_VIEWER_STATE(state: OrdersState) {
		Vue.set(state, 'orderItem', null);
		Vue.set(state, 'orderItemPrice', 0);
		Vue.set(state, 'selectedItem', null);
		Vue.set(state, 'editingItem', false);
	},

	RESET_ORDERS_STATE(state: OrdersState) {
		Vue.set(state, 'orderDetails', {
			type: '',
			tableArea: '',
			tableLocation: '',
			tableNum: '',
			takeoutType: '',
			checkNum: '',
			menuManagerLaneReservation: undefined
		});
		Vue.set(state, 'selectedItem', null);
		Vue.set(state, 'orderItem', null);
		Vue.set(state, 'editingOrderItem', false);
		Vue.set(state, 'orderValidationErrors', {});
		Vue.set(state, 'orderItemPrice', 0);
		Vue.set(state, 'cart', {
			items: [],
			cartIndex: 0
		});
		Vue.set(state, 'checkout', {
			pickup: null,
			delivery: {
				type: ''
			},
			contact: {
				full_name: '',
				email: '',
				phone_number: '',
				password: ''
			},
			loyaltyProgram: null,
			memberInfo: null,
			questions: null
		});
		Vue.set(state, 'costs', {
			subtotal: 0,
			subtotalDiscounted: 0,
			taxBrackets: {},
			taxes: 0,
			tip: {
				percentageValue: 0,
				amount: 0,
				type: 'dollar'
			},
			serviceCharge: {
				percentageValue: 0,
				amount: 0,
				type: 'dollar',
				label: ''
			},
			deliveryCharge: {
				percentageValue: 0,
				amount: 0,
				type: 'dollar'
			},
			voucher: {
				code: '',
				balance: 0,
				amount: 0
			},
			discount: {
				percentageValue: 0,
				amount: 0,
				type: 'percentage',
				code: ''
			},
			memberDiscount: {
				preTax: false,
				amount: 0
			},
			total: 0,
			totalWithTip: 0
		});
	}
};