
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextButton from './TextButton.vue';

type EmitType = 'cancel' | 'confirm' | 'close';

@Component<StandardModal>({
	components: {
		TextButton
	}
})
export default class StandardModal extends Vue {
	@Prop({ type: Boolean, default: false }) private showModal!: boolean;
	@Prop({ type: Boolean, default: false }) private loading!: boolean;
	@Prop({ type: Boolean, default: false }) private nested!: boolean;
	@Prop({ type: Boolean, default: false }) private disableConfirm!: boolean;
	@Prop({ type: String, required: false, default: 'small' }) private size!: string; // TODO UPDATE THIS
	@Prop({ type: String, required: false, default: '' }) private title!: string;
	@Prop({ type: String, required: false }) private customClass!: string;
	@Prop({ type: String, required: false, default: '' }) private description!: string;
	@Prop({ type: String, required: false, default: '' }) private cancelButtonText!: string;
	@Prop({ type: String, required: false, default: '' }) private confirmButtonText!: string;
	@Prop({ type: String, required: false, default: 'primary' }) private confirmButtonType!: string;
	@Prop({ type: String, required: false, default: 'white' }) private cancelButtonType!: string;
	@Prop({ type: String, required: false, default: '' }) private confirmButtonIcon!: string;
	@Prop({ type: String, required: false, default: '' }) private cancelButtonIcon!: string;


	private mutableShowModal: boolean = this.showModal;

	/**
	 * Emit cancel or confirm event if it isn't loading
	 *
	 * @param {EmitType} type
	 * @return {void}
	 */
	private emit(type: EmitType): void {
		!this.loading && this.$emit(type);
	}
}
