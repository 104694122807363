
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DateTime } from 'luxon';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';

const namespace: string = 'eventSuites';

@Component<EmailPreOrderModal>({
	components: {
		InfoBanner,
		StandardModal,
		TextButton
	}
})
export default class EmailPreOrderModal extends Vue {
	@Action('sendPreOrderEmail', { namespace }) private sendPreOrderEmail!: (eventSuite: EventSuite) => Function;
	@Action('updateEventSuite', { namespace }) private updateEventSuite!: (payload: { eventSuite: EventSuite; eventId: number }) => Function;
	@Prop({ type: String, required: true }) private scheduledEmailDate!: string;
	@Prop({ type: Object, required: false, default: null }) private eventSuite!: EventSuite;
	@Prop({ type: Boolean, required: true, default: '' }) private showModal!: boolean;
	// TODO: Replace watcher by v-if in the component (see change history modal for example)
	@Watch('showModal')
	onShowModalChange() {
		// Check if pre-order email has been sent in the last hour when modal is opened
		// Modal doesn't get destroyed on close so we can't use the created or mounted hook to set this value
		if (this.showModal) {
			this.emailSentInLastHour = this.getEmailSentInLastHour();
		}
	}

	private isLoading: boolean = false;
	private emailSentInLastHour: boolean = false;

	// Formats pre_order_email_sent_date for table
	private get formattedEmailSentData(): string {
		if (!this.eventSuite.pre_order_email_sent_date) {
			return '';
		}
		return DateTime.fromISO(this.eventSuite.pre_order_email_sent_date).toFormat(this.$t('event_details.email_pre_order_modal.date_format'));
	}

	/**
	 * Check if pre-order email has been sent in the last hour
	 *
	 * @returns {boolean} - true if email has been sent in the last hour
	 */
	private getEmailSentInLastHour(): boolean {
		if (!this.eventSuite.pre_order_email_sent_date) {
			return false;
		}
		const hoursSinceLastEmail = DateTime.fromISO(this.eventSuite.pre_order_email_sent_date).diffNow().negate().as('hours');

		if (hoursSinceLastEmail < 1) {
			return true;
		}
		return false;
	}

	/**
	 * Handles sending pre-order email and sets emailSentInLastHour to true if successful
	 *
	 * @returns {Promise<void>}
	 */
	private async handleSendEmail(): Promise<void> {
		this.isLoading = true;
		try {
			if (this.emailSentInLastHour) {
				throw this.$t('event_details.email_pre_order_modal.error_already_sent');
			}
			await this.sendPreOrderEmail(this.eventSuite);
			this.emailSentInLastHour = true;
			this.$emit('email-sent');
		}
		catch (errorMessage) {
			this.$emit('error', errorMessage);
		}
		finally {
			this.isLoading = false;
		}
	}

	private handleClose(): void {
		this.$emit('close');
	}
}
