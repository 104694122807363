import { DateTime } from 'luxon';
import i18n from '@/i18n';
import Vue from 'vue';
const localizeFilter = Vue.filter('localize');

/**
 * Get the order status label based on the status
 *
 * @param {string} status
 * @return {string}
 */
export function getOrderStatus(status: string): string {
	const statusMapping: { [key: string]: string } = {
		'pending': i18n.t('order_management.parent_card.order_card.pending'),
		'in-progress': i18n.t('order_management.parent_card.order_card.in_progress'),
		'completed': i18n.t('order_management.parent_card.order_card.completed'),
		'cancelled': i18n.t('order_management.parent_card.order_card.cancelled'),
		'refunded': i18n.t('order_management.parent_card.order_card.refunded'),
		'refund-requested': i18n.t('order_management.parent_card.order_card.refund_requested')
	};

	return statusMapping[status];
}

/**
 * Get the order status color based on the status
 *
 * @param {string} status
 * @return {string}
 */
export function getOrderColorStatus(status: string): string {
	const statusMapping: { [key: string]: string } = {
		'pending': 'status-pending',
		'in-progress': 'status-in-progress',
		'completed': 'status-completed',
		'cancelled': 'status-cancelled',
		'refunded': 'status-refunded',
		'refund-requested': 'status-refunded'
	};

	return statusMapping[status];
}

/**
 * Get the order type value that will be used by the i18n path and the icon to use
 *
 * @param {Order | undefined} order
 * @return {ParentOrderType}
 */
export function getOrderType(order: Order | undefined): ParentOrderType {
	if(order) {
		if(order.type) {
			if(order.type === 'catering') {
				return { type: 'catering', icon: 'mdi-stadium-outline' };
			}
			else if (order.type === 'generic-catering') {
				return { type: 'generic_catering', icon: 'mdi-food-fork-drink' };
			}
			else if(order.type === 'take-out') {
				if(order.data.checkout.pickup?.delivery) {
					return { type: 'delivery', icon: 'mdi-truck-outline' };
				}
				return { type: 'takeout', icon: 'mdi-food-takeout-box-outline' };
			}
			else if(order.type === 'notification') {
				return { type: 'notification', icon: 'mdi-bell-outline' };
			}
			else {
				return { type: 'dine_in', icon: 'mdi-silverware' };
			}
		}
	}
	return { type: 'dine_in', icon: 'mdi-silverware' };
}

/**
 * Get the order title depending of its type
 *
 * @param {Order | undefined} order
 * @return {string}
 */
export function getOrderTitle(order: Order | undefined): string {
	if (order?.type) {
		if (order.type === 'catering' || order.data.suites?.eventDayOrder) {
			return order.data.suites?.eventName || '';
		}
		else if (order.type === 'take-out') {
			if (order.data.checkout.pickup?.delivery) {
				return localizeFilter(order.data.checkout.delivery, 'address');
			}
			return order.data.checkout.contact?.full_name || '';
		}
		else if (order.type === 'generic-catering' || order.type === 'notification') {
			return order.data.checkout.contact?.full_name || '';
		}
		return 'Table ' + order.data.config.tableNum;
	}
	return '';
}

/**
 * Get the order's scheduled time depending of its type
 *
 * @param {Order | EventSuiteOrder} order
 * @return {string}
 */
export function getOrderTime(order: Order | EventSuiteOrder): string {
	if (order.pickup_time) {
		return order.pickup_time;
	}
	if (!order.data.suites?.eventDayOrder && order.data.suites?.eventStartDate) {
		return order.data.suites.eventStartDate;
	}
	return order.updated_at;
}

/**
 * Get the order title depending of its type
 *
 * @param {Order | undefined} order
 * @return {{ text: string; color: string }}
 */
export function getOrderTimeLeft(order: Order | undefined): { text: string; color: string } {
	if (!order) {
		return { text: '', color: '' };
	}

	const orderTime: DateTime = DateTime.fromISO(getOrderTime(order));
	const { weeks, days, hours, minutes } = orderTime.diffNow(['weeks', 'days', 'hours', 'minutes']).toObject();

	const timingUnits = [
		{ unit: weeks, name: 'week' },
		{ unit: days, name: 'day' },
		{ unit: hours, name: 'hour' },
		{ unit: minutes, name: 'minute' }
	];

	for (const { unit, name } of timingUnits) {
		if (unit) {
			const absoluteValue = Math.round(Math.abs(unit));
			const isLate = unit < 0;
			const timePath = `order_management.parent_card.order_card.time_difference.${name}_${isLate ? 'late' : 'left'}`;
			const color = isLate
				? 'late-order'
				: (name === 'minute' && absoluteValue <= 15) ? 'soon-order' : 'early-order';

			return {
				text: i18n.tc(timePath, absoluteValue, { n: absoluteValue }),
				color: color
			};
		}
	}

	return {
		text: i18n.t('order_management.parent_card.order_card.time_difference.minute_left', { n: 0 }),
		color: 'soon-order'
	};
}

/**
 * Get the order scheduled date. Here we either use the pickup_time or the updated_at
 * if the pickup_time is not set. We then compare the date with the current date
 * and send the according format.
 *
 * @param {Order | EventSuiteOrder | undefined} order
 * @return {string | undefined}
 */
export function getOrderScheduledDate(order: Order | EventSuiteOrder | undefined): string | undefined {
	if(order) {
		const date: DateTime = DateTime.fromISO(getOrderTime(order));
		const now: DateTime = DateTime.local();
		const timeFormatLocalized: string = i18n.t('order_management.parent_card.dates.time_format');
		if (date.hasSame(now.minus({ days: 1 }), 'day')) {
			return i18n.t('order_management.parent_card.dates.yesterday', { time: date.toFormat(timeFormatLocalized) });
		}
		else if (date.hasSame(now, 'day')) {
			return i18n.t('order_management.parent_card.dates.today', { time: date.toFormat(timeFormatLocalized) });
		}
		else if (date.hasSame(now.plus({ days: 1 }), 'day')) {
			return i18n.t('order_management.parent_card.dates.tomorrow', { time: date.toFormat(timeFormatLocalized) });
		}
		else {
			return i18n.t('order_management.parent_card.dates.default', { date: date.toFormat(i18n.t('order_management.parent_card.dates.scheduled_default_date_format')), time: date.toFormat(timeFormatLocalized) });
		}
	}

	return;
}

/**
 * Get the order delivery location depending of its type
 *
 * @param {Order | EventSuiteOrder | undefined} order
 * @return {string}
 */
export function getOrderDelivery(order: Order | EventSuiteOrder | undefined): string | undefined {
	if (order?.type) {
		if (order.data.suites?.eventDayOrder || order.type === 'catering') {
			return order.data.suites?.eventSuiteName || '';
		}
		else if ((order.type === 'take-out' || order.type === 'generic-catering') && order.data.checkout.pickup?.delivery) {
			return localizeFilter(order.data.checkout.delivery, 'address');
		}
		else if (order.type === 'dine-in') {
			return 'Table ' + order.data.config.tableNum;
		}
	}
}