import { ActionTree } from 'vuex';
import { EquipmentState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance, AxiosResponse } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';
import { decodeNameOfEntity } from '@/utils/decoding';

export const actions: ActionTree<EquipmentState, RootState> = {
	/**
	 * Fetch all equipment
	 *
	 * @return {Promise<void>}
	 */
	async fetchEquipment({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data }: AxiosResponse = await axiosInst.get('/catering/equipment');
			const decodedEquipment: Equipment[] = data.map((equipment: Equipment) => decodeNameOfEntity(equipment));
			commit('SET_EQUIPMENT', decodedEquipment);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create a new equipment
	 *
	 * @param {{ name: string }} equipment
	 * @return {Promise<void>}
	 */
	async addEquipment({ commit }, equipment: { name: string }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data }: AxiosResponse = await axiosInst.post('/catering/equipment', equipment);
			commit('ADD_EQUIPMENT', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update an equipment
	 *
	 * @param {Equipment} equipment
	 * @return {Promise<void>}
	 */
	async updateEquipment({ commit }, equipment: Equipment): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const { id, name } = equipment;
		try {
			const { data }: AxiosResponse = await axiosInst.put(`/catering/equipment/${id}`, { name });
			commit('UPDATE_EQUIPMENT', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete an equipment
	 *
	 * @param {number} equipmentId
	 * @return {Promise<void>}
	 */
	async deleteEquipment({ commit }, equipmentId: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.delete(`/catering/equipment/${equipmentId}`);
			commit('DELETE_EQUIPMENT', equipmentId);
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
