import { MutationTree } from 'vuex';
import { EventTypeState } from '../types';
import { alphabeticalSort } from '@/utils/sort';
import Vue from 'vue';

export const mutations: MutationTree<EventTypeState> = {
	/**
	 * Set event types
	 *
	 * @param {EventTypeState} state
	 * @param {EventType[]} eventTypes
	 */
	SET_EVENT_TYPES(state, eventTypes: EventType[]) {
		Vue.set(state, 'eventTypes', eventTypes);
	},

	/**
	 * Add a new event type
	 *
	 * @param {EquipmentState} state
	 * @param {Equipment} newEventType
	 * @return {void}
	 */
	ADD_EVENT_TYPE(state: EventTypeState, newEventType: EventType): void {
		Vue.set(state, 'eventTypes', [...state.eventTypes, newEventType].sort(alphabeticalSort));
	},

	/**
	 * Update an event type
	 *
	 * @param {EquipmentState} state
	 * @param {EventType} updatedEventType
	 * @return {void}
	 */
	UPDATE_EVENT_TYPE(state: EventTypeState, updatedEventType: EventType): void {
		Vue.set(state, 'eventTypes', state.eventTypes.map(eventType => eventType.id === updatedEventType.id ? updatedEventType : eventType).sort(alphabeticalSort));
	}
};