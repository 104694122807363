var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"closed-date"},[_c('section',{staticClass:"closed-date-header"},[_c('info-banner',{attrs:{"text":_vm.$t('menu.editor.availability.holidays.banner')}}),_c('text-button',{staticClass:"ml-4",attrs:{"id":"add-date-btn","type":"secondary","icon":"mdi-plus-circle","text":_vm.$t('menu.editor.availability.holidays.add_date_btn'),"disabled":_vm.disableAddDate},on:{"click":_vm.addClosedDate}})],1),_c('section',{staticClass:"closed-date-list"},_vm._l((_vm.orderedDates),function(holiday,index){return _c('div',{key:`holiday-hour-${holiday.date || 'temp' }`},[_c('div',{staticClass:"closed-date-list-item"},[_c('date-picker',{ref:`holidayHour${holiday.date || 'Temp' }`,refInFor:true,attrs:{"textFieldProps":{
						appendIcon: 'mdi-chevron-down',
						contentClass: 'label-md suites-input',
						label: 'Date',
						name: 'holiday-date',
						outlined: true,
						prependInnerIcon: 'mdi-calendar-month-outline',
					},"datePickerProps":{
						allowedDates: _vm.disableDates,
						min: _vm.minDate
					}},on:{"input":function($event){return _vm.handleDateChange(holiday)}},model:{value:(holiday.date),callback:function ($$v) {_vm.$set(holiday, "date", $$v)},expression:"holiday.date"}}),_c('p',{staticClass:"label-md my-0"},[_vm._v(_vm._s(_vm.$t('menu.editor.availability.holidays.closed_all_day')))]),_c('icon-button',{attrs:{"id":"remove-date-btn","type":"white","icon":"mdi-close-circle"},on:{"click":function($event){holiday.date ? _vm.openWarningModal(holiday.date) : _vm.removeClosedDate(index)}}})],1),_c('div',{staticClass:"divider"})])}),0),_c('warning-modal',{attrs:{"showModal":_vm.warningModalOpen,"title":_vm.$t('menu.editor.availability.holidays.remove_date_modal_title'),"text":_vm.$t('menu.editor.availability.holidays.remove_date_modal_text', { date: _vm.dateToRemove }),"confirmButtonText":_vm.$t('menu.editor.availability.holidays.remove_date_modal_confirm'),"cancelButtonText":_vm.$t('menu.editor.availability.holidays.remove_date_modal_cancel')},on:{"confirm":function($event){return _vm.removeClosedDate(_vm.selectedHolidayIndex)},"cancel":_vm.closeWarningModal,"close":_vm.closeWarningModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }