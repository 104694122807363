
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import TextButton from '@/components/shared/suites/TextButton.vue';
import IconButton from '@/components/shared/suites/IconButton.vue';

interface EditableOption {
	option: string;
	isEditing: boolean;
}

@Component<CustomQuestionOption>({
	components: {
		TextButton,
		IconButton,
		ValidationProvider
	}
})
export default class CustomQuestionOption extends Vue {
	@Prop({ type: String, required: true, default: '' }) private option!: string;
	@Prop({ type: String, required: false }) private defaultLocaleOption!: string;
	@Prop({ type: Number, required: true }) private optionIndex!: number;
	@Prop({ type: Boolean, required: true }) private isOptionEditing!: boolean;
	@Prop({ type: Boolean, required: true }) private isQuestionEditing!: boolean;
	@Prop({ type: Boolean, required: false }) private isNewRow!: boolean;
	@Prop({ type: Boolean, required: false }) private isDefaultLocale!: boolean;
	@Prop({ type: Boolean, required: true }) private disabled!: boolean;
	@Watch('isQuestionEditing')
	onIsQuestionEditingUpdate(questionEditing: boolean) {
		if (!questionEditing && this.isDefaultLocale) {
			this.mutableOption = {
				option: this.option,
				isEditing: false
			};
		}
	}
	@Watch('option')
	onOptionUpdate(option: string) {
		this.mutableOption.option = option;
	}

	private mutableOption: EditableOption = {
		option: this.option,
		isEditing: false
	};

	private created(): void {
		this.mutableOption.isEditing = this.option === '';
		this.$nextTick(() => {
			if (this.option === '') {
				(this.$refs.optionInput as HTMLElement[][0]).focus();
			}
		});
	}

	/**
	 * Set edit mode and emits to enable/disable editing of other options.
	 * Focus on text input if editing mode.
	 *
	 * @param {boolean} isEditing
	 * @return {void}
	 */
	private toggleEditMode(isEditing: boolean): void {
		this.mutableOption.isEditing = isEditing;
		this.$emit('toggle-edit', isEditing);
		this.$nextTick(() => {
			if (isEditing) {
				(this.$refs.optionInput as HTMLElement[][0]).focus();
			}
		});
	}

	/**
	 * Emit option to the parent component to save it and remove edit mode
	 *
	 * @return {void}
	 */
	private saveOption(): void {
		if (this.mutableOption.option.length <= 225 && (this.isDefaultLocale || this.mutableOption.option.length)) {
			this.mutableOption.isEditing = false;
			this.$emit('save-option', this.mutableOption.option);
		}
	}

	/**
	 * Cancel the changes and remove edit mode
	 *
	 * @return {void}
	 */
	private cancelOptionEditing(): void {
		if (this.isNewRow) {
			this.$emit('save-option', '');
		}
		else {
			this.mutableOption = {
				option: this.option,
				isEditing: false
			};
			this.$emit('toggle-edit', false);
		}
	}
}
