
import { Component, Vue } from 'vue-property-decorator';
import AddOnsList from '@/components/AddOnsList.vue';

@Component<AddOns>({
	components: {
		AddOnsList
	}
})
export default class AddOns extends Vue {

}
