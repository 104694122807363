import { Module } from 'vuex';
import { actions } from './actions';
import { RootState } from '../types';
const namespaced: boolean = true;

export const ChangeHistory: Module<{}, RootState> = {
	namespaced,
	actions
};

export default ChangeHistory;