
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeMenuGroup } from '@/utils/decoding';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import FileUpload from '@/components/shared/form/FileUpload.vue';
import InfoBanner from '@/components/shared/suites/InfoBanner.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import '@/validation-rules';

const namespace: string = 'menus';

@Component<MenuGroupModal>({
	components: {
		StandardModal,
		WarningModal,
		FileUpload,
		InfoBanner,
		TextButton,
		ValidationObserver,
		ValidationProvider
	}
})
export default class MenuGroupModal extends Vue {
	@Action('createMenuGroup', { namespace }) private createMenuGroup!: (payload: object) => Promise<MenuGroup | void>;
	@Action('updateMenuGroup', { namespace }) private updateMenuGroup!: (payload: object) => Promise<void>;
	@Action('deleteMenuGroup', { namespace }) private deleteMenuGroup!: (menuGroup: MenuGroup) => Promise<void>;
	@Getter('getMenuGroups', { namespace }) private menuGroups!: MenuGroup[];
	@Getter('getMenus', { namespace }) private menus!: Menu[];
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	@Getter('getRestaurants', { namespace: 'auth' }) private restaurants!: Restaurant[];
	@Prop({ type: Object, required: false }) private menuGroup!: MenuGroup | undefined;

	$refs!: {observer: Validate};

	private isLoading: boolean = false;
	private warningModalLoading: boolean = false;
	private warningModalOpened: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private S3_BUCKET_BASE_URL: string = process.env.VUE_APP_S3_BUCKET_BASE_URL;
	private mutableImageFile: string | null = null;
	private mutableMenuGroup: MenuGroup = {
		id: undefined,
		name: '',
		slug: '',
		image: '',
		restaurant_id: '',
		published_at: null,
		menus: []
	};
	private selectedMenus: (number | undefined)[] = this.menuGroup && this.menuGroup.menus ? this.menuGroup.menus.map((menu: Menu) => menu.id) : [];

	private get modalTitle(): string {
		return this.menuGroup && this.menuGroup.id
			? this.$t('advanced.menu_groups.modal.edit_title')
			: this.$t('advanced.menu_groups.modal.create_title');
	}

	/**
	 * Filter through the restaurants and return the ones that are not assigned
	 * to any menu groups yet. We also keep the one that is already assigned to
	 * this menu group if we're editing. We also need to remove the restaurant that is selected (via the restaurant prop)
	 *
	 * @return {Restaurant[]}
	 */
	private get unselectedRestaurants(): Restaurant[] {
		const unselectedRestaurants = this.restaurants.filter((restaurant: Restaurant) => {
			const isAlreadyAssigned = this.menuGroups.some((menuGroup: MenuGroup) => {
				return menuGroup.restaurant_id === restaurant.id;
			});

			return !isAlreadyAssigned || (this.menuGroup && this.menuGroup.restaurant_id === restaurant.id);
		}).filter((restaurant: Restaurant) => {
			return restaurant.id !== this.restaurant.id;
		});

		return unselectedRestaurants;
	}

	private mounted(): void {
		if (this.menuGroup) {
			this.mutableMenuGroup = decodeMenuGroup(this.menuGroup);
		}
	}

	/**
	 * Check if inputs are valid before saving or editing menu groups
	 *
	 * @return {Promise<void>}
	 */
	private async submitHandler(): Promise<void> {
		const isValid = await this.$refs.observer.validate();

		if (isValid) {
			this.menuGroup && this.menuGroup.id ? this.editMenuGroup() : this.addMenuGroup();
		}
	}

	/**
	 * Create new menu group
	 *
	 * @return {Promise<void>}
	 */
	private async addMenuGroup(): Promise<void> {
		this.isLoading = true;
		try {
			await this.createMenuGroup({ menuGroup: { ...this.mutableMenuGroup, restaurant_id: this.mutableMenuGroup.restaurant_id ? this.mutableMenuGroup.restaurant_id : null }, selectedMenus: this.selectedMenus, imageFile: this.mutableImageFile });
			setTimeout(() => {
				this.$emit('close');
			}, 500);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.menu_groups.modal.error_creating', { errorMessage });
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Edit menu group
	 *
	 * @return {Promise<void>}
	 */
	private async editMenuGroup(): Promise<void> {
		this.isLoading = true;
		try {
			await this.updateMenuGroup({ menuGroup: { ...this.mutableMenuGroup, restaurant_id: this.mutableMenuGroup.restaurant_id ? this.mutableMenuGroup.restaurant_id : null }, selectedMenus: this.selectedMenus, imageFile: this.mutableImageFile });
			setTimeout(() => {
				this.$emit('close');
			}, 500);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.menu_groups.modal.error_updating', { errorMessage });
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Delete menu group
	 *
	 * @return {Promise<void>}
	 */
	private async removeMenuGroup(): Promise<void> {
		this.warningModalLoading = true;
		try {
			await this.deleteMenuGroup(this.mutableMenuGroup);
			setTimeout(() => {
				this.$emit('close');
			}, 500);
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('advanced.menu_groups.modal.error_deleting', { errorMessage });
			setTimeout(() => {
				this.warningModalLoading = false;
			}, 500);
		}
	}

	private closeModal(): void {
		this.$emit('close');
	}
}
