const he = require('he');

export const decodeMenu = (menu: Menu): Menu => {
	return {
		...menu,
		name: menu.name ? he.decode(menu.name) : undefined,
		description: menu.description ? he.decode(menu.description) : undefined
	};
};

export const decodeSection = (section: MenuSection): MenuSection => {
	return {
		...section,
		name: section.name ? he.decode(section.name) : undefined,
		description: section.description ? he.decode(section.description) : undefined
	};
};

export const decodeItem = (item: MenuItem): MenuItem => {
	return {
		...item,
		name: item.name ? he.decode(item.name) : undefined,
		description: item.description ? he.decode(item.description) : undefined,
		ingredients: item.ingredients ? he.decode(item.ingredients) : undefined,
		price_unit: item.price_unit ? he.decode(item.price_unit) : undefined,
		price_type: item.price_type ? he.decode(item.price_type) : undefined
	} as MenuItem;
};

export const decodeOption = (option: Option): Option => {
	return {
		...option,
		name: option.name ? he.decode(option.name) : undefined,
		description: option.description ? he.decode(option.description) : undefined,
		ingredients: option.ingredients ? he.decode(option.ingredients) : undefined
	} as Option;
};

export const decodeOptionGroup = (optionGroup: OptionGroup): OptionGroup => {
	optionGroup.values = optionGroup.values?.map((option:Option) => decodeOption(option));
	 return {
		...optionGroup,
		name: optionGroup.name ? he.decode(optionGroup.name) : undefined,
		description: optionGroup.description ? he.decode(optionGroup.description) : undefined,
		internal_name: optionGroup.internal_name ? he.decode(optionGroup.internal_name) : undefined
	} as OptionGroup;
};

export const decodeSuiteOwner = (suiteOwner: SuiteOwner): EditableSuiteOwner => {
	const [firstName, ...otherNames] = suiteOwner.name.split(' ');
	return {
		...suiteOwner,
		firstName: he.decode(firstName),
		lastName: he.decode(otherNames.join(' ')),
		company_name: suiteOwner.company_name ? he.decode(suiteOwner.company_name) : undefined,
		title: suiteOwner.title ? he.decode(suiteOwner.title) : undefined
	};
};

export const decodeSuiteOwners = (suiteOwners: SuiteOwner[]): SuiteOwner[] => {
	return suiteOwners.map((suiteOwner: SuiteOwner) => {
		return {
			...suiteOwner,
			name: he.decode(suiteOwner.name),
			company_name: suiteOwner.company_name ? he.decode(suiteOwner.company_name) : undefined,
			title: suiteOwner.title ? he.decode(suiteOwner.title) : undefined
		};
	});
};

export const decodeEventSuite = (eventSuite: EventSuite): EventSuite => {
	const decodedMenus = eventSuite.menus ? eventSuite.menus.map((menu: EventSuiteMenu) => decodeNameOfEntity(menu)) : null;
	const decodedEquipment = eventSuite.equipment ? eventSuite.equipment.map((equipment: EventSuiteEquipment) => decodeNameOfEntity(equipment)) : null;
	const decodedOrders = eventSuite.orders ? eventSuite.orders.map((order: EventSuiteOrder) => decodeOrder(order)) : null;
	return {
		...eventSuite,
		suite_owner: eventSuite.suite_owner ? {
			...eventSuite.suite_owner,
			name: he.decode(eventSuite.suite_owner.name)
		} : null,
		suite: decodeNameOfEntity(eventSuite.suite) as Suite,
		menus: decodedMenus as EventSuiteMenu[],
		equipment: decodedEquipment as EventSuiteEquipment[],
		orders: decodedOrders as EventSuiteOrder[],
		dietary_restrictions: eventSuite.dietary_restrictions ? he.decode(eventSuite.dietary_restrictions) : null,
		special_requirements: eventSuite.special_requirements ? he.decode(eventSuite.special_requirements) : null,
		pre_order_additional_notes: eventSuite.pre_order_additional_notes ? he.decode(eventSuite.pre_order_additional_notes) : null
	};
};

export const decodeEvent = (event: SuitesEvent, decodeSuites: boolean = true): SuitesEvent => {
	let decodedSuites: EventSuite[] = [];
	if (decodeSuites) {
		decodedSuites = event.event_suites.map((eventSuite: EventSuite) => decodeEventSuite(eventSuite));
	}

	return {
		...event,
		title: he.decode(event.title),
		event_suites: decodeSuites ? decodedSuites : [...event.event_suites],
		event_type: event.event_type ? {
			...event.event_type,
			name: he.decode(event.event_type.name)
		} : null
	};
};

export const decodeDeliveryTimes = (deliveryTimes: DeliveryTimes): DeliveryTimes => {
	const decodedDeliveryTimes: DeliveryTimes = {};
	for (const [key, value] of Object.entries(deliveryTimes)) {
		decodedDeliveryTimes[key] = value.map((eventSuite: EventSuite) => decodeEventSuite(eventSuite));
	}
	return decodedDeliveryTimes;
};

const hasGroupedItems = (order: EventSuiteOrder | Order): order is EventSuiteOrder => {
	return 'groupedItems' in order.data;
};

export const decodeOrder = <T extends EventSuiteOrder | Order>(order: T): T => {
	// Grouped items (grouped by sections)
	if(order.data) {
		if(hasGroupedItems(order) && order.data.groupedItems.length) {
			return {
				...order,
				data: {
					...order.data,
					groupedItems: decodeGroupedOrderItems(order.data.groupedItems)
				}
			};
		}

		// Items directly in the data (usual order items)
		else if (order.data.items && order.data.items.length) {
			return {
				...order,
				data: {
					...order.data,
					items: decodeItems(order.data.items)
				}
			};
		}
	}

	return order;
};

export const decodeGroupedOrderItems = (groupedItems: OrderItemGroup[]): OrderItemGroup[] => {
	return groupedItems.map((orderGroupedDataItem: OrderItemGroup) => {
		if (orderGroupedDataItem.items && orderGroupedDataItem.items.length) {
			return {
				...orderGroupedDataItem,
				name: he.decode(orderGroupedDataItem.name),
				items: decodeItems(orderGroupedDataItem.items)
			};
		}
		return orderGroupedDataItem;
	});
};

export const decodeItems = (items: OrderItem[]): OrderItem[] => {
	const decodedItems = items.map((item: OrderItem) => {
		let decodedOptions: OptionGroup[] = [];

		// Option groups
		if(item.orderOptions && item.orderOptions.length) {
			decodedOptions = item.orderOptions.map((orderOption: any) => {
				let decodedValues: OptionGroup[] = [];

				// Option group options
				if(orderOption.values && orderOption.values.length) {
					decodedValues = orderOption.values.map((orderOptionValue: any) => {

						let decodedSubOptions: any[] = [];
						// Option sub option groups
						if(orderOptionValue.options && orderOptionValue.options.length) {
							decodedSubOptions = orderOptionValue.options.map((subOption: any) => {
								let decodedSubOptionValues: OptionGroup[] = [];
								// Option sub option group options
								if(subOption.values && subOption.values.length) {
									decodedSubOptionValues = subOption.values.map((subOptionValue: any) => {
										return {
											...subOptionValue,
											name: he.decode(subOptionValue.name)
										};
									});
								}
								return {
									...subOption,
									name: he.decode(subOption.name),
									values: decodedSubOptionValues
								};
							});
						}
						return {
							...orderOptionValue,
							name: he.decode(orderOptionValue.name),
							options: decodedSubOptions
						};
					});
				}
				return {
					...orderOption,
					name: he.decode(orderOption.name),
					values: decodedValues
				};
			});
		}
		return {
			...item,
			name: he.decode(item.name),
			orderOptions: decodedOptions,
			parent: item.parent ? {
				...item.parent,
				name: he.decode(item.parent.name)
			} : undefined
		};
	});

	return decodedItems;
};

export const decodeNameOfEntity = (entity: { name: string }): { name: string } => {
	return {
		...entity,
		name: he.decode(entity.name)
	};
};

export const decodeEvents = (events: SuitesEvent[]): SuitesEvent[] => {
	return events.map((event: SuitesEvent) => decodeEvent(event, false));
};

export const decodeMenuGroup = (menuGroup: MenuGroup): MenuGroup => {
	return {
		...menuGroup,
		name: menuGroup.name ? he.decode(menuGroup.name) : '',
		description: menuGroup.description ? he.decode(menuGroup.description) : ''
	};
};

export const decodeString = (string: string): string => {
	return he.decode(string);
};