import { MutationTree } from 'vuex';
import { SectionState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<SectionState> = {

	/**
	 * Set the selected section
	 *
	 * @param state
	 * @param card
	 */
	SET_SELECTED_SECTION(state, selectedSection: MenuSection) {
		Vue.set(state, 'selectedSection', selectedSection);
	}
};