export function initTempAvailability(): MenuAvailability[] {
	return [
		{
			menu_id: null,
			day_of_week: 1,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 2,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 3,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 4,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 5,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 6,
			start_time: null,
			end_time: null
		},
		{
			menu_id: null,
			day_of_week: 7,
			start_time: null,
			end_time: null
		}
	];
}

/**
 * Reset the entire availability list
 *
 * @param {MenuAvailability[]} availabilities
 */
export function resetAvailabilities(availabilities: MenuAvailability[]): MenuAvailability[] {
	availabilities.forEach(availability => {
		resetAvailability(availability);
	});
	return availabilities;
}


/**
 * Reset a specific availability
 *
 * @param {MenuAvailability} availability
 */
export function resetAvailability(availability: MenuAvailability): MenuAvailability {
	availability.start_time = null;
	availability.end_time = null;
	return availability;
}

/**
 * Set the availability of the menu. Return the availabilities if length is 7
 * since it means it's for all week.
 *
 * @param {MenuAvailability} availability
 */
export function setAvailability(availabilities: MenuAvailability[]): MenuAvailability[] {
	if (availabilities.length === 7) {
		return availabilities;
	}
	const tempAvailability = initTempAvailability();
	tempAvailability.forEach(tempAvailability => {
		availabilities.forEach(availability => {
			if (tempAvailability.day_of_week === availability.day_of_week) {
				tempAvailability.start_time = availability.start_time;
				tempAvailability.end_time = availability.end_time;
			}
		});
	});
	return tempAvailability;
}

/**
 * Set the availability of the menu. Return the availabilities if length is 7
 * since it means it's for all week.
 *
 * @param {MenuAvailability} availability
 */
export function getAvailabilityWeekDays(availabilities: MenuAvailability[]): number[] {
	const weekDays: number[] = availabilities.map(availability => {
		return availability.day_of_week;
	});
	return weekDays;
}

/**
 * Set the availability weekdays from the DOM with start_time/end_time of current menu
 * availability, also set the days off if there is no start_time/end_time.
 * If we set one off, this means the availability is not for all week so we also
 * return that to switch that off from the DOM.
 *
 * @param {MenuAvailability[]} availabilities
 * @param {any[]} weekDays
 */
export function setTempWeekDays(availabilities: MenuAvailability[], weekDays: any[]): object {
	const { start_time, end_time } = availabilities[0];
	const isAllWeek: boolean = availabilities.every((day) => (day.start_time === start_time && day.end_time === end_time));

	for (let index = 0; index < availabilities.length; index++) {
		weekDays[index].start_time = availabilities[index].start_time;
		weekDays[index].end_time = availabilities[index].end_time;
		weekDays[index].checked = !!(availabilities[index].start_time && availabilities[index].end_time);
	}

	return { isAllWeek, weekDays };
}

/**
 * Remove any availability that doesn't have both a start_time and end_time
 * since those are invalid.
 *
 * @param {MenuAvailability[]} availabilities
 */
export function cleanAvailabilities(availabilities: MenuAvailability[] | undefined): MenuAvailability[] {
	availabilities = availabilities!.filter(availability => {
		return (availability.start_time && availability.end_time);
	});
	return availabilities;
}
