export function alphabeticalSort (a: any, b: any, property: string = 'name'): number {
	if (!a && !b) {
		return 0;
	}
	if (!a || !b) {
		return a ? -1 : 1;
	}
	const x = typeof a === 'object' ? a[property] : a;
	const y = typeof b === 'object' ? b[property] : b;

	return x.localeCompare(y, undefined, { numeric: true, sensitivity: 'base' });
}

export function valueSort (a: any, b: any, property: string, asc: boolean = true): number {
	const x = typeof a === 'object' ? a[property] : a;
	const y = typeof b === 'object' ? b[property] : b;

	return asc
		? x < y ? -1 : (x > y ? 1 : 0)
		: x < y ? (x > y ? 0 : 1) : - 1;
}

export function dateSort(a: string, b: string, asc: boolean = false): number {
	const dateA = new Date(a).getTime();
	const dateB = new Date(b).getTime();

	return asc ? dateA - dateB : dateB - dateA;
}

/**
 * Re order option groups depending on their order property.
 * We also order their values (options) by their order property if provided, otherwise by price.
 *
 * @param {OrderOptionGroup[]} optionGroups
 * @return {OrderOptionGroup[]}
 */
export function sortOrderOptions(optionGroups: OrderOptionGroup[]): OrderOptionGroup[] {
	const tempOptionGroups: OrderOptionGroup[] = [...optionGroups];
	tempOptionGroups.sort((a: OrderOptionGroup, b: OrderOptionGroup) => {
		if (a.optionGroupType === 'pricing' && b.optionGroupType !== 'pricing') {
			return -1;
		}
		else if (a.optionGroupType !== 'pricing' && b.optionGroupType === 'pricing') {
			return 1;
		}
		else {
			return a.order! - b.order!;
		}
	});

	tempOptionGroups.forEach((orderOptionGroup: OrderOptionGroup) => {
		if (orderOptionGroup.values) {
			if(orderOptionGroup.values.some(option => option.order )) {
				orderOptionGroup.values.sort((a: OrderOption, b: OrderOption) => valueSort(a, b, 'order'));
			}
			else {
				orderOptionGroup.values.sort((a: OrderOption, b: OrderOption) => valueSort(a, b, 'price'));
			}
		}
	});

	return tempOptionGroups;
}