import { AxiosInstance } from 'axios';
import { DateTime } from 'luxon';
import { state } from './orders';
import { state as authState } from '../auth/auth';
import { ActionTree } from 'vuex';
import { handleAllErrors } from '../../utils/errorHandling';
import { OrdersState, RootState } from '../types';
import { decodeOrder } from '@/utils/decoding';
import formatOrder from '@/utils/formatOrder';
import router from '@/router';
import i18n from '@/i18n';

export const actions: ActionTree<OrdersState, RootState> = {

	/**
	 * Fetch orders for marketplace locations
	 *
	 * @param {string} [lastFetchDate] - The latest created_at value of the pending orders
	 * @return {Promise<{ newOrders: boolean, fetchDate: string }|undefined>} - Whether there are new orders or not, and at what time the fetch was made
	 */
	async fetchMarketplaceOrders({ commit }, lastFetchDate? : string): Promise<{ newOrders: boolean, fetchDate: string }|undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data, headers } = await axiosInst.get<{ orders: CurrentOrders, newOrders: boolean }>('/orders/get-marketplace-orders', {
				params: { lastOrder: lastFetchDate }
			});
			commit('SET_CURRENT_ORDERS', data.orders);
			return { newOrders: data.newOrders, fetchDate: headers['date'] };
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch current orders, categorized between pending, in-progress, and completed/refunded
	 *
	 * @param {string} [lastFetchDate] - The latest created_at value of the pending orders
	 * @return {Promise<{ newOrders: boolean, fetchDate: string }|undefined>} - Whether there are new orders or not, and at what time the fetch was made
	 */
	async fetchOrders({ commit }, lastFetchDate? : string): Promise<{ newOrders: boolean, fetchDate: string }|undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data, headers } = await axiosInst.get<{ orders: CurrentOrders, newOrders: boolean }>('/orders?categorized=true', {
				params: { lastOrder: lastFetchDate }
			});
			commit('SET_CURRENT_ORDERS', data.orders);
			return { newOrders: data.newOrders, fetchDate: headers['date'] };
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch orders with a query to filter the results
	 *
	 * @param { [key: string]: string | number | boolean } query - The query to filter the orders
	 * @return {Promise<Order[]>}
	 */
	async fetchOrdersWithQuery({}, query: { [key: string]: string | number | boolean }): Promise<Order[]> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		// Convert the query object into a query string and encode it
		const queryString: string = Object.keys(query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(String(query[key]))}`).join('&');
		try {
			const { data } = await axiosInst.get<{ newOrders: boolean; orders: Order[]}>(`/orders?${queryString}`);
			return data.orders;
		}
		catch (error) {
			handleAllErrors(error);
			return [];
		}
	},

	/**
	 * Fetch cancelled orders in the last 2 days
	 *
	 * @return {Promise<Order[] | undefined>}
	 */
	async fetchCancelledOrders(): Promise<Order[] | undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data } = await axiosInst.get<{ newOrders: boolean; orders: Order[]}>('/orders?status=cancelled,refunded,refund-requested,partially-refunded,partial-refund-requested');
			return data.orders;
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Fetch an order by id and then set orders state with the order info
	 *
	 * @param {string|number} orderId
	 * @return {Promise<void>}
	 */
	async fetchAndSetOrderInfo({ commit, dispatch }, orderId: string | number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data } = await axiosInst.get<{ order: Order; errors: string[] }>(`/orders/order-with-validated-items/${orderId}`);
			commit('SET_ORDER_INFO', decodeOrder(data.order));
			dispatch('setCosts');

			if (data.errors && Object.keys(data.errors).length) {
				commit('SET_ORDER_VALIDATION_ERRORS', data.errors);
			}
		}
		catch (error) {
			router.push('/order-management');
			handleAllErrors(error);
		}
	},

	/**
	 * Send a deposit request
	 *
	 * @param {UpdateOrderPayload} payload
	 * @return {Promise<void>}
	 */
	async sendDepositRequest({}, payload: RequestDepositPayload): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.post<Order>('/orders/request-deposit', payload, {
				headers: {
					'Authorization': authState.idToken as string
				}
			});
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update an order
	 * * Send the Auth0 idToken in the Authorization header instead of the accessToken because we need the role metadata
	 *
	 * @param {UpdateOrderPayload} payload
	 * @return {Promise<void>}
	 */
	async updateOrder({}, { orderId, payload }: UpdateOrderPayload): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.put<Order>(`/orders/${orderId}`, payload, {
				headers: {
					'Authorization': authState.idToken as string
				}
			});
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Refund an order
	 * * Send the Auth0 idToken in the Authorization header instead of the accessToken because we need the role metadata
	 *
	 * @param {number} orderId
	 * @return {Promise<void>}
	 */
	async refundOrder({}, orderId: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.post<any>('/orders/refund', {
				orderId,
				app8RestaurantId: authState.restaurant.app8_restaurant
			},
			{
				headers: {
					'Authorization': authState.idToken as string
				}
			});
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Send an array of order ids to the bulk actions endpoint to perform an action on them
	 *
	 * @param {BulkActionPayload} payload - Contains array of order ids and the action to perform on them
	 * @return {Promise<BulkActionResponse | void>}
	 */
	async bulkActions({}, payload: BulkActionPayload): Promise<BulkActionResponse | void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data } = await axiosInst.post<BulkActionResponse>('/orders/bulk-actions', payload);
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Get the costs of the order
	 *
	 * @param {Order} order
	 * @return {Promise<{ orderItems: OrderItem[], costs: Costs } | undefined>}
	 */
	async getOrderCosts({}, order: Order): Promise<{ orderItems: OrderItem[], costs: Costs } | undefined> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data } = await axiosInst.post<{ orderItems: OrderItem[], costs: Costs }>('/orders/recalculate-costs', order);
			return data;
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	setModifyingOrder({ commit }, modifyingOrder: boolean): void {
		commit('SET_MODIFYING_ORDER', modifyingOrder);
	},

	setEditingItem({ commit }, editingItem: boolean): void {
		commit('SET_EDITING_ITEM', editingItem);
	},

	setOrderDetails({ commit }, orderDetails: OrderDetails): void {
		commit('SET_ORDER_DETAILS', orderDetails);
	},

	resetOrderDetails({ commit }): void {
		commit('SET_ORDER_DETAILS', {
			type: '',
			tableArea: '',
			tableLocation: '',
			tableNum: '',
			takeoutType: '',
			checkNum: '',
			menuManagerLaneReservation: undefined
		});
	},

	setSelectedItem({ commit }, selectedItem: MenuItem): void {
		commit('SET_SELECTED_ITEM', selectedItem);
		commit('SET_ORDER_ITEM', { selectedItem, formatItem: true });
	},

	setOrderItem({ commit }, orderItem: OrderItem): void {
		commit('SET_ORDER_ITEM', { selectedItem: orderItem, formatItem: false });
	},

	setOrderItemOptions({ commit }, orderItemOptions: OrderOptionGroup[]): void {
		commit('SET_ORDER_ITEM_OPTIONS', orderItemOptions);
	},

	setOrderItemQuantity({ commit }, quantity: number): void {
		commit('SET_ORDER_ITEM_QUANTITY', quantity);
	},

	async addToCart({ commit, dispatch }, specialInstructions: string): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const itemPayload = {
			itemsInCart: state.cart.items,
			itemToAdd: state.orderItem,
			type: state.orderDetails.type,
			date: DateTime.local().toISO(),
			isMenuManagerOrder: true
		};

		try {
			await axiosInst.post('/orders/item', itemPayload);
			commit('ADD_TO_CART', specialInstructions);
			await dispatch('setCosts');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	removeFromOrder({ commit, dispatch }, index: number): void {
		commit('REMOVE_FROM_ORDER', index);
		dispatch('setCosts');
		commit('RESET_ITEM_VIEWER_STATE');
	},

	async setCosts({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const payload = {
			tableNum: state.orderDetails.tableNum,
			items: state.cart.items,
			costs: state.costs,
			checkout: {
				...state.checkout,
				pickup: state.checkout.pickup,
				memberInfo: state.checkout.memberInfo ? {
					...state.checkout.memberInfo,
					promosApplied: state.checkout.memberInfo?.promosApplied ? Array.from(state.checkout.memberInfo.promosApplied) : null
				} : null
			},
			date: DateTime.local().toISO()
		};

		try {
			const res = await axiosInst.post('/orders/set-costs', payload);
			commit('SET_COSTS', res.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Validate the cart before going to the checkout process
	 *
	 * @return {Promise<void>}
	 */
	async validateCart({}): Promise<void> {
		if (state.cart.items) {
			const axiosInst: AxiosInstance = authState.axiosInst;
			const cartRequest = {
				itemsInCart: state.cart.items,
				type: state.orderDetails.type,
				date: state.selectedOrder?.data ? state.selectedOrder.data.date : DateTime.local().toISO(),
				isMenuManagerOrder: true
			};
			try {
				await axiosInst.post('/orders/validate', cartRequest);
			}
			catch (error) {
				handleAllErrors(error);
			}
		}
	},

	async fetchSchedulingRestrictions({}, payload: MenuOrderItem[]): Promise<any> {
		try {
			const axiosInst: AxiosInstance = authState.axiosInst;
			const res = await axiosInst.post<ScheduleRestrictions>('/orders/scheduling', payload);
			return res.data;

		}
		catch (error) {
			throw error;
		}
	},

	async submitOrder({}): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const ordersRequest: any = {
				type: state.orderDetails.type,
				data: formatOrder(state, authState),
				status: state.selectedOrder?.status ?? 'pending'
			};

			if (state.checkout.pickup && state.checkout.pickup.dueByDate) {
				const dueByDate: DateTime = DateTime.fromISO(state.checkout.pickup!.dueByDate);
				const dueByTime: DateTime | null = state.checkout.pickup.dueByTime ? DateTime.fromFormat(state.checkout.pickup.dueByTime!, i18n.t('orders.guest_details.takeout.timepicker_format')) : null;

				// JS Dates have months range from 0 - 11 instead of 1 - 12 like luxon
				ordersRequest.pickup_time = DateTime.fromObject({
					year: dueByDate.year,
					month: dueByDate.month,
					day: dueByDate.day,
					hour: dueByTime ? dueByTime.hour : 12,
					minute: dueByTime ? dueByTime.minute : undefined
				});

				// We add prep time to the pickup_time if ASAP
				if(!state.checkout.pickup.scheduled && state.checkout.pickup.prepTime) {
					ordersRequest.pickup_time = ordersRequest.pickup_time.plus({ minutes: state.checkout.pickup.prepTime }).toUTC().toISO();
				}

				// Scheduled is already adjusted with the correct time
				else {
					ordersRequest.pickup_time = ordersRequest.pickup_time.toUTC().toISO();
				}
			}
			if (state.modifyingOrder && state.selectedOrder?.id) {
				await axiosInst.put(`/orders/modify/${state.selectedOrder.id}`, ordersRequest);
			}
			else {
				await axiosInst.post('/orders', ordersRequest);
			}
			if(!!window.B2BApp) {
				window.B2BApp.orderSubmitted();
			}
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Check if the user is part of the membership program of the respective restaurant.
	 * If the user is a part of, we set the member information with its info and
	 * handle the payload returned (contact info, promos, address, etc)
	 *
	 * @param {string} identifier
	 * @return {Promise<any>}
	 */
	async checkIfMemberAndApplyPromo({ dispatch, commit }, { identifier, menus } : { identifier: string; menus: Menu[] }): Promise<any> {
		const axiosInstance: AxiosInstance = authState.axiosInst;
		const payload = {
			identifier,
			app8RestaurantId: authState.restaurant.app8_restaurant
		};
		try {
			const { data } = await axiosInstance.post('/auth/get-user-membership-promo', payload);
			// We don't wanna populate the member object with an empty response
			if(data && data.id) {
				commit('SET_MEMBER_INFO', data);
				commit('SET_MEMBER_PRICES', menus);
			}
			await dispatch('setCosts');
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	setContactInformation({ commit }, contactInfo: Contact): void {
		commit('SET_CONTACT_INFORMATION', contactInfo);
	},

	setTakeoutInformation({ commit }, takeoutInfo: Pickup): void {
		commit('SET_TAKEOUT_INFORMATION', takeoutInfo);
	},

	setDeliveryInformation({ commit }, deliveryInfo: DeliveryInfo): void {
		commit('SET_DELIVERY_INFORMATION', deliveryInfo);
	},

	setCheckoutAnsweredQuestions({ commit }, answeredQuestions: AnsweredCustomQuestion[]): void {
		commit('SET_CHECKOUT_ANSWERED_QUESTIONS', answeredQuestions);
	},

	setNotes({ commit }, notes: string): void {
		commit('SET_NOTES', notes);
	},

	setRestaurantCharges({ commit }, restaurant: Restaurant): void {
		commit('SET_RESTAURANT_CHARGES', restaurant);
	},

	resetItemViewerState({ commit }): void {
		commit('RESET_ITEM_VIEWER_STATE');
	},

	resetOrderState({ commit }): void {
		commit('RESET_ORDERS_STATE');
	}
};