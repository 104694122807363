
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Auth from '@/services/auth/auth';
import LoginForm from '@/components/auth/LoginForm.vue';
import ConfirmationModal from '@/components/shared/ConfirmationModal.vue';

const namespace: string = 'auth';

@Component<Login>({
	components: {
		LoginForm,
		ConfirmationModal
	}
})
export default class Login extends Vue {
	@Action('login', { namespace }) private login!: (credentials: LoginCredentials) => Promise<void>;
	@Action('resetPassword', { namespace }) private resetPassword!: (email: string | null) => Promise<void>;

	private forgotPassword: boolean = false;
	private loading: boolean = false;
	private showInfoModal: boolean = false;
	private errorMsg: string | null = null;
	private loginCredentials: LoginCredentials = {
		username: '',
		password: ''
	};
	private infoHeader: string = '';
	private infoText: string = '';

	/**
	 * Auto fill user's email if we have his last login in the storage
	 *
	 * @return {void}
	 */
	private async created(): Promise<void> {
		this.checkCrossOrigin();
		const lastLogin: any = Auth.lastLogin;
		if (lastLogin) {
			this.loginCredentials.username = lastLogin.email;
		}
	}

	/**
	 * Check if third party cookies was disabled and
	 * was the reason the login failed
	 *
	 * @return {void}
	 */
	private checkCrossOrigin(): void {
		if (Auth.crossOriginFailed) {
			this.showInfoModal = true;
			this.infoHeader = this.$t('auth.error_cross_origin_failed_header');
			this.infoText = this.$t('auth.error_cross_origin_failed_text');
		}
	}

	private closeInfoModal(): void {
		this.showInfoModal = false;
	}

	/**
	 * Make sure the fields are valid, from there either try to
	 * login the user or send a reset password request.
	 *
	 * @return {Promise<void>}
	 */
	private async submit(): Promise<void> {
		this.errorMsg = null;
		if (!this.forgotPassword) {
			await this.loginUser();
		}
		else {
			await this.sendResetPasswordRequest();
		}
	}

	/**
	 * Login user
	 *
	 * @return {Promise<void>}
	 */
	private async loginUser(): Promise<void> {
		try {
			this.loading = true;
			await this.login({
				username: this.loginCredentials.username,
				password: this.loginCredentials.password
			});
		}
		catch (error) {
			const tempErrorInfoMsg = error && error.error ? error.error : this.$t('auth.error_logging_in_default');
			this.errorMsg = this.$t('auth.error_logging_in_default', { errorMessage: tempErrorInfoMsg });
		}
		finally {
			this.loading = false;
		}
	}

	/**
	 * Send reset password request to the user's email
	 *
	 * @return {Promise<void>}
	 */
	private async sendResetPasswordRequest(): Promise<void> {
		await this.resetPassword(this.loginCredentials.username)
			.then(() => {
				this.showInfoModal = true;
				this.infoHeader = this.$t('auth.password_request_header');
				this.infoText = this.$t('auth.password_request_text');
				this.forgotPassword = false;
			})
			.catch(error => {
				this.errorMsg = this.$t('auth.password_request_text', { errorMessage: error.error });
			});
	}

	private forgotPasswordToggle(): void {
		this.forgotPassword = !this.forgotPassword;
	}
}
