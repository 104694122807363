
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LOCALES } from '@/utils/constants';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<CustomLocales>({
	components: {
		ValidationProvider
	}
})
export default class CustomLocales extends Vue {
	@Prop({ type: Array, default: () => [] }) private targetLocales!: RestaurantLocale[];
	@Prop({ type: Boolean, default: false }) private targetMultipleLocales!: boolean;

	private defaultLanguage: RestaurantLocale|null = null;
	private mutableLocales: RestaurantLocale[]|null = this.targetLocales;
	private mutableMultipleLocales: boolean = this.targetMultipleLocales;
	private locales: RestaurantLocale[] = LOCALES;

	/**
	 * On mounted, if there is mutable locales, this means there must be a
	 * default selected. We find it and assign it so it populates the field
	 *
	 * @return {void}
	 */
	private mounted(): void {
		if(this.mutableLocales && this.mutableLocales.length) {
			this.defaultLanguage = this.mutableLocales.find((mutableLocale: RestaurantLocale) => {
				return mutableLocale.is_default;
			})!;
		}
	}

	/**
	 * When the switch turns off, we need to wipe off any languages selected.
	 *
	 * @param {boolean} value
	 * @return {void}
	 */
	private multipleLanguagesSwitch(): void {
		if(!this.mutableMultipleLocales) {
			this.mutableLocales = null;
			this.$emit('update-restaurant-locales', null);
		}
	}

	/**
	 * When a language is added or removed, we reset the default language selected
	 * to ensure that user re-select the language they want as default.
	 *
	 * @return {void}
	 */
	private updateRestaurantLocales(): void {
		this.defaultLanguage = null;
	}


	/** Update the default restaurant locale and fire an event
	 * to the parent to store that data
	 *
	 * @return {void}
	 */
	private updateDefaultRestaurantLocale(): void {
		this.mutableLocales!.map((mutableLocale: RestaurantLocale) => {
			mutableLocale.is_default = !!(mutableLocale.locale_short === this.defaultLanguage!.locale_short);
		});
		this.$emit('update-restaurant-locales', this.mutableLocales);
	}
}
