
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ROLES, TOAST_INSTANCE } from '../utils/constants';
import TitleHeader from '@/components/shared/Header.vue';
import BannerToast from '@/components/shared/BannerToast.vue';
import CsvImportModal from '@/components/menu/CsvImportModal.vue';

const namespace: string = 'auth';

@Component<RestaurantList>({
	components: {
		TitleHeader,
		BannerToast,
		CsvImportModal
	}
})
export default class RestaurantList extends Vue {
	@Action('pullMenus', { namespace: 'menus' }) private pullMenus!: (payload: any) => Promise<Menu[]>;
	@Action('buildLocalMenu', { namespace: 'menus' }) private buildLocalMenu!: (restaurantId: string) => Promise<void>;
	@Action('setTenant', { namespace }) private setTenant!: (tenantId: string) => Promise<void>;
	@Getter('getTenantId', { namespace }) private tenantId!: string;
	@Getter('getRestaurants', { namespace }) private restaurants!: Tenant[];
	@Getter('getRoleLevel', { namespace }) private roleLevel!: number;

	private showCsvImportModal: boolean = false;
	private selectedRestaurantId: string = '';
	private roles: Roles = ROLES;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;

	// Only show pull all button if more than one integrated restaurants, otherwise it's redundant
	private get showPullAllButton(): boolean {
		const integratedRestaurants = this.restaurants.filter((restaurant: Tenant) => {
			return restaurant.pos_integrated;
		});
		if(integratedRestaurants && integratedRestaurants.length > 1) {
			return true;
		}
		return false;
	}

	private get currentEnv(): string {
		return process.env.NODE_ENV;
	}

	private async setRestaurant(tenantId: string): Promise<void> {
		await this.setTenant(tenantId);
		this.$router.replace('/menus');
	}

	/**
	 * Pull menu(s)
	 *
	 * @return {void}
	 */
	private async menuPull(restaurant: Tenant): Promise<void> {
		try {
			await this.pullMenus({ id: restaurant.id, name: restaurant.name, pullAll: false });
			this.bannerToastInfo.color = '#00B796';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('menu.pull.success_menu_pull');
		}
		catch (error) {
			this.bannerToastInfo.color = 'primary';
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('menu.pull.error_menu_pull');
		}
	}

	/**
	 * Open the CSV import modal and pass the restaurant ID to it
	 *
	 * @param {string} restaurantId
	 * @return {void}
	 */
	private openCsvImportModal(restaurantId: string): void {
		this.selectedRestaurantId = restaurantId;
		this.showCsvImportModal = true;
	}
}
