import { validateHTMLColorHex } from 'validate-color';
import { extend } from 'vee-validate';
import { required, email, min_value, numeric, max_value, integer } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

extend('required', {
	...required,
	message: i18n.t('validator.required')
});

extend('integer', {
	...integer,
	message: i18n.t('validator.integer')
});

extend('email', {
	...email,
	message: i18n.t('validator.email')
});

extend('min', {
	validate(value, args) {
		return value.length >= args.length;
	},
	params: ['length'],
	message: i18n.t('validator.min')
});

extend('max', {
	validate(value, args) {
		return value.length <= args.length;
	},
	params: ['length'],
	message: i18n.t('validator.max')
});

extend('min_value', {
	...min_value,
	message: i18n.t('validator.min_value')
});

extend('max_value', {
	...max_value,
	message: i18n.t('validator.max_value')
});

extend('numeric', {
	...numeric,
	message: i18n.t('validator.numeric')
});

extend('decimal', {
	// TO DO: FIX ts error
	//@ts-ignore
	validate: (value, { decimals = '*', separator = '.' } = {}) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false
			};
		}
		if (Number(decimals) === 0) {
			return {
				valid: /^-?\d*$/.test(value)
			};
		}
		const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
		const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.decimal')
});

extend('validateColor',{
	validate: (color) => {
		return color
			? validateHTMLColorHex(color)
			: true;
	},
	message: i18n.t('validator.invalid_color')
});

extend('higherThanZero',{
	validate: (value) => {
		return value > 0;
	},
	message: i18n.t('validator.higher_than_zero')
});

extend('greater_than', {
	params: ['target', 'field1-name', 'field2-name'],
	// TO DO: FIX ts error
	//@ts-ignore
	validate(value, { target }) {
		return value > target;
	},
	message: i18n.t('validator.greater_than', { field1: '{field1-name}', field2: '{field2-name}' })
});

extend('characters_for_name', {
	validate: (value) => {
		if (value[value.length - 1] === '-' || value[value.length - 1] === '\'') {
			return false;
		}

		const regex = /(?![\'-])^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜßŸ\'-\s]*$/i;
		return {
			valid: regex.test(value)
		};
	},
	message: i18n.t('validator.characters_for_name')
});

extend('uppercase', {
	validate: (value) => value === value.toUpperCase(),
	message: i18n.t('validator.uppercase')
});

extend('uniqueName', {
	// TO DO: FIX ts error
	//@ts-ignore
	validate(value, { list }) {
		if (!Array.isArray(list)) {
			return true;
		}
		return list.filter(name => name === value).length === 1;
	},
	params: ['list'],
	message: i18n.t('validator.name_must_be_unique')
});