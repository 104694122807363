
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import '@/validation-rules';

@Component<OrderOptionsInfo>({
	components: {
		ValidationProvider
	}
})
export default class OrderOptionsInfo extends Vue {
	@Prop({ type: Object, default: () => {} }) private targetOrderOptions!: OrderOptions;

	private mutableOrderOptions: any = this.targetOrderOptions;

	private updateOrderOptions(): void {

		// Don't want to pass a property if empty since it's meaningless
		if(this.mutableOrderOptions && !this.mutableOrderOptions.max_items_per_cart) {
			delete this.mutableOrderOptions.max_items_per_cart;
		}
		setTimeout(() => {
			this.$emit('update-order-options', { ...this.mutableOrderOptions });
		}, 100);
	}
}
