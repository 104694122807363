
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TOAST_INSTANCE } from '../../utils/constants';
import PageLoader from '@/components/shared/PageLoader.vue';
import Auth from '@/services/auth/auth';
import BannerToast from '@/components/shared/BannerToast.vue';

const namespace: string = 'auth';

@Component<LoginCallback>({
	components: {
		PageLoader,
		BannerToast
	}
})
export default class LoginCallback extends Vue {
	@Action('authenticate', { namespace }) private authenticate!: () => Promise<void>;
	@Action('logout', { namespace }) private logout!: () => Promise<void>;
	@Action('clearSSO', { namespace }) private clearSSO!: () => Promise<void>;
	@Getter('isAuthenticated', { namespace }) private isAuthenticated!: boolean;
	@Getter('getRestaurants', { namespace }) private restaurants!: Tenant[];

	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private loggingLoader: boolean = false;

	private async mounted() {
		this.loggingLoader = true;
		try {
			if (!this.isAuthenticated) {
				await this.authenticate();
			}
			if (this.restaurants.length > 1) {
				this.$router.replace('/locations').catch(() => {});
			}
			else {
				this.$router.replace('/menus').catch(() => {});
			}
		}
		catch (error) {
			const tempError: any = error;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = tempError;

			// Allow time for the user to see the error toast before being redirect back to login
			this.clearSSO();
			Auth.logout();
		}
		this.loggingLoader = false;
	}
}
