import { MutationTree } from 'vuex';
import { SuiteOwnerState } from '../types';
import { alphabeticalSort } from '@/utils/sort';
import Vue from 'vue';

export const mutations: MutationTree<SuiteOwnerState> = {
	/**
	 * Set suite owners
	 *
	 * @param {SuiteOwnerState} state
	 * @param {SuiteOwner[]} suiteOwners
	 * @return {void}
	 */
	SET_SUITE_OWNERS(state, suiteOwners: SuiteOwner[]): void {
		Vue.set(state, 'suiteOwners', suiteOwners);
	},

	/**
	 * Add a new suite
	 *
	 * @param {SuiteState} state
	 * @param {SuiteOwner} newSuiteOwner
	 * @return {void}
	 */
	ADD_SUITE_OWNER(state: SuiteOwnerState, newSuiteOwner: Suite): void {
		Vue.set(state, 'suiteOwners', [...state.suiteOwners, newSuiteOwner].sort(alphabeticalSort));
	},

	/**
	 * Update a suite owner
	 *
	 * @param {SuiteOwnerState} state
	 * @param {SuiteOwner} updatedSuiteOwner
	 * @return {void}
	 */
	UPDATE_SUITE_OWNER(state: SuiteOwnerState, updatedSuiteOwner: SuiteOwner): void {
		Vue.set(state, 'suiteOwners', state.suiteOwners.map(owner => owner.id === updatedSuiteOwner.id ? updatedSuiteOwner : owner).sort(alphabeticalSort));
	}
};