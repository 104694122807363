
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<ItemLocaleEditor>({})
export default class ItemLocaleEditor extends Vue {
	@Prop({ type: Object, default: () => {} }) private item!: MenuItem;
	@Prop({ type: Object, default: () => {} }) private selectedLocale!: RestaurantLocale;
	@Prop({ type: Array, default: () => [] }) private pricingItems!: Option[];
	@Prop({ type: Array, default: () => [] }) private ingredients!: string[];
	@Prop({ type: Boolean, default: false }) private disabled!: boolean;
	@Prop({ type: Boolean, default: false }) private valid!: boolean;
	@Watch('selectedLocale')
	onSelectedLocaleUpdate() {
		this.tempIngredients = [];
		// If default we don't need to add any localization info, as those are already
		// in the default values
		if(!this.selectedLocale.is_default) {
			this.mutableItem.localization = this.mutableItem.localization ? this.mutableItem.localization : {} as Localization;
			// If no localization for that specific language, we need to initialize them
			if(!this.mutableItem.localization[this.selectedLocale.locale_short]) {
				this.mutableItem.localization[this.selectedLocale.locale_short] = {
					name: '',
					description: '',
					ingredients: '',
					price_type: ''
				};
			}

			// Pricing options
			if(this.pricingItems && this.pricingItems.length) {
				this.mutablePricing = this.pricingItems;

				this.mutablePricing.map((pricingItem: Option) => {
					if(pricingItem.name) {
						pricingItem.localization = pricingItem.localization ? pricingItem.localization : {} as Localization;
						if(!pricingItem.localization[this.selectedLocale.locale_short]) {
							pricingItem.localization[this.selectedLocale.locale_short] = {
								name: ''
							};
						}
					}
					return pricingItem;
				});
			}

			// Setup the ingredients array
			this.localizationIngredients = [...this.mutableItem.localization[this.selectedLocale.locale_short].ingredients.split(',').map((ingredient: string) => ingredient.trim())];
			for (let index = 0; index < this.ingredients.length; index++) {
				if(this.ingredients[index]) {
					this.tempIngredients.push({ name: this.ingredients[index], localizationName: this.localizationIngredients[index] ? this.localizationIngredients[index] : undefined });
				}
			}
			this.autoGeneratedDescription = this.updateAutoGeneratedDescription();
			this.autoGenerateDescriptionSwitch = this.autoGeneratedDescription ? this.mutableItem.localization[this.selectedLocale.locale_short].description === this.autoGeneratedDescription : false;
		}
	}

	private autoGeneratedDescription: string = '';
	private autoGenerateDescriptionSwitch: boolean = false;
	private mutableItem: MenuItem = this.item;
	private mutablePricing: Option[] = [];
	private tempIngredients: IngredientLocale[] = [];
	private localizationIngredients: string[] = [];

	/**
	 * Simply generate an auto description with the current temp ingredients array
	 *
	 * @return {string}
	 */
	private updateAutoGeneratedDescription(): string {
		let tempDescription: string = '';
		if(this.tempIngredients && this.tempIngredients.length) {
			this.tempIngredients.forEach((tempIngredient: IngredientLocale) => {
				if(tempIngredient.localizationName) {
					tempDescription += !tempDescription ? tempIngredient.localizationName : `, ${tempIngredient.localizationName}`;
				}
			});
		}
		return tempDescription;
	}

	/**
	 * Set the description to the auto generated description
	 * or empty it out if false
	 *
	 * @return {void}
	 */
	private changeAutoGeneratedDescriptionSwitch(): void {
		this.mutableItem.localization![this.selectedLocale.locale_short].description = this.autoGenerateDescriptionSwitch ? this.autoGeneratedDescription : '';
	}

	/**
	 * Update the ingredient string and also the auto generated description if so
	 *
	 * @return {void}
	 */
	private onUpdateIngredient(): void {
		this.autoGeneratedDescription = this.updateAutoGeneratedDescription();
		this.mutableItem.localization![this.selectedLocale.locale_short].ingredients = this.autoGeneratedDescription;
		if (this.autoGenerateDescriptionSwitch) {
			this.mutableItem.localization![this.selectedLocale.locale_short].description = this.autoGeneratedDescription;
		}
	}
}
