
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getOrderStatus, getOrderType } from '@/utils/getters';
import { TOAST_INSTANCE } from '@/utils/constants';
import { decodeItems } from '@/utils/decoding';
import { cloneDeep } from 'lodash';
import StandardModal from '@/components/shared/suites/StandardModal.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';
import OrderInfo from '@/components/order-management/OrderInfo.vue';
import OrderDetails from '@/components/order-management/OrderDetails.vue';
import RequestDepositModal from '@/components/order-management/RequestDepositModal.vue';
import DeclineOrderModal from '@/components/order-management/DeclineOrderModal.vue';
import RefundRequestModal from '@/components/order-management/RefundRequestModal.vue';
import OrderModalActions from '@/components/order-management/OrderModalActions.vue';
import GenericCateringWarningOrderModal from '@/components/order-management/GenericCateringWarningOrderModal.vue';

const namespace: string = 'orders';

@Component<OrderModal>({
	components: {
		StandardModal,
		OrderInfo,
		OrderDetails,
		RequestDepositModal,
		DeclineOrderModal,
		RefundRequestModal,
		WarningModal,
		OrderModalActions,
		GenericCateringWarningOrderModal
	}
})
export default class OrderModal extends Vue {
	@Action('updateOrder', { namespace }) private updateOrder!: (payload: UpdateOrderPayload) => Promise<void>;
	@Action('sendDepositRequest', { namespace }) private sendDepositRequest!: (payload: RequestDepositPayload) => Promise<void>;
	@Action('refundOrder', { namespace }) private refundOrder!: (orderId: number) => Promise<void>;
	@Action('getOrderCosts', { namespace }) private getOrderCosts!: (order: Order) => Promise<{ orderItems: OrderItem[], costs: Costs }>;
	@Getter('isMarketplaceEnabled', { namespace: 'auth' }) private isMarketplaceEnabled!: Boolean;
	@Getter('getRestaurant', { namespace: 'auth' }) private restaurant!: Restaurant;
	@Prop({ type: Object, required: true }) private order!: Order;

	private isSuitesCateringEditing: boolean = false;
	private disableSave: boolean = true;
	private loadingItemIndex: number = -1;
	private mutableOrder: Order = cloneDeep(this.order);
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private loading: { [key: string]: boolean } = {
		accept: false,
		requestDeposit: false,
		decline: false,
		complete: false,
		refund: false,
		refundRequest: false,
		save: false
	};
	private orderStatusMapping: { [key: string]: string } = {
		accept: 'in-progress',
		decline: 'cancelled',
		complete: 'completed',
		refundRequest: 'refund-requested'
	};
	private showModal: { [key: string]: boolean } = {
		requestDeposit: false,
		declineOrder: false,
		removeItem: false,
		genericCateringComplete: false,
		chargeOrInvoice: false,
		failedPreAuth: false,
		confirmRefund: false,
		refundRequest: false
	};
	private genericCateringCompleteModaldescription: string = '';

	private get email(): string | undefined {
		return this.order.data.checkout?.contact?.email;
	}

	private get parentOrderType(): ParentOrderType {
		return getOrderType(this.mutableOrder);
	}

	private get orderStatus(): string {
		return getOrderStatus(this.mutableOrder.status);
	}

	private get items(): OrderItem[] {
		return this.mutableOrder.data.items;
	}

	/**
	 * Get the order payment preference. We check all payment types and return the one that has been used with
	 * the localized data. We also set the description for the generic catering complete modal.
	 *
	 * @return {string | undefined}
	 */
	private get orderPaymentPreference(): string | undefined {
		if(this.mutableOrder.type === 'generic-catering') {
			const i18nPathDepositModal: string = 'order_management.request_deposit_modal.warning';
			const i18nPathPaymentPreferences: string = 'order_management.order_modal.payment_preferences';
			if(this.mutableOrder.data.checkout?.invoice) {
				const invoiceMethod = this.restaurant.extra_payment_methods?.find(method => method.type === 'invoice');
				const invoiceLabel = this.$options.filters?.localize(invoiceMethod, 'label');
				this.genericCateringCompleteModaldescription = this.$t(`${i18nPathDepositModal}.invoice_description`);
				return invoiceLabel || this.$t(`${i18nPathPaymentPreferences}.invoice`);
			}
			else if(this.mutableOrder.data.checkout?.costCenter) {
				const costCenterMethod = this.restaurant.extra_payment_methods?.find(method => method.type === 'cost_center');
				const costCenterLabel = this.$options.filters?.localize(costCenterMethod, 'label');
				this.genericCateringCompleteModaldescription = this.$t(`${i18nPathDepositModal}.cost_center_and_purchase_order_description`);
				return `${costCenterLabel}: (#${this.mutableOrder.data.checkout.costCenter})` || `${this.$t(`${i18nPathPaymentPreferences}.cost_center`)}: ${this.mutableOrder.data.checkout.costCenter}`;
			}
			else if(this.mutableOrder.data.checkout?.purchaseOrder) {
				const purchaseOrderMethod = this.restaurant.extra_payment_methods?.find(method => method.type === 'purchase_order');
				const purchaseOrderLabel = this.$options.filters?.localize(purchaseOrderMethod, 'label');
				this.genericCateringCompleteModaldescription = this.$t(`${i18nPathDepositModal}.cost_center_and_purchase_order_description`);
				return `${purchaseOrderLabel}: (#${this.mutableOrder.data.checkout.purchaseOrder})` || `${this.$t(`${i18nPathPaymentPreferences}.purchase_order`)}: ${this.mutableOrder.data.checkout.purchaseOrder}`;
			}
			this.genericCateringCompleteModaldescription = this.$t(`${i18nPathDepositModal}.credit_card_description`);
			return this.$t(`${i18nPathPaymentPreferences}.credit_card`);
		}
		return undefined;
	}

	/**
	 * Cancel changes to items and undo editing mode
	 *
	 * @return {void}
	 */
	private cancelOrderChanges(): void {
		this.mutableOrder = cloneDeep(this.order);
		this.isSuitesCateringEditing = false;
		this.disableSave = true;
	}

	/**
	 * Copy the mutableOrder object and update the item's quantity (decrease by one or 0) before sending the order to the backend
	 * for costs calculation.
	 * On successful request it updates the costs of the mutable order.
	 *
	 * @param {{ index: number; type: 'decrement'|'remove' }}
	 * @return {Promise<void>}
	 */
	private async getCosts({ index, type }: { index: number; type: 'decrement'|'remove' }): Promise<void> {
		let tempOrder: Order = cloneDeep(this.mutableOrder);
		let tempItems: OrderItem[];
		let tempCosts: Costs;

		// Update the item's quantity depending if we just decrease by 1 or remove it
		type === 'decrement' && this.mutableOrder.data.items[index].quantity > 1
			? tempOrder.data.items[index].quantity--
			: tempOrder.data.items[index].quantity = 0;

		// Guard to prevent removing the last item from the order
		if (!tempOrder.data.items.some(item => item.quantity > 0)) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.order_modal.remove_all_items_error');
			return;
		}

		this.loadingItemIndex = index;
		try {
			const { costs, orderItems } = await this.getOrderCosts(tempOrder);
			tempCosts = costs;
			tempItems = orderItems;
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_calculating_costs', { errorMessage });
		}
		finally {
			setTimeout(() => {
				if (tempCosts && tempItems) {
					this.mutableOrder.data.items = decodeItems(tempItems);
					this.mutableOrder.data.costs = tempCosts;
					this.disableSave = false;
				}
				this.loadingItemIndex = -1;
			}, 500);
		}
	}

	/**
	 * Save changes to the order.
	 * If an item has been removed, show a confirmation modal.
	 *
	 * @param {boolean} [saveConfirmed] is the save confirmed in the warning modal
	 * @return {void}
	 */
	private async saveChanges(saveConfirmed?: boolean): Promise<void> {
		const filteredItems = this.mutableOrder.data.items.filter(item => item.quantity > 0);
		// If an item has been removed show confirmation modal
		if (!saveConfirmed && filteredItems.length < this.order.data.items.length) {
			this.showModal.removeItem = true;
			return;
		}

		this.showModal.removeItem = false;
		this.loading.save = true;
		let errorMessage: string = '';

		try {
			await this.updateOrder({
				orderId: this.order.id,
				payload: {
					data: {
						items: filteredItems,
						costs: this.mutableOrder.data.costs
					}
				}
			});
		}
		catch (error) {
			errorMessage = error;
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_updating_order', { errorMessage });
		}
		finally {
			setTimeout(() => {
				if (!errorMessage) {
					this.$emit('refresh', { id: this.order.id });
					this.isSuitesCateringEditing = false;
					this.disableSave = true;
				}
				this.loading.save = false;
			}, 500);
		}
	}

	/**
	 * Update the status of the order and emit a re-fetch of orders.
	 *
	 * @param {string} type - The type of action (complete, accept...)
	 * @param {string} [note] - Cancellation note
	 * @param {boolean} [isPayOrder] - On preorder completion, do we charge the CC (true) or send an invoice (false)
	 * @return {Promise<void>}
	 */
	private async updateOrderStatus(type: string, note?: string, isPayOrder?: boolean): Promise<void> {
		const status = this.orderStatusMapping[type];
		this.showModal.chargeOrInvoice = false;
		this.showModal.genericCateringComplete = false;
		this.loading[type] = true;
		let errorMessage: string = '';
		try {
			await this.updateOrder({
				orderId: this.order.id,
				payload: {
					status,
					cancellation_note: note,
					payOrder: isPayOrder
				}
			});
			this.$emit('refresh');
		}
		catch (error) {
			errorMessage = error;

			if (errorMessage === 'preauth_failed') {
				this.showModal.failedPreAuth = true;
			}
			else {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('order_management.error_updating_order', { errorMessage });
			}
		}
		finally {
			setTimeout(() => {
				this.loading[type] = false;
				if (!errorMessage) {
					this.showModal.declineOrder = false;
					this.showModal.refundRequest = false;
					this.mutableOrder.status = status;
					this.$emit('refresh', { status });
				}
			}, 500);
		}
	}

	/**
	 * Request deposit
	 *
	 * @param {number} amount
	 * @return {Promise<void>}
	 */
	private async requestDeposit(amount: number): Promise<void> {
		this.loading.requestDeposit = true;
		try {
			await this.sendDepositRequest({
				orderId: this.mutableOrder.id,
				email: this.email!,
				amount
			});
			setTimeout(() => {
				this.showModal.requestDeposit = false;
			}, 500);
		}
		catch (error) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.request_deposit_modal.error_sending_deposit_request', { errorMessage: error });
		}
		finally {
			setTimeout(() => {
				this.loading.requestDeposit = false;
			}, 500);
		}
	}

	/**
	 * Refund the order and set the mutable order as refunded.
	 *
	 * @return {Promise<void>}
	 */
	private async refund(): Promise<void> {
		this.showModal.confirmRefund = false;
		this.loading.refund = true;
		let status: string = this.mutableOrder.status;
		try {
			await this.refundOrder(this.order.id);
			this.$emit('refresh');
			status = 'refunded';
		}
		catch (errorMessage) {
			this.bannerToastInfo.showMessage = true;
			this.bannerToastInfo.message = this.$t('order_management.error_refunding_order', { errorMessage });
		}
		finally {
			setTimeout(() => {
				this.loading.refund = false;
				this.mutableOrder.status = status;
			}, 500);
		}
	}

	/**
	 * Complete the order for other orders than suites catering and generic-catering.
	 * For suites catering we show a specific modal, same for generic-catering.
	 *
	 * @return {void}
	 */
	private completeOrder(): void {
		if(this.order.type === 'catering') {
			this.showModal.chargeOrInvoice = true;
		}
		else if (this.order.type === 'generic-catering') {
			this.showModal.genericCateringComplete = true;
		}
		else {
			this.updateOrderStatus('complete');
		}
	}
}
