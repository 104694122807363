
import { Component, Vue } from 'vue-property-decorator';
import { Storage } from '@/services/auth/storage';
import Auth from '@/services/auth/auth';
import TextButton from '@/components/shared/suites/TextButton.vue';

@Component<PinScreen>({
	components: {
		TextButton
	}
})
export default class PinScreen extends Vue {
	private storage: Storage = new Storage();
	private pin: string | null = null;
	private pinDisplay: string = this.$t('orders.pin_screen.input_placeholder');
	private error: string = '';
	private pinAttempts: number | null = null;
	private numberMaskTimeout: number | null = null;
	private disableSubmit: boolean = false;

	$refs!: {
		errorMsg: HTMLElement;
	};

	private get logoutBtnText(): string {
		return window.B2BApp ? this.$t('navbar.btn_back_to_app') : this.$t('navbar.btn_logout');
	}

	/**
	 * Get the pinAttempts from localStorage
	 * If the pinAttempts are not set, set them to 5
	 *
	 * @return {void}
	 */
	private created(): void {
		const attempts = localStorage.getItem('pinAttempts');
		if (attempts) {
			this.pinAttempts = +attempts;
		}
		else {
			this.storage.setUniversal('pinAttempts', '5');
			this.pinAttempts = 5;
		}
	}

	/**
	 * Update the pin with the given number
	 *
	 * @param {string} num - The number to add to the pin string
	 * @return {void}
	 */
	private updatePin(num: string): void {
		if (this.numberMaskTimeout) {
			clearTimeout(this.numberMaskTimeout);
		}
		this.pin = (this.pin ? this.pin.toString() : '') + num;

		// Display the pin with the last character visible
  		this.pinDisplay = this.pin.slice(0, -1).replace(/./g, '•') + this.pin.slice(-1);

		// After 750ms, replace the last character with a dot
		this.numberMaskTimeout = setTimeout(() => {
			this.pinDisplay = this.pin ? this.pin.replace(/./g, '•') : this.$t('orders.pin_screen.input_placeholder');
		}, 750);
	}

	/**
	 * Delete the last character from the pin
	 *
	 * @return {void}
	 */
	private deleteChar(): void {
		this.pin = this.pin ? this.pin.slice(0, -1) : null;
		this.pinDisplay = this.pin ? this.pin.replace(/./g, '•') : this.$t('orders.pin_screen.input_placeholder');
	}

	/**
	 * Handle the submission of the pin
	 * If the pin is valid, emit the pin-validated event
	 * If the pin is invalid, decrement the pinAttempts and display an error message
	 * If the pinAttempts reach 0, log the user out
	 *
	 * @return {void}
	 */
	private submitPin(): void {
		if (this.pin) {
			// If the pin is valid, emit the pin-validated event and reset pinAttempts and pinEntered
			if (Auth.validatePin(this.pin.toString())) {
				this.$emit('pin-validated');
				this.storage.setUniversal('pinAttempts', '5');
				this.storage.setUniversal('pinEntered', 'true');
			}
			else {
				// Decrement the pin attempts, if pinAttempts wasn't set, set it to 4 as there was an attempt made at this point
				this.pinAttempts ? this.pinAttempts-- : this.pinAttempts = 4;
				this.storage.setUniversal('pinAttempts', this.pinAttempts.toString());

				// If the pinAttempts reach 0, log the user out and reset the pinAttempts and pinEntered
				if (this.pinAttempts === 0) {
					this.error = this.$t('orders.pin_screen.error_max_attempts');
					this.disableSubmit = true;
					setTimeout(() => {
						window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
					}, 1500);
				}
				else {
					// If an repeated error occurs, shake the error message
					if (this.error) {
						this.$refs.errorMsg?.classList.add('shake');

						setTimeout(() => {
							this.$refs.errorMsg?.classList.remove('shake');
						}, 500);
					}
					this.pin = null;
					this.pinDisplay = this.$t('orders.pin_screen.input_placeholder');
					this.error = this.$t('orders.pin_screen.error_invalid_pin', { attempts: this.pinAttempts });
				}
			}
		}
		else {
			if (this.error) {
				this.$refs.errorMsg?.classList.add('shake');

				setTimeout(() => {
					this.$refs.errorMsg?.classList.remove('shake');
				}, 500);
			}
			this.error = this.$t('orders.pin_screen.error_no_pin');
		}
	}

	/**
	 * Reset pin attempts and log the user out
	 *
	 * @return {void}
	 */
	private handleLogout(): void {
		window.B2BApp ? window.B2BApp.backToApp() : Auth.logout();
	}
}
