
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { TOAST_INSTANCE } from '@/utils/constants';
import IconButton from '@/components/shared/suites/IconButton.vue';
import StatusLabel from '@/components/shared/suites/StatusLabel.vue';
import TextButton from '@/components/shared/suites/TextButton.vue';
import WarningModal from '@/components/shared/suites/WarningModal.vue';

@Component<PastEventDetailsTable>({
	components: {
		IconButton,
		StatusLabel,
		TextButton,
		WarningModal
	}
})
export default class PastEventDetailsTable extends Vue {
	@Prop({ type: Array, required: true, default: () => [] }) private eventSuites!: EventSuite[];
	@Action('finalizeEventDayOrders', { namespace: 'eventSuites' }) private finalizeEventDayOrders!: (eventSuite: EventSuite) => EventSuite;

	private isFinalizingOrders: boolean = false;
	private showFinalizeOrdersModal: boolean = false;
	private showDeclinedModal: boolean = false;
	private bannerToastInfo: ToastObject = TOAST_INSTANCE;
	private selectedEventSuite: EventSuite | null = null;

	/**
	* Get invoice status for past events
	* Isn't used now, but will be used when invoicing is implemented
	*
	* @param {SuiteStatusTypes} status - current status of event suite
	* @returns {string | null} - invoice status
	*/
	private getInvoiceStatus(status: SuiteStatusTypes): string | null {
		switch (status) {
			case 'email_sent':
			case 'email_scheduled':
			case 'not_configured':
				return this.$t('event_details.invoice_status.no_invoice');
			case 'accepted':
			case 'pending':
				return this.$t('event_details.invoice_status.not_ready');
			case 'completed':
				return this.$t('event_details.invoice_status.ready');
			default:
				return null;
		}
	}
	/**
	 * Sets event suite to finalize orders for and open modal
	 *
	 * @param {EventSuite} eventSuite - event suite to finalize orders for
	 * @returns {void}
	 */
	private openFinalizeOrdersModal(eventSuite: EventSuite): void {
		this.selectedEventSuite = eventSuite;
		this.showFinalizeOrdersModal = true;
	}

	/**
	 * Close finalize orders modal and reset selected event suite
	 *
	 * @returns {void}
	 */
	private closeFinalizeOrdersModal(): void {
		this.showFinalizeOrdersModal = false;
		this.selectedEventSuite = null;
	}

	/**
	 * Finalize orders for event suite and show declined modal if payment preference was changed from charge to invoice
	 *
	 * @returns {Promise<void>}
	 */
	private async handleFinalizeOrders(): Promise<void> {
		if (this.selectedEventSuite) {
			this.isFinalizingOrders = true;
			try {
				const updatedEventSuite = await this.finalizeEventDayOrders(this.selectedEventSuite);
				this.selectedEventSuite.event_day_orders_complete = true;
				this.showFinalizeOrdersModal = false;

				// If the payment preference was changed from charge to invoice when finalizing orders, show the declined modal
				if (this.selectedEventSuite.event_day_payment_preference === 'charge' && updatedEventSuite.event_day_payment_preference === 'invoice') {
					this.showDeclinedModal = true;
				}
				this.selectedEventSuite = null;
			}
			catch (errorMessage) {
				this.bannerToastInfo.showMessage = true;
				this.bannerToastInfo.message = this.$t('event_details.error_finalizing_orders', { errorMessage });
			}
			finally {
				this.isFinalizingOrders = false;
			}
		}
	}
}
