export const MENU_ITEM = {
	id: 0,
	section_id: 0,
	menu_id: 0,
	name: '',
	description: '',
	ingredients: '',
	slug: '',
	sku: '',
	category: '',
	calories: null,
	price: '',
	price_unit: '',
	price_type: '',
	image: '',
	includes: '',
	allergens: '',
	diets: '',
	is_hidden: false,
	options: [],
	addons: [],
	sold_out: false,
	tax_rebate_eligibility: 'eligible' as const,
	auto_restock: 'disabled' as const,
	order: 0,
	published_at: ''
};

export const OPTION = {
	name: '',
	price: null,
	calories: null,
	max_quantity: 1
};