import { ActionTree } from 'vuex';
import { SuiteOwnerState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance, AxiosResponse } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';

export const actions: ActionTree<SuiteOwnerState, RootState> = {
	/**
	 * Fetch all suite owners
	 *
	 * @return {Promise<void>}
	 */
	async fetchSuiteOwners({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const suiteOwners: AxiosResponse = await axiosInst.get('/catering/suite-owners');
			commit('SET_SUITE_OWNERS', suiteOwners.data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create a new suite owner after formatting the payload accordingly
	 *
	 * @param {EditableSuiteOwner} suiteOwner
	 * @return {Promise<SuiteOwner|void>}
	 */
	async addSuiteOwner({ commit }, suiteOwner: EditableSuiteOwner): Promise<SuiteOwner | void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		const { firstName, lastName, email, company_name, title, phone_number } = suiteOwner;
		const formattedSuiteOwner: SuiteOwnerPayload = {
			firstName,
			lastName,
			email,
			company_name: company_name ? company_name : undefined,
			phone_number: phone_number ? phone_number : undefined,
			title: title ? title : undefined
		};

		try {
			const { data }: AxiosResponse = await axiosInst.post('/catering/suite-owners', formattedSuiteOwner);
			commit('ADD_SUITE_OWNER', data);
			return data;
		}
		catch (error) {
			handleAllErrors(error);
			return;
		}
	},

	/**
	 * Update a suite owner
	 *
	 * @param {EditableSuiteOwner} suiteOwner
	 * @return {Promise<void>}
	 */
	async updateSuiteOwner({ commit }, suiteOwner: EditableSuiteOwner): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const { id, firstName, lastName, email, company_name, title, phone_number } = suiteOwner;
		const formattedSuiteOwner: SuiteOwnerPayload = {
			firstName,
			lastName,
			email,
			company_name: company_name ? company_name : '',
			phone_number: phone_number ? phone_number : '',
			title: title ? title : ''
		};
		try {
			const { data }: AxiosResponse = await axiosInst.put(`/catering/suite-owners/${id}`, formattedSuiteOwner);
			commit('UPDATE_SUITE_OWNER', data);
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
