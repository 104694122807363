import { ActionTree } from 'vuex';
import { SuiteState, RootState } from '../types';
import { state as authState } from '../auth/auth';
import { AxiosInstance, AxiosResponse } from 'axios';
import { handleAllErrors } from '@/utils/errorHandling';
import { decodeNameOfEntity } from '@/utils/decoding';

export const actions: ActionTree<SuiteState, RootState> = {
	/**
	 * Fetch all suites
	 *
	 * @return {Promise<void>}
	 */
	async fetchSuites({ commit }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			const { data }: AxiosResponse = await axiosInst.get('/catering/suites');
			const decodedSuites: Suite[] = data.map((suite: Suite) => decodeNameOfEntity(suite));
			commit('SET_SUITES', decodedSuites);
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Create a new suite
	 *
	 * @param {{ name: string }} suite
	 * @return {Promise<void>}
	 */
	async addSuite({ commit }, suite: { name: string }): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;

		try {
			const { data }: AxiosResponse = await axiosInst.post('/catering/suites', suite);
			commit('ADD_SUITE', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Update a suite
	 *
	 * @param {Suite} suite
	 * @return {Promise<void>}
	 */
	async updateSuite({ commit }, suite: Suite): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		const { id, name } = suite;
		try {
			const { data }: AxiosResponse = await axiosInst.put(`/catering/suites/${id}`, { name });
			commit('UPDATE_SUITE', decodeNameOfEntity(data));
		}
		catch (error) {
			handleAllErrors(error);
		}
	},

	/**
	 * Delete a suite
	 *
	 * @param {number} suiteId
	 * @return {Promise<void>}
	 */
	async deleteSuite({ commit }, suiteId: number): Promise<void> {
		const axiosInst: AxiosInstance = authState.axiosInst;
		try {
			await axiosInst.delete(`/catering/suites/${suiteId}`);
			commit('DELETE_SUITE', suiteId);
		}
		catch (error) {
			handleAllErrors(error);
		}
	}
};
