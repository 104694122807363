import { MutationTree } from 'vuex';
import { EventState } from '../types';
import Vue from 'vue';

export const mutations: MutationTree<EventState> = {
	/**
	 * Set events
	 *
	 * @param {EventState} state
	 * @param {SuitesEvent[]} events
	 */
	SET_EVENTS(state, events: Event[]) {
		Vue.set(state, 'events', events);
	},

	/**
	 * Update the corresponding event
	 *
	 * @param {EventState} state
	 * @param {SuitesEvent} updatedEvent
	 */
	UPDATE_EVENTS_LIST(state, updatedEvent: SuitesEvent) {
		Vue.set(state, 'events', state.events.map(event => event.id === updatedEvent.id ? updatedEvent : event));
	},

	/**
	 * Set selected event
	 *
	 * @param {EventState} state
	 * @param {SuitesEvent|null} event
	 */
	SET_SELECTED_EVENT(state, event: SuitesEvent|null) {
		Vue.set(state, 'selectedEvent', event);
	},

	/**
	 * Set deep event data
	 *
	 * @param {EventState} state
	 * @param {DeepSuitesEvent} deepEvent
	 */
	SET_DEEP_EVENT(state, deepEvent: DeepSuitesEvent) {
		Vue.set(state, 'deepEvent', deepEvent);
	}
};